import { Coin } from '@cosmjs/amino';
import { MsgExecuteContract } from '@cosmjs/cosmwasm';
import { Network } from 'core/constants';

type BaseMsgParams = {
  networkType: Network;
  address: string;
};
type MsgParams = BaseMsgParams & {
  [key: string]: any;
};

const formMsgExecuteContract = (
  sender: string,
  contract: string,
  msg: any,
  sent_funds?: Coin[]
) => {
  return {
    type: 'wasm/MsgExecuteContract',
    value: {
      sender,
      contract,
      msg,
      sent_funds,
    },
  } as MsgExecuteContract;
};

export const whitelistMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nftMinter, {
    whitelist: {},
  });
};

export const customMintMsg = (params: MsgParams) => {
  return formMsgExecuteContract(
    params.address,
    params.nftMinter,
    {
      mint: {},
    },
    params.coins
  );
};

export const nftStakeMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft, {
    send_nft: {
      contract: params.nft_staking,
      token_id: params.token_id,
      msg: btoa(
        JSON.stringify({
          stake: {},
        })
      ),
    },
  });
};

export const nftUnstakeMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft_staking, {
    unstake: {
      nft_contract_addr: params.nft,
      nft_token_id: params.token_id,
    },
  });
};

export const nftSellMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft, {
    send_nft: {
      contract: params.nft_sale,
      token_id: params.token_id,
      msg: btoa(
        JSON.stringify({
          deposit: {
            denom: params.denom,
            amount: params.amount,
          },
        })
      ),
    },
  });
};

export const nftCancelSellMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft_sale, {
    withdraw: {
      nft_contract_addr: params.nft,
      nft_token_id: params.token_id,
    },
  });
};

export const nftAuctionMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft, {
    send_nft: {
      contract: params.nft_auction,
      token_id: params.token_id,
      msg: btoa(
        JSON.stringify({
          start_auction: {
            end_time: params.end_time,
            coin_denom: params.denom,
            min_amount: params.amount,
          },
        })
      ),
    },
  });
};

export const nftCancelAuctionMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft_auction, {
    cancel_auction: {
      token_id: params.token_id,
      token_address: params.nft,
    },
  });
};

export const nftClaimAuctionMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft_auction, {
    claim: {
      token_id: params.token_id,
      token_address: params.nft,
    },
  });
};

export const buySaleNFTMsg = (params: MsgParams) => {
  return formMsgExecuteContract(
    params.address,
    params.nft_sale,
    {
      buy: {
        nft_contract_addr: params.nft,
        nft_token_id: params.token_id,
      },
    },
    params.coins
  );
};

export const bidAuctionNFTMsg = (params: MsgParams) => {
  return formMsgExecuteContract(
    params.address,
    params.nft_auction,
    {
      place_bid: {
        token_address: params.nft,
        token_id: params.token_id,
      },
    },
    params.coins
  );
};

export const nftTransferMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nft, {
    transfer_nft: {
      recipient: params.recipient,
      token_id: params.token_id,
    },
  });
};

export const nftStakeClaimMsg = (params: MsgParams) => {
  return formMsgExecuteContract(params.address, params.nftStaking, {
    claim: {
      nft_contract_addr: params.nft,
      nft_token_id: params.token_id,
    },
  });
};
