import React, { useEffect } from 'react';
import Modal from '../Modal';

import CompleteIcon from '../../assets/images/complete.svg';
import styles from './notification.module.scss';
import { useHistory } from 'react-router-dom';
import DataLayer from 'components/DataLayer/DataLayer';

const Complete = ({
  setTheme,
  txHash,
  redirectURL,
  txLogs,
  transactionInfo,
}: {
  setTheme: any;
  txHash: string | undefined;
  redirectURL: string | undefined;
  txLogs:any;
  transactionInfo?:any
}) => {
  const history = useHistory();
  function onClose() {
    setTheme({
      type: undefined,
      subType: undefined,
    });
    if (redirectURL) {
      history.push(redirectURL);
      redirectURL === "/mynft" && window.location.reload();
    } else {
      window.location.reload();
    }
  }

  return (
    <>
    <DataLayer
    txHash={txHash}
    txLogs={txLogs}
    transactionInfo={transactionInfo}
    />
    <Modal isOpen={true} title='' onClose={() => onClose()} zIndex={4} center>
      <div className={styles.notification_content}>
        <img src={CompleteIcon} alt='wait' />
        <p className={styles.title}>Complete!</p>
        <div className={styles.description}>
          Your transaction is successful!
        </div>
        <div className={styles.transactionId}>
          Transaction ID:
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://www.mintscan.io/juno/txs/${txHash}`}
          >
            {txHash}
          </a>
        </div>
        {/*<button className='view-nft' onClick={() => onClose()}>*/}
        {/*  ViewNFT*/}
        {/*</button>*/}
      </div>
    </Modal>
    </>
  );
};

export default Complete;
