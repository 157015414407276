import Modal from '../../../components/Modal';
import styles from './notification.module.scss';

const WebAppInProgressError = ({setTheme}: { setTheme: any }) => {
  function onClose() {
    setTheme({
      type   : undefined,
      subType: undefined,
    });
  }

  return (
    <Modal isOpen={true} title='' onClose={() => onClose()} zIndex={4} center webApp>
      <div className={styles.notification_content}>
        <div className={styles.image_content}>
          <p className={styles.title}>Unexpected Delays</p>
          <div style={{textAlign: 'center', marginBottom: '50px'}}>
            Transaction has been submitted but is taking longer than expected.
            <br/>
            Please verify in a few minutes
          </div>
          <button style={{background: 'transparent', color: '#787B9C', position: 'absolute', bottom: '20px'}} onClick={() => onClose()}>CLOSE</button>
        </div>
      </div>
    </Modal>
  );
};

export default WebAppInProgressError;
