import {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {networkType, TICKET_COLLECTIONS} from 'core/nftConfig';
import useLaunchPadNFTMinterQuery from 'hooks/useLaunchPadNFTMinterQuery';
import {useRecoilValue} from 'recoil';
import {walletState} from 'state/walletAtoms';
import * as api from '../../../api/launchpad.api';
import WebAppLaunchpadForm from "./webapp.mint.form";
import ProgressBar from '@ramonak/react-progress-bar';
import { formatNumberString } from 'utils/conversion/conversion';
import CountdownComp from './countdown';

const WebAppEventDetail = (props: any) => {
  const [mintModal, setMintModal] = useState<any>();
  const history = useHistory();
  const params                  = useParams<any>();
  const [launchpadNFT, setData] = useState<any>();
  const {address}               = useRecoilValue(walletState);
  const queryResult             = useLaunchPadNFTMinterQuery(networkType, launchpadNFT?.bookingInfo.MINTER, address);
  const totalMinted      =
          queryResult?.tier_infos.reduce(
            (res, cur) => res + parseInt(cur.current_supply),
            0
          ) || 0;
  const totalMintRemains =
    (queryResult?.mintConfig.nft_max_supply || 0) -
    (queryResult?.mintConfig.nft_current_supply || 0);

  useEffect(() => {
    if (!launchpadNFT) {
      api.FetchTicket(params.address).then(launchpadNFT => setData(launchpadNFT));
    }
  }, [launchpadNFT, params]);

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const eventLocalData = TICKET_COLLECTIONS.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))


  return (
    <>
      {launchpadNFT && (
        <>
          <div className='webapp_launchViewHeader'>
            <div className='launchViewLeft'>
              <div className='nft_view'>
                <div className='block'>
                  <div
                    onClick={() => mintModal && setMintModal(false)}
                    style={mintModal ? { filter: 'blur(10px)', transition: 'ease-in-out .3s' } : {transition: 'ease-in-out .3s'}}
                  >
                    <div className='daynamicSlider'>
                      <img
                        style={{borderRadius: '20px'}}
                        src={'https://loop-markets.directus.app/assets/' + launchpadNFT?.banner.id}
                        alt='rect'
                      />
                    </div>

                    <div
                      className='webapp_back'
                      onClick={() => history.push('/webapp/ticketdemo')}
                    >
                      <img src='/webapp_back.svg' alt='aro_lft.png' style={{
                        background: '#1a193099',
                        marginRight: '10px',
                        zIndex: '99999',
                        position: 'absolute',
                        top: '16px',
                        left: '16px',
                        padding: '15px 19px',
                        borderRadius: '10px',
                        width: '55px'
                        }}
                      />
                    </div>
                    <div className='webapp_project'>
                      <div className='project_left'>
                        {launchpadNFT.project_status == 'live' && (
                          <div className="date">
                            <span className="day">{new Date(launchpadNFT.bookingInfo.startDate).getUTCDate()}</span>
                            <span className="month">{monthNames[new Date(launchpadNFT.bookingInfo.startDate).getUTCMonth()]}</span> 
                          </div>
                        )}
                      </div>
                      <div className='project_right'>
                        <p className="title">{launchpadNFT.ticket_name}</p>
                        <div>
                          <span>{eventLocalData[0].location}</span>
                          <span>•</span>
                          <span>{eventLocalData[0].time} onwards</span>
                          <img src="/m_clock_gray.svg" />
                        </div>
                      </div>
                    </div>

                    <div className="webapp_container">
                      <div className="tickets">
                        <p style={{display: 'flex', gap: '5px', alignItems: 'center'}}><img src="/webapp_ticket.svg" style={{width: '20px'}} /> Total Tickets</p>
                        <p>{formatNumberString(totalMinted + totalMintRemains)}</p>
                      </div>
                      <div className="ticket_progress">
                        <ProgressBar
                          className='ProgressBar'
                          barContainerClassName='container'
                          bgColor='#32FE9A'
                          height='5px'
                          completed={
                            ((totalMinted || 0) * 100) /
                            (totalMinted + totalMintRemains || 1)
                          }
                          customLabel=' '
                        />
                      </div>
                      <div className="tickets">
                        <p style={{display: 'flex', gap: '5px', alignItems: 'center'}}><img src="/m_clock_gray.svg" style={{width: '20px'}} /> Mint Ends</p>
                        <p><span>{<CountdownComp item={launchpadNFT} />}</span></p>
                      </div>
                    </div>

                    <div className='webapp_project_about'>
                      <h6>About Event</h6>
                      <p>{launchpadNFT.about}</p>
                    </div>
                  </div>
                  <div className='launchViewRight'>
                    {launchpadNFT.project_status === "live" && (
                      <WebAppLaunchpadForm
                        queryResult={queryResult}
                        launchpadNFT={launchpadNFT}
                        mintModal={mintModal}
                        setMintModal={setMintModal}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WebAppEventDetail;
