import {NFTMinterQuery} from 'hooks/useLaunchPadNFTMinterQuery';
import {LaunchPadNFT} from 'core/nftConfig';
import {useRecoilValue} from 'recoil';
import {walletState} from 'state/walletAtoms';
import {useJunoTokenBalance} from 'hooks/newHooks/useTokenBalance';
import {Dec} from '@terra-money/terra.js';
import {formatNumberString} from 'utils/conversion/conversion';
import {Link} from "react-router-dom";
import {useJunoConnectedWallet} from "../../../hooks/newHooks/useJunoConnectedWallet";
import Wallet from "../../../components/Wallet";
import WebAppEventBreakDown from './WebAppEventBreakDown';
import { useState } from 'react';

const WebAppLaunchpadForm = ({queryResult,launchpadNFT,mintModal,setMintModal}: {queryResult?: NFTMinterQuery; launchpadNFT: LaunchPadNFT; mintModal?: any; setMintModal?: any; }) => {
  const {mutate: connectWallet}                 = useJunoConnectedWallet();
  const {address}                               = useRecoilValue(walletState);
  const {balance}: any                          = useJunoTokenBalance('USDC') || {balance: 0};

  const isMintStart     =
          queryResult &&
          queryResult.mintConfig.mint_start_time !== 0 &&
          new Date().getTime() / 1000 > queryResult.mintConfig.mint_start_time;
  const isInWhitelist   =
          isMintStart &&
          new Date().getTime() / 1000 <
          queryResult.mintConfig.mint_start_time +
          queryResult.mintConfig.whitelist_mint_period;
  const isWhitelistMint =
          isMintStart &&
          queryResult.is_whitelisted &&
          new Date().getTime() / 1000 <
          queryResult.mintConfig.mint_start_time +
          queryResult.mintConfig.whitelist_mint_period;
  const isPublicMint    =
          isMintStart &&
          new Date().getTime() / 1000 >=
          queryResult.mintConfig.mint_start_time +
          queryResult.mintConfig.whitelist_mint_period &&
          new Date().getTime() / 1000 <
          queryResult.mintConfig.mint_start_time +
          queryResult.mintConfig.whitelist_mint_period +
          queryResult.mintConfig.public_mint_period;
  let mintableCount     = queryResult
    ? isPublicMint
      ? queryResult.mintConfig.public_mint_limit - queryResult.supplyByAddress
      : isWhitelistMint
        ? queryResult.mintConfig.whitelist_mint_limit -
        queryResult.supplyByAddress
        : 0
    : 0;
  if (
    (queryResult?.mintConfig.nft_max_supply || 0) -
    (queryResult?.mintConfig.nft_current_supply || 0) <
    mintableCount
  ) {
    mintableCount =
      (queryResult?.mintConfig.nft_max_supply || 0) -
      (queryResult?.mintConfig.nft_current_supply || 0);
  }

  return (
    <>
      <div className='nft_view' style={{padding: '20px', borderTop: '3px solid rgb(58, 53, 87)'}}>
        <footer className='footer'>
          <div className='price'>
            <b>
              <img className="ticket_icon" src="/webapp_ticket.svg" />
            </b>
            <button
              className={balance < 0 ? 'buyNowButton disabled' : 'buyNowButton'}
              onClick={() => setMintModal(true)}>
              Get Ticket
            </button>
            {mintModal && (
              <WebAppEventBreakDown launchpadNFT={launchpadNFT} />
            )}
          </div>

        </footer>
      </div>
    </>

  );
};

export default WebAppLaunchpadForm;
