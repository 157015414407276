import axios from 'axios';
const hostname = window.location.hostname;
const checkHost = hostname === 'nft-juno.loop.markets';

// Fetch Launchpad info for card
export async function quesyLaunchpadInfo({
  id,
  isFans,
}: {
  id: string;
  isFans: boolean;
}) {
  if (isFans) {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}fans/fans_launchpad/detail/${id}`
    );
    return result.data;
  } else {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}launchpad/${id}`
    );
    return result.data;
  }
  return;
}

export async function FetchFanLaunchPadLiveList() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/fans_launchpad?filter[project_status][_eq]=live&sort[]=-date_created&fields=*.*`
  );
  return result.data.data;
}

export async function FetchLaunchPadLiveList() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/launchpad?filter[project_status][_eq]=live&sort[]=-date_created&fields=*.*`
  );
  return result.data.data;
}

export async function FetchLaunchPadUpcoming() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/launchpad?filter[project_status][_eq]=upcoming&sort=launchInfo.startDate&fields=*.*`
  );
  return result.data.data;
}

export async function FetchLaunchPad(name: string, status: string) {
  if (checkHost) {
    const result = await axios.get(
      `https://loop-markets.directus.app/items/launchpad?filter[project_slug][_eq]=${name}&fields=*.*`
    );
    return result.data.data[0];
  } else {
    const result = await axios.get(
      `https://loop-markets.directus.app/items/launchpad?filter[project_slug][_eq]=${name}&fields=*.*`,
      {
        headers: {
          Authorization: 'Bearer ' + '6p-AeaC0Vz25GVx2bodqsXa6a96YqAkb',
        },
      }
    );
    return result.data.data[0];
  }
}

export async function FetchQC() {
  if (checkHost) {
    const result = await axios.get(
      `https://loop-markets.directus.app/items/launchpad?filter[status][_eq]=qc&sort=launchInfo.startDate&fields=*.*`
    );
    return result.data.data;
  } else {
    const result = await axios.get(
      `https://loop-markets.directus.app/items/launchpad?filter[status][_eq]=qc&sort=launchInfo.startDate&fields=*.*`,
      {
        headers: {
          Authorization: 'Bearer ' + '6p-AeaC0Vz25GVx2bodqsXa6a96YqAkb',
        },
      }
    );
    return result.data.data;
  }
}

export async function FetchTicketBooking() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/ticket_booking?sort[]=-date_created&fields=*.*`
  );
  return result.data.data;
}

export async function FetchTicket(name: string) {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/ticket_booking?filter[project_slug][_eq]=${name}&fields=*.*`
  );
  return result.data.data[0];
}

export async function FetchTicketData(name: string) {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/ticket_booking?filter[project_slug][_eq]=${name}&fields=project_slug,bookingInfo.*,banner.id`
  );
  return result.data.data[0];
}

export async function fetchHotCollections() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/nft_collections`
  );
  return result.data.data;
}

export async function FetchJunoTokenUnitPrice() {
  const result = await axios.get(
    `https://middlewareapi.loop.markets/v1/juno/tokenInfo`
  );
  return result?.data;
}
