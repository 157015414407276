  import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useJunoConnectedWallet } from 'hooks/newHooks/useJunoConnectedWallet';
import {useRecoilState, useRecoilValue} from 'recoil';
import {openWalletModalState, walletState, WalletStatusType} from 'state/walletAtoms';
import { useWalletManager } from '@noahsaso/cosmodal';
import {useWallet} from "../../Loop_Reuseable_Component/packages/loop_wallet";

const walletConnected = ({
  type,
  connected,
  noNft,
  sale,
  bid,
  isWebApp
}: {
  type?: string;
  connected: boolean;
  noNft?: boolean;
  sale?: boolean;
  bid?: boolean;
  isWebApp?: boolean;
}) => {
  const { mutate: connectWallet } = useJunoConnectedWallet();
  const { connect, connectedWallet } = useWalletManager();
  const { status } = useRecoilValue(walletState);
  const { address } =  useWallet()
  const isConnected = address?.length > 0 ?? status === WalletStatusType.connected;
  const [, setOpenModalState,] = useRecoilState(openWalletModalState);

  const startConnect = async () => {
    connect();
  };

  useEffect(() => {
    if (!isConnected && connectedWallet?.address) {
      connectWallet(null);
    }
  }, [connectWallet, connectedWallet, connectedWallet?.address, isConnected]);

  return (
    <section>
      <div className={'notConnected'}>
        {connected ? (
          type === 'owned' && !noNft ? (
            <div>
              <img src='/no_nft.svg' />
              <h1>Oh no!</h1>
              <p>There are no NFTs in this wallet yet!</p>
              <p>
                Buy from the marketplace or mint on the launchpad to get your
                NFT collection started today!
              </p>
              {!isWebApp && (
                <div className='buttons'>
                  <Link to='../nftExplore'>
                    <button className='ConnectWallet'>MARKETPLACE</button>
                  </Link>
                  <Link to='../launchpad'>
                    <button className='ConnectWallet'>LAUNCHPAD</button>
                  </Link>
                </div>
              )}
            </div>
          ) : type === 'sale' && !sale ? (
            <div>
              <img src='/no_nft.svg' />
              <h1>No Nfts on sale</h1>
              {!isWebApp && (
                <div className='buttons'>
                  <Link to='../mynft'>
                    <button className='ConnectWallet'>View MyNFTs</button>
                  </Link>
                </div>
              )}
            </div>
          ) : (
            type === 'bids' &&
            !bid ? (
              <div>
                <img src='/no_nft.svg' />
                <h1>No Available Bids</h1>
                {!isWebApp && (
                  <div className='buttons'>
                    <Link to='../nftExplore'>
                      <button className='ConnectWallet'>
                        Explore MARKETPLACE
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              type === 'tickets' && (
                <div>
                  <img src='/no_nft.svg' />
                  <h1>No Available Tickets</h1>
                </div>
              )
            )
          )
        ) : (
          <div>
            <img src='/connect.svg' />
            <h1>Connect Wallet</h1>
            <p>Explore your Loop DeFi NFTs, bids and activity</p>
            <button className='ConnectWallet' onClick={() => setOpenModalState(true)}>
              <img src='/connect_icon.svg' style={{ marginRight: '5px' }} />{' '}
              CONNECT WALLET
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default walletConnected;
