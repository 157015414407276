import { useCallback, useContext, useEffect, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { NFTMinterQuery } from 'hooks/useLaunchPadNFTMinterQuery';
import { customMintMsg } from 'helper/junoContractMsg';
import { LaunchPadNFT, networkType } from 'core/nftConfig';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { useJunoTokenBalance } from 'hooks/newHooks/useTokenBalance';
import { Dec } from '@terra-money/terra.js';
import CountdownComp from './countdown';
import { formatNumberString } from 'utils/conversion/conversion';
import { DirectusImage, getIpfsUrl, symbolToDenom } from 'core/common';
import { FetchJunoTokenUnitPrice } from 'api/launchpad.api';
import styles from './view.module.scss';
import fav from '../../../../assets/fans/fav.svg';
import notify from '../../../../assets/fans/notify.svg';
import share from '../../../../assets/fans/share.svg';
import Modal from 'components/Modal';
import ShareButton from 'components/SocialShare/shareButton';
import handleFansTransaction from 'hooks/handleFansTransaction';
import { NotificationContext } from 'contexts/notification';

const curDate = new Date().toISOString();

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const LaunchpadForm = ({
  queryResult,
  launchpadNFT,
  index,
  setPaymentModal,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT: LaunchPadNFT;
  index?: Number;
  setPaymentModal?: any;
}) => {
  const { MintNft } = handleFansTransaction();
  const [pausedModal, setPausedModal] = useState(false);
  const { address } = useRecoilValue(walletState);

  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo.mintPrice?.split(' ') || ['', 'USDC'];
  const { balance } = useJunoTokenBalance(symbol);

  const contractAddr =
    launchpadNFT?.launchpad_type[Number(index)]?.launchInfo?.NFT;

  const mintStarted =
    curDate >=
    new Date(
      launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.startDate
    ).toISOString();

  const mintEnded =
    curDate >=
    new Date(
      launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
    ).toISOString();

  const [mintCount, setMintCount] = useState(1);

  const totalMinted = Math.max(
    queryResult?.tier_infos.reduce(
      (res, cur) => res + parseInt(cur.current_supply),
      0
    ) || 0,
    queryResult?.mintConfig.nft_current_supply || 0
  );
  const totalMintRemains =
    (queryResult?.mintConfig.nft_max_supply || 0) -
    (queryResult?.mintConfig.nft_current_supply || 0);

  const isMintStart =
    queryResult &&
    queryResult.mintConfig.mint_start_time !== 0 &&
    new Date().getTime() / 1000 > queryResult.mintConfig.mint_start_time;
  // console.log('isMintStart = ', isMintStart);
  const isInWhitelist =
    isMintStart &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period;
  const isWhitelistMint =
    isMintStart &&
    queryResult.is_whitelisted &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period;
  // console.log('isWhitelistMint = ', isWhitelistMint);
  const isPublicMint =
    isMintStart &&
    new Date().getTime() / 1000 >=
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period +
        queryResult.mintConfig.public_mint_period;
  // console.log('isPublicMint = ', isPublicMint);
  let mintableCount = queryResult
    ? isPublicMint
      ? queryResult.mintConfig.public_mint_limit - queryResult.supplyByAddress
      : isWhitelistMint
      ? queryResult.mintConfig.whitelist_mint_limit -
        queryResult.supplyByAddress
      : 0
    : 0;
  // console.log('mintableCount = ', mintableCount);
  if (
    (queryResult?.mintConfig.nft_max_supply || 0) -
      (queryResult?.mintConfig.nft_current_supply || 0) <
    mintableCount
  ) {
    mintableCount =
      (queryResult?.mintConfig.nft_max_supply || 0) -
      (queryResult?.mintConfig.nft_current_supply || 0);
  }

  const mintHandler = () => {
    if (launchpadNFT?.mint_status === 'paused') {
      setPausedModal(true);
    } else {
      MintNft({
        collection_addr: contractAddr,
        address: address,
        redirectTo: '/fans/mynft',
        mint_type: 'free_claim'
      });
    }
  };

  const mintStatus =
    queryResult?.mintConfig.nft_current_supply ===
    queryResult?.mintConfig.nft_max_supply
      ? queryResult?.mintConfig.nft_max_supply === 0
        ? 'LIVE'
        : 'SOLD OUT'
      : isMintStart
      ? isPublicMint || isWhitelistMint
        ? 'LIVE'
        : isInWhitelist
        ? 'UPCOMING'
        : 'COMPLETED'
      : 'UPCOMING';

  const EndDate = () => {
    return (
      <p
        className={styles.endDate}
        style={{ textAlign: 'center', paddingTop: '10px' }}
      >
        End Date:{' '}
        <b>
          {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCDate()}{' '}
          {
            monthNames[
              new Date(
                launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
              ).getUTCMonth()
            ]
          }{' '}
          {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCFullYear()}
        </b>
      </p>
    );
  };

  return (
    <div>
      <>
        <div className={styles.project_social}>
          <img src={notify} alt='thumb' />
          <img src={fav} alt='thumb' />
          <ShareButton name={launchpadNFT?.project_name} icon={share} />
        </div>

        <div className={styles.nft_view}>
          <div className={styles.header}>
            <h3>
              {launchpadNFT?.launchpad_type[Number(index)].collection.name}
            </h3>
            <p
              className={styles.about}
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)].collection
                    .description,
              }}
            />
            <div className={styles.artist}>
              <div className={styles.profile}>
                <img
                  src={DirectusImage(launchpadNFT?.artist.avatar)}
                  alt='thumb'
                />
                <div>
                  <span>CREATOR</span>
                  <p>{launchpadNFT?.artist.first_name}</p>
                </div>
              </div>
              <div className={styles.divider} />
              <div>
                <span>COLLECTION TYPE</span>
                <p>{launchpadNFT?.collection_type}</p>
              </div>
            </div>
            <div className={styles.benefits}>
              <span>Benefits</span>
              <div className={styles.benefit_details}>
                {launchpadNFT?.launchpad_type[
                  Number(index)
                ]?.benefits?.benefit?.map((r: any, i: number) => {
                  return (
                    <label key={i}>
                      <p>
                        <img src={`/fans/${r.icon}.svg`} alt='' />{' '}
                        <b>{r?.name}</b>
                      </p>
                    </label>
                  );
                })}
              </div>
            </div>
            {mintStarted ? (
              <>
                <div className={styles.mint_live}>
                  <div>
                    <p className={styles.price_text}>price</p>
                    <p className={styles.price_value}>
                      {price == 0 ? `Free` : `$${price} ${symbol}`}
                    </p>
                    <p className={styles.convert} hidden={price == 0}>
                      243,000 <span>COP</span>
                    </p>
                  </div>
                  {mintEnded ? (
                    <button className={styles.buy} disabled>
                      Mint Ended
                    </button>
                  ) : (
                    <button
                      disabled={!address}
                      onClick={() =>
                        price == 0 ? mintHandler() : setPaymentModal(true)
                      }
                      className={styles.buy}
                    >
                      {launchpadNFT?.mint_status === 'paused'
                        ? 'MINTING PAUSED'
                        : price == 0
                        ? `MINT NOW`
                        : `BUY NOW`}
                    </button>
                  )}
                </div>
                <EndDate />
                <Modal
                  isOpen={pausedModal}
                  title=''
                  onClose={() => setPausedModal(false)}
                  customClass='pauseModal'
                >
                  <div
                    style={{ textAlign: 'center', marginBottom: '50px' }}
                    className='pauseCard'
                  >
                    <h3>
                      Mint is currently paused, minting will continue again soon
                    </h3>
                  </div>
                </Modal>
              </>
            ) : (
              <div className={styles.mint_live}>
                <span className={styles.countdown}>
                  <CountdownComp
                    launchpadNFT={launchpadNFT}
                    index={Number(index)}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default LaunchpadForm;
