// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignoreimport { useEffect, useRef, useState } from "react"


import {QueryClientProvider} from 'react-query';
import {QueryParamProvider} from 'use-query-params';
import App from './App';
import './index.scss';
import './styles/App.scss';
import './styles/upgrades.scss';
import {RecoilRoot} from 'recoil';
import { createRoot } from "react-dom/client"
import {BrowserRouter as Router, Route,BrowserRouter} from 'react-router-dom';
import {queryClient} from 'services/queryClient';
import {
    WalletManagerProvider,
    ChainInfoID,
    WalletType,
} from '@noahsaso/cosmodal';
import Boundary from "./components/Boundary";
import { WalletProvider } from "./Loop_Reuseable_Component/packages/loop_wallet"
const LOCAL_STORAGE_KEY = 'connectedUserWallet';

const root = createRoot(document.getElementById("root"))

// @ts-ignore
root.render(
    <BrowserRouter>
    <RecoilRoot>
        <Boundary>
            <Router>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <QueryClientProvider client={queryClient}>
                        <WalletManagerProvider
                            defaultChainId={ChainInfoID.Juno1}
                            localStorageKey={LOCAL_STORAGE_KEY}
                            enabledWalletTypes={
                                window.innerWidth < 820
                                    ? [WalletType.WalletConnectKeplr]
                                    : [WalletType.Keplr, WalletType.WalletConnectKeplr]
                            }
                            classNames={{
                                modalOverlay: 'modalOverlay',
                                modalContent: 'modalContent',
                                modalHeader: 'modalHeader',
                                wallet: 'wallet',
                                walletName: 'walletName',
                                walletDescription: 'walletDescription',
                            }}
                        >
                            {/* <WalletConnectProvider> */}
                            {/* <Network> */}
                            <WalletProvider>
                            <App/>
                            </WalletProvider>
                            {/* </Network> */}
                            {/* </WalletConnectProvider> */}
                        </WalletManagerProvider>
                    </QueryClientProvider>
                </QueryParamProvider>
            </Router>
        </Boundary>
    </RecoilRoot>
    </BrowserRouter>
    // document.getElementById('root')
);
