import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import { networkType, nftConfig } from 'core/nftConfig';
import { Link } from 'react-router-dom';
import * as api from '../../api/launchpad.api';
import { useState, useEffect } from 'react';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const Staging = () => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
  const [launchpadList, setLaunchpadList] = useState<any>();

  useEffect(() => {
    if (!launchpadList) {
      api.FetchQC().then(launchpadList => setLaunchpadList(launchpadList));
    }
  }, [launchpadList]);


  return (
    <>
    {launchpadList && launchpadList.length > 0 && (
      <div className='launchUcomingProject'>
        <h6>Quality Control</h6>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          navigation
          //loop
          autoplay={{ delay: 7000 }}
          className='feedSwiper'
          slideToClickedSlide
          breakpoints={{
            1200: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
          }}
        >
        {launchpadList.map((item:any, index:number) => {
            return (
              <SwiperSlide key={JSON.stringify(item) + index}>
                <Link to={`/launchpad/${item.project_slug}`}>
                  <div className='launchUcomingSlider'>
                    <div className='launchUcomingSliderInner'>
                      <div className='daynamicSlider'>
                        <img
                          style={{ borderRadius: '20px' }}
                          src={'https://loop-markets.directus.app/assets/' + item.banner.id}
                          alt='rect'
                        />
                      </div>
                      <span>
                        <img
                          style={{ width: '60px' }}
                          src={'https://loop-markets.directus.app/assets/' + item.project_logo.id}
                          alt='thumb'
                        />
                      </span>
                      <h6>{item.project_name}</h6>
                      <label>
                        Mint Price <b>{item.launchInfo.mintPrice}</b>
                      </label>
                      <label>
                        Start Date
                        <b>
                          {item.launch_tba ? 'TBA' : (
                            <>
                              {new Date(item.launchInfo.startDate).getUTCDate()}
                              {' '}
                              {monthNames[new Date(item.launchInfo.startDate).getUTCMonth()]}
                              {' '}
                              {new Date(item.launchInfo.startDate).getUTCFullYear()}
                            </>
                          )}
                        </b>
                      </label>
                      <label>
                        Start Time <b>{item.launch_tba ? 'TBA' : item.launchInfo.startTime}</b>
                      </label>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    )}
    </>
  );
};

export default Staging;
