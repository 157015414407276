import { FC, useEffect, useState, useMemo } from 'react';
import { queryCollections, searchNfts } from 'hooks/queryCollectionsSearch';
import { getIpfsUrl } from 'core/common';
import { formatNumberString } from 'utils/conversion/conversion';
import { Dec } from '@terra-money/terra.js';
import { Link, useHistory } from 'react-router-dom';

const CollectionsList = ({
  collections,
  resetSearch,
}: {
  collections: any;
  resetSearch: any;
}) => {
  const history = useHistory();

  const handleClickHandle = (collectionName: string) => {
    history.push(`/exploreCollection/${collectionName}`);
    resetSearch();
  };

  return (
    <div>
      {collections.length > 0 && <h3>Collections</h3>}
      <div className='collections'>
        {collections.length > 0 &&
          collections.map((item: any) => {
            return (
              <div key={item.address} className='item'>
                <div
                  onClick={() => handleClickHandle(item.url)}
                  className='searchCard'
                  style={{ height: '100%' }}
                >
                  <img
                    src={item.logo}
                    alt={item.name}
                    style={{ height: '50px', width: 'auto' }}
                  />
                  <div className='searchListDetail'>
                    <span className='header'>{item.name}</span>
                    {/*
                    <span className='text text__white'>
                      <b style={{ color: '#c83e93' }}>Owners:</b>{' '}
                      {item?.ownerCount}
                    </span>
                    */}
                    <span className='text text__white'>
                      <b style={{ color: '#c83e93' }}>For Sale:</b>{' '}
                      {item?.itemCount}
                    </span>
                    <span className='text'>
                      <b style={{ color: '#c83e93' }}>Floor Price:</b> $
                      {item.floorPrice
                        ? formatNumberString(
                            new Dec(item.floorPrice).div(1000000)
                          ) + ' USDC'
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CollectionsList;
