import { BACKEND_ENDPOINT, PAGE_SIZE } from 'core/common';
import axios from 'axios';
import { NFTData } from './useEligibleStakeNFTs';

export const queryListedNFTs = async (
  address: string
): Promise<{
  totalCount: number;
  nfts: {
    contract: string;
    token_id: string;
    inAuction: boolean;
    inSale: boolean;
  }[];
}> => {
  const query = `
  {
    nfts (
      filter: {
        owner: {
          equalTo: "${address}"
        }
        or: [
          {
            inSale: {
              equalTo: true
            }
          }
          {
            inAuction: {
              equalTo: true
            }
            or: [
              {
                auctionEndTime: {
                  greaterThan: "${Math.floor(new Date().getTime() / 1000)}"
                }
              }
              {
                auctionHighBidderAddr: {
                  isNull: true
                }
              }
            ]
          }
        ]
      }
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
      }
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
        nodes: NFTData[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  return {
    nfts: data.data.nfts.nodes.map(item => ({
      contract: item.contract.id,
      token_id: item.tokenID,
      inSale: item.inSale,
      inAuction: item.inAuction,
    })),
    totalCount: data.data.nfts.totalCount,
  };
};
