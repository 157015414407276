import Messages from 'components/Mesages';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { walletState, WalletStatusType } from 'state/walletAtoms';
import WebAppHeader from '../WebAppHeader';
import WebAppTabs from '../WebAppTabs';

import WebAppMyTicketsOwned from './webapp.owned';

const WebAppMyTickets = () => {
  const { address, status } = useRecoilValue(walletState);
  const location = useLocation();
  const type =
    location.pathname === '/webapp/mytickets/staked'
      ? 'staked'
      : 'owned';
  const isWebApp = window.location.pathname.startsWith("/webapp");
  return (
    <>
      <Helmet>
        <title>MyTickets</title>
      </Helmet>
      <div className='myticketsMain'>
        <div className='mytickets'>
          <div className='wrapper'>
            <div className='myticketsInner'>
              {isWebApp && (
                <div>
                  <WebAppHeader />
                  <WebAppTabs />
                </div>
              )}

              {status === WalletStatusType.connected && (<Messages type="nft" />)}
              {type === 'owned' && <WebAppMyTicketsOwned />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebAppMyTickets;
