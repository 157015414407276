export const setParam = (name: string, value: any) => {
  const url = new URL(window.location.href);
  url.searchParams.set(name, value);
  return window.history.replaceState(null, ``, url.href);
};

export const getParam = (name: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(name);
  return param ? Number(param) : null;
};
