
import { useState, useEffect } from 'react';
import NftFooter from '../../Nft/NftFooter';
import LaunchRewards from './reward.table';
import LaunchpadForm from './mint.form';
import UpcomingForm from './upcoming.form';
import UpcomingRewards from './upcomingReward.table';
import { useParams } from 'react-router-dom';
import { networkType } from 'core/nftConfig';
import useLaunchPadNFTMinterQuery from 'hooks/useLaunchPadNFTMinterQuery';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import ProjectInfo from './projectInfo';
import * as api from '../../../api/launchpad.api';
import { Helmet } from 'react-helmet';
import AirdropForm from './airdrop.form';
import DataLayer from 'components/DataLayer/DataLayer';
import { DirectusImage } from 'core/common';

const LaunchpadView = (props: any) => {
  const params = useParams<any>();
  const [launchpadNFT, setData] = useState<any>();
  const { address } = useRecoilValue(walletState);
  const queryResult = useLaunchPadNFTMinterQuery(networkType, launchpadNFT?.launchInfo.MINTER, address);

  useEffect(() => {
    if (!launchpadNFT) {
      api.FetchLaunchPad(params.address, 'live').then(launchpadNFT => setData(launchpadNFT));
    }
  }, [launchpadNFT, params]);

  const isAirdropNFt = launchpadNFT?.project_slug === "loop-airdrop"

  return (
    <>
    {launchpadNFT && (
        <>
       {queryResult &&  <DataLayer
        itemInfo={{
          type: 'viewItemLaunchapad',
          id:queryResult?.mintConfig?.nft_symbol,
          title:launchpadNFT.project_name,
          creator:launchpadNFT?.additional_info?.founder_status, 
          collection:launchpadNFT.project_name, 
          price:queryResult?.mintConfig?.nft_price_amount,
          currency:launchpadNFT.launchInfo.mintPrice?.split(' ')[1] || 'USDC'
        }}
      />}
          <Helmet>
            <title>NFT | Launchpad: {launchpadNFT.project_name}</title>
            <meta name="description" content={(launchpadNFT.project_about || "").replace(/(<([^>]+)>)/ig, '').substring(0, 155)} />
            <meta property="og:url" content="%PUBLIC_URL%" />
            <meta property="og:image" content={DirectusImage(launchpadNFT?.banner.id)} />

            <meta name="twitter:description" content={(launchpadNFT.project_about || "").replace(/(<([^>]+)>)/ig, '').substring(0, 155)} />
            <meta name="twitter:image" content={DirectusImage(launchpadNFT?.banner.id)} />
            <meta name="twitter:card" content={DirectusImage(launchpadNFT?.banner.id)} />
          </Helmet>
          <div
            className='back'
            onClick={() => props.history.push('/launchpad')}
          >
            <img src='/barrow.png' alt='aro_lft.png' style={{marginRight: '10px'}} /> Back
          </div>
          <div className='launchViewHeader'>
            <div className='launchViewLeft'>
              <label className='titleBar'>
                <div className='title'>
                  <img style={{ width: '60px', borderRadius: '8px' }} src={DirectusImage(launchpadNFT?.project_logo.id)} alt='thumb' />
                  <h6>{launchpadNFT.project_name}</h6>
                </div>
              </label>
              <div className='nft_view'>
                <div className='block'>
                  <div className='daynamicSlider'>
                    <img
                      style={{ borderRadius: '20px' }}
                      src={DirectusImage(launchpadNFT?.banner.id)}
                      alt='rect'
                    />
                  </div>
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: launchpadNFT.project_about }} />
                  </p>
                  <div className='project_social'>
                    <a href={launchpadNFT?.website} target="_blank" rel="noreferrer">
                      <img src='/s1.png' alt='thumb' />
                    </a>
                    <a href={launchpadNFT?.twitter} target="_blank" rel="noreferrer">
                      <img src='/s2.png' alt='thumb' />
                    </a>
                    <a href={launchpadNFT?.discord} target="_blank" rel="noreferrer">
                      <img src='/s3.png' alt='thumb' />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='launchViewRight'>
            {launchpadNFT.project_status == "live" && (
              isAirdropNFt ?
              <AirdropForm
                queryResult={queryResult}
                launchpadNFT={launchpadNFT}
              /> :
              <LaunchpadForm
                queryResult={queryResult}
                launchpadNFT={launchpadNFT}
              />
            )}
            {launchpadNFT.project_status == "upcoming" && (
              <UpcomingForm
                queryResult={queryResult}
                launchpadNFT={launchpadNFT}
              />
            )}
            </div>
          </div>
          {!isAirdropNFt && launchpadNFT.reward_status == true && (
            <>
              {launchpadNFT.project_status == "live" && (
                <LaunchRewards queryResult={queryResult} />
              )}
              {launchpadNFT.project_status == "upcoming" && (
                <UpcomingRewards launchpadNFT={launchpadNFT} />
              )}
            </>
          )}
          <ProjectInfo info={launchpadNFT} />
        </>
      )}
      <NftFooter />
    </>
  );
};

export default LaunchpadView;
