import {FC, ReactNode, useRef} from 'react';
import useOutsideAlerter from '../hooks/useOutsideAlerter';
import styles from './Modal.module.scss';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    zIndex?: number;
    center?: boolean;
    webApp?: boolean
    customClass?: string
    customSubClass?: string
    sm?: boolean
    children?: ReactNode
    modalContentClass?: string
    blur?: boolean
}

const Modal: FC<Props> = ({
                              isOpen,
                              onClose,
                              title = 'Select a Token',
                              sm = false,
                              children,
                              zIndex,
                              center,
                              webApp,
                              customClass,
                              customSubClass,
                              modalContentClass,
                              blur
                          }) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onClose);
    return !isOpen ? null : (
        <div
            className={`${customClass} ${styles.modal} ${
                isOpen ? styles.open_modal : styles.close_modal
            }`}
            style={{
                ...(blur ? {backdropFilter: 'blur(20px)'} : {}),
                ...(zIndex ? {zIndex} : {}),
                ...(center ? {display: 'flex', padding: '0px'} : {}),
            }}
        >
            <div className={`${modalContentClass ? modalContentClass : styles.modalContent} ${customSubClass}`} ref={wrapperRef} style={{
                ...(webApp ? {width: '100%', height: '100%', paddingTop: '25vh'} : {})
            }}>
                {!sm && <div className={styles.modalHeader}>
          <span hidden={webApp} className={styles.close} onClick={onClose}>
            &times;
          </span>
                    <h1>{title}</h1>
                </div>
                }
                <div className={styles.modalBody}>{children}</div>
            </div>
        </div>
    );
};

export default Modal;
