import { getIpfsUrl } from 'core/common';
import { Link } from 'react-router-dom';

const LevanaCard = ({ data }: { data: { tokenID, contractId,image, name} }) => {
  const {
    tokenID,
    contractId,
      image,
      name
  } = data;

  return (
    <>
      <Link
        to={`${
          window.location.pathname.startsWith('/webapp')
            ? '/webapp/nftDetail'
            : '/nftDetail'
        }/${contractId}/${tokenID}`}
      >
        {name && (
          <>
            <div className='nftRightChild'>
              <span>
                <img src={getIpfsUrl(image, true)} alt='' />
                <label className='nftTitle'>
                    <div>
                      {name}
                    </div>
                  </label>
              </span>
              <div className='mynftHub'>
                </div>
              </div>
          </>
        )}
      </Link>
    </>
  );
};

export default LevanaCard;
