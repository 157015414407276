// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import ReactTooltip from 'react-tooltip';

export default function ToolTip(data?: any) {
  return (
    <>
      {/* Staking Coming Soon */}
      <ReactTooltip id='stakeToolTip' place='top' effect='solid'>
        {/* Will be enabled after LOOP airdrop and DEX trading begins */}
        Disabled
      </ReactTooltip>
      {/* stakeToolTipForSuperLoopPowern */}
      <ReactTooltip
        id='stakeToolTipForSuperLoopPower'
        place='top'
        effect='solid'
      >
        <div style={{ width: '500px' }}>
          Are you sure you want to stake and permanently disable the "SUPER LOOP
          Power" trait?
          <br />
          On the 11th February 2023 all SUPER LOOP Power NFTs will earn 10x Loop
          Power based on the amount of LOOP in the NFT. This will result in
          permanent ongoing LOOP rewards from your boosted Loop Power NFT.
          <br />
          Staking this NFT at any point will disable the SUPER LOOP Power trait
          and therefore it will no longer be eligible for Loop Power rewards.
          <br />
          More info here.
        </div>
      </ReactTooltip>
      {/* Sale and Auction ToolTip */}
      <ReactTooltip id='saleAuctionToolTip' place='top' effect='solid'>
        Will be enabled after mint is sold out or <b>{data?.endDate}</b>
      </ReactTooltip>
    </>
  );
}
