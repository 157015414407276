import { BACKEND_ENDPOINT } from 'core/common';
import axios from 'axios';

export const queryCollectionNftsCount = async (
  collection: string
): Promise<number> => {
  const query = `
  {
    nfts (
      filter: {
        contractId:{
          equalTo: "${collection}"
        }
      }
    ) {
      totalCount
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  return data.data.nfts.totalCount;
};

export const queryCollectionNftsCountByAttribute = async (
  collection: string,
  trait_type: string,
  value: string
): Promise<number> => {
  const query = `
  {
    nfts (
      filter: {
        contractId:{
          equalTo: "${collection}"
        }
        attributes: {
          includes: "\\"trait_type\\":\\"${trait_type}\\",\\"value\\":\\"${value}\\""
        }
      }
    ) {
      totalCount
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  return data.data.nfts.totalCount;
};

export const queryCollectionNftsAttributesCount = async (
  collection: string
): Promise<{
  [s: string]: number
}> => {
  const query = `
  {
    nftAttributes (
      filter:{
        contractId: {
          equalTo: "${collection}"
        }
      }
    ) {
      groupedAggregates(
        groupBy: [TRAIT_TYPE, VALUE]
      ) {
        keys
        distinctCount {
          id
        }
      }
    }
  }`;

  const data: {
    data: {
      nftAttributes: {
        groupedAggregates: {
          keys: string[],
          distinctCount: {
            id: string
          }
        }[]
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  const res = {} as {
    [s: string]: number
  }
  for (let i = 0; i < data.data.nftAttributes.groupedAggregates.length; i++) {
    res[`${data.data.nftAttributes.groupedAggregates[i].keys[0]}-${data.data.nftAttributes.groupedAggregates[i].keys[1]}`] = Number(data.data.nftAttributes.groupedAggregates[i].distinctCount.id)
  }

  return res;
};