import { FC, useEffect, useState, useMemo } from 'react';
import { queryCollections, searchNfts } from 'hooks/queryCollectionsSearch';
import { getIpfsUrl } from 'core/common';
import { formatNumberString } from 'utils/conversion/conversion';
import { Dec } from '@terra-money/terra.js';
import { Link, useHistory } from 'react-router-dom';

const SearchNFTList = ({
  NFTs,
  totalNFTs,
  search,
  resetSearch,
}: {
  NFTs: any;
  totalNFTs: number;
  search: string;
  resetSearch: any;
}) => {
  const history = useHistory();

  const searchMoreHandle = () => {
    history.push(`/nftExplore?term=${search}`);
    resetSearch();
  };

  return (
    <div>
      {NFTs.length > 0 && (
        <>
          <h3>NFTs</h3>
          <ul>
            {NFTs.map((item: any) => {
              return (
                <li key={item.address}>
                  <a
                    href={`/nftDetail/${item.contract.id}/${item.tokenID}`}
                    className='searchList'
                    style={{ height: '100%' }}
                  >
                    <img src={getIpfsUrl(item.image, true)} alt='' />
                    <div className='searchListDetail'>
                      <span className='header'>{`${item.name}`} </span>
                      {item.inSale && (
                        <span className='text'>
                          <b style={{ color: '#32fe9a' }}>Sale Price:</b> $
                          {formatNumberString(
                            new Dec(item?.saleAmount || 0).div(1000000)
                          )}
                        </span>
                      )}
                      {item.inAuction && (
                        <span className='text'>
                          <b style={{ color: '#01cdfd' }}>Auction Price:</b> $
                          {formatNumberString(
                            new Dec(item?.auctionMinAmount || 0).div(1000000)
                          )}
                        </span>
                      )}
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
          {totalNFTs > 25 && (
            <>
              <button className='searchMore' onClick={() => searchMoreHandle()}>
                View More
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchNFTList;
