import {Network} from 'core/constants';
import {nftConfig} from 'core/nftConfig';
import {BACKEND_ENDPOINT, PAGE_SIZE} from 'core/common';
import axios from 'axios';
import {NFTSaleInfo, NFTAuctionInfo} from './queryLaunchpadNFTInfo';
import {getQueryClient} from './newHooks/useJunoConnectedWallet';
import {
    EligibleNFT,
    LaunchPadNFTInfo,
    LaunchpadNFTMetadata, LaunchPadNFTStakeInfo,
    NFTData,
} from './useEligibleStakeNFTs';
import {Dec} from "@terra-money/terra.js";

export type StandardNft = {
    type: 'launchpad' | 'lp';
    contract: string;
    tokenId: string;
    name: string;
    symbol: string;
    info: LaunchPadNFTInfo;
    metadata: LaunchpadNFTMetadata;
    saleInfo?: NFTSaleInfo;
    auctionInfo?: NFTAuctionInfo;

    isInAuction: boolean;
    isInSale: boolean;

    myBidAmount?: string;
};

const StandardNftTypeString = 'Standard';

const getOwnedQuery = (address: string, offset = 0, count = PAGE_SIZE) => {
    return `
  {
    nfts (
      filter: {
        owner: {
          equalTo: "${address}"
        }
        inSale: {
          equalTo: false
        }
        inAuction: {
          equalTo: false
        }
        type: {
          equalTo: "${StandardNftTypeString}"
        }
      },
      offset: ${offset},
      first: ${count}
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
      }
    }
  }`;
};

const getBidQuery = (address: string, offset = 0, count = PAGE_SIZE) => {
    return `
  {
    nfts (
      filter: {
        activeBidders: {
          contains: "${address.toLowerCase()}"
        }
        type: {
          equalTo: "${StandardNftTypeString}"
        }
      },
      offset: ${offset},
      first: ${count}
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
        activeBidders
        active_bids (filter: {
          user: {
            equalTo: "${address.toLowerCase()}"
          }
        }  orderBy: [TIMESTAMP_DESC]) {
          nodes {
            user
            amount
            timestamp
          }
        }
      }
    }
  }`;
};

const getOnsaleQuery = (address: string, offset = 0, count = PAGE_SIZE) => {
    return `
  {
    nfts (
      filter: {
        owner: {
          equalTo: "${address}"
        }
        type: {
          equalTo: "${StandardNftTypeString}"
        }
        or: [
          {
            inSale: {
              equalTo: true
            }
          }
          {
            inAuction: {
              equalTo: true
            }
            or: [
              {
                auctionEndTime: {
                  greaterThan: "${Math.floor(new Date().getTime() / 1000)}"
                }
              }
              {
                auctionHighBidderAddr: {
                  isNull: true
                }
              }
            ]
          }
        ]
      },
      offset: ${offset},
      first: ${count}
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
      }
    }
  }`;
};

export const queryMyStandardNfts = async (
    type: 'owned' | 'bid' | 'onsale',
    networkType: Network,
    address: string,
    offset = 0,
    count = PAGE_SIZE
): Promise<{
    nfts: StandardNft[];
    totalCount: number;
}> => {
    const client = await getQueryClient();
    const query = (
        type === 'owned'
            ? getOwnedQuery
            : type === 'bid'
                ? getBidQuery
                : getOnsaleQuery
    )(address, offset, count);

    const data: {
        data: {
            nfts: {
                totalCount: number;
                nodes: NFTData[];
            };
        };
    } = await axios
        .post(BACKEND_ENDPOINT, {
            operationName: null,
            query,
            variables: {},
        })
        .then((response: { data: any }) => response.data);

    const nfts: StandardNft[] = [];

    for (let i = 0; i < data.data.nfts.nodes.length; i++) {
        const nft = data.data.nfts.nodes[i];
        const info = {
            owner: address,
            ...JSON.parse(nft.info),
        };
        nfts.push({
            type: 'launchpad',
            tokenId: nft.tokenID,
            contract: nft.contract.id,
            name: nft.contract.name,
            symbol: nft.contract.symbol,
            info,
            metadata: JSON.parse(nft.metadata),
            isInAuction: nft.inAuction,
            isInSale: nft.inSale,
        });

        if (nft.active_bids) {
            nfts[nfts.length - 1].myBidAmount = nft.active_bids.nodes.find(
                item => item.user === address.toLowerCase()
            )?.amount;
        }

        if (nft.inSale) {
            try {
                const saleInfo = (await client.queryContractSmart(
                    nftConfig[networkType].SALE,
                    {
                        nft_info: {
                            wallet: nft.owner,
                            nft_contract_addr: nft.contract.id,
                            nft_token_id: nft.tokenID,
                        },
                    }
                )) as NFTSaleInfo;

                nfts[nfts.length - 1].saleInfo = saleInfo;
            } catch {
                console.log(
                    'sale info not available: ' + nft.contract.id + ' / ' + nft.tokenID
                );
            }
        }
        if (nft.inAuction) {
            try {
                const auctionInfo = (await client.queryContractSmart(
                    nftConfig[networkType].AUCTION,
                    {
                        auction_state: {
                            token_id: nft.tokenID,
                            token_address: nft.contract.id,
                        },
                    }
                )) as NFTAuctionInfo;
                nfts[nfts.length - 1].auctionInfo = auctionInfo;

                try {
                    const min_bid_amount = (await client.queryContractSmart(
                        nftConfig[networkType].AUCTION,
                        {
                            next_min_bid_amount: {
                                token_id: nft.tokenID,
                                token_address: nft.contract.id,
                            },
                        }
                    )) as string;
                    auctionInfo.min_bid_amount = min_bid_amount;

                    nfts[nfts.length - 1].auctionInfo = auctionInfo;
                } catch {
                    console.log(
                        'auction min bid amount not available: ' +
                        nft.contract.id +
                        ' / ' +
                        nft.tokenID
                    );
                }
            } catch {
                console.log(
                    'auction info not available: ' + nft.contract.id + ' / ' + nft.tokenID
                );
            }
        }
    }

    return {
        nfts,
        totalCount: data.data.nfts.totalCount,
    };
};


export const queryMyLevana = async (address: string, contract: string) => {
    const client = await getQueryClient();
    try {
        const nfts = (await client.queryContractSmart(
            contract,
            {
                tokens: {owner: address, limit: 30},
            }
        ))
        const query = `
  {
    nfts (
      filter: {
        tokenID: {in: ${JSON.stringify(nfts.tokens)}}
        contractId: {
          equalTo: "${contract}"
        }
      }
    ) {
      totalCount
      nodes{
      nodeId
      id
      name
      contractId
      tokenID
      image
      type
      }
    }
  }`;
        const data: {
            data: {
                nfts: {
                    totalCount: number;
                    nodes: NFTData[];
                };
            };
        } = await axios
            .post(BACKEND_ENDPOINT, {
                operationName: null,
                query,
                variables: {},
            })
            .then((response: { data: any }) => response.data);
        return data.data?.nfts?.nodes?.map((item)=>({...item, type: "launchpad"})) ?? []
    } catch (e) {
        return []
    }
}