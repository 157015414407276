import { Dec } from '@terra-money/terra.js';
import { networkType } from 'core/nftConfig';
import { NFTtype, transformNFTs } from 'core/utils';
import { queryNFTData } from 'hooks/queryNFTs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatNumberString } from 'utils/conversion/conversion';

const Nft = ({ postObj, index, setNftModal, className, isSale }: any) => {
  const [timeLeft2, setTimeLeft2] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [nftInfo, setNftInfo] = useState<NFTtype[]>([]);
  const [nftLoaded, setNftLoaded] = useState(false);
  const endTime = nftInfo[0]?.endTime;

  useEffect(() => {
    if (postObj && !nftLoaded) {
      queryNFTData(
        postObj.owner,
        postObj.tokenId,
        postObj.collection,
        postObj.vesting_period,
        postObj.type,
        postObj.metadata
      ).then(({ nftInfo }) => {
        setNftInfo(transformNFTs(nftInfo, networkType))
        setNftLoaded(true)
      });
    }
    return () => {nftInfo && setNftLoaded(true)};
  }, [postObj, nftLoaded, nftInfo]);
  
  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = parseInt((Date.now() / 1000).toFixed(0));
      if (currentTime > endTime) {
        setTimeLeft2(
          '<div><b>' +
            0 +
            '</b>d</div>&nbsp:&nbsp<div><b>' +
            0 +
            '</b>h</div>&nbsp:&nbsp<div><b>' +
            0 +
            '</b>m</div>&nbsp:&nbsp<div><b>' +
            0 +
            '</b>s</div>'
        );
      } else {
        const timeDiff = endTime - currentTime;
        if (timeDiff > 60 * 60 * 24 * 365) {
          setTimeLeft2(
            '<div><b>' +
              0 +
              '</b>d</div>&nbsp:&nbsp<div><b>' +
              0 +
              '</b>h</div>&nbsp:&nbsp<div><b>' +
              0 +
              '</b>m</div>&nbsp:&nbsp<div><b>' +
              0 +
              '</b>s</div>'
          );
        } else {
          const d = Math.floor(timeDiff ? timeDiff / (3600 * 24) : 0);
          const h = Math.floor(timeDiff ? (timeDiff % (3600 * 24)) / 3600 : 0);
          const m = Math.floor(timeDiff ?(timeDiff % 3600) / 60 : 0);
          const s = Math.floor(timeDiff ? timeDiff % 60 : 0);

          setTimeLeft2(
            '<div><b>' +
              d +
              '</b>d</div>&nbsp:&nbsp<div><b>' +
              h +
              '</b>h</div>&nbsp:&nbsp<div><b>' +
              m +
              '</b>m</div>&nbsp:&nbsp<div><b>' +
              s +
              '</b>s</div>'
          );
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [postObj,endTime,nftLoaded]);


  if (isSale === 0 && parseFloat(nftInfo[0]?.price.toLocaleString()) < 1 ) {
    return <></>;
  }

  return (
    <>
      <li key={index} className={className} onClick={() => setNftModal(postObj)}>
        <span className='nftImage'>
        <img
            style={imageLoaded ? {} : {display: 'none'}}
            src={`${postObj.img}`}
            alt={postObj.img}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/blank.png';
            }}
            onLoad={() => setImageLoaded(true)}
          />
          <div
            style={!imageLoaded ? {} : {display: 'none'}}
            className="loading_animation"
          >
            <div className="background-masker btn-divide-left"></div>
          </div>
          {postObj.type === 'Auction' && timeLeft2.length > 0 && (
            <div className='auction-timestamp'>
              <div className='counterNewSet'>
                <img src='/counterT.png' alt='image' />
                &nbsp;
                <span
                  className='counter'
                  dangerouslySetInnerHTML={{ __html: timeLeft2 }}
                ></span>
              </div>
            </div>
          )}
        </span>
        <p>
          <span>{postObj.tokenName}</span>
          <span style={{ display: 'contents' }}>{!isSale ? "Price" : ""}</span>
        </p>
        <p>
          <span className='title'>{`#${
            postObj?.tokenId?.includes('#')
              ? postObj?.tokenId?.split('#')[1]
              : postObj?.tokenId
          }`}</span>
          <label>
            { !isSale ? nftLoaded ? <><img src={nftInfo[0]?.denom === 'JUNO' ? '/junoicon.svg' : '/USDCicon.svg'} alt='' /> {nftInfo[0]?.price.toLocaleString()}</> : <div className="asset_loader" /> : <></>}
          </label>
        </p>
        {postObj.rewards1Amount > 0 && postObj.rewards1Symbol && (
          <p className='rewardInfo-wrapper'>
            <img src='/gift.svg' alt='' className='icon' />
            <label className='reward'>
              {`${formatNumberString(new Dec(postObj.rewards1Amount)
                .div(1000000)
                .mul(nftInfo[0] ? nftInfo[0]?.daysRemain : 0)
                .toPrecision()
                .toLocaleString())} ${postObj.rewards1Symbol}`}
            </label>
            {' / '}
            {nftLoaded ? nftInfo[0]?.daysRemain : 0} days
          </p>
        )}
        {postObj.rewards2Amount > 0 && postObj.rewards2Symbol && (
          <p className='rewardInfo-wrapper'>
            <img src='/gift.svg' alt='' className='icon' />
            <label className='reward'>
              {`${new Dec(postObj.rewards2Amount)
                .div(1000000)
                .toPrecision()
                .toLocaleString()} ${postObj.rewards2Symbol}`}
            </label>
            {' / '}
            {nftLoaded ? nftInfo[0]?.daysRemain : 0} days
          </p>
        )}
      </li>
    </>
  );
};
export default Nft;
