import React from 'react';
import Modal from '../../../components/Modal';

import FailedIcon from '../../../assets/images/complete.svg';
//import FailedIcon from '../../../assets/images/fail.svg';
import styles from './notification.module.scss';

const convertErrorMessage = (message: string): string => {
  if (
    message ===
    'Account does not exist on chain. Send some tokens there before trying to query sequence.' ||
    message.includes('insufficient')
  ) {
    return 'Add funds to your wallet!';
  }

  return message;
};

const WebAppFail = ({setTheme, message}: { setTheme: any; message?: string }) => {
  function onClose() {
    setTheme(undefined, undefined);
    return;
  }

  return (
    <Modal isOpen={true} title='' onClose={onClose} zIndex={4} center webApp>
      <div className={styles.notification_content}>
        {/*<img src={FailedIcon} alt='wait'/>*/}
        {/* TEMP */}
        <p className={styles.title}>In Progress</p>
        <div className={styles.description}>
          {/* message ? convertErrorMessage(message) : 'Please try again.' */}
          Your request is in progress. Feel free to close this modal and check your tickets.
        </div>
        <button style={{background: 'transparent', color: '#787B9C', position: 'absolute', bottom: '20px'}} onClick={() => onClose()}>Back</button>
      </div>
    </Modal>
  );
};

export default WebAppFail;
