import { useRecoilValue } from 'recoil';
import { walletState, WalletStatusType } from '../../state/walletAtoms';
import { useHistory } from "react-router-dom";
import profile from '../../assets/images/profile.png';
import { junoAddress } from 'core/utils';

const WebAppTabs = (props:any) => {
  const history = useHistory();
  const { address, status } = useRecoilValue(walletState);

  return (
    <section>
      <div
      className='mynftRight'
      style={{
         display: 'flex',
         flexDirection: 'column',
      }}
      >
         <div>
            <div className='webapp_header_tabs'>
               <div
                  onClick={() => history.push(`/webapp/mynfts`)}
                  className={window.location.pathname.endsWith('/mynfts') ? 'active' : ''}
               >My NFTs</div>
               <div
                  onClick={() => history.push(`/webapp/mytickets`)}
                  className={window.location.pathname.endsWith('/mytickets') ? 'active' : ''}
               >My Tickets</div>
            </div>
         </div>
      </div>
    </section>
  );
};

export default WebAppTabs;
