import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';

import * as api from '../../../api/launchpad.api';
import {useState, useEffect} from 'react';
import WebAppEvent from './webapp.event';

const WebAppLaunchpad = (props: any) => {
  const [launchpadList, setLaunchpadList] = useState<any>();

  useEffect(() => {
    if (!launchpadList) {
      api.FetchTicketBooking()
         .then(launchpadList => {
           setLaunchpadList(launchpadList)
         });
    }
  }, [launchpadList]);

  return (
    launchpadList ? (
      <>
        <div className='launchSection'>
          <div
          style={{maxWidth: '600px', margin: 'auto', overflow: 'hidden'}}
            className={
              props.onlyFeatured
                ? 'launchLiveProject launchLiveProject__no_margin'
                : 'launchLiveProject'
            }
          >
            {launchpadList.map((item: any, index: number) => {
              return (
                <WebAppEvent item={item}/>
              );
            })}
          </div>
        </div>
      </>
    ) : (
      <div className='noPosts'>
        <p
          className='loader'
          style={{
            marginLeft : 'auto',
            marginRight: 'auto',
          }}
        ></p>
      </div>
    )
  );
};

export default WebAppLaunchpad;
