import { ATOM_DENOM, Network, USDC_DENOM, JUNO_DENOM } from './constants';

export type CommonConfig = {
  SWAP_FACTORY: string;
  TOKEN_SYMBOLS: {
    [token: string]: string;
  };
  NFT_CREATORS: {
    [token: string]: string;
  };
  IS_AVAILABLE_TOKEN: {
    [token: string]: boolean;
  };
};

export const commonConfig: {
  [network: number]: CommonConfig;
} = {
  [Network.MAINNET]: {
    SWAP_FACTORY:
      'juno1p4dmvjtdf3qw9394k7zl65eg8g5ehzvdxnvm9hd3ju7a7aslrmdqaspeak',
    TOKEN_SYMBOLS: {
      juno1qsrercqegvs4ye0yqg93knv73ye5dc3prqwd6jcdcuj8ggp6w0us66deup: 'LOOP',
      juno1gz8cf86zr4vw9cjcyyv432vgdaecvr9n254d3uwwkx9rermekddsxzageh: 'GKEY',
      juno1hnftys64ectjfynm6qjk9my8jd3f6l9dq9utcd3dy8ehwrsx9q4q7n9uxt: 'AQUA',
      juno1ju8k8sqwsqu5k6umrypmtyqu2wqcpnrkf4w4mntvl0javt4nma7s8lzgss: 'CASA',
      ujuno: 'JUNO',
      juno1x5xz6wu8qlau8znmc60tmazzj3ta98quhk7qkamul3am2x8fsaqqcwy7n9: 'BOTTLE',
      juno18c5uecrztn4rqakm23fskusasud7s8afujnl8yu54ule2kak5q4sdnvcz4: 'DRINK',
      juno1j0a9ymgngasfn3l5me8qpd53l5zlm9wurfdk7r65s5mg6tkxal3qpgf5se: 'GLTO',
      juno1m4h8q4p305wgy7vkux0w6e5ylhqll3s6pmadhxkhqtuwd5wlxhxs8xklsw: 'WATR',
    },
    NFT_CREATORS: {
      juno1j6ewq25hfgww8pty9p4d5q8kkyf4sg9j24crstj78a237tk0thnqe0pa93:
        'LOOP FINANCE',
    },
    IS_AVAILABLE_TOKEN: {
      juno1qsrercqegvs4ye0yqg93knv73ye5dc3prqwd6jcdcuj8ggp6w0us66deup: true,
      // ujuno: true,
    },
  },
  [Network.TESTNET]: {
    SWAP_FACTORY:
      'juno1p4dmvjtdf3qw9394k7zl65eg8g5ehzvdxnvm9hd3ju7a7aslrmdqaspeak',
    TOKEN_SYMBOLS: {
      juno1qsrercqegvs4ye0yqg93knv73ye5dc3prqwd6jcdcuj8ggp6w0us66deup: 'LOOP',
      juno1gz8cf86zr4vw9cjcyyv432vgdaecvr9n254d3uwwkx9rermekddsxzageh: 'GKEY',
      juno1hnftys64ectjfynm6qjk9my8jd3f6l9dq9utcd3dy8ehwrsx9q4q7n9uxt: 'AQUA',
      juno1ju8k8sqwsqu5k6umrypmtyqu2wqcpnrkf4w4mntvl0javt4nma7s8lzgss: 'CASA',
    },
    NFT_CREATORS: {},
    IS_AVAILABLE_TOKEN: {
      juno1qsrercqegvs4ye0yqg93knv73ye5dc3prqwd6jcdcuj8ggp6w0us66deup: true,
    },
  },
};

export const denomToSymbol: {
  [denom: string]: string;
} = {
  [USDC_DENOM]: 'USDC',
  [ATOM_DENOM]: 'ATOM',
  [JUNO_DENOM]: 'JUNO',
};

export const symbolToDenom: {
  [denom: string]: string;
} = {
  USDC: USDC_DENOM,
  ATOM: ATOM_DENOM,
  JUNO: JUNO_DENOM,
};

export const IPFS_GATEWAY = 'https://nft-api.loop.markets/';

export const BACKEND_ENDPOINT = 'https://nft-juno-backend.loop.markets/';
export const PAGE_SIZE = 8;

export const getIpfsUrl = (url: string, image: boolean) => {
  if (!url.startsWith('ipfs')) {
    return url;
  }

  const isFormat = url.match(/\.(jpeg|jpg|gif|png|webp)$/);
  const ipfsUrl = IPFS_GATEWAY + url.replace(':/', '');
  return image && isFormat
    ? `${ipfsUrl}?img-width=700&img-quality=50&img-fit=contain&img-format=webp`
    : ipfsUrl;
};

export const getMetadata = async (nftData: {
  tokenUri: string;
  metadata?: string;
}) => {
  if (nftData.metadata) {
    return JSON.parse(nftData.metadata);
  }

  const metadata = (await (
    await fetch(getIpfsUrl(nftData.tokenUri, false))
  ).json()) as {
    name: string;
    project: string;
    description: string;
    image: string;
    attributes: {
      trait_type: string;
      value: string;
    }[];
  };

  return metadata;
};

export const DirectusImage = (id: string) => {
  return `https://loop-markets.directus.app/assets/${id}`;
}
