import { useQuery } from 'react-query';
import { Dec } from '@terra-money/terra.js';

import { Network } from 'core/constants';
import { nftConfig } from 'core/nftConfig';
import { BACKEND_ENDPOINT, PAGE_SIZE } from 'core/common';
import axios from 'axios';
import { NFTAuctionInfo, NFTSaleInfo } from './queryLaunchpadNFTInfo';
import {
  EligibleNFT,
  NFTData,
  LaunchPadNFTStakeInfo,
} from './useEligibleStakeNFTs';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';

export const queryEligibleListNFTs = async (
  networkType: Network,
  address: string,
  offset = 0,
  count = PAGE_SIZE
): Promise<{
  nfts: EligibleNFT[];
  totalCount: number;
}> => {
  const client = await getQueryClient();
  const query = `
  {
    nfts (
      filter: {
        owner: {
          equalTo: "${address}"
        }
        type: {
          equalTo: "Reward"
        }
        or: [
          {
            inSale: {
              equalTo: true
            }
          }
          {
            inAuction: {
              equalTo: true
            }
            or: [
              {
                auctionEndTime: {
                  greaterThan: "${Math.floor(new Date().getTime() / 1000)}"
                }
              }
              {
                auctionHighBidderAddr: {
                  isNull: true
                }
              }
            ]
          }
        ]
      },
      offset: ${offset},
      first: ${count}
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
      }
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
        nodes: NFTData[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  const nfts: EligibleNFT[] = [];

  for (let i = 0; i < data.data.nfts.nodes.length; i++) {
    const nft = data.data.nfts.nodes[i];
    const info = {
      owner: address,
      ...JSON.parse(nft.info),
    };
    const stakeInfo = (await client.queryContractSmart(
      nftConfig[networkType].STAKING,
      {
        stake_info: {
          nft_contract_addr: nft.contract.id,
          nft_token_id: nft.tokenID,
        },
      }
    )) as LaunchPadNFTStakeInfo;
    const daysStaked = Math.floor(stakeInfo.total_staked_time / 60 / 60 / 24);
    nfts.push({
      type: 'launchpad',
      tokenId: nft.tokenID,
      contract: nft.contract.id,
      name: nft.contract.name,
      symbol: nft.contract.symbol,
      info,
      stakeInfo,
      metadata: JSON.parse(nft.metadata),
      isInAuction: nft.inAuction,
      isInSale: nft.inSale,
      daysStaked:
        daysStaked <= parseInt(info.vesting_period)
          ? daysStaked
          : parseInt(info.vesting_period),
      daysRemain:
        daysStaked <= parseInt(info.vesting_period)
          ? parseInt(info.vesting_period) - daysStaked
          : 0,
      dailyValue: new Dec(0),
      totalValue: new Dec(0),
    });

    if (nft.inSale) {
      try {
        const saleInfo = (await client.queryContractSmart(
          nftConfig[networkType].SALE,
          {
            nft_info: {
              wallet: nft.owner,
              nft_contract_addr: nft.contract.id,
              nft_token_id: nft.tokenID,
            },
          }
        )) as NFTSaleInfo;

        nfts[nfts.length - 1].saleInfo = saleInfo;
      } catch {
        console.log(
          'sale info not available: ' + nft.contract.id + ' / ' + nft.tokenID
        );
      }
    }
    if (nft.inAuction) {
      try {
        const auctionInfo = (await client.queryContractSmart(
          nftConfig[networkType].AUCTION,
          {
            auction_state: {
              token_id: nft.tokenID,
              token_address: nft.contract.id,
            },
          }
        )) as NFTAuctionInfo;
        nfts[nfts.length - 1].auctionInfo = auctionInfo;

        try {
          const min_bid_amount = (await client.queryContractSmart(
            nftConfig[networkType].AUCTION,
            {
              next_min_bid_amount: {
                token_id: nft.tokenID,
                token_address: nft.contract.id,
              },
            }
          )) as string;
          auctionInfo.min_bid_amount = min_bid_amount;

          nfts[nfts.length - 1].auctionInfo = auctionInfo;
        } catch {
          console.log(
            'auction min bid amount not available: ' +
              nft.contract.id +
              ' / ' +
              nft.tokenID
          );
        }
      } catch {
        console.log(
          'auction info not available: ' + nft.contract.id + ' / ' + nft.tokenID
        );
      }
    }
  }

  return {
    nfts,
    totalCount: data.data.nfts.totalCount,
  };
};
