import { Dec } from '@terra-money/terra.js';
import Modal from 'components/Modal';
import { denomToSymbol, getIpfsUrl } from 'core/common';
import { networkType, nftConfig } from 'core/nftConfig';
import {
  bidAuctionNFTMsg,
  nftCancelAuctionMsg,
  nftCancelSellMsg,
  nftClaimAuctionMsg,
} from 'helper/junoContractMsg';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { USDC_DENOM } from 'core/constants';
import { formatNumberString } from 'utils/conversion/conversion';
import ToolTip from 'components/ToolTips';
import { StandardNft } from 'hooks/queryMyStandardNfts';

const LaunchpadCardStandard = ({ data }: { data: StandardNft }) => {
  const {
    tokenId,
    contract,
    info,
    auctionInfo,
    metadata,
    saleInfo,

    myBidAmount,
  } = data;

  const location = useLocation();
  const isFans = location.pathname.startsWith('/fans');
  const isWebApp = location.pathname.startsWith('/webapp');
  const pageRoute = isWebApp
    ? '/webapp/nftDetail'
    : isFans
    ? '/fans/nftDetail'
    : '/nftDetail';

  const isInAuction = false;
  const isInSale = false;

  const nftInfo = nftConfig[networkType].launchPadNFTs.find(
    item => item.NFT === contract
  );
  const { address } = useRecoilValue(walletState);
  const { estimateGas, submit } = useJunoTransactions();
  const [bidModal, setBidModal] = useState(false);
  const [bidPrice, setBidPrice] = useState('');

  const bidAuctionHandler = useCallback(async () => {
    const txMsg = bidAuctionNFTMsg({
      address,
      networkType,
      nft: contract,
      nft_auction: nftConfig[networkType].AUCTION,
      token_id: tokenId,
      coins: [
        {
          amount: new Dec(bidPrice).mul(1000000).toFixed(0),
          denom: USDC_DENOM,
        },
      ],
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({ msgs: [txMsg] });
  }, [address, bidPrice, contract, estimateGas, submit, tokenId]);

  const cancelHandler = useCallback(async () => {
    const txMsgs = [
      isInAuction
        ? (auctionInfo?.end_time || 0) < Math.floor(new Date().getTime() / 1000)
          ? nftClaimAuctionMsg({
              address,
              networkType,
              nft: contract,
              nft_auction: nftConfig[networkType].AUCTION,
              token_id: tokenId,
            })
          : nftCancelAuctionMsg({
              address,
              networkType,
              nft: contract,
              nft_auction: nftConfig[networkType].AUCTION,
              token_id: tokenId,
            })
        : nftCancelSellMsg({
            address,
            networkType,
            nft: contract,
            nft_sale: nftConfig[networkType].SALE,
            token_id: tokenId,
          }),
    ];

    console.log('txMsgs = ', txMsgs);
    const fee = await estimateGas(txMsgs);
    console.log('FEE:', fee);
    submit({ msgs: txMsgs });
  }, [
    address,
    auctionInfo?.end_time,
    contract,
    estimateGas,
    isInAuction,
    submit,
    tokenId,
  ]);

  const claimAuctionHandler = useCallback(async () => {
    const txMsgs = [
      nftClaimAuctionMsg({
        address,
        networkType,
        nft: contract,
        nft_auction: nftConfig[networkType].AUCTION,
        token_id: tokenId,
      }),
    ];

    console.log('txMsgs = ', txMsgs);
    const fee = await estimateGas(txMsgs);
    console.log('FEE:', fee);
    submit({ msgs: txMsgs });
  }, [address, contract, tokenId, estimateGas, submit]);

  return (
    <Link to={`${pageRoute}/${contract}/${tokenId}`} style={{ width: '100%' }}>
      {nftInfo?.disabled && <ToolTip />}
      <div
        className='nftRightChild_Standard'
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '10px',
          overflow: 'hidden',
          padding: '0px',
          margin: '0px',
        }}
      >
        <span
          style={{
            padding: '0px',
            borderRadius: '12px',
            border: '1px solid #262636',
            overflow: 'hidden',
            width: '100%',
            height: '430px',
          }}
        >
          <img
            src={getIpfsUrl(metadata?.image, true)}
            alt=''
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/blank.png';
            }}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
          />
        </span>
        <div
          className='mynftHub'
          style={{
            width: '100%',
            padding: '0px',
          }}
        >
          <div
            className='mynftHubHedare'
            style={{
              marginBottom: ' 0px',
            }}
          >
            <label
              className='nftTitle'
              style={{
                background: 'transparent',
                padding: '15px',
                fontSize: '15px',
              }}
            >
              {/* <img src='fav.png' alt='' /> */}
              <div
              /* style={{
                  textAlign: 'center',
                  whiteSpace: 'normal',
                }} */
              >
                <p>{metadata.name}</p>
                <p>{metadata?.project}</p>
              </div>
              {isInAuction && (
                <div className='bidInfo'>
                  <img src='/hammer_blue.png' alt='hammer' />
                  {myBidAmount ? (
                    <>
                      Your bid:
                      <span className='bidPrice'>
                        {formatNumberString(new Dec(myBidAmount).div(1000000))}
                      </span>
                      {denomToSymbol[auctionInfo?.coin_denom || USDC_DENOM]}
                      {auctionInfo?.high_bidder_addr.toLowerCase() !==
                        address.toLowerCase() && (
                        <>
                          <span className='separator'></span>
                          Highest bid:
                          <span className='bidPrice'>
                            {formatNumberString(
                              new Dec(auctionInfo?.high_bidder_amount).div(
                                1000000
                              )
                            )}
                          </span>
                          {denomToSymbol[auctionInfo?.coin_denom || USDC_DENOM]}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      Highest bid:
                      <span className='bidPrice'>
                        {formatNumberString(
                          new Dec(auctionInfo?.high_bidder_amount).div(1000000)
                        )}
                      </span>
                      {denomToSymbol[auctionInfo?.coin_denom || USDC_DENOM]}
                    </>
                  )}
                </div>
              )}
              {isInSale && (
                <div className='bidInfo'>
                  <img src='/hammer_blue.png' alt='hammer' />
                  Sale price:
                  <span className='bidPrice'>
                    {formatNumberString(new Dec(saleInfo?.amount).div(1000000))}
                  </span>
                  {denomToSymbol[saleInfo?.denom || USDC_DENOM]}
                </div>
              )}
            </label>
            <div className='nftButtons'>
              {isInAuction && myBidAmount ? (
                address.toLowerCase() !== auctionInfo?.high_bidder_addr ? (
                  <></>
                ) : (
                  <>
                    {isInAuction &&
                      (auctionInfo?.end_time || 0) <
                        Math.floor(new Date().getTime() / 1000) && (
                        <button
                          onClick={e => {
                            claimAuctionHandler();
                            e.preventDefault();
                          }}
                          style={{ background: '#c83e93' }}
                        >
                          CLAIM
                        </button>
                      )}
                    {/* <button
                      onClick={() => {
                        setBidModal(true);
                      }}
                    >
                      RAISE BID
                    </button> */}
                  </>
                )
              ) : (
                <>
                  {isInAuction && (
                    <span
                      style={{
                        width: 'auto',
                        background: 'none',
                        color: '#c83e93',
                      }}
                    >
                      <img src='stake.png' alt='' /> IN AUCTION
                    </span>
                  )}
                  {isInSale && (
                    <span
                      style={{
                        width: 'auto',
                        background: 'none',
                        color: '#c83e93',
                      }}
                    >
                      <img src='stake.png' alt='' /> ON SALE
                    </span>
                  )}
                  {isInAuction && (
                    <button
                      onClick={e => {
                        cancelHandler();
                        e.preventDefault();
                      }}
                      style={{ background: '#c83e93' }}
                    >
                      {(auctionInfo?.end_time || 0) <
                      Math.floor(new Date().getTime() / 1000)
                        ? 'CLAIM'
                        : 'CANCEL AUCTION'}
                    </button>
                  )}
                  {isInSale && (
                    <button
                      onClick={e => {
                        cancelHandler();
                        e.preventDefault();
                      }}
                      style={{ background: '#c83e93' }}
                    >
                      CANCEL SALE
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={bidModal} title='' onClose={() => setBidModal(false)}>
        <div className='SellAuction'>
          <h6>Bid</h6>
          <div className='aucHub'>
            <section>
              <span>
                <img src={getIpfsUrl(metadata.image, true)} alt='' />
              </span>
            </section>
            <div className='AucInput'>
              <h5>{metadata.name}</h5>
              <label>
                Bid{' '}
                <label>
                  Minimum Bid Price:{' '}
                  <span>
                    {formatNumberString(
                      new Dec(auctionInfo?.high_bidder_amount).div(1000000)
                    )}{' '}
                    <i>USDC</i>
                  </span>
                </label>
                <b>USDC</b>
              </label>
              <input
                className={
                  new Dec(auctionInfo?.high_bidder_amount || '0')
                    .div(1000000)
                    .lt(new Dec(bidPrice || '0'))
                    ? ''
                    : 'invalid'
                }
                type='text'
                placeholder='0.00'
                value={bidPrice}
                onChange={e => {
                  setBidPrice(e.target.value);
                }}
                onBlur={e => {
                  setBidPrice((parseFloat(e.target.value) || 0.0).toString());
                }}
              />
              <img src='/USDClogo.png' alt='' />
              <b>USDC</b>
            </div>
            {/* <p>
                      <b>
                        Royalty Fee: <i>4%</i>
                      </b>{' '}
                      |{' '}
                      <b>
                        Platfomr Fee: <i>2%</i>
                      </b>
                    </p> */}
            <button
              onClick={e => {
                bidAuctionHandler();
                e.preventDefault();
              }}
            >
              CONFIRM
            </button>
          </div>
        </div>
      </Modal>
    </Link>
  );
};

export default LaunchpadCardStandard;
