import axios from 'axios';
import { Dec } from '@terra-money/terra.js';
import { BACKEND_ENDPOINT } from 'core/common';
import { Network } from 'core/constants';
import { MintConfigCosmWasm } from './useLaunchPadNFTMinterQuery';
import { nftConfig } from 'core/nftConfig';
import {
  LaunchPadNFTInfo,
  LaunchpadNFTMetadata,
  LaunchPadNFTStakeInfo,
  NFTData,
} from './useEligibleStakeNFTs';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';

export type NFTAuctionInfo = {
  owner: string;
  auction_id: string;
  coin_denom: string;
  start_time: number;
  end_time: number;
  high_bidder_addr: string;
  high_bidder_amount: string;
  is_cancelled: boolean;
  min_amount: string;
  min_bid_amount: string;
};

export type NFTSaleInfo = {
  amount: string;
  denom: string;
  nft_contract_addr: string;
  nft_token_id: string;
};

export type LaunchPadNFTQueryInfo = {
  mintConfig?: MintConfigCosmWasm;
  info: LaunchPadNFTInfo;
  stakeInfo: LaunchPadNFTStakeInfo;
  metadata: LaunchpadNFTMetadata;
  auctionInfo?: NFTAuctionInfo;
  saleInfo?: NFTSaleInfo;

  daysStaked: number;
  daysRemain: number;
  dailyValue1: Dec;
  totalValue1: Dec;
  dailyValue2: Dec;
  totalValue2: Dec;

  royalty_bp: number;

  contracts: {
    nft: string;
    nftStaking: string;
    nftAuction: string;
    nftSale: string;
  };

  isInStaking: boolean;
};

export const queryLaunchpadNFTInfo = async (
  networkType: Network,
  nft: string,
  tokenId: string
) => {
  const query = `
  {
    nfts (
      filter: {
        contractId: {
          equalTo: "${nft}"
        }
        tokenID: {
          equalTo: "${tokenId}"
        }
      }
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        attributes
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
        tierIndex
        token1Addr
        token2Addr
        token1Amount
        token2Amount
        vestingPeriod
        tokenUri
      }
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
        nodes: (NFTData & {
          tierIndex: string;
          token1Addr: string;
          token2Addr: string;
          token1Amount: string;
          token2Amount: string;
          vestingPeriod: string;
          tokenUri: string;
          metadata: string;
          attributes: string;
          type: 'Reward' | 'Standard';
        })[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);
  const _info = data.data.nfts.nodes[0];

  const info = {
    owner: data.data.nfts.nodes[0].owner,
    tier_index: _info.tierIndex,
    token1_addr: _info.token1Addr,
    token1_amount: _info.token1Amount,
    token2_addr: _info.token2Addr,
    token2_amount: _info.token2Amount,
    vesting_period: _info.vestingPeriod,
    token_uri: _info.tokenUri,
    type: _info.type,
  };

  const client = await getQueryClient();

  // query nft configuration data
  const nftContractInfo = (await client.queryContractSmart(nft, {
    contract_info: {},
  })) as {
    royalty_bps: number[];
  };

  // query nft mint info
  const minterAddress =
    nftConfig[networkType].launchPadNFTs.find(item => item.NFT === nft)
      ?.MINTER ?? '0';

  let mintConfig;
  try {
    mintConfig = (await client.queryContractSmart(minterAddress, {
      config: {},
    })) as MintConfigCosmWasm;
  } catch { }

  // *** get minter config

  // query nft staking info
  const stakingAddress = nftConfig[networkType].STAKING;

  const stakeInfo = (await client.queryContractSmart(stakingAddress, {
    stake_info: {
      nft_contract_addr: nft,
      nft_token_id: tokenId,
    },
  })) as LaunchPadNFTStakeInfo;

  // query nft auction info
  const auctionAddress = nftConfig[networkType].AUCTION;

  let auctionInfo: NFTAuctionInfo | undefined = undefined;
  if (_info.inAuction) {
    try {
      auctionInfo = (await client.queryContractSmart(auctionAddress, {
        auction_state: {
          token_id: tokenId,
          token_address: nft,
        },
      })) as NFTAuctionInfo;

      if (auctionInfo.is_cancelled) {
        auctionInfo = undefined;
      } else {
        try {
          const min_bid_amount = (await client.queryContractSmart(
            nftConfig[networkType].AUCTION,
            {
              next_min_bid_amount: {
                token_id: tokenId,
                token_address: nft,
              },
            }
          )) as string;
          auctionInfo.min_bid_amount = min_bid_amount;
        } catch {
          console.log(
            'auction min bid amount not available: ' + nft + ' / ' + tokenId
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  // query nft saleInfo
  const saleAddress = nftConfig[networkType].SALE;
  let saleInfo: NFTSaleInfo | undefined = undefined;

  if (_info.inSale) {
    try {
      const saleOwner = (await client.queryContractSmart(saleAddress, {
        nft_owner_info: {
          nft_contract_addr: nft,
          nft_token_id: tokenId,
        },
      })) as string;

      saleInfo = (await client.queryContractSmart(saleAddress, {
        nft_info: {
          wallet: saleOwner,
          nft_contract_addr: nft,
          nft_token_id: tokenId,
        },
      })) as NFTSaleInfo;
    } catch (e) {
      console.log(e);
    }
  }

  // query nft metadata
  const metadata: LaunchpadNFTMetadata = {
    attributes: [],
    ...JSON.parse(_info.metadata),
  }

  if (_info.attributes) {
    metadata.attributes = JSON.parse(_info.attributes)
  }

  return {
    mintConfig,
    info,
    stakeInfo,
    metadata,
    auctionInfo,
    saleInfo,
    daysStaked: 0,
    daysRemain: 0,
    dailyValue1: new Dec('0'),
    totalValue1: new Dec('0'),
    dailyValue2: new Dec('0'),
    totalValue2: new Dec('0'),
    royalty_bp: nftContractInfo.royalty_bps
      ? nftContractInfo.royalty_bps.reduce((acc, cur) => acc + cur, 0)
      : 0,
    contracts: {
      nft: nft,
      nftStaking: stakingAddress,
      nftAuction: auctionAddress,
      nftSale: saleAddress,
    },
    isInStaking: _info.inStaking,
  } as LaunchPadNFTQueryInfo;
};
