import { NotificationContext } from '../../../contexts/notification';
import WebAppApproval from "./WebAppApproval";
import WebAppComplete from './WebAppComplete';
import WebAppFail from './WebAppFail';
import WebAppInProgress from './WebAppInProgress';

const WebAppNotificationModals = () => (
  <NotificationContext.Consumer>
    {({ theme, setTheme }) => {
      if (theme.type === 0 && theme.subType === 0)
        return <WebAppApproval setTheme={setTheme} />;
      else if (theme.type === 0 && theme.subType === 1)
        return <WebAppInProgress setTheme={setTheme} />;
      else if (theme.type === 0 && theme.subType === 2)
        return (
          <WebAppComplete
            setTheme={setTheme}
            txHash={theme.txHash}
            redirectURL={theme.redirectURL}
          />
        );
      else if (theme.type === 0 && theme.subType === 3)
        return <WebAppFail setTheme={setTheme} message={theme.txErrorMessage} />;
      else return <></>;
    }}
  </NotificationContext.Consumer>
);

export default WebAppNotificationModals;
