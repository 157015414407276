import { useQuery } from 'react-query';
import { Dec } from '@terra-money/terra.js';

import { Network, USDC_DENOM } from 'core/constants';
import { commonConfig } from 'core/common';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';
import { isJunoContractAddress } from 'utils/check';

export type MintConfigCosmWasm = {
  nft_addr: string;
  nft_max_supply: number;
  nft_current_supply: number;
  nft_price_amount: string;
  nft_symbol: string;
  mint_start_time: number;
  public_mint_limit: number;
  public_mint_period: number;
  whitelist_mint_limit: number;
  whitelist_mint_period: number;
  public_mint_price?: string;
  whitelist_mint1_limit?: number;
  whitelist_mint1_period?: number;
  whitelist_mint1_price?: string;
  whitelist_mint2_limit?: number;
  whitelist_mint2_period?: number;
  whitelist_mint2_price?: string;
};

export type RewardTokenInfo = {
  info: {
    Token?: {
      contract_addr: string;
    };
    NativeToken?: {
      denom: string;
    };
  };
  amount: string;
};

export type TierInfo = {
  creator: string;
  current_supply: string;
  description: string;
  max_supply: string;
  name: string;
  tier_index: string;
  token1_addr: string;
  token1_amount: string;
  token2_addr: string;
  token2_amount: string;
  vesting_period: string;
};

export type TokenInfo = {
  token: {
    contract_addr: string;
  };
};

export type NativeTokenInfo = {
  native_token: {
    denom: string;
  };
};

export type PairInfo = {
  asset_infos: (TokenInfo | NativeTokenInfo)[];
  contract_addr: string;
  liquidity_token: string;
};

export type PoolInfo = {
  assets: [
    {
      info: TokenInfo | NativeTokenInfo;
      amount: string;
    },
    {
      info: TokenInfo | NativeTokenInfo;
      amount: string;
    }
  ];
  total_share: string;
};

export type TokenReserve = {
  [token: string]: {
    tokenAmount: string;
    usdcAmount: string;
  };
};

export type NFTMinterQuery = {
  mintConfig: MintConfigCosmWasm;
  is_whitelisted: boolean;
  tier_infos: TierInfo[];
  token_reserve: TokenReserve;
  supplyByAddress: number;
};

export const useLaunchPadNFTMinterQuery = (
  networkType: Network,
  nftMinter: string,
  user: string
): NFTMinterQuery | undefined => {
  const { data, isLoading } = useQuery<unknown, unknown, NFTMinterQuery>(
    ['nftMinterQuery', networkType, nftMinter, user],
    async () => {
      const queryClient = await getQueryClient();

      const [
        mintConfig,
        tierInfosResult,
        supplyByAddress,
        isWhitelisted1,
        isWhitelisted2,
      ] = (await Promise.all([
        queryClient.queryContractSmart(nftMinter, {
          config: {},
        }),
        queryClient.queryContractSmart(nftMinter, {
          tier_infos: {},
        }),
        user
          ? queryClient.queryContractSmart(nftMinter, {
              supply_by_address: {
                addr: user,
              },
            })
          : 0,
        user
          ? queryClient.queryContractSmart(nftMinter, {
              is_whitelisted1: {
                addr: user,
              },
            })
          : false,
        user
          ? queryClient.queryContractSmart(nftMinter, {
              is_whitelisted2: {
                addr: user,
              },
            })
          : false,
      ])) as [
        MintConfigCosmWasm,
        {
          creator: string;
          current_supply: string;
          description: string;
          max_supply: string;
          name: string;
          tier_index: string;
          token1?: RewardTokenInfo;
          token2?: RewardTokenInfo;
          vesting_period: string;
        }[],
        number,
        boolean,
        boolean
      ];

      let isWhitelisted = false;
      if (!mintConfig.nft_price_amount) {
        // change to whitelist1 and whitelist2
        const cur = Math.floor(new Date().getTime() / 1000);
        if (
          cur >=
          mintConfig.mint_start_time + mintConfig.whitelist_mint1_period!
        ) {
          mintConfig.whitelist_mint_limit = mintConfig.whitelist_mint2_limit!;
          mintConfig.whitelist_mint_period =
            mintConfig.whitelist_mint1_period! +
            mintConfig.whitelist_mint2_period!;

          if (isWhitelisted1) {
            mintConfig.whitelist_mint_limit +=
              mintConfig.whitelist_mint1_limit!;
          }

          if (
            cur >=
            mintConfig.mint_start_time +
              mintConfig.whitelist_mint1_period! +
              mintConfig.whitelist_mint2_period!
          ) {
            mintConfig.nft_price_amount =
              mintConfig.public_mint_price!.toString();
            isWhitelisted = true;
            if (isWhitelisted1) {
              mintConfig.public_mint_limit += mintConfig.whitelist_mint1_limit!;
            }
            if (isWhitelisted2) {
              mintConfig.public_mint_limit += mintConfig.whitelist_mint2_limit!;
            }
          } else {
            mintConfig.nft_price_amount =
              mintConfig.whitelist_mint2_price!.toString();
            isWhitelisted = isWhitelisted2;
          }
        } else {
          mintConfig.nft_price_amount =
            mintConfig.whitelist_mint1_price!.toString();
          mintConfig.whitelist_mint_limit = mintConfig.whitelist_mint1_limit!;
          mintConfig.whitelist_mint_period = mintConfig.whitelist_mint1_period!;
          isWhitelisted = isWhitelisted1;
        }
      }

      const tierInfos: TierInfo[] = tierInfosResult.map(info => {
        return {
          creator: info.creator,
          current_supply: info.current_supply,
          description: info.description,
          max_supply: info.max_supply,
          name: info.name,
          tier_index: info.tier_index,
          token1_addr:
            info.token1?.info.NativeToken?.denom ||
            info.token1?.info.Token?.contract_addr ||
            '',
          token1_amount: info.token1?.amount || '0',
          token2_addr:
            info.token2?.info.NativeToken?.denom ||
            info.token2?.info.Token?.contract_addr ||
            '',
          token2_amount: info.token2?.amount || '0',
          vesting_period: info.vesting_period,
        };
      });

      const isToken: {
        [token: string]: boolean;
      } = {};
      const tokens = [];
      const pairInfoQueries = [];
      for (let i = 0; i < tierInfos.length; i++) {
        if (
          !isToken[tierInfos[i].token1_addr] &&
          new Dec(tierInfos[i].token1_amount).gt(0) &&
          commonConfig[networkType].IS_AVAILABLE_TOKEN[tierInfos[i].token1_addr]
        ) {
          isToken[tierInfos[i].token1_addr] = true;
          tokens.push(tierInfos[i].token1_addr);
          pairInfoQueries.push(
            queryClient.queryContractSmart(
              commonConfig[networkType].SWAP_FACTORY,
              {
                pair: {
                  asset_infos: [
                    isJunoContractAddress(tierInfos[i].token1_addr)
                      ? {
                          token: {
                            contract_addr: tierInfos[i].token1_addr,
                          },
                        }
                      : {
                          native_token: {
                            denom: tierInfos[i].token1_addr,
                          },
                        },
                    {
                      native_token: {
                        denom: USDC_DENOM,
                      },
                    },
                  ],
                },
              }
            )
          );
        }
        if (
          !isToken[tierInfos[i].token2_addr] &&
          new Dec(tierInfos[i].token2_amount).gt(0) &&
          commonConfig[networkType].IS_AVAILABLE_TOKEN[tierInfos[i].token2_addr]
        ) {
          isToken[tierInfos[i].token2_addr] = true;
          tokens.push(tierInfos[i].token2_addr);
          pairInfoQueries.push(
            queryClient.queryContractSmart(
              commonConfig[networkType].SWAP_FACTORY,
              {
                pair: {
                  asset_infos: [
                    isJunoContractAddress(tierInfos[i].token2_addr)
                      ? {
                          token: {
                            contract_addr: tierInfos[i].token2_addr,
                          },
                        }
                      : {
                          native_token: {
                            denom: tierInfos[i].token2_addr,
                          },
                        },
                    {
                      native_token: {
                        denom: USDC_DENOM,
                      },
                    },
                  ],
                },
              }
            )
          );
        }
      }
      const pairInfos = (await Promise.all(pairInfoQueries)) as PairInfo[];

      const poolInfoQueries = [];
      for (let i = 0; i < pairInfos.length; i++) {
        poolInfoQueries.push(
          queryClient.queryContractSmart(pairInfos[i].contract_addr, {
            pool: {},
          })
        );
      }
      const poolInfos = (await Promise.all(poolInfoQueries)) as PoolInfo[];

      const token_reserve: TokenReserve = {};
      for (let i = 0; i < tokens.length; i++) {
        if (
          (poolInfos[i].assets[1].info as NativeTokenInfo).native_token
            .denom === USDC_DENOM
        ) {
          token_reserve[tokens[i]] = {
            tokenAmount: poolInfos[i].assets[0].amount,
            usdcAmount: poolInfos[i].assets[1].amount,
          };
        } else {
          token_reserve[tokens[i]] = {
            tokenAmount: poolInfos[i].assets[1].amount,
            usdcAmount: poolInfos[i].assets[0].amount,
          };
        }
      }

      // quick fix, will remove after watr-warriors
      // Remove first array
      if (nftMinter === 'juno1h5ntvxkrnwa5v2pms6auyy58vcnutu2m3z9wjq7gpfvv8jed5mqsf29hl3') {
        tierInfos.shift(); 
      }

      return {
        mintConfig,
        is_whitelisted: isWhitelisted,
        tier_infos: tierInfos,
        token_reserve,
        supplyByAddress,
      };
    }
  );

  if (isLoading || !data) {
    return undefined;
  }

  console.log('NFT Minter Query Data', data);

  return data;
};

export default useLaunchPadNFTMinterQuery;
