import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NftFooter from '../NftFooter';
import LaunchPad from '../../Launchpad/launchpad';
import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import card from '../../../assets/featured_nft.png';
import star from '../../../assets/tara.svg';
import { Helmet } from 'react-helmet';
import * as api from '../../../api/welcome.api';
import styles from './NftWelcome.module.scss';

const NftWelcome: React.FC = (props: any) => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    if (!pageData) {
      api.fetchPageData().then(data => setPageData(data));
    }
  }, [pageData]);

  return (
    <div>
      <Helmet>
        <title>Loop DeFi NFTs</title>
      </Helmet>
      {/* Banner Section */}
      <div className='discoverBanner'>
        <div className='bannerText'>
          <div className='wrapper'>
            <div className='textHub'>
              <div className='welcomeTexthandle'>
                <div className='welcomeText'>
                  <p className='_fancy'>Welcome to where</p>
                  <h1>DeFi Meets NFTs</h1>
                  <p>
                    Find NFTs with real utility. real governance. real rewards{' '}
                  </p>
                </div>
                {/* <div className='comingSoon'>
                    <img src='/coming-soon.png' alt='coming-soon.png' />
                  </div> */}
                <div className='discoverBannerBtn'>
                  <Link
                    className='exploreBtn'
                    to={{
                      pathname: '/collections',
                    }}
                  >
                    EXPLORE
                  </Link>
                  <Link
                    to={{
                      pathname: '/nftExplore',
                    }}
                  >
                    REWARDS
                  </Link>
                </div>
              </div>
              <div className='textHubCard'>
                <div className='taraCard'>
                  <img src={star} alt='' />
                </div>
                {pageData ? (
                  <>
                    <section>
                      <a href={pageData?.link}>
                        <img
                          src={
                            'https://loop-markets.directus.app/assets/' +
                            pageData.nft_image.id
                          }
                          alt='Card'
                          className='featured_nft'
                        />
                        <b>Featured NFT</b>
                      </a>
                    </section>
                    <a href={pageData?.link}>
                      <div className='textHubValue'>
                        <label>
                          <h6>
                            <small>
                              {pageData?.nft_title}: #{pageData?.nft_id}
                            </small>
                          </h6>
                          <p>
                            <b>{pageData?.nft_name}</b>
                          </p>
                        </label>
                        <button>View NFT</button>
                      </div>
                    </a>
                  </>
                ) : (
                  <>
                    <section>
                      <a href='#'>
                        <div className={styles.imgPlaceholder} />
                        <b>Featured NFT</b>
                      </a>
                    </section>
                    <a href="#">
                      <div className='textHubValue'>
                        <label>
                          <h6>
                            <small>
                              . . .
                            </small>
                          </h6>
                          <p>
                            <b>. . .</b>
                          </p>
                        </label>
                        <button>View NFT</button>
                      </div>
                    </a>
                  </>
                )}
                {/*
                  <div className='textHubValue'>
                    <label>
                      <h6>Spaced Ape #777</h6>
                      <p>
                        Minimum Bid: <b>5,000 USDC</b>
                      </p>
                    </label>
                    <button>PLACE BID</button>
                  </div>
                  */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<LaunchPad onlyFeatured={true} />*/}
      {/* @dev disable promoted section for v1 launch
         <NftPromotedSection />
        */}
      {/* @dev disable Categories Section for v1 launch
         <div className='category'>
          <div className='wrapper'>
            <div className='categoryInner'>
              <h2>Categories</h2>
              <ul>
                <Swiper
                  slidesPerView={4}
                  spaceBetween={15}
                  navigation
                  loop
                  autoplay={{ delay: 7000 }}
                  className='feedSwiper'
                  slideToClickedSlide
                  breakpoints={{
                    1100: {
                      freeMode: true,
                      slidesPerView: 4,
                      spaceBetween: 15,
                    },
                    1080: {
                      freeMode: true,
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    768: {
                      freeMode: true,
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                    320: {
                      freeMode: true,
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}
                >
                  <SwiperSlide>
                    <li>
                      <Link
                        to={{
                          pathname: '/nftExplore',
                          state: {
                            category: 'LOOP',
                          },
                        }}
                      >
                        <img src='/ico/looplp.png' alt='category' /> LOOP LP
                        Bonds
                      </Link>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li>
                      <Link
                        to={{
                          pathname: '/nftExplore',
                          state: {
                            category: 'LOOPR',
                          },
                        }}
                      >
                        <img src='/ico/looplp.png' alt='category' /> LOOPR LP
                        Bonds
                      </Link>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li>
                      <Link
                        to={{
                          pathname: '/nftExplore',
                          state: {
                            category: 'LUNA',
                          },
                        }}
                      >
                        <img src='/ico/lunalp.png' alt='category' /> LUNA LP
                        Bonds
                      </Link>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li>
                      <Link
                        to={{
                          pathname: '/nftExplore',
                          state: {
                            category: 'BTC',
                          },
                        }}
                      >
                        <img src='/ico/btclp.png' alt='category' /> BTC LP Bonds
                      </Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide>
                    <li>
                      <Link
                        to={{
                          pathname: '/nftExplore',
                          state: {
                            category: 'ETH',
                          },
                        }}
                      >
                        <img src='/ico/ethlp.png' alt='category' /> ETH LP Bonds
                      </Link>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li>
                      <Link
                        to={{
                          pathname: '/nftExplore',
                          state: {
                            category: 'BNB',
                          },
                        }}
                      >
                        <img src='/ico/bnblp.png' alt='category' /> BNB LP Bonds
                      </Link>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li>
                      <Link
                        to={{
                          pathname: '/nftExplore',
                          state: {
                            category: 'SOL',
                          },
                        }}
                      >
                        <img src='/ico/sollp.png' alt='category' /> SOL LP Bonds
                      </Link>
                    </li>
                  </SwiperSlide>
                </Swiper>
              </ul>
            </div>
          </div>
        </div>
         */}
      <div className='creator'>
        <div className='wrapper'>
          <div className='creatorInner'>
            <h2>Your Users want NFTs with utility. Give them Reward NFTs.</h2>
            <ul>
              <li>
                <span>
                  <img src='/rewards_icon.png' alt='creator' />
                </span>
                <b>BE REWARDING</b>
                <p>
                  Sustainably distribute gaming, DAO, and utility tokens with
                  your Reward NFTs
                </p>
              </li>
              <li>
                <span>
                  <img
                    src='/levelup_icon.png'
                    alt='creator'
                    className='levelup'
                  />
                </span>
                <b>LEVEL-UP</b>
                <p>
                  Breathe excitement into your NFT mints with the Level-Up
                  Launchpad
                </p>
              </li>
              <li>
                <span>
                  <img
                    src='/govern_icon.png'
                    className='govern'
                    alt='creator'
                  />
                </span>
                <b>GOVERN</b>
                <p>
                  Reward your most active DAO members with NFTs packed with DAO
                  tokens
                </p>
              </li>
              <li>
                <span>
                  <img src='/c2.svg' alt='creator' />
                </span>
                <b>BE CREATIVE</b>
                <p>
                  DeFi is more than tokens. What could your project accomplish
                  with Reward NFTs?
                </p>
              </li>
            </ul>
          </div>
          <div className='flex'>
            <a
              href='https://www.loop.markets/using-reward-nfts-for-user-engagement-sustainable-token-distribution-and-more/'
              target='_blank'
              className='button_outline'
              rel='noreferrer'
            >
              LEARN MORE
            </a>
            <a
              href='https://docs.loop.markets/loop-finance/nft-marketplace/project-launch-info/requirements/application'
              target='_blank'
              className='button_outline'
              rel='noreferrer'
            >
              APPLY NOW
            </a>
          </div>
        </div>
      </div>
      <NftFooter />
    </div>
  );
};

export default NftWelcome;
