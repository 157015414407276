import {
  CosmWasmClient,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { walletState, WalletStatusType } from '../../state/walletAtoms';
import { useWalletManager } from '@noahsaso/cosmodal';

import chainData from '../../chain_info.mainnet.json';

export const getQueryClient = () =>
  CosmWasmClient.connect(chainData.rpc) as any;

export const useJunoConnectedWallet = (
  mutationOptions?: Parameters<typeof useMutation>[2]
) => {
  const [{ status }, setWalletState] = useRecoilState(walletState);
  const { connectedWallet } = useWalletManager();

  const mutation = useMutation(async () => {
    const queryClient = await getQueryClient();
    /* set the fetching state */
    setWalletState(value => ({
      ...value,
      client: chainData.rpc ? queryClient : null,
      state: WalletStatusType.connecting,
    }));

    if (connectedWallet) {
      try {
        if (window.keplr) {
          await window.keplr.experimentalSuggestChain(chainData);
          await window.keplr.enable(chainData.chainId);
        }

        const offlineSigner = connectedWallet.offlineSigner;

        const wasmChainClient = await SigningCosmWasmClient.connectWithSigner(
          chainData.rpc,
          offlineSigner
        );

        const [{ address }] = await offlineSigner.getAccounts();

        /* successfully update the wallet state */
        setWalletState({
          address,
          client: wasmChainClient,
          status: WalletStatusType.connected,
        });
      } catch (e) {
        /* set the error state */
        setWalletState({
          address: '',
          client: chainData.rpc ? queryClient : null,
          status: WalletStatusType.error,
        });

        /* throw the error for the UI */
        throw e;
      }
    } else {
      setWalletState({
        key: undefined,
        address: '',
        client: chainData.rpc ? queryClient : null,
        status: WalletStatusType.error,
      });
    }
  }, mutationOptions);

  useEffect(() => {
    /* restore wallet connection if the state has been set with the */
    if (
      chainData?.rpc &&
      [WalletStatusType.restored, WalletStatusType.connected].includes(status)
    ) {
      mutation.mutate(null);
    }
  }, [status, chainData?.rpc]); // eslint-disable-line

  useEffect(() => {
    function reconnectWallet() {
      if (
        [WalletStatusType.restored, WalletStatusType.connected].includes(status)
      ) {
        mutation.mutate(null);
      }
    }

    window.addEventListener('keplr_keystorechange', reconnectWallet);
    return () => {
      window.removeEventListener('keplr_keystorechange', reconnectWallet);
    };
  }, [status]); // eslint-disable-line

  return mutation;
};
