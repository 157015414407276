import { Dec } from '@terra-money/terra.js';
import Modal from 'components/Modal';
import { commonConfig, denomToSymbol, getIpfsUrl } from 'core/common';
import { networkType, nftConfig } from 'core/nftConfig';
import {
  bidAuctionNFTMsg,
  nftCancelAuctionMsg,
  nftCancelSellMsg,
  nftClaimAuctionMsg,
  nftStakeMsg,
  nftUnstakeMsg,
} from 'helper/junoContractMsg';
import { EligibleNFT } from 'hooks/useEligibleStakeNFTs';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { USDC_DENOM } from 'core/constants';
import { formatNumberString } from 'utils/conversion/conversion';
import ToolTip from 'components/ToolTips';
import { queryLoopPowerStaking } from 'hooks/queryLoopPowerStaking';

const LaunchpadCard = ({ data }: { data: EligibleNFT }) => {
  const {
    tokenId,
    contract,
    info,
    stakeInfo,
    auctionInfo,
    metadata,
    isInAuction,
    isInSale,
    daysStaked,
    daysRemain,
    dailyValue,
    totalValue,
    saleInfo,

    myBidAmount,
  } = data;

  const nftInfo = nftConfig[networkType].launchPadNFTs.find(
    item => item.NFT === contract
  );
  const { address } = useRecoilValue(walletState);
  const { estimateGas, submit } = useJunoTransactions();
  const [bidModal, setBidModal] = useState(false);
  const [bidPrice, setBidPrice] = useState('');

  const isPower = contract === "juno1pkye52d2zhqj6xuszfzjywt042z6hh279d5lydz25qsxyhw2dpys9z5vwf";
  const [lpStaked, setLpStaked] = useState(false);
  useEffect(() => {
    queryLoopPowerStaking(address)?.then((r:boolean) => {
      setLpStaked(r);
    });
  }, [isPower, address]);
  
  const [superLoopPowerModal, setSuperLoopPowerModal] = useState(false);
  const isSuperLoopPower =
    contract ===
      'juno1pkye52d2zhqj6xuszfzjywt042z6hh279d5lydz25qsxyhw2dpys9z5vwf' &&
    metadata.attributes.findIndex(
      attribute =>
        attribute.trait_type === 'SUPER LOOP Power' &&
        attribute.value === 'True'
    ) !== -1;

  const bidAuctionHandler = useCallback(async () => {
    const txMsg = bidAuctionNFTMsg({
      address,
      networkType,
      nft: contract,
      nft_auction: nftConfig[networkType].AUCTION,
      token_id: tokenId,
      coins: [
        {
          amount: new Dec(bidPrice).mul(1000000).toFixed(0),
          denom: USDC_DENOM,
        },
      ],
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({ msgs: [txMsg] });
  }, [address, bidPrice, contract, estimateGas, submit, tokenId]);

  const stakeHandler = useCallback(async () => {
    const txMsg = nftStakeMsg({
      address,
      networkType,
      nft: contract,
      nft_staking: nftConfig[networkType].STAKING,
      token_id: tokenId,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({ msgs: [txMsg] });
  }, [address, contract, estimateGas, submit, tokenId]);

  const unStakeHandler = useCallback(async () => {
    const txMsg = nftUnstakeMsg({
      address,
      networkType,
      nft: contract,
      nft_staking: nftConfig[networkType].STAKING,
      token_id: tokenId,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({ msgs: [txMsg] });
  }, [address, contract, estimateGas, submit, tokenId]);

  const cancelHandler = useCallback(async () => {
    const txMsgs = [
      isInAuction
        ? (auctionInfo?.end_time || 0) < Math.floor(new Date().getTime() / 1000)
          ? nftClaimAuctionMsg({
              address,
              networkType,
              nft: contract,
              nft_auction: nftConfig[networkType].AUCTION,
              token_id: tokenId,
            })
          : nftCancelAuctionMsg({
              address,
              networkType,
              nft: contract,
              nft_auction: nftConfig[networkType].AUCTION,
              token_id: tokenId,
            })
        : nftCancelSellMsg({
            address,
            networkType,
            nft: contract,
            nft_sale: nftConfig[networkType].SALE,
            token_id: tokenId,
          }),
    ];

    console.log('txMsgs = ', txMsgs);
    const fee = await estimateGas(txMsgs);
    console.log('FEE:', fee);
    submit({ msgs: txMsgs });
  }, [
    address,
    auctionInfo?.end_time,
    contract,
    estimateGas,
    isInAuction,
    submit,
    tokenId,
  ]);

  const claimAuctionHandler = useCallback(async () => {
    const txMsgs = [
      nftClaimAuctionMsg({
        address,
        networkType,
        nft: contract,
        nft_auction: nftConfig[networkType].AUCTION,
        token_id: tokenId,
      }),
    ];

    console.log('txMsgs = ', txMsgs);
    const fee = await estimateGas(txMsgs);
    console.log('FEE:', fee);
    submit({ msgs: txMsgs });
  }, [address, contract, tokenId, estimateGas, submit]);

  return (
    <>
      <Link
        to={`${
          window.location.pathname.startsWith('/webapp')
            ? '/webapp/nftDetail'
            : '/nftDetail'
        }/${contract}/${tokenId}`}
      >
        {nftInfo?.disabled && <ToolTip />}
        {metadata && (
          <>
            <div className='nftRightChild'>
              <span>
                <img src={getIpfsUrl(metadata?.image, true)} alt='' />
              </span>
              <div className='mynftHub'>
                <div className='mynftHubHedare'>
                  <label className='nftTitle'>
                    {/* <img src='fav.png' alt='' /> */}
                    <div>
                      {metadata.name}
                      {info.tier_index && (
                        <>
                          {' '}
                          |{' '}
                          <span style={{ color: '#9ab0ce' }}>
                            Tier {info.tier_index}
                          </span>
                        </>
                      )}
                    </div>
                    {isInAuction && (
                      <div className='bidInfo'>
                        <img src='/hammer_blue.png' alt='hammer' />
                        {myBidAmount ? (
                          <>
                            Your bid:
                            <span className='bidPrice'>
                              {formatNumberString(
                                new Dec(myBidAmount).div(1000000)
                              )}
                            </span>
                            {
                              denomToSymbol[
                                auctionInfo?.coin_denom || USDC_DENOM
                              ]
                            }
                            {auctionInfo?.high_bidder_addr.toLowerCase() !==
                              address.toLowerCase() && (
                              <>
                                <span className='separator'></span>
                                Highest bid:
                                <span className='bidPrice'>
                                  {formatNumberString(
                                    new Dec(
                                      auctionInfo?.high_bidder_amount
                                    ).div(1000000)
                                  )}
                                </span>
                                {
                                  denomToSymbol[
                                    auctionInfo?.coin_denom || USDC_DENOM
                                  ]
                                }
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            Highest bid:
                            <span className='bidPrice'>
                              {formatNumberString(
                                new Dec(auctionInfo?.high_bidder_amount).div(
                                  1000000
                                )
                              )}
                            </span>
                            {
                              denomToSymbol[
                                auctionInfo?.coin_denom || USDC_DENOM
                              ]
                            }
                          </>
                        )}
                      </div>
                    )}
                    {isInSale && (
                      <div className='bidInfo'>
                        <img src='/hammer_blue.png' alt='hammer' />
                        Sale price:
                        <span className='bidPrice'>
                          {formatNumberString(
                            new Dec(saleInfo?.amount).div(1000000)
                          )}
                        </span>
                        {denomToSymbol[saleInfo?.denom || USDC_DENOM]}
                      </div>
                    )}
                  </label>
                  <div className='nftButtons'>
                    {stakeInfo.is_staking ? (
                      <button
                        onClick={e => {
                          unStakeHandler();
                          e.preventDefault();
                        }}
                      >
                        <img src='/unstake.png' alt='' /> UNSTAKE
                      </button>
                    ) : isInAuction && myBidAmount ? (
                      address.toLowerCase() !==
                      auctionInfo?.high_bidder_addr ? (
                        <></>
                      ) : (
                        <>
                          {isInAuction &&
                            (auctionInfo?.end_time || 0) <
                              Math.floor(new Date().getTime() / 1000) && (
                              <button
                                onClick={e => {
                                  claimAuctionHandler();
                                  e.preventDefault();
                                }}
                                style={{ background: '#c83e93' }}
                              >
                                CLAIM
                              </button>
                            )}
                          {/* <button
                      onClick={() => {
                        setBidModal(true);
                      }}
                    >
                      RAISE BID
                    </button> */}
                        </>
                      )
                    ) : (
                      <>
                        {isInAuction && (
                          <span
                            style={{
                              width: 'auto',
                              background: 'none',
                              color: '#c83e93',
                            }}
                          >
                            {/*<img src='/stake.png' alt='' />*/} IN AUCTION
                          </span>
                        )}
                        {isInSale && (
                          <span
                            style={{
                              width: 'auto',
                              background: 'none',
                              color: '#c83e93',
                            }}
                          >
                            {/*<img src='/stake.png' alt='' />*/} ON SALE
                          </span>
                        )}
                        {!isInAuction && !isInSale && (
                          <button
                            disabled={nftInfo?.disabled}
                            onClick={e => {
                              if (isSuperLoopPower || lpStaked) {
                                setSuperLoopPowerModal(true);
                              } else {
                                stakeHandler();
                              }
                              e.preventDefault();
                            }}
                            style={{ background: '#32fe9a' }}
                          >
                            {/* Remove --stakeToolTip-- ToopTip When DEX trading begins*/}
                            <b data-tip data-for='stakeToolTip'>
                              <img src='/plus-black.png' alt='' width={'16'} />{' '}
                              STAKE
                            </b>
                          </button>
                        )}
                        {isInAuction && (
                          <button
                            onClick={e => {
                              cancelHandler();
                              e.preventDefault();
                            }}
                            style={{ background: '#c83e93' }}
                          >
                            {(auctionInfo?.end_time || 0) <
                            Math.floor(new Date().getTime() / 1000)
                              ? 'CLAIM'
                              : 'CANCEL AUCTION'}
                          </button>
                        )}
                        {isInSale && (
                          <button
                            onClick={e => {
                              cancelHandler();
                              e.preventDefault();
                            }}
                            style={{ background: '#c83e93' }}
                          >
                            CANCEL SALE
                          </button>
                        )}
                      </>
                    )}
                    {!isInAuction && !isInSale && (
                      <Link to={`/nftDetail/${contract}/${tokenId}`}>
                        <button>
                          <img src='/sell.png' alt='' /> MANAGE
                        </button>
                      </Link>
                    )}
                  </div>
                </div>

                <div className='nftData'>
                  <label>
                    <b>
                      {isInAuction || isInSale
                        ? 'Remaining Rewards'
                        : 'Claimable Rewards'}
                      {/* <img src='info.png' alt='' /> */}
                    </b>
                    <div>
                      <div>
                        {new Dec(info.token1_amount).gt(0) && (
                          <p>
                            {isInAuction || isInSale
                              ? formatNumberString(
                                  new Dec(info.token1_amount)
                                    .mul(
                                      info.vesting_period
                                        ? Math.max(
                                            parseInt(info.vesting_period) -
                                              stakeInfo.claimed_days,
                                            0
                                          )
                                        : 1
                                    )
                                    .div(1000000)
                                )
                              : formatNumberString(
                                  new Dec(info.token1_amount)
                                    .mul(
                                      info.vesting_period
                                        ? Math.max(
                                            Math.min(
                                              Math.floor(
                                                stakeInfo.total_staked_time /
                                                  60 /
                                                  60 /
                                                  24
                                              ),
                                              parseInt(info.vesting_period)
                                            ) - stakeInfo.claimed_days,
                                            0
                                          )
                                        : stakeInfo.is_staking
                                        ? 1
                                        : 0
                                    )
                                    .div(1000000)
                                )}{' '}
                            <i>
                              {
                                commonConfig[networkType].TOKEN_SYMBOLS[
                                  info.token1_addr
                                ]
                              }
                            </i>
                          </p>
                        )}
                      </div>
                      <div>
                        {new Dec(info.token2_amount).gt(0) && (
                          <p>
                            {isInAuction || isInSale
                              ? formatNumberString(
                                  new Dec(info.token2_amount)
                                    .mul(
                                      info.vesting_period
                                        ? parseInt(info.vesting_period) -
                                            stakeInfo.claimed_days
                                        : 1
                                    )
                                    .div(1000000)
                                )
                              : formatNumberString(
                                  new Dec(info.token2_amount)
                                    .mul(
                                      info.vesting_period
                                        ? Math.min(
                                            Math.floor(
                                              stakeInfo.total_staked_time /
                                                60 /
                                                60 /
                                                24
                                            ),
                                            parseInt(info.vesting_period)
                                          ) - stakeInfo.claimed_days
                                        : stakeInfo.is_staking
                                        ? 1
                                        : 0
                                    )
                                    .div(1000000)
                                )}{' '}
                            <i>
                              {
                                commonConfig[networkType].TOKEN_SYMBOLS[
                                  info.token2_addr
                                ]
                              }
                            </i>
                          </p>
                        )}
                      </div>
                    </div>
                  </label>
                  <label>
                    <b>Daily Rewards</b>
                    <div>
                      <div>
                        <div>
                          {new Dec(info.token1_amount).gt(0) && (
                            <p>
                              {formatNumberString(
                                new Dec(info.token1_amount).div(1000000)
                              )}{' '}
                              <i>
                                {
                                  commonConfig[networkType].TOKEN_SYMBOLS[
                                    info.token1_addr
                                  ]
                                }
                                /DAY
                              </i>
                            </p>
                          )}
                        </div>
                        <div>
                          {new Dec(info.token2_amount).gt(0) && (
                            <p>
                              {formatNumberString(
                                new Dec(info.token2_amount).div(1000000)
                              )}{' '}
                              <i>
                                {
                                  commonConfig[networkType].TOKEN_SYMBOLS[
                                    info.token2_addr
                                  ]
                                }
                                /DAY
                              </i>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </label>
                  <label>
                    <b>Staking Value</b>
                    <p>
                      {formatNumberString(totalValue.div(1000000))} <i>USDC</i>
                    </p>
                  </label>
                  <label>
                    <b>Days Staked</b>
                    <p>{daysStaked ? daysStaked : 0}</p>
                  </label>
                  <label>
                    <b>Days Remaining </b>
                    <p>{daysRemain}</p>
                  </label>
                  <label>
                    <b>Daily Staking Value</b>
                    <p>
                      {formatNumberString(dailyValue.div(1000000))}{' '}
                      <i>USDC/DAY</i>
                    </p>
                  </label>
                </div>
              </div>
            </div>

            <Modal
              isOpen={bidModal}
              title=''
              onClose={() => setBidModal(false)}
            >
              <div className='SellAuction'>
                <h6>Bid</h6>
                <div className='aucHub'>
                  <section>
                    <span>
                      <img src={getIpfsUrl(metadata.image, true)} alt='' />
                    </span>
                  </section>
                  <div className='AucInput'>
                    <h5>{metadata.name}</h5>
                    <label>
                      Bid{' '}
                      <label>
                        Minimum Bid Price:{' '}
                        <span>
                          {formatNumberString(
                            new Dec(auctionInfo?.high_bidder_amount).div(
                              1000000
                            )
                          )}{' '}
                          <i>USDC</i>
                        </span>
                      </label>
                      <b>USDC</b>
                    </label>
                    <input
                      className={
                        new Dec(auctionInfo?.high_bidder_amount || '0')
                          .div(1000000)
                          .lt(new Dec(bidPrice || '0'))
                          ? ''
                          : 'invalid'
                      }
                      type='text'
                      placeholder='0.00'
                      value={bidPrice}
                      onChange={e => {
                        setBidPrice(e.target.value);
                      }}
                      onBlur={e => {
                        setBidPrice(
                          (parseFloat(e.target.value) || 0.0).toString()
                        );
                      }}
                    />
                    <img src='/USDClogo.png' alt='' />
                    <b>USDC</b>
                  </div>
                  {/* <p>
                      <b>
                        Royalty Fee: <i>4%</i>
                      </b>{' '}
                      |{' '}
                      <b>
                        Platfomr Fee: <i>2%</i>
                      </b>
                    </p> */}
                  <button
                    onClick={e => {
                      bidAuctionHandler();
                      e.preventDefault();
                    }}
                  >
                    CONFIRM
                  </button>
                </div>
              </div>
            </Modal>
          </>
        )}
      </Link>
      <Modal
        isOpen={superLoopPowerModal}
        title=''
        onClose={() => setSuperLoopPowerModal(false)}
        customClass='super_loop_power_modal'
      >
        <div className='SellAuction'>
          <div className='super_loop_power_dialog'>
            {!lpStaked ? (
              <>
                <h2>Good News!</h2>
                <span>Your NFT is eligible for 2 types of staking! </span>
                <span>
                  <b>1.</b> Stake here to receive your daily LOOP allocation & loose the SUPER LOOP Power attribute 
                </span>
                <span>
                  <b>2.</b> Harness the LOOP Power from this NFT <a href="https://juno.loop.markets/stake?tab=STAKING#stake" target="_blank" rel="noreferrer">here</a> to boost your LOOP Power rewards + voting power on the Community DAO and Gauges
                </span>
                <span>
                  Important: If you only do option 2 you will receive 12x LOOP Power for each LOOP in your NFT. If you do option 1 first then option 2: you will only receive 3x LOOP Power for each remaining LOOP in your NFT.
                </span>

                <div>
                  <button
                    onClick={() => {
                      setSuperLoopPowerModal(false);
                    }}
                  >
                    CLOSE
                  </button>
                  <button onClick={() => stakeHandler()}>STAKE ANYWAY</button>
                </div>
              </>
            ) : (
              <>
                <h2 style={{marginTop: "20%"}}>Already Staked</h2>
                <span>
                  You are currently staking this NFT to boost your LOOP Power! First unstake
                  on the <a href='https://juno.loop.markets/stake?tab=STAKING#stake' target='_blank'>LOOP Power Dashboard</a> to be able to stake here
                </span>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LaunchpadCard;
