import { Dec } from '@terra-money/terra.js';
import { commonConfig } from 'core/common';
import { networkType } from 'core/nftConfig';
import { junoAddress } from 'core/utils';
import { NFTMinterQuery } from 'hooks/useLaunchPadNFTMinterQuery';
import CopyToClipboard from 'react-copy-to-clipboard';
import hyperlink from '../../../assets/icons/hyperlink.svg';
import { formatNumberString } from 'utils/conversion/conversion';

const Additional_info = ({ info }: { info?: any }) => {
  return (
    <div className='launchRewards'>
      <div className='projectinfo'>
        <div className='desc'>
          <div className='info_header'>
            <h6>More Information</h6>
            <p>Always learn about NFT projects you mint! Check out their social media, chat with the founders, and get involved with projects you love and want to join!</p>
          </div>
          <div>
            <div className='split'>
              <div className='info_card info_card__left'>
                <p>Primary Use Case</p>
                <span>{info?.additional_info?.primary_usecase?.join(", ")}</span>
              </div>
              <div className='info_card info_card__right' style={{minWidth: '300px'}}>
                <p>Additional Use Case(s)</p>
                <span>{info?.additional_info?.additional_usecase?.join(", ")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='split'>
          <div className='info_card info_card__left' style={{width: '100%'}}>
            <p>Project Pre-mint</p>
            <span>{info?.additional_info?.pre_mint} {/* / {info?.launchInfo.maxSupply} */}</span>
          </div>
          <div className={!info?.additional_info?.community_walle ? 'info_card info_card__center' : 'info_card info_card__right'} style={{width: '100%'}}>
            <p>Founder Status</p>
            <span>{info?.additional_info?.founder_status}</span>
          </div>
          {info?.additional_info?.community_wallet && (
            <div className='info_card info_card__wallet info_card__right' style={{width: '100%', overflow: 'scroll'}}>
              <p>Community Wallet</p>
              <CopyToClipboard text={info?.additional_info?.community_wallet}>
                <span className='address'>
                  {junoAddress(info?.additional_info?.community_wallet, 40)} <img src={hyperlink} className="hyperlink" alt='hyperlink' />
                </span>
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
      <div className='tableHeader__mobile__divider' />
    </div>
  );
};

export default Additional_info;
