import { useCallback, useEffect, useState } from 'react';
import ArrowDown from '../../assets/arrow_down.png';
import ArrowUp from '../../assets/arrow_up.png';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {
  queryUserTransaction,
  UserActivity,
} from '../../hooks/queryUserActivity';
import { useRecoilValue } from 'recoil';
import { walletState } from '../../state/walletAtoms';
import { formatTokenPrice } from 'utils/conversion/conversion';
import { junoAddress } from 'core/utils';
import { denomToSymbol } from 'core/common';
import {useHistory} from "react-router-dom";
import * as api from "../../api/launchpad.api";

TimeAgo.addLocale(en);

const ActivityTable = () => {
  const timeAgo = new TimeAgo('en-US');
  const [isLoading, setIsLoading] = useState(true);
  const [NFTHistory, setNFTHistory] = useState<UserActivity[]>();
  const [activePanel, setActivePanel] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<boolean>(true); // true => descending, false => ascending
  const { address } = useRecoilValue(walletState);
  const userActivity = useCallback(async () => {
    setIsLoading(true);
    const res = await queryUserTransaction(address, activePanel, sortOrder);
    setNFTHistory(res.nodes);
    setIsLoading(false);
  }, [activePanel, sortOrder, address]);

  useEffect(() => {
    userActivity();
  }, [activePanel, sortOrder, userActivity, address]);

  const [launchpadList, setLaunchpadList] = useState<any>()

  useEffect(() => {
    if (!launchpadList) {
      api.FetchLaunchPadLiveList().then(launchpadList => setLaunchpadList(launchpadList))
    }
  }, [launchpadList])

  const history = useHistory();

  const handleClickHandle = (collectionName: string) => {
    const isLaunchpad = launchpadList?.find((item:any) => item.project_slug === collectionName)
    isLaunchpad ? history.push(`/launchpad/${collectionName}`) : history.push(`/exploreCollection/${collectionName}`);
  }

  const handleClickDetail = (address: string, id: string) => {
    history.push(`/nftDetail/${address}/${id}`);
  }

  return isLoading ? (
    <div className='noPosts'>
      <p
        className='loader'
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      ></p>
    </div>
  ) : (
    <div className='detailTrendingRight4' style={{ margin: 0 }}>
      <div className='tbleAbout'>
        <h6>
          {/* Activity Feed {' '} */}
          {/*<span>*/}
          {/*  <button*/}
          {/*    className={activePanel === '' ? 'activeActions' : ''}*/}
          {/*    onClick={() => setActivePanel('')}*/}
          {/*  >*/}
          {/*    All*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    className={activePanel === 'Bid' ? 'activeActions' : ''}*/}
          {/*    onClick={() => setActivePanel('Bid')}*/}
          {/*  >*/}
          {/*    Bid*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    className={activePanel === 'Mint' ? 'activeActions' : ''}*/}
          {/*    onClick={() => setActivePanel('Mint')}*/}
          {/*  >*/}
          {/*    Minted*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    className={activePanel === 'Sold' ? 'activeActions' : ''}*/}
          {/*    onClick={() => setActivePanel('Sold')}*/}
          {/*  >*/}
          {/*    Sold*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    className={activePanel == 'Rewards' ? 'activeActions' : ''}*/}
          {/*    onClick={() => setActivePanel('Rewards')}*/}
          {/*  >*/}
          {/*    Rewards*/}
          {/*  </button>*/}
          {/*</span>*/}
        </h6>

        <table>
          <thead>
            <tr>
              <th>Event</th>
              <th>Item</th>
              <th>Collection</th>
              <th>Price</th>
              <th>From</th>
              <th>To</th>
              <th
                className='th--cursor'
                onClick={() => setSortOrder(!sortOrder)}
              >
                Date{' '}
                {sortOrder ? (
                  <img src={ArrowDown} alt='updown' />
                ) : (
                  <img src={ArrowUp} alt='up' />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {NFTHistory?.map((history: any) => (
              <tr key={JSON.stringify(history)}>
                <td >
                  <div className="iconnftname">
                  <img src={`/icons/activity/${history?.event}.svg`} alt={""} style={{width: "35px", paddingRight: "10px"}} />
                  {history?.event}
                </div>
                </td>
                <td >
                  <div className="iconnftname" onClick={() => handleClickDetail(history?.nft_addr,history?.nft_id)}>
                  <img src={history?.icon} alt={""} />
                  {history?.nft?.name}
                </div>
                </td>
                <td className="collectionName" onClick={ () => handleClickHandle(history?.url)}>{history?.name}</td>
                {/*<td>*/}
                  {/*<td className='blueActive'>*/}
                  {/*{history.event === 'Mint' && (*/}
                  {/*  <img src={Star} alt='star-active.svg' />*/}
                  {/*)}*/}
                  {/*{history.event === 'Sold' && (*/}
                  {/*  <img src={Sold} alt='star-active.svg' />*/}
                  {/*)}*/}
                  {/*{history.event === 'Bid' && (*/}
                  {/*  <img src='/hammer.svg' alt='hammer.svg' />*/}
                  {/*)}*/}
                  {/*{history.event}*/}
                {/*</td>*/}
                <td>
                  {history.amount > 0 &&
                    `${formatTokenPrice(history.amount)} ${
                      denomToSymbol[history.denom] || 'USDC'
                    }`}
                </td>
                <td>{junoAddress(history.from)}</td>
                <td>{junoAddress(history.to)}</td>
                <td>
                  {timeAgo.format(new Date(history.timestamp).getTime())}

                  {history?.hash &&
                      <a href={`https://www.mintscan.io/juno/txs/${history?.hash}`} target={'_blank'} rel="noreferrer">
                        <img
                            className='expander'
                            src='/expander.svg'
                            alt='expander.svg'
                        />
                      </a>
                  }

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActivityTable;
