import { useState } from 'react';
import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import Countdown, { zeroPad } from 'react-countdown';
import CountdownComp from './countdown';
import { LaunchPadNFT } from 'core/nftConfig';
import { NFTMinterQuery } from 'hooks/useLaunchPadNFTMinterQuery';

import styles from './view.module.scss';
import chat from '../../../../assets/fans/chat.svg';
import unlock from '../../../../assets/fans/unlock.svg';
import video from '../../../../assets/fans/video.svg';

import fav from '../../../../assets/fans/fav.svg';
import notify from '../../../../assets/fans/notify.svg';
import share from '../../../../assets/fans/share.svg';

const UpcomingForm = ({
  queryResult,
  launchpadNFT,
  index,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT: LaunchPadNFT;
  index?: Number
}) => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
  const [mintInput, setMintInput] = useState(0);

  const mintValue = (e: any) => {
    const type = e.target.id;
  };

  const isMintStart =
    queryResult &&
    queryResult.mintConfig.mint_start_time !== 0 &&
    new Date().getTime() / 1000 > queryResult.mintConfig.mint_start_time;

  const isPublicMint =
    isMintStart &&
    new Date().getTime() / 1000 >=
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.public_mint_period;

  return (
    <>
      <div className={styles.project_social}>
        <img src={notify} alt='thumb' />
        <img src={fav} alt='thumb' />
        <img src={share} alt='thumb' />
      </div>

      <div className={styles.nft_view}>
        <div className={styles.header}>
          <h3>{launchpadNFT?.launchpad_type[Number(index)].collection.name}</h3>
          <p
            className={styles.about}
            dangerouslySetInnerHTML={{ __html: launchpadNFT?.launchpad_type[Number(index)].collection.description }}
          />
          <div className={styles.artist}>
            <div className={styles.profile}>
              <img
                src={
                  'https://loop-markets.directus.app/assets/' +
                  launchpadNFT?.artist.avatar
                }
                alt='thumb'
              />
              <div>
                <span>CREATOR</span>
                <p>{launchpadNFT?.artist.first_name}</p>
              </div>
            </div>
            <div className={styles.divider} />
            <div>
              <span>COLLECTION TYPE</span>
              <p>{launchpadNFT?.collection_type}</p>
            </div>
          </div>
          <div className={styles.benefits}>
            <span>Benefits</span>
            <div className={styles.benefit_details}>
              {launchpadNFT?.launchpad_type[Number(index)]?.benefits?.benefit?.map((r:any) => {
                return (
                  <label>
                    <p>
                      <img src={`/fans/${r.icon}.svg`}alt='' />{' '}
                      <b>{r?.name}</b>
                    </p>
                  </label>
                )
              })}
            </div>
          </div>
          <button className={styles.mint}>
              {launchpadNFT.launch_tba ? (
                <span className={styles.soon}>Coming Soon</span>
              ) : (
                <span>
                  <CountdownComp launchpadNFT={launchpadNFT} index={Number(index)} />
                </span>
              )}
          </button>
        </div>
        <div hidden className='footer'>
          <button id='send' onClick={e => mintValue(e)} disabled>
            {isPublicMint ? 'MINT NFT' : 'APPLY FOR ALLOW LIST'}
          </button>
        </div>
      </div>
    </>
  );
};

export default UpcomingForm;
