import {commonConfig, denomToSymbol, getIpfsUrl} from './common';
import { MarketNFTs } from '../hooks/useSaleNFTs';
import { Dec } from '@terra-money/terra.js';
import { NFT_COLLECTIONS } from './nftConfig';
import {USDC_DENOM} from "./constants";

export function junoAddress(input: string, sliceLength = -6): string {
  return `juno1...${input.slice(sliceLength)}`;
}

export type NFTtype = {
  img: string;
  tokenName: string;
  tokenId: string;
  owner: string;
  collection: string;
  name: string;
  catName: string;
  daysRemain: number;
  rewards1Amount: number;
  rewards1Symbol: string;
  rewards2Symbol: string;
  rewards2Amount: number;
  rewards1TotalAmount: number;
  rewards2TotalAmount: number;
  price: number;
  // endTime: ,
  type: string;
  desc: string;
  tokenVestingType: boolean; // check if vesting token is available
  rewardTokens: string[];
  startTime: number;
  endTime: number;
  metadata: any;

  denom?: string;
};

export function transformNFTs(
  nfts: MarketNFTs[] | undefined,
  network: number
): NFTtype[] {
  if (!nfts) {
    return [];
  }
  
  return nfts.map((nft: MarketNFTs) => {
    const filteredNFT = {
      img: getIpfsUrl(nft.metadata.image, true),
      metadata: nft.metadata,
      tokenName:
        nft.metadata.project ||
        NFT_COLLECTIONS.find(
          collection => collection.address === nft.collection
        )?.name ||
        '',
      collection: nft.collection,
      tokenId: nft.tokenId,
      owner: nft.owner,
      vesting_period: nft.vesting_period,
      name: nft.metadata.name,
      catName: 'Launchpad',
      daysRemain: nft.daysRemain,
      rewards1Amount:
        nft.type === 'Standard' ? 0 : parseInt(nft.info.token1_amount),
      rewards1TotalAmount:
        nft.type === 'Standard'
          ? 0
          : parseInt(nft.info.token1_amount) *
            parseInt(nft.info.vesting_period),
      rewards2Amount:
        nft.type === 'Standard' ? 0 : parseInt(nft.info.token2_amount),
      rewards2TotalAmount:
        nft.type === 'Standard'
          ? 0
          : parseInt(nft.info.token2_amount) *
            parseInt(nft.info.vesting_period),
      rewards1Symbol: commonConfig[network].TOKEN_SYMBOLS[nft.info.token1_addr],
      rewards2Symbol: commonConfig[network].TOKEN_SYMBOLS[nft.info.token2_addr],
      tokenVestingType: !!(nft.info.token1_addr || nft.info.token2_addr),
      denom: denomToSymbol[nft.saleInfo?.denom || nft.auctionInfo?.coin_denom || USDC_DENOM],
      price: nft.saleInfo
        ? parseFloat(new Dec(nft.saleInfo.amount).div(10 ** 6).toFixed(2))
        : nft.auctionInfo && parseFloat(nft.auctionInfo.high_bidder_amount) > 0
        ? parseFloat(
            new Dec(nft.auctionInfo.high_bidder_amount).div(10 ** 6).toFixed(2)
          )
        : nft.auctionInfo
        ? parseFloat(
            new Dec(nft.auctionInfo.min_amount).div(10 ** 6).toFixed(2)
          )
        : 0,
      // endTime: ,
      type: nft.inAuction ? 'Auction' : 'Buy Now',
      desc: nft.metadata.description,
      rewardTokens: [
        commonConfig[network].TOKEN_SYMBOLS[nft.info.token1_addr],
        commonConfig[network].TOKEN_SYMBOLS[nft.info.token2_addr],
      ],
      startTime: nft.auctionInfo
        ? nft.auctionInfo.start_time
        : 9999999999999999999,
      endTime: nft.auctionInfo ? nft.auctionInfo.end_time : 9999999999999999999,
    };
    return filteredNFT;
  });
}
