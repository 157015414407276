import { CustomFilters, MAX } from 'hooks/useSaleNFTs';
import { useMemo, useState } from 'react';
import { formatNumberString } from 'utils/conversion/conversion';
import styles from './AttributeFilter.module.scss';

const AttributeFilter = ({
  types,
  values,
  filter,
  setFilter,
  attributesCount,
  totalCount,
}: {
  types: string[];
  values: string[][];
  filter: {
    attribute: string;
    value: string;
  }[];
  setFilter: any;
  attributesCount: {
    [s: string]: number;
  };
  totalCount: number;
}) => {
  const [selected, setSelected] = useState([] as string[]);

  const attributesCountStats = useMemo(() => {
    return types.map((type, index) => {
      const availableValues = values[index];
      if (Object.keys(CustomFilters).includes(type)) {
        return CustomFilters[type].map(([min, max]) => {
          return availableValues
            .filter(value => Number(value) >= min && Number(value) < max)
            .map(value => attributesCount[`${type}-${value}`])
            .reduce((acc, cur) => acc + cur, 0);
        });
      } else {
        return availableValues.map(
          value => attributesCount[`${type}-${value}`]
        );
      }
    });
  }, [attributesCount, types, values]);

  const showValues = (type: string, index: number) => {
    if (Object.keys(CustomFilters).includes(type)) {
      return CustomFilters[type].map(([min, max], j) => {
        const valueSeleceted =
          filter.findIndex(
            item => item.attribute === type && item.value === `${min}-${max}`
          ) !== -1;

        return (
          <div
            className={styles.valueItem}
            key={`${min}-${max}`}
            onClick={() => {
              if (valueSeleceted) {
                setFilter(
                  filter.filter(
                    item =>
                      item.attribute !== type || item.value !== `${min}-${max}`
                  )
                );
              } else {
                setFilter([
                  ...filter,
                  {
                    attribute: type,
                    value: `${min}-${max}`,
                  },
                ]);
              }
            }}
          >
            {min} - {max === MAX ? '∞' : max}
            <span>
              {' '}
              {formatNumberString(
                totalCount
                  ? (attributesCountStats[index][j] * 100) / totalCount
                  : 0,
                2
              )}{' '}
              %
            </span>
            <div className={styles.checkbox}>
              {valueSeleceted && <div className={styles.checkmark}></div>}
            </div>
          </div>
        );
      });
    } else {
      return values[index].map((value, j) => {
        const valueSeleceted =
          filter.findIndex(
            item => item.attribute === type && item.value === value
          ) !== -1;

        return (
          <div
            className={styles.valueItem}
            key={value}
            onClick={() => {
              if (valueSeleceted) {
                setFilter(
                  filter.filter(
                    item => item.attribute !== type || item.value !== value
                  )
                );
              } else {
                setFilter([
                  ...filter,
                  {
                    attribute: type,
                    value: value,
                  },
                ]);
              }
            }}
          >
            {value}
            <span>
              {' '}
              {formatNumberString(
                totalCount
                  ? (attributesCountStats[index][j] * 100) / totalCount
                  : 0,
                2
              )}{' '}
              %
            </span>
            <div className={styles.checkbox}>
              {valueSeleceted && <div className={styles.checkmark}></div>}
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>Attributes</div>
        <div
          onClick={() => {
            setFilter([]);
          }}
        >
          Clear All
        </div>
      </div>
      {types.map((type, index) => {
        const isSelected = selected.includes(type);
        return (
          <div key={type}>
            <div
              className={
                isSelected
                  ? `${styles.attribute} ${styles.selected}`
                  : `${styles.attribute}`
              }
              onClick={() => {
                if (!isSelected) {
                  setSelected([...selected, type]);
                } else {
                  setSelected(selected.filter(item => item !== type));
                }
              }}
            >
              {type}
            </div>
            {isSelected && (
              <div className={styles.values}>
                <div className={styles.line} />
                {showValues(type, index)}
              </div>
            )}
            <div className={styles.line} />
          </div>
        );
      })}
    </div>
  );
};
export default AttributeFilter;
