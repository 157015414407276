import { useCallback, useEffect, useState } from 'react';
import { EligibleNFT } from 'hooks/useEligibleStakeNFTs';
import LaunchpadCard from './launchpadCard';
import useTokenReserve from 'hooks/useTokenReserve';
import { commonConfig } from 'core/common';
import { Dec } from '@terra-money/terra.js';
import { nftCancelAuctionMsg, nftCancelSellMsg } from 'helper/junoContractMsg';
import { networkType, nftConfig } from 'core/nftConfig';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import { queryListedNFTs } from 'hooks/queryListedNFTs';
import { queryActiveBidNFTs } from 'hooks/queryActiveBidNFTs';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { queryMyStandardNfts, StandardNft } from 'hooks/queryMyStandardNfts';
import LaunchpadCardStandard from './launchpadCardStandard';
import WalletConnectState from './walletConnectState';

const MyNftBids = () => {
  const { address } = useRecoilValue(walletState);
  const { submit, estimateGas } = useJunoTransactions();
  const token_reserve = useTokenReserve(
    networkType,
    Object.keys(commonConfig[networkType]?.IS_AVAILABLE_TOKEN || {})
  );

  const [isRewardNftsLoading, setRewardNftsLoading] = useState(true);
  const [rewardNftMoreExists, setRewardNftMoreExists] = useState(false);
  const [rewardNfts, setRewardNfts] = useState([] as EligibleNFT[]);

  const [isStandardNftsLoading, setStandardNftsLoading] = useState(true);
  const [standardNftMoreExists, setStandardNftMoreExists] = useState(false);
  const [standardNfts, setStandardNfts] = useState([] as StandardNft[]);

  useEffect(() => {
    if (token_reserve) {
      setRewardNftsLoading(true);
      queryActiveBidNFTs(networkType, address).then(({ nfts, totalCount }) => {
        setRewardNftMoreExists(nfts.length < totalCount);
        setRewardNftsLoading(false);

        for (let i = 0; i < nfts.length; i++) {
          const info = nfts[i].info;
          if (token_reserve[info.token1_addr]) {
            nfts[i].dailyValue = nfts[i].dailyValue.add(
              new Dec(info.token1_amount)
                .mul(token_reserve[info.token1_addr].usdcAmount)
                .div(token_reserve[info.token1_addr].tokenAmount)
            );
          }
          if (token_reserve[info.token2_addr]) {
            nfts[i].dailyValue = nfts[i].dailyValue.add(
              new Dec(info.token2_amount)
                .mul(token_reserve[info.token2_addr].usdcAmount)
                .div(token_reserve[info.token2_addr].tokenAmount)
            );
          }

          nfts[i].totalValue = nfts[i].dailyValue.mul(nfts[i].daysRemain);
        }

        setRewardNfts(nfts);
      });
    }
  }, [address, token_reserve]);

  const loadMore = () => {
    if (token_reserve) {
      setRewardNftsLoading(true);
      queryActiveBidNFTs(networkType, address, rewardNfts.length).then(
        ({ nfts, totalCount }) => {
          setRewardNftMoreExists(rewardNfts.length + nfts.length < totalCount);
          setRewardNftsLoading(false);

          nfts = [...rewardNfts, ...nfts];

          for (let i = 0; i < nfts.length; i++) {
            const info = nfts[i].info;
            if (token_reserve[info.token1_addr]) {
              nfts[i].dailyValue = nfts[i].dailyValue.add(
                new Dec(info.token1_amount)
                  .mul(token_reserve[info.token1_addr].usdcAmount)
                  .div(token_reserve[info.token1_addr].tokenAmount)
              );
            }
            if (token_reserve[info.token2_addr]) {
              nfts[i].dailyValue = nfts[i].dailyValue.add(
                new Dec(info.token2_amount)
                  .mul(token_reserve[info.token2_addr].usdcAmount)
                  .div(token_reserve[info.token2_addr].tokenAmount)
              );
            }

            nfts[i].totalValue = nfts[i].dailyValue.mul(nfts[i].daysRemain);
          }

          setRewardNfts(nfts);
        }
      );
    }
  };

  useEffect(() => {
    setStandardNftsLoading(true);
    queryMyStandardNfts('bid', networkType, address).then(
      ({ nfts, totalCount }) => {
        setStandardNftMoreExists(nfts.length < totalCount);
        setStandardNftsLoading(false);
        setStandardNfts(nfts);
      }
    );
  }, [address]);

  const loadMoreStandardNfts = () => {
    setStandardNftsLoading(true);
    queryMyStandardNfts('bid', networkType, address, standardNfts.length).then(
      ({ nfts, totalCount }) => {
        setStandardNftMoreExists(
          standardNfts.length + nfts.length < totalCount
        );
        setStandardNftsLoading(false);
        setStandardNfts([...standardNfts, ...nfts]);
      }
    );
  };

  const cancelAllHandler = useCallback(async () => {
    const res = await queryListedNFTs(address);
    const txMsgs = res.nfts.map(nft =>
      nft.inAuction
        ? nftCancelAuctionMsg({
            address,
            networkType,
            nft: nft.contract,
            nft_auction: nftConfig[networkType].AUCTION,
            token_id: nft.token_id,
          })
        : nftCancelSellMsg({
            address,
            networkType,
            nft: nft.contract,
            nft_sale: nftConfig[networkType].SALE,
            token_id: nft.token_id,
          })
    );
    const fee = await estimateGas(txMsgs);
    console.log('FEE:', fee);
    submit({ msgs: txMsgs });
  }, [address, estimateGas, submit]);

  return (
    <section>
      <div
        className='mynftRight'
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {rewardNfts.length > 0 ? (
          rewardNfts?.map((nft, index) => {
            if (nft.type === 'launchpad') {
              return (
                <LaunchpadCard key={JSON.stringify(nft) + index} data={nft} />
              );
            } else {
              return <></>;
            }
          })
        ) : isRewardNftsLoading ? (
          <div className='noPosts'>
            <p
              className='loader'
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            ></p>
          </div>
        ) : (
          rewardNftMoreExists && (
            <div className='flex-center'>
              <button onClick={loadMore}>Load More</button>
            </div>
          )
        )}

        {(isStandardNftsLoading || standardNfts?.length > 0) && (
          <div
            style={{
              marginTop:
                isRewardNftsLoading || rewardNfts.length > 0 ? '50px' : '0px',
            }}
          >
            <div className='header'>
              <h6 style={{ fontSize: '25px' }}>Standard NFTs</h6>
            </div>
            <div className='trendingInfo'>
              <ul>
                {standardNfts?.length > 0 &&
                  standardNfts?.map((nft, index) => {
                    if (nft.type === 'launchpad') {
                      return (
                        <li>
                          <LaunchpadCardStandard
                            key={JSON.stringify(nft) + index}
                            data={nft}
                          />
                        </li>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </ul>
            </div>

            {isStandardNftsLoading ? (
              <div className='noPosts'>
                <p
                  className='loader'
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                ></p>
              </div>
            ) : (
              standardNftMoreExists && (
                <div className='flex-center'>
                  <button onClick={loadMoreStandardNfts}>Load More</button>
                </div>
              )
            )}
          </div>
        )}

        {!isRewardNftsLoading &&
          !isStandardNftsLoading &&
          rewardNfts.length === 0 &&
          standardNfts.length === 0 && (
            <WalletConnectState
              type='bids'
              connected={Boolean(address)}
              noNft={false}
            />
          )}
      </div>
    </section>
  );
};

export default MyNftBids;
