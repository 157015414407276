import { useEffect, useState } from 'react';
import styles from './mintcard.module.scss';
import { DirectusImage } from 'core/common';
import Button from 'components/Button';
import Countdown, { zeroPad } from 'react-countdown';
import { quesyLaunchpadInfo } from 'api/launchpad.api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const MintCard = ({ id, address }: { id: string; address: string }) => {
  const history = useHistory();
  const isFans = location.pathname.startsWith('/fans');
  const [mintDetail, setMintDetail] = useState<any>();
  const [mintDetailLoaded, setMintDetailLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const launchInfo = !isFans
    ? mintDetail?.launchInfo
    : mintDetail?.launchpad_type?.find((item: any) => item.collection.url === id)
        .launchInfo;

  const launchDate = new Date(
    launchInfo?.startDate + ' ' + launchInfo?.startTime
  );
  const launchEndDate = new Date(
    launchInfo?.endDate + ' ' + launchInfo?.endTime
  );

  /****************************
   * Query Launchpad Details
   *****************************/
  useEffect(() => {
    const FetchLaunchpadInfo = async () => {
      if (id !== undefined && !mintDetailLoaded) {
        quesyLaunchpadInfo({ id, isFans }).then((mintDetail: any) => {
          setMintDetail(mintDetail);
          setMintDetailLoaded(true);
        });
      }
    };

    FetchLaunchpadInfo();
  }, [mintDetailLoaded]);
  const currentTime = moment.tz('America/Los_Angeles').valueOf();
  const genTime = (time: string) =>
    moment(time).add(moment(time).utcOffset(), 'minutes').valueOf();

  const whitelistStartTime = genTime(
    moment
      .tz('America/Los_Angeles')
      .format(`${launchInfo?.startDate} ${launchInfo?.startTime}`)
  );
  const publicMintStartTime = genTime(
    moment
      .tz('America/Los_Angeles')
      .format(`${launchInfo?.publicDate} ${launchInfo?.publicTime}`)
  );
  const publicMintEndTime = genTime(
    moment
      .tz('America/Los_Angeles')
      .format(`${launchInfo?.endDate} ${launchInfo?.endTime}`)
  );

  const beforeWhitelistMint = currentTime < whitelistStartTime;
  const isWhitelistMint =
    whitelistStartTime <= currentTime && currentTime < publicMintStartTime;
  const isPublicMint =
    publicMintStartTime <= currentTime && currentTime < publicMintEndTime;

  const mintEnded = currentTime > publicMintEndTime;

  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <>
        <div className={styles.date}>
          {zeroPad(days)} <span>DAYS</span>
        </div>
        <div className={styles.date}>
          {zeroPad(hours)} <span>HRS</span>
        </div>
        <div className={styles.date}>
          {zeroPad(minutes)} <span>MINS</span>
        </div>
        <div className={styles.date}>
          {zeroPad(seconds)} <span>SECS</span>
        </div>
      </>
    );
  };

  const Mintbutton = () => {
    const handleBtn = (action: any, text: string) => (
      <Button
        onClick={() => history.push(action)}
        className={styles.mintButton}
      >
        {text}
      </Button>
    );
    return (
      <>
        {beforeWhitelistMint
          ? handleBtn(
              isFans
                ? `/fans/exploreCollection/${mintDetail?.project_slug}/mint`
                : `/launchpad/${mintDetail?.project_slug}`,
              'Coming Soon'
            )
          : !mintEnded
          ? handleBtn(
              isFans
                ? `/fans/exploreCollection/${mintDetail?.project_slug}/mint`
                : `/launchpad/${mintDetail?.project_slug}`,
              'Minting Now'
            )
          : handleBtn(
              isFans
                ? `/fans/exploreCollection/${mintDetail?.project_slug}`
                : `/exploreCollection/${mintDetail?.project_slug}`,
              'View Collection'
            )}
      </>
    );
  };

  return (
    <>
      {mintDetail && (
        <div className='wrapper'>
          <div className={styles.card}>
            <div className={styles.item}>
              <div className={styles.collectionImage}>
                {imageLoaded ? (
                  <img
                    src={`${DirectusImage(
                      mintDetail?.banner?.id
                    )}?fit=cover&width=600&height=600&quality=90`}
                  />
                ) : (
                  <>
                    <div className={styles.placeholderImage} />
                    <img
                      src={`${DirectusImage(
                        mintDetail?.banner?.id
                      )}?fit=cover&width=600&height=600&quality=90`}
                      hidden
                      onLoad={() => {
                        setImageLoaded(true);
                      }}
                    />
                  </>
                )}
              </div>
              <div className={styles.right}>
                <h4>
                  {beforeWhitelistMint
                    ? 'Minting Soon'
                    : !mintEnded
                    ? 'Minting Now'
                    : 'Mint Ended'}
                </h4>
                <p>
                  {beforeWhitelistMint
                    ? 'Public Minting will be open soon meanwhile you can explore and set a reminder so you don’t miss out.'
                    : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam imperdiet, mauris quis semper aliquet, metus risus maximus libero, eget dictum tortor massa sit amet odio.'}
                </p>

                {!beforeWhitelistMint && !mintEnded && (
                  <div className={styles.mintDate}>
                    <Countdown
                      date={
                        beforeWhitelistMint
                          ? whitelistStartTime
                          : isWhitelistMint
                          ? publicMintStartTime
                          : isPublicMint
                          ? publicMintEndTime
                          : new Date()
                      }
                      renderer={renderer}
                    />
                  </div>
                )}

                <div
                  className={styles.mintStart}
                  style={
                    !beforeWhitelistMint && mintEnded
                      ? { paddingTop: '50px' }
                      : {}
                  }
                >
                  <p>
                    <span>
                      {beforeWhitelistMint ? 'Mint Start' : 'Mint Started'}
                    </span>
                    {launchDate.toLocaleString()}
                  </p>

                  {!mintEnded ? (
                    <p>
                      <span>Mint Ends</span>
                      {launchEndDate?.toLocaleString()}
                    </p>
                  ) : (
                    <p>
                      <span>Mint Ended</span>
                      {launchEndDate?.toLocaleString()}
                    </p>
                  )}
                </div>
                <Mintbutton />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MintCard;
