import { NFT_COLLECTIONS, blockList } from 'core/nftConfig';
import axios from 'axios';
import { BACKEND_ENDPOINT, PAGE_SIZE } from 'core/common';
import { NFTData } from './useEligibleStakeNFTs';

export type NftCollection = {
  name: string;
  icon: string;
  url: string;
  itemCount: number;
  floorPrice: number;
  ownerCount: number;
};

export type NftAttribute = {
  trate_type: string;
  value: string;
}

export const queryCollections = async (
  search: string,
): Promise<{
  totalCount: number;
  collections: NftCollection[];
}> => {
  const query = `
  {
    nftContracts(
      filter: {
        name: {
          includesInsensitive: "${search}"
        }
      }
    ) {
       nodes {
        id
        name
        nfts (filter: {
          contractId: {
            notIn: "${blockList}"
          }
          or: [
            {
              inSale: {
                equalTo: true
              }
            }
            {
              inAuction: {
                equalTo: true
              }
            }
          ]
          saleAmount: {
            greaterThan: "0"
          }
        }) {
          aggregates {
            distinctCount {
              id
              owner
            }
            min {
              saleAmount
            }
          }
        }
      }
    }
  }`;
  const data: {
    data: {
      nftContracts: {
        totalCount: number;
        nodes: {
          id: string;
          name: string;
          nfts: {
            aggregates: {
              distinctCount: {
                id: string;
                owner: string;
              };
              min: {
                saleAmount: string;
              };
            };
          };
        }[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  const collections: NftCollection[] = [];
  for (let i = 0; i < data.data.nftContracts.nodes.length; i++) {
    const item = data.data.nftContracts.nodes[i];
    const collection = NFT_COLLECTIONS.find(e => e.address === item.id)!;
    if (!item.nfts.aggregates.min.saleAmount) {
      continue;
    }

    collections.push({
      ...collection,
      itemCount: parseFloat(item.nfts.aggregates.distinctCount.id || '0'),
      floorPrice: parseFloat(
        item.nfts.aggregates.min.saleAmount || '0'
      ),
      ownerCount: parseFloat(item.nfts.aggregates.distinctCount.owner || '0'),
    });
  }

  return {
    totalCount: data.data.nftContracts.totalCount,
    collections,
  };
};


export const searchNfts = async (
  search: string,
  count = PAGE_SIZE
): Promise<{
  data: any;
  totalCount: number;
}> => {
  const query = `
  {
    nfts(
      filter: {
        name: {
          includesInsensitive: "${search}"
        }
        contractId: {
          notIn: "${blockList}"
        }
      }
      first: 25
    ) {
       nodes{
        id
        name
        contract {
          id
          name
          symbol
        }
        type
        tokenID
        updatedAt
        tokenUri
        image
        inSale
        inAuction
        saleAmount
        auctionMinAmount
      }
      totalCount
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
        nodes: NFTData[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  return {
    data: data.data.nfts.nodes,
    totalCount: data.data.nfts.totalCount,
  };
};