import {Helmet} from 'react-helmet';
import {useCallback, useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import Sidebar from '../../../components/Sidebar';
import Modal from '../../../components/Modal';
import {
  LaunchPadNFTQueryInfo,
} from 'hooks/queryLaunchpadNFTInfo';
import {Dec} from '@terra-money/terra.js';
import {commonConfig, denomToSymbol, getIpfsUrl} from 'core/common';
import rewards from '../../../assets/reward.svg';
import rewardGrey from '../../../assets/rewardGrey.png';
import {
  bidAuctionNFTMsg,
  buySaleNFTMsg,
  nftAuctionMsg,
  nftCancelAuctionMsg,
  nftCancelSellMsg,
  nftClaimAuctionMsg,
  nftSellMsg,
  nftStakeClaimMsg,
  nftStakeMsg,
  nftTransferMsg,
  nftUnstakeMsg,
} from 'helper/junoContractMsg';
import {truncate} from 'helper/text';
import {networkType, nftConfig, TICKET_COLLECTIONS} from 'core/nftConfig';
import useTokenReserve from 'hooks/useTokenReserve';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import {useRecoilValue} from 'recoil';
import {walletState} from 'state/walletAtoms';
import {USDC_DENOM} from 'core/constants';
import {
  formatNumberString,
  formatTokenPrice,
} from 'utils/conversion/conversion';
import Countdown, {zeroPad} from 'react-countdown';
import {toast} from 'react-toastify';
import {useJunoTokenBalance} from 'hooks/newHooks/useTokenBalance';
import ReactTooltip from 'react-tooltip';
import ToolTip from '../../../components/ToolTips';
import {
  queryCollectionNftsCount,
  queryCollectionNftsCountByAttribute,
} from 'hooks/queryCount';
import { junoAddress } from 'core/utils';
import profile from '../../../assets/images/profile.png';
import WebAppHeader from '../WebAppHeader';
import { queryCosmoverseNftInfo } from 'hooks/queryCosmoverseNftInfo';

const maxSalePrice = 999999999;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const SkeletonComponent = ({
                             count,
                             width,
                             children,
                             info,
                           }: {
  count: number;
  width: string;
  children: any;
  info: any;
}) => {
  return (
    <>
      {children}
      {/* {info ? (
        children
      ) : (
        <SkeletonTheme highlightColor='#a8a8a8'>
          <Skeleton count={count} width={width} />
        </SkeletonTheme>
      )} */}
    </>
  );
};

const WebAppNftExploreItemDetails = () => {
  const params       = useParams<any>();
  const location     = useLocation();
  const history      = useHistory();
  const [nftAddress] = useState(params && params.address);
  const [nftTokenId] = useState<string>(
    params && (decodeURI(params.id) + location.hash).toString()
  );

  const {address}                         = useRecoilValue(walletState);
  const {balance}                         = useJunoTokenBalance('USDC');
  const {submit, estimateGas}             = useJunoTransactions();
  const token_reserve                     = useTokenReserve(
    networkType,
    Object.keys(commonConfig[networkType]?.IS_AVAILABLE_TOKEN || {})
  );
  const [info, setInfo]                   = useState<LaunchPadNFTQueryInfo & {
    claimableRewards1: Dec;
    claimableRewards2: Dec;
    claimableTime: number;
    isReward1: boolean;
    isReward2: boolean;
  }>();
  const isOwner                           = info?.info?.owner === address;
  const isInStaking                       = info?.stakeInfo.is_staking;
  const isInSale                          = info?.saleInfo !== undefined;
  const isInAuction                       = info?.auctionInfo !== undefined;
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [stakeModal, setStakeModal] = useState(false);
  const [unstakeModal, setUnStakeModal] = useState(false);
  const [recipient, setRecipient]         = useState('');

  const [attributesCount, setAttributesCount]     = useState<number[]>([]);
  const [totalCount, setTotalCount]               = useState(0);

  useEffect(() => {
    if (token_reserve) {
      queryCosmoverseNftInfo(networkType, nftAddress, nftTokenId, address)?.then(
        result => {
          if (result) {
            const daysStaked =
                    Math.floor(result.stakeInfo.total_staked_time / 60 / 60 / 24) <=
                    parseInt(result.info.vesting_period)
                      ? Math.floor(result.stakeInfo.total_staked_time / 60 / 60 / 24)
                      : parseInt(result.info.vesting_period);
            const daysRemain =
                    parseInt(result.info.vesting_period) - daysStaked;
            let dailyValue1  = new Dec(0);
            let totalValue1  = new Dec(0);
            let dailyValue2  = new Dec(0);
            let totalValue2  = new Dec(0);

            if (
              result.info.token1_addr &&
              token_reserve[result.info.token1_addr]
            ) {
              dailyValue1 = new Dec(result.info.token1_amount)
                .mul(token_reserve[result.info.token1_addr].usdcAmount)
                .div(token_reserve[result.info.token1_addr].tokenAmount);
              totalValue1 = dailyValue1.mul(result.info.vesting_period);
            }
            if (
              result.info.token2_addr &&
              token_reserve[result.info.token2_addr]
            ) {
              dailyValue2 = new Dec(result.info.token2_amount)
                .mul(result.info.vesting_period)
                .mul(token_reserve[result.info.token2_addr].usdcAmount)
                .div(token_reserve[result.info.token2_addr].tokenAmount);
              totalValue2 = dailyValue2.mul(result.info.vesting_period);
            }

            setAttributesCount(result.metadata.attributes.map(_ => 0));
            setTotalCount(0);

            setInfo({
              ...result,
              daysStaked,
              daysRemain,
              dailyValue1,
              totalValue1,
              dailyValue2,
              totalValue2,
              isReward1        : !!result.info.token1_addr,
              isReward2        : !!result.info.token2_addr,
              claimableRewards1: result.info.token1_addr
                ? new Dec(result.info.token1_amount)
                  .mul(
                    Math.max(
                      Math.min(
                        Math.floor(
                          result.stakeInfo.total_staked_time / 60 / 60 / 24
                        ),
                        parseInt(result.info.vesting_period)
                      ) - result.stakeInfo.claimed_days,
                      0
                    )
                  )
                  .div(1000000)
                : new Dec(0),
              claimableRewards2: result.info.token2_addr
                ? new Dec(result.info.token2_amount)
                  .mul(
                    Math.max(
                      Math.min(
                        Math.floor(
                          result.stakeInfo.total_staked_time / 60 / 60 / 24
                        ),
                        parseInt(result.info.vesting_period)
                      ) - result.stakeInfo.claimed_days,
                      0
                    )
                  )
                  .div(1000000)
                : new Dec(0),
              claimableTime    :
                Date.now() +
                (86400 - (result.stakeInfo.total_staked_time % 86400)) * 1000,
            });
          }
        }
      );
    }
  }, [nftAddress, nftTokenId, token_reserve, address]);

  useEffect(() => {
    if (info) {
      const queries = [];
      for (let i = 0; i < info.metadata.attributes.length; i++) {
        queries.push(
          queryCollectionNftsCountByAttribute(
            nftAddress,
            info.metadata.attributes[i].trait_type,
            info.metadata.attributes[i].value
          )
        );
      }

      Promise.all(queries).then(counts => {
        setAttributesCount(counts);
      });

      queryCollectionNftsCount(nftAddress).then(count => setTotalCount(count));
    }
  }, [info, info?.metadata.attributes, nftAddress]);

  const stakeHandler = useCallback(async () => {
    const txMsg = nftStakeMsg({
      address,
      networkType,
      nft        : info?.contracts.nft,
      nft_staking: info?.contracts.nftStaking,
      token_id   : nftTokenId,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({msgs: [txMsg]});
  }, [
    address,
    estimateGas,
    info?.contracts.nftStaking,
    info?.contracts.nft,
    nftTokenId,
    submit,
  ]);

  const unStakeHandler = useCallback(async () => {
    const txMsg = nftUnstakeMsg({
      address,
      networkType,
      nft        : info?.contracts.nft,
      nft_staking: info?.contracts.nftStaking,
      token_id   : nftTokenId,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({msgs: [txMsg]});
  }, [
    address,
    estimateGas,
    info?.contracts.nftStaking,
    info?.contracts.nft,
    nftTokenId,
    submit,
  ]);

  const transferHandler = useCallback(async () => {
    const txMsg = nftTransferMsg({
      address,
      networkType,
      nft     : info?.contracts.nft,
      token_id: nftTokenId,
      recipient,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({msgs: [txMsg]});
  }, [
    address,
    estimateGas,
    info?.contracts.nft,
    nftTokenId,
    recipient,
    submit,
  ]);

  const claimStakeHandler = useCallback(async () => {
    const txMsg = nftStakeClaimMsg({
      address,
      networkType,
      nftStaking: info?.contracts.nftStaking,
      nft       : info?.contracts.nft,
      token_id  : nftTokenId,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({msgs: [txMsg]});
  }, [
    address,
    estimateGas,
    info?.contracts.nft,
    info?.contracts.nftStaking,
    nftTokenId,
    submit,
  ]);


  if (!info) {
    return (
      <>
        <Helmet>
          <title>NFT | Explore Detail</title>
        </Helmet>
        <div>
          <div>
            <div
              className='wrapper'
              style={{
                height        : 'calc(100vh - 480px)',
                display       : 'flex',
                alignItems    : 'center',
                justifyContent: 'center',
              }}
            >
              <div className='loader' style={{margin: 'auto'}}></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const nftInfo       = nftConfig[networkType].launchPadNFTs.find(
    item => item.NFT === info?.contracts.nft
  );
  const launchEndDate = nftInfo?.launchInfo?.endDate;
  const launchEndTime = nftInfo?.launchInfo?.endTime;
  const date          = new Date().getTime();
  const endDate       = new Date(launchEndDate + ' ' + launchEndTime).getTime();
  const eventLocalData = TICKET_COLLECTIONS.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  const eventData = (name: string) => info.metadata.attributes.find(data => data.trait_type == (name));
  const isBottle = (id:any) => TICKET_COLLECTIONS[0].reward.find((data:any) => data == id);

  return (
    <>
      <Helmet>
        <title>NFT | Explore Detail</title>
      </Helmet>
      {nftInfo && <ToolTip endDate={launchEndDate + ' ' + launchEndTime}/>}
      <div>
        <>
          <div>
            <div className={`${(stakeModal || unstakeModal) && "webapp_modalBlur"} wrapper`}>
              <div className='myticketsInner'>
                <WebAppHeader history="mytickets" />
                <div className='nftRightChild nftRightChild__webapp'>
                  <div style={{padding: '20px'}}>
                    <div className="img_container img_container_full">
                      <SkeletonComponent count={10} info={info} width={'470px'}>
                        <img
                          src={getIpfsUrl(info?.metadata.image, true)}
                          onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src     = '/blank.png';
                          }}
                          alt=''
                          style={{width: '470px', maxWidth: '100%'}}
                        />
                      </SkeletonComponent>
                    </div>
                  </div>

                  <div className='mynftHub'>
                    <div className='mynftHubHedare'>
                      <label className='nftTitle'>
                        <div className="header" style={{justifyContent: 'flex-start'}}>
                          <div
                            className="qrcode"
                            onClick={() => setQrCodeModal(true)}
                          >
                            {info?.metadata.external_url && (
                              <img
                                src={getIpfsUrl(info?.metadata.external_url, true)}
                                onError={({currentTarget}) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src     = '/blank.png';
                                }}
                                alt=''
                              />
                            )}
                          </div>
                          <div>
                            <span>Ticket no.</span>#{formatNumberString( nftTokenId.split('#')[1])}
                            <p style={{paddingLeft: '10px'}}>{info?.metadata.name}</p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="dashed_div" />

                  <div className='nftData nftData__webapp'>
                    <label>
                      <b>DATE</b>
                      <p>{eventData('Date') ? eventData('Date')?.value : eventLocalData[0].date }</p>
                    </label>
                    <label>
                      <b>TIME</b>
                      <p>{eventData('Time') ? eventData('Time')?.value : eventLocalData[0].time }</p>
                    </label>
                  </div>

                  <div className='nftData nftData__webapp'>
                    <label className="full">
                      <b>LOCATION</b>
                      <p>{eventData('Location') ? eventData('Location')?.value : eventLocalData[0].location }</p>
                    </label>
                  </div>
                  
                  <div className="dashed_div" />
                  <div className='nftData nftData__webapp'>
                    <label>
                      <b>Rewards</b>
                      <p>
                        {isBottle(nftTokenId.split('#')[1]) ? (
                          <b>1 BOTTLE</b>
                        ): (
                          <b>1 DRINK</b>
                        )}
                      </p>
                      {/*new Dec(info.info.token1_amount).gt(0) && (
                        <div>
                          <p>
                            <img
                              src='/drink.svg'
                              alt='aro_lft.png'
                              style={{marginRight: '5px'}}
                            /> 
                            {formatNumberString(
                              new Dec(info.info.token1_amount)
                                .div(1000000)
                            )}{' '}
                            <b>{commonConfig[networkType].TOKEN_SYMBOLS[info.info.token1_addr]}</b>
                          </p>
                        </div>
                        )}
                      {new Dec(info.info.token2_amount).gt(0) && (
                        <div>
                          <p>
                            <img
                              src='/drink.svg'
                              alt='aro_lft.png'
                              style={{marginRight: '5px'}}
                            /> 
                            {formatNumberString(
                              new Dec(info.info.token2_amount)
                                .div(1000000)
                            )}{' '}
                            <b>{commonConfig[networkType].TOKEN_SYMBOLS[info.info.token2_addr]}</b>
                          </p>
                        </div>
                        )*/}
                    </label>
                  </div>

                  {isOwner && (
                    <>
                      <div className="dashed_div" />

                      <div className='webapp_actions'>
                        {info.stakeInfo.total_staked_time == 0 ? (
                          <>
                            {!isInSale && !isInAuction && !isInStaking && (
                              <button
                                className='stake'
                                disabled={nftInfo?.disabled}
                                onClick={() => setStakeModal(true)}
                              >
                                + STAKE
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {isInStaking ? (
                              <span>
                                <button
                                  className='unstake'
                                  onClick={() => setUnStakeModal(true)}
                                >
                                 {/*  - UNSTAKE */}
                                 CLAIM
                                </button>
                                {/* 
                                {(info.claimableRewards1.gt(0) ||
                                  info.claimableRewards2.gt(0)) && (
                                  <button
                                    onClick={() => claimStakeHandler()}
                                    style={{background: '#32fe9a'}}
                                  >
                                    CLAIM
                                  </button>
                                )}
                                */}
                              </span>
                            ) : (
                              <button
                                disabled
                                style={{background: '#484662', borderColor: '#484662'}}
                              >
                                REWARDED
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      <div className='webapp_actions'>
                        {!isInSale && !isInAuction && !isInStaking && (
                          <button
                            onClick={() => setTransferModal(true)}
                          >
                            TRANSFER
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Modal
              isOpen={stakeModal}
              title=''
              customClass='webapp_stakeModal'
              onClose={() => setStakeModal(false)}
            >
              <span className='stakeAction'>
                <h6></h6>
                <span className='aucHub'>
                  <span className='auc_split'>
                    <span className='TransferInput'>
                      <h5>Stake NFT</h5>
                    </span>
                    <span className="actions">
                      <button
                        className='cnfrmStake'
                        onClick={() => stakeHandler()}
                      >
                        Stake Now
                      </button>
                      <button
                        className='closeStake'
                        onClick={() => setStakeModal(false)}
                      >
                        I'll do later
                      </button>
                    </span>
                  </span>
                </span>
              </span>
            </Modal>

            <Modal
              isOpen={unstakeModal}
              title=''
              customClass='webapp_stakeModal'
              onClose={() => setUnStakeModal(false)}
            >
              <span className='stakeAction'>
                <h6></h6>
                <span className='aucHub'>
                  <span className='auc_split'>
                    <span className='TransferInput'>
                      <h5>Claim NFT Reward</h5>
                    </span>
                    <span className="actions">
                      <button
                        className='cnfrmStake'
                        onClick={() => unStakeHandler()}
                      >
                        Claim now
                      </button>
                      <button
                        className='closeStake'
                        onClick={() => setUnStakeModal(false)}
                      >
                        No, I’ll wait
                      </button>
                    </span>
                  </span>
                </span>
              </span>
            </Modal>


            <Modal
              isOpen={transferModal}
              title=''
              onClose={() => setTransferModal(false)}
            >
              <div className='SellAuction'>
                <h6>Transfer NFT</h6>
                <div className='aucHub'>
                  <section>
                    <span>
                      <img src={getIpfsUrl(info?.metadata.image, true)} alt=''/>
                    </span>
                  </section>
                  <div className='auc_split'>
                    <div className='TransferInput'>
                      <h5>{info?.metadata.name}</h5>
                      <label>Recipient</label>
                      <input
                        type='text'
                        placeholder=''
                        value={recipient}
                        onChange={e => {
                          setRecipient(e.target.value);
                        }}
                      />
                    </div>
                    <button
                      className='cnfrmTransfer'
                      onClick={() => transferHandler()}
                    >
                      CONFIRM
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              isOpen={qrCodeModal}
              title=''
              onClose={() => setQrCodeModal(false)}
            >
              <div>
                <section>
                  <span>
                    <img style={{width: '100%'}} src={info?.metadata.external_url && getIpfsUrl(info?.metadata.external_url, true)} alt=''/>
                  </span>
                </section>
              </div>
            </Modal>
          </div>
        </>
      </div>
    </>
  );
};

export default WebAppNftExploreItemDetails;
