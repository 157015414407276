import { useCallback, useEffect, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { NFTMinterQuery } from 'hooks/useLaunchPadNFTMinterQuery';
import { customMintMsg } from 'helper/junoContractMsg';
import { LaunchPadNFT, networkType } from 'core/nftConfig';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { useJunoTokenBalance } from 'hooks/newHooks/useTokenBalance';
import { Dec } from '@terra-money/terra.js';
import CountdownComp from './countdown';
import { formatNumberString } from 'utils/conversion/conversion';
import { symbolToDenom } from 'core/common';
import { FetchJunoTokenUnitPrice } from 'api/launchpad.api';
import Modal from 'components/Modal';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const LaunchpadForm = ({
  queryResult,
  launchpadNFT,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT: LaunchPadNFT;
}) => {
  const [pausedModal,setPausedModal] = useState(false);
  const { address } = useRecoilValue(walletState);
  const { submit, estimateGas, setTransactionInfo } = useJunoTransactions();
  const [, symbol] = launchpadNFT.launchInfo.mintPrice?.split(' ') || [
    '',
    'USDC',
  ];
  const { balance } = useJunoTokenBalance(symbol);
  const [mintCount, setMintCount] = useState(0);

  const totalMinted = Math.max(
    queryResult?.tier_infos.reduce(
      (res, cur) => res + parseInt(cur.current_supply),
      0
    ) || 0,
    queryResult?.mintConfig.nft_current_supply || 0
  );
  const totalMintRemains =
    (queryResult?.mintConfig.nft_max_supply || 0) -
    (queryResult?.mintConfig.nft_current_supply || 0);

  const isMintStart =
    queryResult &&
    queryResult.mintConfig.mint_start_time !== 0 &&
    new Date().getTime() / 1000 > queryResult.mintConfig.mint_start_time;
  // console.log('isMintStart = ', isMintStart);
  const isInWhitelist =
    isMintStart &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period;
  const isWhitelistMint =
    isMintStart &&
    queryResult.is_whitelisted &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period;
  // console.log('isWhitelistMint = ', isWhitelistMint);
  const isPublicMint =
    isMintStart &&
    new Date().getTime() / 1000 >=
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period +
        queryResult.mintConfig.public_mint_period;
  // console.log('isPublicMint = ', isPublicMint);
  let mintableCount = queryResult
    ? isPublicMint
      ? queryResult.mintConfig.public_mint_limit - queryResult.supplyByAddress
      : isWhitelistMint
      ? queryResult.mintConfig.whitelist_mint_limit -
        queryResult.supplyByAddress
      : 0
    : 0;
  // console.log('mintableCount = ', mintableCount);
  if (
    (queryResult?.mintConfig.nft_max_supply || 0) -
      (queryResult?.mintConfig.nft_current_supply || 0) <
    mintableCount
  ) {
    mintableCount =
      (queryResult?.mintConfig.nft_max_supply || 0) -
      (queryResult?.mintConfig.nft_current_supply || 0);
  }
 
  const mintHandler = useCallback(async () => {
    if ((launchpadNFT?.mint_status === "paused")) {
      setPausedModal(true);
    } else if (mintCount > 0) {
      const txMsgs = Array(mintCount).fill(
        customMintMsg({
          address,
          networkType,
          nftMinter: launchpadNFT.launchInfo.MINTER,
          coins: [
            {
              amount: queryResult?.mintConfig.nft_price_amount,
              denom: symbolToDenom[symbol],
            },
          ],
        })
      );
      console.log(txMsgs);
      console.log(await estimateGas(txMsgs));
      const tokenPrices=await FetchJunoTokenUnitPrice()
      submit({
        msgs: txMsgs,
        redirectTo:
          launchpadNFT.NFT ===
          'juno1zzcugrt4x30vu0qxg765rnxkxm6lru6cwe0nvcl90ctc9k4cwvksx8tcew'
            ? '/webapp/mytickets'
            : '/mynft',
      });
      setTransactionInfo({
        type: 'mintNft',
        price: queryResult?.mintConfig.nft_price_amount,
        quantity: txMsgs?.length,
        symbol:symbol,
        tokenPrices:tokenPrices
      });
    }
  }, [
    mintCount,
    address,
    launchpadNFT.NFT,
    launchpadNFT.launchInfo.MINTER,
    queryResult?.mintConfig.nft_price_amount,
    symbol,
    estimateGas,
    submit,
    setTransactionInfo,
  ]);

  const mintStatus =
    queryResult?.mintConfig.nft_current_supply ===
    queryResult?.mintConfig.nft_max_supply
      ? queryResult?.mintConfig.nft_max_supply === 0
        ? 'LIVE'
        : 'SOLD OUT'
      : isMintStart
      ? isPublicMint || isWhitelistMint
        ? 'Live Public Mint'
        : isInWhitelist
        ? 'Live Allow List'
        : 'COMPLETED'
      : 'UPCOMING';

  return (
    <div>
      <label className='titleBar'>
        {isPublicMint && <div className={'status'}>PUBLIC MINT</div>}
        {isWhitelistMint && <div className={'status'}>ALLOW LIST</div>}
      </label>
      <div className='nft_view'>
        {/* =========================================== */}
        {/* Hidden as I don't know how date is returned */}
        {/* =========================================== */}
        {}
        <CountdownComp launchpadNFT={launchpadNFT} />
        <div className='header'>
          <div className='price'>
            <span>Mint Price</span>
            <b>
              {formatNumberString(
                new Dec(queryResult?.mintConfig.nft_price_amount || 0).div(
                  1000000
                )
              )}{' '}
              {symbol}
            </b>
          </div>
          {queryResult && <div className='status'>{mintStatus}</div>}
        </div>
        <div className='details'>
          <label>
            <p>
              Items{' '}
              <b>
                {launchpadNFT.launchInfo.MINTER === 'juno1h5ntvxkrnwa5v2pms6auyy58vcnutu2m3z9wjq7gpfvv8jed5mqsf29hl3' ?
                  formatNumberString(
                    launchpadNFT.launchInfo.maxSupply || 0
                  ) : formatNumberString(
                    queryResult?.mintConfig.nft_max_supply || 0
                  )
                }
              </b>
            </p>
          </label>
          <label>
            <p>
              Start Date
              <b>
                {new Date(launchpadNFT.launchInfo.startDate).getUTCDate()}{' '}
                {
                  monthNames[
                    new Date(launchpadNFT.launchInfo.startDate).getUTCMonth()
                  ]
                }{' '}
                {new Date(launchpadNFT.launchInfo.startDate).getUTCFullYear()}
              </b>
            </p>
          </label>
          <label>
            <p>
              End Date
              <b>
                {new Date(launchpadNFT.launchInfo.endDate).getUTCDate()}{' '}
                {
                  monthNames[
                    new Date(launchpadNFT.launchInfo.endDate).getUTCMonth()
                  ]
                }{' '}
                {new Date(launchpadNFT.launchInfo.endDate).getUTCFullYear()}
              </b>
            </p>
          </label>
          <label>
            <p style={{ marginTop: '20px' }}>
              Globally Minted{' '}
              <b
                className={
                  queryResult?.mintConfig.nft_current_supply ===
                    queryResult?.mintConfig.nft_max_supply &&
                  queryResult?.mintConfig.nft_max_supply !== undefined &&
                  queryResult?.mintConfig.nft_max_supply !== 0
                    ? 'text-green'
                    : ''
                }
              >
                {/* {formatNumberString(totalMinted)} */}
                {launchpadNFT.launchInfo.MINTER === 'juno1h5ntvxkrnwa5v2pms6auyy58vcnutu2m3z9wjq7gpfvv8jed5mqsf29hl3' ?
                  formatNumberString(totalMinted - 160) : formatNumberString(totalMinted)
                }
                /
                <span
                  className={
                    queryResult?.mintConfig.nft_current_supply ===
                      queryResult?.mintConfig.nft_max_supply &&
                    queryResult?.mintConfig.nft_max_supply !== undefined &&
                    queryResult?.mintConfig.nft_max_supply !== 0
                      ? 'text-green'
                      : ''
                  }
                >
                  {formatNumberString(totalMinted + totalMintRemains)}
                </span>
              </b>
            </p>
            <ProgressBar
              className='ProgressBar'
              barContainerClassName='container'
              bgColor='#32FE9A'
              height='5px'
              completed={
                ((totalMinted || 0) * 100) /
                (totalMinted + totalMintRemains || 1)
              }
              customLabel=' '
            />
          </label>
          <label>
            <p style={{ marginTop: '20px' }}>
              You Have Minted{' '}
              <b
                className={
                  mintableCount === 0 &&
                  queryResult?.supplyByAddress !== undefined &&
                  queryResult?.supplyByAddress !== 0
                    ? 'text-green'
                    : ''
                }
              >
                {formatNumberString(queryResult?.supplyByAddress || 0)}/
                <span
                  className={
                    mintableCount === 0 &&
                    queryResult?.supplyByAddress !== undefined &&
                    queryResult?.supplyByAddress !== 0
                      ? 'text-green'
                      : ''
                  }
                >
                  {formatNumberString(
                    (queryResult?.supplyByAddress || 0) + mintableCount
                  )}
                </span>
              </b>
            </p>
            <ProgressBar
              className='ProgressBar'
              barContainerClassName='container'
              bgColor='#32FE9A'
              height='5px'
              completed={
                ((queryResult?.supplyByAddress || 0) * 100) /
                ((queryResult?.supplyByAddress || 0) + mintableCount || 1)
              }
              customLabel=' '
            />
          </label>
        </div>
        <div className='mint'>
          <div className='input'>
            <button
              id='minus'
              disabled={!address || mintCount === 0}
              onClick={() => {
                setMintCount(mintCount > 0 ? mintCount - 1 : 0);
              }}
            />
            <input
              type='text'
              disabled={!address}
              value={mintCount}
              placeholder='0'
              onChange={e => {
                const value = parseInt(e.target.value);
                setMintCount(
                  value < 0 ? 0 : value <= mintableCount ? value : mintableCount
                );
              }}
            />
            <button
              id='plus'
              disabled={!address || mintCount === mintableCount}
              onClick={() => {
                const value = mintCount + 1;
                setMintCount(
                  value < 0 ? 0 : value <= mintableCount ? value : mintableCount
                );
              }}
            />
          </div>
          <span>
            ≈
            <b>
              {formatNumberString(
                new Dec(queryResult?.mintConfig.nft_price_amount || 0)
                  .mul(mintCount)
                  .div(1000000)
              )}{' '}
            </b>
            {symbol}
          </span>
        </div>
        <div className='footer'>
          {address &&
            queryResult &&
            parseFloat(queryResult.mintConfig.nft_price_amount) * mintCount >
              balance * 1000000 && (
              <a
                href='https://juno.loop.markets/'
                target='_blank'
                rel='noreferrer'
              >
                <button>ADD FUNDS TO WALLET</button>
              </a>
            )}
          <button
            id='mint'
            onClick={() => mintHandler()}
            disabled={
              (!isPublicMint && !isWhitelistMint) ||
              mintCount === 0 ||
              (queryResult &&
                parseFloat(queryResult.mintConfig.nft_price_amount) *
                  mintCount >
                  balance * 1000000)
            }
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src='/leaf.png' alt='' style={{ marginRight: '5px' }} />
            {(launchpadNFT?.mint_status === "paused") ? "MINTING PAUSED" :"MINT NFT"}
          </button>
        </div>
      </div>

      <Modal isOpen={pausedModal} title='' onClose={() => setPausedModal(false)} customClass="pauseModal">
        <div style={{ textAlign: 'center', marginBottom: '50px' }} className="pauseCard">
          <h3>Mint is currently paused, minting will continue again soon</h3>
        </div>
      </Modal>
    </div>
  );
};

export default LaunchpadForm;
