import { Dec } from '@terra-money/terra.js';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Nft = ({ postObj, index, setNftModal }: any) => {
  return (
    <>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
      <li>
          <div className="loading_animation">
            <div className="background-masker btn-divide-left"></div>
          </div>
          <p style={{height: '100px'}} />
      </li>
    </>
  );
};
export default Nft;
