import { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LaunchPadNFT, networkType } from 'core/nftConfig';
import useLaunchPadNFTMinterQuery from 'hooks/useLaunchPadNFTMinterQuery';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import * as api from '../../../api/launchpad.api';
import { formatNumberString } from '../../../utils/conversion/conversion';
import { Dec } from '@terra-money/terra.js';
import styles from './WebAppEventBreakDown.module.scss';
import { useJunoTokenBalance } from '../../../hooks/newHooks/useTokenBalance';
import { USDC_DENOM } from '../../../core/constants';
import useJunoTransactions from '../../../hooks/newHooks/useJunoTransactions';
import { NotificationContext } from 'contexts/notification';

const WebAppEventBreakDown = ({launchpadNFT}: {launchpadNFT: LaunchPadNFT; }) => {
  const params = useParams<any>();
  const { submit, estimateGas } = useJunoTransactions();
  const [mintCount, setMintCount] = useState(1);
  const { balance } = useJunoTokenBalance('USDC');
  const { address } = useRecoilValue(walletState);
  const queryResult = useLaunchPadNFTMinterQuery(
    networkType,
    launchpadNFT?.bookingInfo.MINTER,
    address
  );

  const isMintStart =
    queryResult &&
    queryResult?.mintConfig?.mint_start_time !== 0 &&
    new Date().getTime() / 1000 > queryResult?.mintConfig?.mint_start_time;

  const isPublicMint =
    isMintStart &&
    new Date().getTime() / 1000 >=
      queryResult?.mintConfig?.mint_start_time +
        queryResult?.mintConfig?.whitelist_mint_period &&
    new Date().getTime() / 1000 <
      queryResult?.mintConfig?.mint_start_time +
        queryResult?.mintConfig?.whitelist_mint_period +
        queryResult?.mintConfig?.public_mint_period;

  const isWhitelistMint =
    isMintStart &&
    queryResult?.is_whitelisted &&
    new Date().getTime() / 1000 <
      queryResult?.mintConfig?.mint_start_time +
        queryResult?.mintConfig?.whitelist_mint_period;

  const mintableCount = queryResult
    ? isPublicMint
      ? queryResult?.mintConfig?.public_mint_limit - queryResult.supplyByAddress
      : isWhitelistMint
      ? queryResult?.mintConfig?.whitelist_mint_limit -
        queryResult?.supplyByAddress
      : 0
    : 0;

    const notificationContext = useContext(NotificationContext);
  //////////////////////////////////////////

  const mintHandler = async () => {
    notificationContext.setTheme({ type: 0, subType: 1 });
    const timer = setTimeout(() => notificationContext.setTheme({ type: 0, subType: 2 }), 4 * 1000);
  };

  return (
    <>
      {launchpadNFT && (
        <>
          {/*
          <div
            className='back'
            onClick={() => props.history.push(`/webapp/launchpad/${launchpadNFT?.project_slug}`)}
          >
            <img src='/barrow.png' alt='aro_lft.png' style={{marginRight: '10px'}}/> Back
          </div>
           */}
          <div className='webapp_launchViewHeader'>

            <div className='launchViewRight'>
              <div className="webapp_details fadeInUp animated">
                <b>
                  <img className="ticket_icon" src="/webapp_ticket.svg" />
                </b>
              <div className="div" />
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px'}}>
                <div>
                  <p>Ticket <span className="mintCount">x{mintCount}</span></p>
                  <p className="mintLimit">Ticket limit <span>{formatNumberString(mintCount)}/{formatNumberString((queryResult?.supplyByAddress || 0) + mintableCount)}</span> consumed</p>
                </div>
              </div>
              </div>
              <div className='nft_view' style={{borderTop: 'solid 3px rgb(58 53 87)'}}>
                <div className='block'>
                  <footer className='footer'>
                    <div className='price'>
                      <>
                        <div className={'footer_div ' + styles.input}>
                          <button
                            id='minus'
                            disabled={!address || mintCount === 0}
                            onClick={() => {
                              setMintCount(mintCount > 0 ? mintCount - 1 : 0);
                            }}
                          >
                            -
                          </button>
                          <input
                            type='text'
                            disabled={!address}
                            value={mintCount}
                            placeholder='0'
                            onChange={e => {
                              const value = parseInt(e.target.value);
                              setMintCount(
                                value < 0
                                  ? 0
                                  : value <= mintableCount
                                  ? value
                                  : mintableCount
                              );
                            }}
                          />
                          <button
                            id='plus'
                            disabled={!address || mintCount === mintableCount}
                            onClick={() => {
                              const value = mintCount + 1;
                              setMintCount(
                                value < 0
                                  ? 0
                                  : value <= mintableCount
                                  ? value
                                  : mintableCount
                              );
                            }}
                          >
                            +
                          </button>
                        </div>

                        <div>
                          <button
                            className='buyNowButton'
                            id='mint'
                            onClick={() => mintHandler()}
                          >
                            Mint Ticket
                          </button>
                        </div>
                      </>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WebAppEventBreakDown;
