import { useContext } from 'react';
import { NotificationContext } from '../contexts/notification';
import axios from 'axios';

type TxProps = {
  collection_addr?: string;
  address?: string;
};

export default (props?: TxProps) => {
  const notificationContext = useContext(NotificationContext);

  const notificationEvent = ({
    subType,
    txErrorMessage,
    redirectURL,
    txHash,
  }: {
    subType: number;
    txErrorMessage?: string;
    redirectURL?: string;
    txHash?: string;
  }) => {
    notificationContext.setTheme({
      type: 0,
      subType: subType,
      ...(txErrorMessage && { txErrorMessage: txErrorMessage }),
      ...(redirectURL && { redirectURL: redirectURL }),
      ...(txHash && { txHash: txHash }),
    });
  };

  const MintNft = async ({
    collection_addr,
    address,
    redirectTo,
    mint_type,
  }: {
    collection_addr: string;
    address: string;
    redirectTo: string;
    mint_type: string;
  }) => {
    try {
      if (!address || !collection_addr) {
        // Notification Error
        notificationEvent({ subType: 3 });
        return;
      } else {
        // Send Transaction
        // Notification Progress
        notificationEvent({ subType: 1 });
        await axios({
          url: `${process.env.REACT_APP_BACKEND_API}fans/marketplace/mint`,
          method: 'post',
          data: {
            type: mint_type,
            query: {
              collection_addr: collection_addr,
              recipient: address,
            },
          },
        })
          .then((res: any) => {
            if (res.status === 200) {
              notificationEvent({
                subType: 2,
                txHash: res.data.tx_hash,
                redirectURL: redirectTo,
              });
              return;
            } else {
              notificationEvent({ subType: 3, txErrorMessage: res.message });
              return;
            }
          })
          .catch(err => {
            //return error
            notificationEvent({
              subType: 3,
              txErrorMessage: err.response.data,
            });
            return;
          });
      }
    } catch (error) {}
  };

  return {
    MintNft,
  };
};
