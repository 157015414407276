import { FC, useEffect, useState, useMemo } from 'react';
import { queryCollections, searchNfts } from 'hooks/queryCollectionsSearch';
import { getIpfsUrl } from 'core/common';
import { formatNumberString } from 'utils/conversion/conversion';
import { Dec } from '@terra-money/terra.js';
import { Link } from 'react-router-dom';
import CollectionsList from './Collection.list';
import SearchNFTList from './NFTs.list';

const HeaderSearch = ({
  search,
  resetSearch,
}: {
  search: string;
  resetSearch: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [CollectionData, setCollectionData] = useState<any>([]);
  const [NFTData, setNFTData] = useState<any>([]);
  const [totalNFTs, setTotalNFTs] = useState<any>([]);

  useEffect(() => {
    if (search.length > 0) {
      setIsLoading(true);
      queryCollections(search).then(({ collections }) => {
        setCollectionData(collections);
        setIsLoading(false);
      });

      searchNfts(search).then(({ data, totalCount }) => {
        setNFTData(data);
        setTotalNFTs(totalCount);
      });
    }
  }, [search]);

  return (
    <div>
      <div className='search_backdrop' onClick={() => resetSearch()} />
      <div className='search'>
        {isLoading ? (
          <div className='loader' style={{ margin: 'auto' }}></div>
        ) : search.length > 0 ? (
          <>
            {CollectionData.length === 0 && NFTData.length === 0 && (
              <p className='NoResult'>No Results</p>
            )}

            <CollectionsList
              collections={CollectionData}
              resetSearch={resetSearch}
            />
            <SearchNFTList
              NFTs={NFTData}
              totalNFTs={totalNFTs}
              resetSearch={resetSearch}
              search={search}
            />
          </>
        ) : (
          <>
            <p className='NoResult'>Search Collections or NFTs</p>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderSearch;
