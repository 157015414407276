import { useQuery } from 'react-query';
import { Dec } from '@terra-money/terra.js';

import { Network } from 'core/constants';
import { LaunchPadNFT, LPNFT, nftConfig } from 'core/nftConfig';
import { BACKEND_ENDPOINT, PAGE_SIZE } from 'core/common';
import axios from 'axios';
import { NFTSaleInfo, NFTAuctionInfo } from './queryLaunchpadNFTInfo';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';

export type NFTContractData = {
  id: string;
  name: string;
  symbol: string;
};

export type NFTData = {
  contract: NFTContractData;
  id: string;
  inAuction: boolean;
  inSale: boolean;
  inStaking: boolean;
  info: string;
  metadata: string;
  attributes: string;
  owner: string;
  tokenID: string;
  updatedAt: string;
  activeBidders: string[];
  active_bids: {
    nodes: {
      user: string;
      amount: string;
      timestamp: string;
    }[];
  };
  type: 'Standard' | 'Reward';
  saleAmount?: string,
  saleDenom?: string,
  auctionHighBidderAmount?: string,
  auctionMinAmount?: string,
  auctionStartTime?: string,
  auctionEndTime?: string,
};

export type ActiveAuction = {
  auction_id: string;
  token_address: string;
  token_id: string;
};

export type LaunchpadNFTMetadata = {
  name: string;
  project: string;
  description: string;
  image: string;
  attributes: {
    trait_type: string;
    value: string;
  }[];
  external_url?: string;
  animation_url?: string;
  youtube_url?: string;
};

export type LaunchPadNFTInfo = {
  owner: string;
  tier_index: string;
  token1_addr: string;
  token1_amount: string;
  token2_addr: string;
  token2_amount: string;
  vesting_period: string;
  token_uri: string;
  type: 'Reward' | 'Standard';
};

export type LaunchPadNFTStakeInfo = {
  claimed_days: number;
  total_staked_time: number;
  is_staking: boolean;
};

export type StakerRewardsInfo = {
  daily_reward: string;
  token: string;
  total_reward: string;
};

export type EligibleNFT = {
  type: 'launchpad' | 'lp';
  contract: string;
  tokenId: string;
  name: string;
  symbol: string;
  info: LaunchPadNFTInfo;
  stakeInfo: LaunchPadNFTStakeInfo;
  metadata: LaunchpadNFTMetadata;
  saleInfo?: NFTSaleInfo;
  auctionInfo?: NFTAuctionInfo;

  isInAuction: boolean;
  isInSale: boolean;

  myBidAmount?: string;

  daysStaked: number;
  daysRemain: number;
  dailyValue: Dec;
  totalValue: Dec;
};

export type EligibleNFTsQueryResult = {
  nfts: EligibleNFT[];

  totalValue: Dec;
  dailyValue: Dec;
};

export const queryTotalStakedCount = async (
  address: string
): Promise<number> => {
  const query = `
    {
      nfts (
        filter: {
          owner: {
            equalTo: "${address}"
          }
          inStaking: {
            equalTo: true
          }
        },
      ) {
        totalCount
      }
    }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  return data.data.nfts.totalCount;
};

export const queryEligibleStakeNFTs = async (
  networkType: Network,
  address: string,
  offset = 0,
  count = PAGE_SIZE
): Promise<{
  nfts: EligibleNFT[];
  stakerRewardsInfo: StakerRewardsInfo[];
  totalCount: number;
  totalStakeCount: number;
}> => {
  const client = await getQueryClient();
  const query = `
  {
    nfts (
      filter: {
        owner: {
          equalTo: "${address}"
        }
        inSale: {
          equalTo: false
        }
        inAuction: {
          equalTo: false
        }
        type: {
          equalTo: "Reward"
        }
      },
      offset: ${offset},
      first: ${count}
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
        attributes
      }
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
        nodes: NFTData[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  const nfts: EligibleNFT[] = [];

  for (let i = 0; i < data.data.nfts.nodes.length; i++) {
    const nft = data.data.nfts.nodes[i];
    const info = {
      owner: address,
      ...JSON.parse(nft.info),
    };
    const stakeInfo = (await client.queryContractSmart(
      nftConfig[networkType].STAKING,
      {
        stake_info: {
          nft_contract_addr: nft.contract.id,
          nft_token_id: nft.tokenID,
        },
      }
    )) as LaunchPadNFTStakeInfo;
    const daysStaked = Math.floor(stakeInfo.total_staked_time / 60 / 60 / 24);

    const metadata: LaunchpadNFTMetadata = {
      attributes: [],
      ...JSON.parse(nft.metadata),
    }

    if (nft.attributes) {
      metadata.attributes = JSON.parse(nft.attributes)
    }
    nfts.push({
      type: 'launchpad',
      tokenId: nft.tokenID,
      contract: nft.contract.id,
      name: nft.contract.name,
      symbol: nft.contract.symbol,
      info,
      stakeInfo,
      metadata,
      isInAuction: nft.inAuction,
      isInSale: nft.inSale,
      daysStaked:
        daysStaked <= parseInt(info.vesting_period)
          ? daysStaked
          : parseInt(info.vesting_period),
      daysRemain:
        daysStaked <= parseInt(info.vesting_period)
          ? parseInt(info.vesting_period) - daysStaked
          : 0,
      dailyValue: new Dec(0),
      totalValue: new Dec(0),
    });
  }

  let stakerRewardsInfo: StakerRewardsInfo[] = [];

  if (offset === 0) {
    try {
      stakerRewardsInfo = (await client.queryContractSmart(
        nftConfig[networkType].STAKING,
        {
          staker_info: {
            addr: address,
          },
        }
      )) as StakerRewardsInfo[];
    } catch { }
  }

  return {
    nfts,
    stakerRewardsInfo: stakerRewardsInfo,
    totalCount: data.data.nfts.totalCount,
    totalStakeCount: offset === 0 ? await queryTotalStakedCount(address) : 0,
  };
};

export const useEligibleStakeNFTs = (
  networkType: Network,
  address: string
): EligibleNFT[] | undefined => {
  const { data, isLoading } = useQuery<unknown, unknown, EligibleNFT[]>(
    ['eligibleNFTs', networkType, address],
    async () => {
      return (await queryEligibleStakeNFTs(networkType, address)).nfts;
    }
  );

  if (isLoading || !data) {
    return undefined;
  }

  console.log('Query My NFTs = ', data);
  return data;
};

export default useEligibleStakeNFTs;
