export const findValue =
  (logs: any[]) =>
  (key: string, index = 0) => {
    const attribute = logs[0]?.events.find(
      (e: { type: string }) => e.type === 'wasm'
    )?.attributes;
    return (
      attribute?.find((attr: { key: string }) => attr.key === key)?.value ?? ''
    );
  };
