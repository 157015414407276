import React, {useCallback, useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {ToastContainer} from 'react-toastify';
import {useWallet, WalletConnectionStatus} from '@noahsaso/cosmodal';

import NftExplore from './pages/Nft/NftExplore/NftExplore';
import NftWelcome from './pages/Nft/NftWelcome/NftWelcome';
import NftExploreItemDetails from './pages/Nft/NftExplore/NftExploreItemDetails';
import NftCreation from './pages/Nft/NftExplore/nftCreation';
import ScrollToTop from './pages/Shared/ScrollToTop';
import MyNft from './pages/MyNft';
import Launchpad from './pages/Launchpad/launchpad';
import LaunchpadView from './pages/Launchpad/DetailsView/view';
import Collection from './pages/Collections/collections';
import ExploreCollection from './pages/Collections/exploreCollection';
import NftHeader from './pages/Nft/NftHeader';
import {NotificationContext} from './contexts/notification';
import NotificationModals from './components/notificationModals';
import 'react-toastify/dist/ReactToastify.css';
import NftHeaderBanner from './pages/Nft/NftHeaderBanner';

// Live Project
import WebAppTicketLaunchpad from './pages/WebApp/ticketLaunchpad/webapp.launchpad';
import WebAppEventDetail from './pages/WebApp/ticketLaunchpad/webapp.eventDetail';
import WebAppNftExploreItemDetails from './pages/WebApp/mytickets/webapp.ticket.view';
import WebAppNotificationModals from './pages/WebApp/NotificationModals';
import WebAppMyTickets from './pages/WebApp/mytickets';

//Fans
import FansLaunchpad from './pages/Fans/Launchpad/launchpad';
import FansLaunchpadView from './pages/Fans/Launchpad/DetailsView/view';

// Demo
import WebAppDemoTicket from './pages/WebApp/demo/webapp.launchpad';
import WebAppDemoDetail from './pages/WebApp/demo/webapp.eventDetail';
import useAddress from './hooks/useAddress';
import launchpad from "./pages/Launchpad/launchpad";
import {useSetRecoilState} from "recoil";
import {walletState} from "./state/walletAtoms";
import { useWallet as  useGWallet } from "./Loop_Reuseable_Component/packages/loop_wallet"
import NftTopMenu from './pages/Nft/NftTopMenu';

import NftFansHeader from 'pages/Nft/NftFansHeader';
import FanRoutes from 'Routes/FanRoutes';
import CollectionDetailView from 'pages/Collections/collectionDetailView';
import Faqs from 'pages/Faqs';
import Privacy from "./pages/Privacy";

declare const window: any;

const App: React.FC = () => {
    const [type, setType] = useState(undefined);
    const [subType, setSubType] = useState(undefined);
    const [txHash, setTxHash] = useState(undefined);
    const [onOk, setOnOk] = useState(undefined);
    const [txErrorMessage, setTxErrorMessage] = useState(undefined);
    const [redirectURL, setRedirectURL] = useState('');
    const [sidebarActive, setSidebar] = useState(false);
    const [previosStatus, setPreviosStatus] = useState(0)
    const location = useLocation();
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    const wallet = useWallet()
    const setWalletState = useSetRecoilState(walletState)
    const { address: gAddress, client } = useGWallet()
 // @ts-ignore
    useEffect(() => {
        // @ts-ignore
        client && setWalletState({ status: WalletConnectionStatus.Connected,
            address: gAddress,
            chainId: 'juno-1',
            key: null, client
        })
        client && setPreviosStatus(WalletConnectionStatus.Connected)
    }, [client,gAddress])

    const handleSidebar = (status: any) => {
        setSidebar(status);
    };

    const [themeData, setThemeData] = useState<any>({theme: {}});

    const address = useAddress();


    const setTheme = useCallback(theme => {
        setThemeData({theme: theme});
        setType(theme?.type);
        setSubType(theme?.subType);
        setTxHash(theme?.txHash);
        setOnOk(theme?.onOk);
        setTxErrorMessage(theme?.txErrorMessage);
        if (theme?.redirectURL !== undefined) {
            setRedirectURL(theme?.redirectURL);
        }
    }, []);

    useEffect(() => {
        if(window.location.pathname.startsWith("/fans")) {
            document.body.classList.add("body-dark");
          } else {
            document.body.classList.remove("body-dark");
          }
    })

    useEffect(() => {
        if (localStorage && address) {
            if (localStorage.getItem('junoWalletAddress') !== address) {
                localStorage.removeItem('junoWalletEvent');
                localStorage.removeItem('junoWalletAddress');
            }
            if (localStorage.getItem('junoWalletEvent')) {
                const currDate = Date.now();
                const savedDate = localStorage.getItem('junoWalletEvent');
                const diff = currDate - Number(savedDate);
                if (diff / 1000 > 43200) {
                    localStorage.removeItem('junoWalletEvent');
                }
            }
            if (!localStorage.getItem("junoWalletEvent")) {
                localStorage.setItem('junoWalletEvent', Date.now().toString());
                localStorage.setItem('junoWalletAddress', address);
                if (address) {
                    dataLayer.push({
                        event: 'wallet-connect',
                        event_category: 'engagement',
                        wallet_type: window.innerWidth > 821
                            ? "Keplr Chrome Extension"
                            : "Keplr Mobile",
                        wallet_address: address,
                    });
                }
            }
        }

        // eslint-disable-next-line
    }, [address, wallet.status]);

    const [state, setState] = useState<any>({
        theme: {
            type: undefined,
            subType: undefined,
            txHash: undefined,
            onOk: undefined,
            txErrorMessage: undefined,
            redirectURL: undefined,
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setTheme,
    });
    useEffect(() => {
        setState((prevState: any) => ({
            ...prevState,
            theme: {
                type,
                subType,
                txHash,
                onOk,
                txErrorMessage,
                redirectURL,
                ...themeData.theme,
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setTheme,
        }));
    }, [
        type,
        subType,
        txHash,
        onOk,
        txErrorMessage,
        redirectURL,
        setTheme,
        themeData.theme,
    ]);

    const pageName = location.pathname;
    const isFans = pageName.split('/')[1] === 'fans';

    return (
        <NotificationContext.Provider value={state}>
            <ToastContainer style={{width: 'fit-content', top: '4em'}}/>
            <Router>
                <ScrollToTop/>
                <div className={`App ${isFans ? 'fans' : 'cosmos'}`}>
                    <Helmet>
                        <title>Loop DeFi NFTs</title>
                        <meta
                            name='description'
                            content='Find NFTs with real utility. real governance. real rewards'
                        />
                        <meta property='og:url' content='%PUBLIC_URL%'/>
                        <meta property='og:image' content='../Launchpad.jpg'/>
                        <meta
                            name='twitter:description'
                            content='Find NFTs with real utility. real governance. real rewards'
                        />
                        <meta name='twitter:image' content='../Launchpad.jpg'/>
                        <meta name='twitter:card' content='../Launchpad.jpg'/>
                    </Helmet>
                    {!location.pathname.includes('/webapp') ? (
                        <NotificationModals/>
                    ) : (
                        <WebAppNotificationModals/>
                    )}
                    <div className='nftexploreMain'>
                        {!location.pathname.startsWith('/webapp') ? (
                            <>
                            {location.pathname.startsWith('/fans') ?
                            <NftFansHeader handleSidebar={handleSidebar}/>
                            : <NftHeader handleSidebar={handleSidebar}/>}
                                {/* <NftTopMenu /> */}
                                {!location.pathname.startsWith('/fans') && (
                                    <>
                                        <NftHeaderBanner/>
                                    </>
                                )}
                            </>
                        ) : null}
                        <Switch>
                            <Route path='/' exact component={NftWelcome}/>
                            <Route path='/nftCreation' exact component={NftCreation}/>
                            <Route path='/nftExplore' exact component={NftExplore}/>
                            <Route path='/launchpad' exact component={Launchpad}/>
                            <Route
                                path='/launchpad/:address'
                                exact
                                component={LaunchpadView}
                            />
                            <Route path='/collections' exact component={Collection}/>
                            <Route
                                path='/exploreCollection/:id'
                                exact
                                component={ExploreCollection}
                            />
                            <Route
                                path='/exploreCollection/:id/info'
                                exact
                                component={CollectionDetailView}
                            />
                            <Route
                                path='/nftDetail/:address/:id'
                                exact
                                component={NftExploreItemDetails}
                            />
                            <Route path='/mynft' component={MyNft}/>

                            <Route path='/faqs' exact component={Faqs}/>
                            <Route path='/privacy' exact component={Privacy}/>

                            {/* WebApp Routes */}
                            <Route
                                path='/webapp/tickets'
                                exact
                                component={WebAppTicketLaunchpad}
                            />
                            <Route
                                path='/webapp/ticket/:address'
                                exact
                                component={WebAppEventDetail}
                            />
                            <Route
                                path='/webapp/ticketDetail/:address/:id'
                                exact
                                component={WebAppNftExploreItemDetails}
                            />
                            <Route path='/webapp/mytickets' component={WebAppMyTickets}/>
                            <Route path='/webapp/mynfts' component={MyNft}/>
                            <Route
                                path='/webapp/nftDetail/:address/:id'
                                exact
                                component={NftExploreItemDetails}
                            />

                            {/* Demo Routes */}
                            <Route
                                path='/webapp/ticketdemo'
                                exact
                                component={WebAppDemoTicket}
                            />
                            <Route
                                path='/webapp/ticketdemo/:address'
                                exact
                                component={WebAppDemoDetail}
                            />
                            {/* Fan Routes */}
                            {/* <Route path='/fans/launchpad' exact component={FansLaunchpad}/> */}
                            <FanRoutes />
                        </Switch>
                    </div>
                </div>
            </Router>
        </NotificationContext.Provider>
    );
};

export default App;

