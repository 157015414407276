import Select from 'react-select';
import {ReactNode} from "react";

export interface Option {
  value: string | number;
  label: string | ReactNode;
}

const customStyles: any = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#969696' : '#919191',
    padding: 20,
    backgroundColor: '#1b1a28',
    borderTop: '1px solid rgba(145,145,145,0.1)',
  }),
  indicatorSeparator: () => null,
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = 'white';

    return { ...provided, opacity, transition, color };
  },
  control: (provided: any, state: any) => ({
    ...provided,
    //backgroundColor: '#2d2c3a',
    backgroundColor: '#25233e',
    borderColor: '#1b1a28',
    borderRadius: '10px',
    borderBottomRightRadius: state.menuIsOpen ? 0 : '10px',
    borderBottomLeftRadius: state.menuIsOpen ? 0 : '10px',
    borderStyle: 'none',
    boxShadow: 'none',
    '&:hover': {
      //backgroundColor: '#3b3b4a',
      backgroundColor: '#403a59',
      borderColor: '#1b1a28',
      cursor: 'pointer',
    },
    height: '55px',
  }),
  container: () => ({
    width: '100%',
    marginBottom: '20px',
  }),
  input: (provided: any) => ({
    ...provided,
    height: '55px',
    margin: 0,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#1b1a28',
    margin: 0,
    boxShadow: 'none',
    borderTopRightRadius: !state.menuIsOpen ? 0 : '4px',
    borderTopLeftRadius: !state.menuIsOpen ? 0 : '4px',
  }),
};

const SelectBox = ({
  options,
  onChange,
  placeholder,
  value,
}: {
  options: Option[];
  onChange: any;
  placeholder: string;
  value?: any;
}) => {
  return (
    <Select
      options={options}
      styles={customStyles}
      className='select_dropdown'
      menuPlacement='auto'
      menuPosition='fixed'
      onChange={e => onChange(e)}
      placeholder={placeholder}
      value={value}
      isSearchable={false}
    />
  );
};

export default SelectBox;
