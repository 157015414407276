import { useCallback, useEffect, useState } from 'react';
import { Dec } from '@terra-money/terra.js';
import { toast } from 'react-toastify';
import { EligibleNFT } from 'hooks/useEligibleStakeNFTs';
import LaunchpadCard from './webapp.tickets';
import useTokenReserve from 'hooks/useTokenReserve';
import { commonConfig, PAGE_SIZE } from 'core/common';
import { queryStakedNFTs } from 'hooks/queryStakedNFTs';
import { networkType, nftConfig } from 'core/nftConfig';
import { queryListedNFTs } from '../../../hooks/queryListedNFTs';
import { useRecoilValue } from 'recoil';
import { walletState, WalletStatusType } from '../../../state/walletAtoms';
import useJunoTransactions from '../../../hooks/newHooks/useJunoTransactions';
import WalletConnectState from '../../MyNft/walletConnectState';
import { queryMyStandardNfts, StandardNft } from 'hooks/queryMyStandardNfts';
import LaunchpadCardStandard from './webapp.launchpadCardStandard';
import { nftStakeClaimMsg } from '../../../helper/junoContractMsg';
import { queryOwnedCosmoverseNfts } from 'hooks/queryOwnedCosmoverseNfts';

const WebAppMyTicketsOwned = (props: any) => {
  const { address, status } = useRecoilValue(walletState);
  const { submit, estimateGas } = useJunoTransactions();
  const token_reserve = useTokenReserve(
    networkType,
    Object.keys(commonConfig[networkType]?.IS_AVAILABLE_TOKEN || {})
  );

  const [totalSaleNfts, setTotalSaleNfts] = useState(0);
  const [last, setLast] = useState('');
  const [myNfts, setNfts] = useState<EligibleNFT[]>([]);
  const [isRewardNftsLoading, setRewardNftsLoading] = useState(true);
  const [rewardNftMoreExists, setRewardNftMoreExists] = useState(false);
  const [result, setResult] = useState({
    totalValue: new Dec(0),
    dailyValue: new Dec(0),
  });

  const [myStandardNfts, setStandardNfts] = useState<StandardNft[]>([]);
  const [isStandardNftsLoading, setStandardNftsLoading] = useState(true);
  const [standardNftMoreExists, setStandardNftMoreExists] = useState(false);

  useEffect(() => {
    if (token_reserve) {
      setRewardNftsLoading(true);
      queryOwnedCosmoverseNfts(networkType, address).then(({ nfts, last }) => {
        setLast(last);
        setRewardNftMoreExists(nfts.length === PAGE_SIZE);
        setRewardNftsLoading(false);

        const totalValue = new Dec(0);
        const dailyValue = new Dec(0);

        for (let i = 0; i < nfts.length; i++) {
          const info = nfts[i].info;
          if (token_reserve[info.token1_addr]) {
            nfts[i].dailyValue = nfts[i].dailyValue.add(
              new Dec(info.token1_amount)
                .mul(token_reserve[info.token1_addr].usdcAmount)
                .div(token_reserve[info.token1_addr].tokenAmount)
            );
          }
          if (token_reserve[info.token2_addr]) {
            nfts[i].dailyValue = nfts[i].dailyValue.add(
              new Dec(info.token2_amount)
                .mul(token_reserve[info.token2_addr].usdcAmount)
                .div(token_reserve[info.token2_addr].tokenAmount)
            );
          }

          nfts[i].totalValue = nfts[i].dailyValue.mul(nfts[i].daysRemain);
        }

        /*
          for (let i = 0; i < stakerRewardsInfo.length; i++) {
            if (token_reserve[stakerRewardsInfo[i].token]) {
              dailyValue = dailyValue.add(
                new Dec(stakerRewardsInfo[i].daily_reward)
                  .mul(token_reserve[stakerRewardsInfo[i].token].usdcAmount)
                  .div(token_reserve[stakerRewardsInfo[i].token].tokenAmount)
              );
              totalValue = totalValue.add(
                new Dec(stakerRewardsInfo[i].total_reward)
                  .mul(token_reserve[stakerRewardsInfo[i].token].usdcAmount)
                  .div(token_reserve[stakerRewardsInfo[i].token].tokenAmount)
              );
            }
          }
          */

        setNfts(nfts);

        setResult({
          dailyValue,
          totalValue,
        });
      });
    }

    queryListedNFTs(address).then(({ totalCount }) => {
      setTotalSaleNfts(totalCount);
    });
  }, [address, token_reserve, totalSaleNfts]);

  const loadMoreRewardNfts = () => {
    if (token_reserve) {
      setRewardNftsLoading(true);
      queryOwnedCosmoverseNfts(networkType, address, last, myNfts.length).then(
        ({ nfts, last }) => {
          setLast(last);
          setRewardNftMoreExists(nfts.length === PAGE_SIZE);
          setRewardNftsLoading(false);

          nfts = [...myNfts, ...nfts];

          for (let i = 0; i < nfts.length; i++) {
            const info = nfts[i].info;
            if (token_reserve[info.token1_addr]) {
              nfts[i].dailyValue = nfts[i].dailyValue.add(
                new Dec(info.token1_amount)
                  .mul(token_reserve[info.token1_addr].usdcAmount)
                  .div(token_reserve[info.token1_addr].tokenAmount)
              );
            }
            if (token_reserve[info.token2_addr]) {
              nfts[i].dailyValue = nfts[i].dailyValue.add(
                new Dec(info.token2_amount)
                  .mul(token_reserve[info.token2_addr].usdcAmount)
                  .div(token_reserve[info.token2_addr].tokenAmount)
              );
            }

            nfts[i].totalValue = nfts[i].dailyValue.mul(nfts[i].daysRemain);
          }

          setNfts(nfts);
        }
      );
    }
  };

  useEffect(() => {
    setStandardNftsLoading(true);
    queryMyStandardNfts('owned', networkType, address).then(
      ({ nfts, totalCount }) => {
        setStandardNftMoreExists(nfts.length < totalCount);
        setStandardNftsLoading(false);
        setStandardNfts(nfts);
      }
    );
  }, [address]);

  const loadMoreStandardNfts = () => {
    setStandardNftsLoading(true);
    queryMyStandardNfts(
      'owned',
      networkType,
      address,
      myStandardNfts.length
    ).then(({ nfts, totalCount }) => {
      setStandardNftMoreExists(
        myStandardNfts.length + nfts.length < totalCount
      );
      setStandardNftsLoading(false);
      setStandardNfts([...myStandardNfts, ...nfts]);
    });
  };

  const claimHandler = useCallback(async () => {
    const res = await queryStakedNFTs(address);
    const txMsgs: any = [];
    for (let i = 0; i < res.nfts.length; i++) {
      const nft = res.nfts[i];
      const msg: any = nftStakeClaimMsg({
        address,
        networkType,
        nftStaking: nftConfig[networkType].STAKING,
        nft: nft.contract,
        token_id: nft.token_id,
      });

      const fee = await estimateGas([msg]);
      if (fee) {
        txMsgs.push(msg);
      }
    }
    console.log('txMsgs = ', txMsgs);

    if (txMsgs.length === 0) {
      toast.error(
        <div>
          <div>No rewards are currently available.</div>
          <div>Stake your Reward NFTs to earn rewards.</div>
        </div>
      );
    } else {
      const fee = await estimateGas(txMsgs);
      console.log('FEE:', fee);
      submit({ msgs: txMsgs });
    }
  }, [address, estimateGas, submit]);

  return (
    <section>
      {(status === WalletStatusType.connected && myNfts?.length > 0) ||
      isRewardNftsLoading ||
      isStandardNftsLoading ? (
        <>
          <div className='mynftLeft'>
            {/*<ul>*/}
            {/*  <li>*/}
            {/*    <a href=''>*/}
            {/*      <span>*/}
            {/*        <b>*/}
            {/*          Total Staking Value /!* <img src='info.png' alt='' /> *!/*/}
            {/*        </b>*/}
            {/*        <p>*/}
            {/*          {formatNumberString(result?.totalValue.div(1000000))}{' '}*/}
            {/*          <i>USDC</i>*/}
            {/*        </p>*/}
            {/*      </span>*/}
            {/*      <img src='m2.png' alt='' style={{ marginLeft: 10 }} />*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a href=''>*/}
            {/*      <span>*/}
            {/*        <b>*/}
            {/*          Daily Staking Value /!* <img src='info.png' alt='' /> *!/*/}
            {/*        </b>*/}
            {/*        <p>*/}
            {/*          {formatNumberString(result?.dailyValue.div(1000000))}{' '}*/}
            {/*          <i>USDC</i>*/}
            {/*        </p>*/}
            {/*      </span>*/}
            {/*      <img src='m3.png' alt='' style={{ marginLeft: 10 }} />*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a href=''>*/}
            {/*      <span>*/}
            {/*        <b>*/}
            {/*          Staking Ratio*/}
            {/*          /!* <img src='info.png' alt='' /> *!/*/}
            {/*        </b>*/}
            {/*        <p>*/}
            {/*          {totalOnwedNfts + totalSaleNfts === 0*/}
            {/*            ? 0*/}
            {/*            : formatNumberString(*/}
            {/*                new Dec(totalStakedNfts * 100).div(*/}
            {/*                  totalOnwedNfts + totalSaleNfts*/}
            {/*                )*/}
            {/*              )}{' '}*/}
            {/*          <i>%</i>*/}
            {/*        </p>*/}
            {/*      </span>*/}
            {/*      <img src='m4.png' alt='' style={{ marginLeft: 10 }} />*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>
          <div
            className='mynftRight'
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <p className='note'>Stake your NFTs to earn rewards</p>

              {myNfts?.length > 0 &&
                myNfts?.map((nft, index) => {
                  if (nft.type === 'launchpad') {
                    return (
                      <LaunchpadCard
                        key={JSON.stringify(nft) + index}
                        data={nft}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}

              {isRewardNftsLoading ? (
                <div className='noPosts'>
                  <p
                    className='loader'
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  ></p>
                </div>
              ) : (
                rewardNftMoreExists && (
                  <div className='flex-center'>
                    <button onClick={loadMoreRewardNfts}>Load More</button>
                  </div>
                )
              )}
            </div>

            {/*I dont think this will ever render */}
            {false && (isStandardNftsLoading || myStandardNfts?.length > 0) && (
              <div style={{ marginTop: '50px' }}>
                <div className='header'>
                  <h6 style={{ fontSize: '25px' }}>Standard NFTs</h6>
                </div>
                <div className='trendingInfo'>
                  <ul>
                    {myStandardNfts?.length > 0 &&
                      myStandardNfts?.map((nft, index) => {
                        if (nft.type === 'launchpad') {
                          return (
                            <li>
                              <LaunchpadCardStandard
                                key={JSON.stringify(nft) + index}
                                data={nft}
                              />
                            </li>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                  </ul>
                </div>

                {isStandardNftsLoading ? (
                  <div className='noPosts'>
                    <p
                      className='loader'
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    ></p>
                  </div>
                ) : (
                  standardNftMoreExists && (
                    <div className='flex-center'>
                      <button onClick={loadMoreStandardNfts}>Load More</button>
                    </div>
                  )
                )}
              </div>
            )}

            {!isRewardNftsLoading &&
              !isStandardNftsLoading &&
              myNfts.length === 0 &&
              myStandardNfts.length === 0 && <p>No NFTs in your wallet yet</p>}
          </div>
          <div>
            {myNfts?.length > 0 && totalSaleNfts > 0 && (
              <a href='/mynft/onsale'>{totalSaleNfts} nfts are on sale</a>
            )}
          </div>
        </>
      ) : (
        <>
          <WalletConnectState
            connected={Boolean(address)}
            type='tickets'
            noNft={myNfts?.length > 0}
          />
        </>
      )}
    </section>
  );
};

export default WebAppMyTicketsOwned;
