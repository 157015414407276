import { LaunchPadNFT, networkType, nftConfig } from 'core/nftConfig';
import { useJunoTokenBalance } from 'hooks/newHooks/useTokenBalance';
import useLaunchPadNFTMinterQuery from 'hooks/useLaunchPadNFTMinterQuery';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const SwiperItem = ({ item }: { item: LaunchPadNFT }) => {
  const { address } = useRecoilValue(walletState);
  const queryResult = useLaunchPadNFTMinterQuery(
    networkType,
    item.MINTER,
    address
  );
  const [, symbol] = item.launchInfo.mintPrice?.split(' ') || ["", "USDC"];
  const { balance } = useJunoTokenBalance(symbol);

  const isMintStart =
    queryResult &&
    queryResult.mintConfig.mint_start_time !== 0 &&
    new Date().getTime() / 1000 > queryResult.mintConfig.mint_start_time;

  const isPublicMint =
    isMintStart &&
    new Date().getTime() / 1000 >=
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.public_mint_period;

  const isWhitelistMint =
    isMintStart &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period;

  return (
    <Link
      to={`/launchpad/${item.project_slug}`}
      className={balance < 0 ? 'disabled' : ''}
    >
      <div className='launchLiveSlider'>
        <div
          className='sliderBlock'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
          }}
        >
          <div className='daynamicSlider'>
            <img
              style={{ borderRadius: '20px' }}
              src={'https://loop-markets.directus.app/assets/' + item.banner.id}
              alt='rect'
            />
          </div>
        </div>
        <div className='launchLiveSliderInner'>
        {queryResult && (
            <div className='status'>
              {queryResult?.mintConfig.nft_current_supply ===
              queryResult?.mintConfig.nft_max_supply
                ? 'SOLD OUT'
                : isMintStart
                ? isPublicMint
                  ? 'LIVE'
                  : isWhitelistMint 
                  ? 'ALLOW LIST'
                  : 'COMPLETED'
                : 'UPCOMING'}
            </div>
          )}
          <span>
            <img
              style={{ width: '60px' }}
              src={'https://loop-markets.directus.app/assets/' + item.project_logo.id}
              alt='thumb'
            />
          </span>
          <h6>{item.project_name}</h6>
          <div className='social' />
          <div className='details'>
            <label style={{ cursor: 'pointer' }}>
              Mint Price <b>{item.launchInfo.mintPrice}</b>
            </label>
            <label style={{ cursor: 'pointer' }}>
              Supply <b>{item.launchInfo.maxSupply}</b>
            </label>
            <label style={{ cursor: 'pointer' }}>
              Start Date
              <b>
                {new Date(item.launchInfo.startDate).getUTCDate()}
                {' '}
                {monthNames[new Date(item.launchInfo.startDate).getUTCMonth()]}
                {' '}
                {new Date(item.launchInfo.startDate).getUTCFullYear()}
              </b>
            </label>
            <label style={{ cursor: 'pointer' }}>
              End Date
              <b>
                {new Date(item.launchInfo.endDate).getUTCDate()}
                {' '}
                {monthNames[new Date(item.launchInfo.endDate).getUTCMonth()]}
                {' '}
                {new Date(item.launchInfo.endDate).getUTCFullYear()}
              </b>
            </label>
            {address ? (
              <div
              //className={balance <= 0 ? 'mintButton disabled' : 'mintButton'}
              className={'mintButton'}
            >
              MINT NOW
            </div>
            ) : 
            <div
              className={'mintButton'}
            >
              CONNECT WALLET
            </div>}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SwiperItem;
