import { blockList, networkType, nftConfig } from 'core/nftConfig';
import { NFTAuctionInfo, NFTSaleInfo } from './queryLaunchpadNFTInfo';
import {
  LaunchPadNFTInfo,
  LaunchpadNFTMetadata,
  LaunchPadNFTStakeInfo,
  NFTData,
} from './useEligibleStakeNFTs';
import axios from 'axios';
import { BACKEND_ENDPOINT, PAGE_SIZE } from 'core/common';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';
import { USDC_DENOM } from 'core/constants';

export type MarketNFTs = {
  type: 'Reward' | 'Standard';
  owner: string;
  collection: string;
  tokenId: string;
  info: LaunchPadNFTInfo;
  metadata: LaunchpadNFTMetadata;
  saleInfo?: NFTSaleInfo;
  auctionInfo?: NFTAuctionInfo;
  daysRemain: number;
  vesting_period: number;
  inAuction?: boolean;
};

export const MAX = 10000000000;
export const SPIRIT_LEVEL = 'Spirit Level'
export const DRAGON_ID = 'Dragon ID'
export const METEOR_ID = 'Meteor ID'
export const RANK = 'Rank'
export const CustomFilters: {
  [s: string]: number[][]
} = {
  'Spirit Level': [
    [0, 100],
    [100, 500],
    [500, 1000],
    [1000, 5000],
    [5000, 10000],
    [10000, MAX],
  ],
  'Dragon ID': [
    [1, 1000],
    [1000, 2000],
    [2000, 3000],
    [3000, 4000],
    [4000, 5000],
    [5000, 6000],
    [6000, 7000],
    [7000, 8000],
    [8000, 9000],
    [9000, 10000],
  ],
  'Meteor ID': [
    [1, 10000],
    [10000, 20000],
    [20000, 30000],
    [30000, 40000],
    [40000, 50000],
  ],
  'Rank': [
    [1, 10000],
    [10000, 20000],
    [20000, 30000],
    [30000, 40000],
  ]
}

export const querySaleCollections = async (address = '') => {
  const query = `  
  {
    nftContracts {
      nodes {
        id
        nfts (
          filter: {
            contractId: {
              notIn: "${blockList}"
            }
            or: [
              {
                inSale: {
                  equalTo: true
                }
              }
              {
                inAuction: {
                  equalTo: true
                }
                or: [
                  {
                    auctionEndTime: {
                      greaterThan: "${Math.floor(new Date().getTime() / 1000)}"
                    }
                  }
                  {
                    auctionHighBidderAddr: {
                      equalTo: "${address}"
                    }
                  }
                  {
                    owner: {
                      equalTo: "${address || ''}"
                    }
                  }
                ]
              }
            ]
          }
        ) {
          totalCount
        }
      }
    }
  }
  `;

  const data = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  return data.data.nftContracts.nodes
    .filter(
      (node: { nfts: { totalCount: number } }) => node.nfts.totalCount > 0
    )
    .map((node: { id: number }) => node.id);
};

export const querySaleNFTs = async (
  address = '',
  term = '',
  tokenVesting = 0,
  sortOrder = 0, // 0 => Ascending, 1 => Descending
  /*
   * SORT TYPE
   * 0: not selected
   * 1: Recently Listed
   * 2: Price
   * 3: End Time
   * 4: Rewards per day
   * 5: Total Rewards
   */
  sortType = '0',
  /*
   * SALE TYPE
   * 0: not selected
   * 1: Auction
   * 2: Fixed sale
   */
  saleType = '0',
  saleAsset = '',
  rewardTokenType = '',
  collection = '',
  offset = 0,
  count = PAGE_SIZE
): Promise<{
  nfts: MarketNFTs[];
  totalCount: number;
}> => {
  const client = await getQueryClient();

  let orderByString = '';
  if (sortOrder === 0) {
    if (sortType === '1') {
      orderByString += 'UPDATED_AT_ASC';
    } else if (sortType === '2') {
      orderByString += 'MARKETPLACE_PRICE_AMOUNT_ASC';
    } else if (sortType === '3') {
      orderByString += 'AUCTION_END_TIME_ASC';
    } else if (sortType === '4') {
      orderByString += 'TOKEN1_AMOUNT_ASC TOKEN2_AMOUNT_ASC';
    } else if (sortType === '5') {
      orderByString += 'TOKEN1_AMOUNT_ASC TOKEN2_AMOUNT_ASC';
    }
  } else {
    if (sortType === '1') {
      orderByString += 'UPDATED_AT_DESC';
    } else if (sortType === '2') {
      orderByString += 'MARKETPLACE_PRICE_AMOUNT_DESC';
    } else if (sortType === '3') {
      orderByString += 'AUCTION_END_TIME_DESC';
    } else if (sortType === '4') {
      orderByString += 'TOKEN1_AMOUNT_DESC TOKEN2_AMOUNT_DESC';
    } else if (sortType === '5') {
      orderByString += 'TOKEN1_AMOUNT_DESC TOKEN2_AMOUNT_DESC ';
    }
  }
  orderByString += ' AUCTION_END_TIME_DESC';

  let rewardTokenFilter = '';
  if (rewardTokenType.length > 0) {
    rewardTokenFilter = `{token1Addr: { equalTo: "${rewardTokenType}"}}
    {token2Addr: {equalTo: "${rewardTokenType}"}}`;
  }

  let saleTypeFilter = '';
  if (saleType === '1') {
    saleTypeFilter = `{ inAuction: { equalTo: true }}`;
  } else if (saleType === '2') {
    saleTypeFilter = `{ inSale: { equalTo: true }}`;
  }

  let collectionFilter = '';
  if (collection) {
    collectionFilter = `{ contractId: { equalTo: "${collection}" }}`;
  }

  let tokenVestingFilter = '';
  if (tokenVesting === 1) {
    tokenVestingFilter = `{ type: { equalTo: "Reward" }}`;
  } else if (tokenVesting === 2) {
    tokenVestingFilter = `{ type: { equalTo: "Standard" }}`;
  }

  const query = `
  {
    nfts (
      ${orderByString.length > 0 ? 'orderBy: [' + orderByString + ']' : ''}
      filter: {
        name: {
          includesInsensitive: "${term}"
        },
        marketplacePriceAmount: {
          isNull: false
        },
        contractId: {
          notIn: "${blockList}"
        }
        saleDenom: {
          includes: "${saleAsset ? saleAsset : ""}"
        }
        and: [
        {
          or: [
            {
              inSale: {
                equalTo: true
              }
            }
            {
              inAuction: {
                equalTo: true
              }
              or: [
                {
                  auctionEndTime: {
                    greaterThan: "${Math.floor(new Date().getTime() / 1000)}"
                  }
                }
                {
                  auctionHighBidderAddr: {
                    equalTo: "${address}"
                  }
                }
                {
                  owner: {
                    equalTo: "${address || ''}"
                  }
                }
              ]
            }
          ]
        }
        {
          or: [
              ${rewardTokenType.length > 0 ? rewardTokenFilter : ''}
          ]
        }
        ${collectionFilter}
        ${saleTypeFilter}
        ${tokenVestingFilter}
        ],
      },
      offset: ${offset},
      first: ${count}
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
      }
    }
  }`;
  const data: {
    data: {
      nfts: {
        totalCount: number;
        nodes: NFTData[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  const nfts: MarketNFTs[] = [];

  for (let i = 0; i < data.data.nfts.nodes.length; i++) {
    const nft = data.data.nfts.nodes[i];
    const info = {
      owner: nft.owner,
      ...JSON.parse(nft.info),
    };

    const daysRemain = 0;
    nfts.push({
      type: nft.type,
      owner: nft.owner,
      vesting_period: info.vesting_period,
      collection: nft.contract.id,
      tokenId: nft.tokenID,
      info,
      metadata: JSON.parse(nft.metadata),
      inAuction: nft.inAuction,
      daysRemain,
    });
  }

  return {
    nfts,
    totalCount: data.data.nfts.totalCount,
  };
};

export const queryCollectionNFTs = async (
  collection: string,
  address = '',
  tokenVesting = 0,
  sortOrder = 0, // 0 => Ascending, 1 => Descending
  /*
   * SORT TYPE
   * 0: not selected
   * 1: Recently Listed
   * 2: Price
   * 3: End Time
   * 4: Rewards per day
   * 5: Total Rewards
   */
  sortType = '0',
  /*
   * SALE TYPE
   * 0: All
   * 1: Auction
   * 2: Fixed sale
   */
  saleType = '0',
  saleAsset = '',
  offset = 0,
  count = PAGE_SIZE,
  listType = 0,
  attributeFilter: {
    attribute: string;
    value: string;
  }[] = [],
): Promise<{
  nfts: MarketNFTs[];
  totalCount: number;
}> => {
  const client = await getQueryClient();

  let orderByString = '';
  if (sortOrder === 0) {
    if (sortType === '1') {
      orderByString += 'UPDATED_AT_ASC';
    } else if (sortType === '2') {
      orderByString += 'MARKETPLACE_PRICE_AMOUNT_ASC';
    } else if (sortType === '3') {
      orderByString += 'AUCTION_END_TIME_ASC';
    } else if (sortType === '4') {
      orderByString += 'TOKEN1_AMOUNT_ASC TOKEN2_AMOUNT_ASC';
    } else if (sortType === '5') {
      orderByString += 'TOKEN1_AMOUNT_ASC TOKEN2_AMOUNT_ASC';
    }
  } else {
    if (sortType === '1') {
      orderByString += 'UPDATED_AT_DESC';
    } else if (sortType === '2') {
      orderByString += 'MARKETPLACE_PRICE_AMOUNT_DESC';
    } else if (sortType === '3') {
      orderByString += 'AUCTION_END_TIME_DESC';
    } else if (sortType === '4') {
      orderByString += 'TOKEN1_AMOUNT_DESC TOKEN2_AMOUNT_DESC';
    } else if (sortType === '5') {
      orderByString += 'TOKEN1_AMOUNT_DESC TOKEN2_AMOUNT_DESC ';
    }
  }
  orderByString += ' AUCTION_END_TIME_DESC';

  let saleTypeFilter = '';
  if (saleType === '1') {
    saleTypeFilter = `{ inAuction: { equalTo: true }}`;
  } else if (saleType === '2') {
    saleTypeFilter = `{ inSale: { equalTo: true }}`;
  }

  let collectionFilter = '';
  if (collection) {
    collectionFilter = `{ contractId: { equalTo: "${collection}" }}`;
  }

  let tokenVestingFilter = '';
  if (tokenVesting === 1) {
    tokenVestingFilter = `{ type: { equalTo: "Reward" }}`;
  } else if (tokenVesting === 2) {
    tokenVestingFilter = `{ type: { equalTo: "Standard" }}`;
  }

  let attributeFilterString = ''
  if (attributeFilter.length > 0) {
    for (let i = 0; i < attributeFilter.length; i++) {
      if (attributeFilter[i].attribute === SPIRIT_LEVEL) {
        const spiritLevelValues = CustomFilters[SPIRIT_LEVEL].map(item => `${item[0]}-${item[1]}`)
        if (attributeFilter[i].value === spiritLevelValues[0]) {
          // 0 - 99
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"_.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"__.%\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === spiritLevelValues[1]) {
          // 100 - 499
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"1__.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"2__.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"3__.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"4__.%\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === spiritLevelValues[2]) {
          // 500 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"5__.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"6__.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"7__.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"8__.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"9__.%\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === spiritLevelValues[3]) {
          // 1000 - 5000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"1___.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"2___.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"3___.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"4___.%\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === spiritLevelValues[4]) {
          // 5000 - 10000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"5___.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"6___.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"7___.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"8___.%\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"9___.%\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === spiritLevelValues[5]) {
          // 10000 - 
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${SPIRIT_LEVEL}\\",\\"value\\":\\"%_____.%\\"%"
                }
              }
            ]
          }`;
        }
      } else if (attributeFilter[i].attribute === DRAGON_ID) {
        const levelValues = CustomFilters[DRAGON_ID].map(item => `${item[0]}-${item[1]}`)
        if (attributeFilter[i].value === levelValues[0]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"_\\"%"
                }
              } 
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"__\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[1]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"1___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[2]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"2___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[3]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"3___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[4]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"4___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[5]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"5___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[6]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"6___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[7]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"7___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[8]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"8___\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[9]) {
          // 0 - 1000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${DRAGON_ID}\\",\\"value\\":\\"9___\\"%"
                }
              }
            ]
          }`;
        }
      } else if (attributeFilter[i].attribute === METEOR_ID) {
        const levelValues = CustomFilters[METEOR_ID].map(item => `${item[0]}-${item[1]}`)
        if (attributeFilter[i].value === levelValues[0]) {
          // 0 - 10000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"_\\"%"
                }
              } 
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"__\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"___\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"____\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[1]) {
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"1____\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[2]) {
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"2____\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[3]) {
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"3____\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[4]) {
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${METEOR_ID}\\",\\"value\\":\\"4____\\"%"
                }
              }
            ]
          }`;
        }
      } else if (attributeFilter[i].attribute === RANK) {
        const levelValues = CustomFilters[RANK].map(item => `${item[0]}-${item[1]}`)
        if (attributeFilter[i].value === levelValues[0]) {
          // 0 - 10000
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${RANK}\\",\\"value\\":\\"_\\"%"
                }
              } 
              {
                attributes: {
                  like: "%\\"${RANK}\\",\\"value\\":\\"__\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${RANK}\\",\\"value\\":\\"___\\"%"
                }
              }
              {
                attributes: {
                  like: "%\\"${RANK}\\",\\"value\\":\\"____\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[1]) {
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${RANK}\\",\\"value\\":\\"1____\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[2]) {
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${RANK}\\",\\"value\\":\\"2____\\"%"
                }
              }
            ]
          }`;
        } else if (attributeFilter[i].value === levelValues[3]) {
          attributeFilterString += `{
            or: [
              {
                attributes: {
                  like: "%\\"${RANK}\\",\\"value\\":\\"3____\\"%"
                }
              }
            ]
          }`;
        }
      } else {
        attributeFilterString += `{attributes: {includes: "\\"${attributeFilter[i].attribute}\\",\\"value\\":\\"${attributeFilter[i].value}\\""}}`;
      }
    }

    attributeFilterString = `{
      and: [
        ${attributeFilterString}
      ]
    }`
  }

  const query = `
  {
    nfts (
      ${orderByString.length > 0 ? 'orderBy: [' + orderByString + ']' : ''}
      filter: {
        ${!listType ? `marketplacePriceAmount: {isNull: false}` : ``},
        ${!listType ? `saleDenom: {includes: "${saleAsset}"}` : ``},
        and: [
        {
          or: [
            {
              inSale: {
                equalTo: ${listType ? false : true}
              }
            }
            {
              inAuction: {
                equalTo: ${listType ? false : true}
              }
              or: [
                {
                  auctionEndTime: {
                    greaterThan: "${Math.floor(new Date().getTime() / 1000)}"
                  }
                }
                {
                  auctionHighBidderAddr: {
                    equalTo: "${address}"
                  }
                }
                {
                  owner: {
                    equalTo: "${address || ''}"
                  }
                }
              ]
            }
          ]
        }
        ${collectionFilter}
        ${saleTypeFilter}
        ${tokenVestingFilter}
        ${attributeFilterString}
        ],
      },
      offset: ${offset},
      first: ${count}
    ) {
      totalCount
      nodes{
        id
        contract {
          id
          name
          symbol
        }
        owner
        info
        metadata
        type
        tokenID
        updatedAt
        inAuction
        inSale
        inStaking
        saleAmount
        saleDenom
        auctionHighBidderAmount
        auctionMinAmount
        auctionStartTime
        auctionEndTime
      }
    }
  }`;
  // console.log(query)
  const data: {
    data: {
      nfts: {
        totalCount: number;
        nodes: NFTData[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  const nfts: MarketNFTs[] = [];

  for (let i = 0; i < data.data.nfts.nodes.length; i++) {
    const nft = data.data.nfts.nodes[i];
    const info = {
      owner: nft.owner,
      ...JSON.parse(nft.info),
    };

    let daysRemain = 0;
    if (nft.type === "Reward") {
      try {
        const stakeInfo = (await client.queryContractSmart(
          nftConfig[networkType].STAKING,
          {
            stake_info: {
              nft_contract_addr: nft.contract.id,
              nft_token_id: nft.tokenID,
            },
          }
        )) as LaunchPadNFTStakeInfo;
        const daysStaked = Math.floor(stakeInfo.total_staked_time / 60 / 60 / 24);
        daysRemain =
          daysStaked <= parseInt(info.vesting_period)
            ? parseInt(info.vesting_period) - daysStaked
            : 0;
      } catch { }
    }

    if (nft.inSale && saleType=='2') {
      try {
        // const saleInfo = (await client.queryContractSmart(
        //   nftConfig[networkType].SALE,
        //   {
        //     nft_info: {
        //       wallet: nft.owner,
        //       nft_contract_addr: nft.contract.id,
        //       nft_token_id: nft.tokenID,
        //     },
        //   }
        // )) as NFTSaleInfo;
        nfts.push({
          type: nft.type,
          owner: nft.owner,
          vesting_period: info.vesting_period,
          collection: nft.contract.id,
          tokenId: nft.tokenID,
          info,
          metadata: JSON.parse(nft.metadata),
          saleInfo: {
            amount: nft.saleAmount || "0",
            denom: nft.saleDenom || USDC_DENOM,
            nft_contract_addr: nft.contract.id,
            nft_token_id: nft.tokenID
          },
          daysRemain,
        });
      } catch {
        console.log(
          'sale info not available: ' + nft.contract.id + ' / ' + nft.tokenID
        );
      }
    } else if (nft.inAuction && saleType=='1') {
      try {
        // const auctionInfo = (await client?.queryContractSmart(
        //   nftConfig[networkType].AUCTION,
        //   {
        //     auction_state: {
        //       token_id: nft.tokenID,
        //       token_address: nft.contract.id,
        //     },
        //   }
        // )) as NFTAuctionInfo;
        nfts.push({
          type: nft.type,
          owner: nft.owner,
          vesting_period: info.vesting_period,
          collection: nft.contract.id,
          tokenId: nft.tokenID,
          info,
          metadata: JSON.parse(nft.metadata),
          auctionInfo: {
            start_time: parseInt(nft.auctionStartTime!.toString()),
            end_time: parseInt(nft.auctionEndTime!.toString()),
            high_bidder_amount: nft.auctionHighBidderAmount!,
            min_amount: nft.auctionMinAmount!,
          } as any,
          inAuction: nft.inAuction,
          daysRemain,
        });

        // try {
        //   const min_bid_amount = (await client.queryContractSmart(
        //     nftConfig[networkType].AUCTION,
        //     {
        //       next_min_bid_amount: {
        //         token_id: nft.tokenID,
        //         token_address: nft.contract.id,
        //       },
        //     }
        //   )) as string;
        //   auctionInfo.min_bid_amount = min_bid_amount;

        //   nfts[nfts.length - 1].auctionInfo = auctionInfo;
        // } catch {
        //   console.log(
        //     'auction min bid amount not available: ' +
        //     nft.contract.id +
        //     ' / ' +
        //     nft.tokenID
        //   );
        // }
      } catch {
        console.log(
          'auction info not available: ' + nft.contract.id + ' / ' + nft.tokenID
        );
      }
    }
    if (saleType=='0'){
      try{
        if (nft.inAuction){
          nfts.push({
            type: nft.type,
            owner: nft.owner,
           vesting_period: info.vesting_period,
            collection: nft.contract.id,
            tokenId: nft.tokenID,
            info,
            metadata: JSON.parse(nft.metadata),
            auctionInfo: {
              start_time: parseInt(nft.auctionStartTime!.toString()),
              end_time: parseInt(nft.auctionEndTime!.toString()),
              high_bidder_amount: nft.auctionHighBidderAmount!,
              min_amount: nft.auctionMinAmount!,
            } as any,
            inAuction: nft.inAuction,
            daysRemain,
          });
        }
        if(nft.inSale){
          nfts.push({
            type: nft.type,
            owner: nft.owner,
            vesting_period: info.vesting_period,
            collection: nft.contract.id,
            tokenId: nft.tokenID,
            info,
            metadata: JSON.parse(nft.metadata),
            saleInfo: {
              amount: nft.saleAmount || "0",
              denom: nft.saleDenom || USDC_DENOM,
              nft_contract_addr: nft.contract.id,
              nft_token_id: nft.tokenID
            },
            inAuction: nft.inAuction,
            daysRemain,
          });
        }
        if(!nft.inSale && !nft.inAuction){
          nfts.push({
            type: nft.type,
            owner: nft.owner,
            vesting_period: info.vesting_period,
            collection: nft.contract.id,
            tokenId: nft.tokenID,
            info,
            metadata: JSON.parse(nft.metadata),
            inAuction: nft.inAuction,
            daysRemain,
          });
        }
      }
      catch(error){
        console.log(
          'auction info not available: ' + nft.contract.id + ' / ' + nft.tokenID
        );
      }
    }
  }

  return {
    nfts,
    totalCount: data.data.nfts.totalCount,
  };
};
