import { Dec } from '@terra-money/terra.js';
import { formatNumberString } from 'utils/conversion/conversion';

const LaunchRewards = ({ launchpadNFT }: { launchpadNFT:any }) => {
  const [, symbol] = launchpadNFT.launchInfo.mintPrice?.split(' ') || ["", "USDC"];
  return (
    <div className='launchRewards'>
      <div className='title'>
        <img src='/icons/rewards_icon.svg' alt='thumb' />
        <h6>Rewards</h6>
      </div>

      {launchpadNFT.rewards.reward.map((info: any, index: number) => {
              let mintRemaining = 0;
              for (let i = 0; i < launchpadNFT.rewards.reward.length; i++) {
                mintRemaining +=
                  parseInt(launchpadNFT.rewards.reward[i].supply) - 0;
              }

              const tierValue =
                launchpadNFT.launchInfo.maxSupply > 0
                  ? new Dec(info.supply)
                      .sub(new Dec(0))
                      .mul(100)
                      .div(mintRemaining)
                  : new Dec(0);
        return (
          <>
            <div className='tableHeader__mobile'>
              <p>
                Reward Tiers:
                <span>
                  <img
                    src={`/icons/reward_tier/tier_${index < 3 ? index + 1 : 3}.png`}
                    alt=''
                    style={{ paddingRight: '10px', width: '30px' }}
                  />
                  {`Tier ${index + 1}`}
                </span>
              </p>
              <p>
                Supply:{' '}
                <span>
                  {launchpadNFT.launch_tba ? 'TBA' :
                    <>
                      <b>{formatNumberString(info.supply, 0)}</b>
                    </>
                  }
                </span>
               
              </p>
              <p>
                Probability of Minting:{' '}
                <span className="tiersGreen">
                  {launchpadNFT.launch_tba ? 'TBA' :
                    <>
                      {formatNumberString(tierValue)}%
                    </>
                  }
                </span>
              </p>
              <p>
                Tokens:
                <span>
                  {launchpadNFT.launch_tba ? 'TBA' :
                    <>
                      {new Dec(info.token1_amount).gt(0) && (
                        <div>
                          {info.token1_amount && (
                            info.token1_amount
                            + ' ' +
                            info.token1_addr
                            + ' + '
                          )}
                        </div>
                      )}
                      {new Dec(info.token2_amount).gt(0) && (
                        <div>
                          {info.token2_amount && (
                            info.token2_amount
                            + ' ' +
                            info.token2_addr
                            + ' + '
                          )}
                        </div>
                      )}
                    </>
                  }
                  
                </span>
              </p>
              <p>
                Vesting:{' '}
                <span>
                  {launchpadNFT.launch_tba ? 'TBA' :
                    <>
                      {formatNumberString(info.vesting_period)} days
                    </>
                  }
                </span>
              </p>
              <p>
                Value ({symbol}): <span>?</span>
              </p>
            </div>
            <div className='tableHeader__mobile__divider' />
          </>
        );
      })}

      <div style={{ borderRadius: '20px', overflow: 'auto' }}>
        <table>
          <tbody>
            <tr className='tableHeader'>
              <td>Reward Tiers</td>
              <td className='tooltip_nfts'>Minted/Total NFTs</td>
              <td>Probability of Minting</td>
              <td>Tokens</td>
              <td>Vesting</td>
              <td>APR</td>
            </tr>
            {launchpadNFT.rewards.reward.map((info: any, index: number) => {
              let mintRemaining = 0;
              for (let i = 0; i < launchpadNFT.rewards.reward.length; i++) {
                mintRemaining +=
                  parseInt(launchpadNFT.rewards.reward[i].supply) - 0;
              }

              const tierValue =
                launchpadNFT.launchInfo.maxSupply > 0
                  ? new Dec(info.supply)
                      .sub(new Dec(0))
                      .mul(100)
                      .div(mintRemaining)
                  : new Dec(0);

              return (
                <tr>
                  <td>
                    <img
                      src={`/icons/reward_tier/tier_${
                        index < 3 ? index + 1 : 3
                      }.png`}
                      alt=''
                    />
                    {`Tier ${index + 1}`}
                  </td>
                  <td>
                    {launchpadNFT.launch_tba ? 'TBA' :
                    <>
                      {formatNumberString(info.supply, 0)}
                    </>
                    }
                  </td>
                  <td>
                    <p className='tiersGreen'>
                      {launchpadNFT.launch_tba ? 'TBA' :
                      <>
                        {formatNumberString(tierValue)}%
                      </>
                      }
                    </p>
                  </td>
                  <td>
                  {launchpadNFT.launch_tba ? 'TBA' :
                    <>
                      <div>
                        {new Dec(info.token1_amount).gt(0) && (
                          info.token1_amount && (
                            info.token1_amount
                            + ' ' +
                            info.token1_addr
                          )
                        )}
                      </div>
                      <div>
                        {new Dec(info.token2_amount).gt(0) && (
                          info.token2_amount && (
                            info.token2_amount
                            + ' ' +
                            info.token2_addr
                          )
                        )}
                        {new Dec(info.token3_amount).gt(0) && (
                          info.token3_amount && (
                            info.token3_amount
                            + ' ' +
                            info.token3_addr
                          )
                        )}
                      </div>
                    </>
                  }
                  </td>
                  <td>
                    {launchpadNFT.launch_tba ? 'TBA' :
                      <>
                        {formatNumberString(info.vesting_period)} days
                      </>
                    }
                  </td>
                  <td>
                    ?
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LaunchRewards;
