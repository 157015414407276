import styles from './mintcard.module.scss';

const MintCardPlaceholder = ({ id }: { id: string }) => {

  return (
    <>
      {
        <div className='wrapper'>
          <div className={styles.card}>
            <div className={styles.item}>
              <div className={styles.collectionImage}>
                <div className={styles.placeholderImage} />
              </div>
              <div className={styles.right}>
                <h4>. . . .</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  imperdiet, mauris quis semper aliquet, metus risus maximus
                  libero, eget dictum tortor massa sit amet odio.
                </p>

                <div className={styles.mintDate}>
                  <div className={styles.date}>
                    0 <span>DAYS</span>
                  </div>
                  <div className={styles.date}>
                    0 <span>HRS</span>
                  </div>
                  <div className={styles.date}>
                    0 <span>MINS</span>
                  </div>
                  <div className={styles.date}>
                    0 <span>SECS</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default MintCardPlaceholder;
