import { div, multiple } from 'libs/math';
import { useEffect } from 'react';
import { findValue } from './helper';

declare const window: any;

interface Props {
  txLogs?: any;
  transactionInfo?: any | undefined;
  txHash?: string | undefined;
  itemInfo?: any;
}

const DataLayer = ({ txLogs, transactionInfo, txHash, itemInfo }: Props) => {
  const dataLayer = (window.dataLayer = window.dataLayer || []);

  useEffect(() => {
    try {
      if (transactionInfo?.type === 'mintNft') {
        const val = findValue(txLogs);
        const title = val('token_id', 0);
        const mintNo = title?.split('#')[1];
        let tokenPrice;
        if (transactionInfo?.symbol == 'JUNO') {
          tokenPrice = transactionInfo?.tokenPrices?.find(
            (item: any) => item?.token == 'ujuno'
          )?.unitPrice;
        } else {
          tokenPrice = transactionInfo?.tokenPrices?.find(
            (item: any) => item?.token == transactionInfo?.symbol
          )?.unitPrice;
        }
        dataLayer.push({
          event: 'mint-item',
          event_category: 'nft',
          title: `${title}`,
          id: `#${mintNo}`,
          price: div(transactionInfo?.price, '1000000'),
          value: multiple(
            transactionInfo?.quantity,
            div(transactionInfo?.price, '1000000')
          ),
          currency: transactionInfo?.symbol,
          quantity: transactionInfo?.quantity,
          transaction_id: `${txHash}`,
          transaction_volume: multiple(
            transactionInfo?.quantity,
            multiple(tokenPrice ?? 1, div(transactionInfo?.price, '1000000'))
          ),
        });
      }
      if (itemInfo?.type === 'viewNft') {
        const mintNo = itemInfo?.title?.split('#')[1];
        window.dataLayer.push({
          event: 'view-item',
          event_category: 'nft',
          title: itemInfo?.title,
          creator: itemInfo?.creator,
          collection: itemInfo?.collection,
          tier: itemInfo?.tier,
          id: `#${mintNo}`,
          price: div(itemInfo?.value, '1000000'),
          currency: 'USDC',
          reward: div(itemInfo?.reward, '1000000'),
        });
      }
      if (itemInfo?.type === 'viewItemLaunchapad') {
        dataLayer.push({
          event: 'view-item',
          event_category: 'nft',
          id: itemInfo?.id,
          title: itemInfo?.title,
          creator: itemInfo?.creator,
          collection: itemInfo?.collection,
          price: div(itemInfo?.price ?? 1, '1000000'),
          currency: itemInfo?.currency,
        });
      }
      if (transactionInfo?.type === 'buyNft') {
        const mintNo = transactionInfo?.title?.split('#')[1];
        dataLayer.push({
          event: 'buy-item',
          event_category: 'nft',
          title: transactionInfo?.title,
          creator: transactionInfo?.creator,
          collection: transactionInfo?.collection,
          tier: transactionInfo?.tier,
          id: `#${mintNo}`,
          price: div(transactionInfo?.value, '1000000'),
          value: multiple('1', div(transactionInfo?.value, '1000000')),
          currency: 'USDC',
          commission: 0,
          reward: div(transactionInfo?.reward, '1000000'),
          transaction_id: txHash,
          quantity: 1,
          transaction_volume: multiple(
            1,
            div(transactionInfo?.value, '1000000')
          ),
        });
      }
    } catch (e) {
      console.log(e);
    }
    // @ts-ignore
    // eslint-disable-next-line
  }, []);
  return <></>;
};

export default DataLayer;
