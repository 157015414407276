import {atom, selector, selectorFamily} from "recoil";
import {fetchAPI} from "../../libs/fetchApi";
import {walletState} from "../../state/walletAtoms";
import {
    MintConfigCosmWasm, NativeTokenInfo,
    PairInfo,
    PoolInfo,
    RewardTokenInfo,
    TierInfo, TokenReserve
} from "../../hooks/useLaunchPadNFTMinterQuery";
import {Dec} from "@terra-money/terra.js";
import {commonConfig} from "../../core/common";
import {isJunoContractAddress} from "../../utils/check";
import {USDC_DENOM} from "../../core/constants";
import {networkType} from "../../core/nftConfig";
import {minus, plus} from "../../libs/math";
import {equals} from "ramda";
import {useStore} from "../utils/loadable"

export const fetchLaunchpadList = selector({
    key: "fetchLaunchpadList",
    get: async ({ get }) => {
        const fetchAPIQ = get(fetchAPIQuery)
        return fetchAPIQ({name: 'items/launchpad?filter[project_status][_eq]=live&sort[]=-date_created&fields=*.*'})
    }
})

export const fetchLaunchpadConfig = selector({
    key: "fetchLaunchpadConfig",
    get: async ({ get }) => {
        const { client } = get(walletState)
        if (!client) {
            return
        }
        const list = get(fetchLaunchpadList)
        // eslint-disable-next-line consistent-return
        return list?.data?.map((item:any) => get(fetchLaunchpadMeta(item)))
    }
})

export const fetchLaunchpadMeta = selectorFamily({
    key: "fetchLaunchpadMeta",
    get: (launchpadNFT:any) => async ({ get }) => {
        const { client, address: user } = get(walletState)
        if (!client) {
            return
        }
        if(!launchpadNFT?.launchInfo.MINTER){
            return
        }
        const [
            mintConfig,
            tierInfosResult,
            supplyByAddress,
            isWhitelisted1,
            isWhitelisted2,
        ] = (await Promise.all([
            client.queryContractSmart(launchpadNFT?.launchInfo.MINTER, {
                config: {},
            }),
            client.queryContractSmart(launchpadNFT?.launchInfo.MINTER, {
                tier_infos: {},
            }),
            user
                ? client.queryContractSmart(launchpadNFT?.launchInfo.MINTER, {
                    supply_by_address: {
                        addr: user,
                    },
                })
                : 0,
            user
                ? client.queryContractSmart(launchpadNFT?.launchInfo.MINTER, {
                    is_whitelisted1: {
                        addr: user,
                    },
                })
                : false,
            user
                ? client.queryContractSmart(launchpadNFT?.launchInfo.MINTER, {
                    is_whitelisted2: {
                        addr: user,
                    },
                })
                : false,
        ])) as [
            MintConfigCosmWasm,
            {
                creator: string;
                current_supply: string;
                description: string;
                max_supply: string;
                name: string;
                tier_index: string;
                token1?: RewardTokenInfo;
                token2?: RewardTokenInfo;
                vesting_period: string;
            }[],
            number,
            boolean,
            boolean
        ];

        let isWhitelisted = false;
        if (!mintConfig.nft_price_amount) {
            // change to whitelist1 and whitelist2
            const cur = Math.floor(new Date().getTime() / 1000);
            if (
                cur >=
                mintConfig.mint_start_time + mintConfig.whitelist_mint1_period!
            ) {
                mintConfig.whitelist_mint_limit = mintConfig.whitelist_mint2_limit!;
                mintConfig.whitelist_mint_period =
                    mintConfig.whitelist_mint1_period! +
                    mintConfig.whitelist_mint2_period!;

                if (isWhitelisted1) {
                    mintConfig.whitelist_mint_limit +=
                        mintConfig.whitelist_mint1_limit!;
                }

                if (
                    cur >=
                    mintConfig.mint_start_time +
                    mintConfig.whitelist_mint1_period! +
                    mintConfig.whitelist_mint2_period!
                ) {
                    mintConfig.nft_price_amount =
                        mintConfig.public_mint_price!.toString();
                    isWhitelisted = true;
                    if (isWhitelisted1) {
                        mintConfig.public_mint_limit += mintConfig.whitelist_mint1_limit!;
                    }
                    if (isWhitelisted2) {
                        mintConfig.public_mint_limit += mintConfig.whitelist_mint2_limit!;
                    }
                } else {
                    mintConfig.nft_price_amount =
                        mintConfig.whitelist_mint2_price!.toString();
                    isWhitelisted = isWhitelisted2;
                }
            } else {
                mintConfig.nft_price_amount =
                    mintConfig.whitelist_mint1_price!.toString();
                mintConfig.whitelist_mint_limit = mintConfig.whitelist_mint1_limit!;
                mintConfig.whitelist_mint_period = mintConfig.whitelist_mint1_period!;
                isWhitelisted = isWhitelisted1;
            }
        }

        const tierInfos: TierInfo[] = tierInfosResult?.map(info => {
            return {
                creator: info.creator,
                current_supply: info.current_supply,
                description: info.description,
                max_supply: info.max_supply,
                name: info.name,
                tier_index: info.tier_index,
                token1_addr:
                    info.token1?.info.NativeToken?.denom ||
                    info.token1?.info.Token?.contract_addr ||
                    '',
                token1_amount: info.token1?.amount || '0',
                token2_addr:
                    info.token2?.info.NativeToken?.denom ||
                    info.token2?.info.Token?.contract_addr ||
                    '',
                token2_amount: info.token2?.amount || '0',
                vesting_period: info.vesting_period,
            };
        });

        const isToken: {
            [token: string]: boolean;
        } = {};
        const tokens = [];
        const pairInfoQueries = [];
        for (let i = 0; i < tierInfos?.length; i++) {
            if (
                !isToken[tierInfos[i].token1_addr] &&
                new Dec(tierInfos[i].token1_amount).gt(0) &&
                commonConfig[networkType].IS_AVAILABLE_TOKEN[tierInfos[i].token1_addr]
            ) {
                isToken[tierInfos[i].token1_addr] = true;
                tokens.push(tierInfos[i].token1_addr);
                pairInfoQueries.push(
                    client.queryContractSmart(
                        commonConfig[networkType].SWAP_FACTORY,
                        {
                            pair: {
                                asset_infos: [
                                    isJunoContractAddress(tierInfos[i].token1_addr)
                                        ? {
                                            token: {
                                                contract_addr: tierInfos[i].token1_addr,
                                            },
                                        }
                                        : {
                                            native_token: {
                                                denom: tierInfos[i].token1_addr,
                                            },
                                        },
                                    {
                                        native_token: {
                                            denom: USDC_DENOM,
                                        },
                                    },
                                ],
                            },
                        }
                    )
                );
            }
            if (
                !isToken[tierInfos[i].token2_addr] &&
                new Dec(tierInfos[i].token2_amount).gt(0) &&
                commonConfig[networkType].IS_AVAILABLE_TOKEN[tierInfos[i].token2_addr]
            ) {
                isToken[tierInfos[i].token2_addr] = true;
                tokens.push(tierInfos[i].token2_addr);
                pairInfoQueries.push(
                    client.queryContractSmart(
                        commonConfig[networkType].SWAP_FACTORY,
                        {
                            pair: {
                                asset_infos: [
                                    isJunoContractAddress(tierInfos[i].token2_addr)
                                        ? {
                                            token: {
                                                contract_addr: tierInfos[i].token2_addr,
                                            },
                                        }
                                        : {
                                            native_token: {
                                                denom: tierInfos[i].token2_addr,
                                            },
                                        },
                                    {
                                        native_token: {
                                            denom: USDC_DENOM,
                                        },
                                    },
                                ],
                            },
                        }
                    )
                );
            }
        }
        const pairInfos = (await Promise.all(pairInfoQueries)) as PairInfo[];

        const poolInfoQueries = [];
        for (let i = 0; i < pairInfos?.length; i++) {
            poolInfoQueries.push(
                client.queryContractSmart(pairInfos[i].contract_addr, {
                    pool: {},
                })
            );
        }
        const poolInfos = (await Promise.all(poolInfoQueries)) as PoolInfo[];

        const token_reserve: TokenReserve = {};
        for (let i = 0; i < tokens?.length; i++) {
            if (
                (poolInfos[i].assets[1].info as NativeTokenInfo).native_token
                    .denom === USDC_DENOM
            ) {
                token_reserve[tokens[i]] = {
                    tokenAmount: poolInfos[i].assets[0].amount,
                    usdcAmount: poolInfos[i].assets[1].amount,
                };
            } else {
                token_reserve[tokens[i]] = {
                    tokenAmount: poolInfos[i].assets[1].amount,
                    usdcAmount: poolInfos[i].assets[0].amount,
                };
            }
        }

        const totalMinted = Math.max(
            tierInfos.reduce(
                (res, cur) => res + parseInt(cur.current_supply),
                0
            ) || 0,
            mintConfig.nft_current_supply || 0
        );

        const totalMintRemains = minus(mintConfig.nft_max_supply || 0, mintConfig.nft_current_supply || 0)
        // eslint-disable-next-line consistent-return
        return {
            ...launchpadNFT,
            mintConfig,
            is_whitelisted: isWhitelisted,
            tier_infos: tierInfos,
            token_reserve,
            supplyByAddress,
            totalMinted: totalMinted?.toString(),
            totalSupply: plus(totalMinted, totalMintRemains)
        }
    }
})

export const completeMintedList = selector({
    key: "completeMintedList",
    get: ({ get }) => {
        const list = get(fetchLaunchpadConfig)
        return list?.filter((item: any) => equals(item?.totalMinted, item?.totalSupply))
    }
})

const completeMintedListState = atom<any[]>({
    key: "completeMintedListState",
    default: []
})

export const useCompletedMints = () => {
    return useStore(completeMintedList,completeMintedListState)
}

export const fetchAPIQuery = selector({
    key: "fetchAPIQuery",
    get: ({ get }) => {
        const url = 'https://loop-markets.directus.app'
        return async({ name }: { name: string}) => await fetchAPI(`${url}/` + name)
    },
})