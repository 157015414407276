import NftFooter from '../Nft/NftFooter';
import { useEffect, useRef, useState } from 'react';
import styles from './status.module.scss';
import { Button } from 'components';
import { useHistory } from "react-router-dom";

const Transaction = (props: any) => {
  const [launchpadList, setLaunchpadList] = useState<any>();
  const history = useHistory();

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <img src="https://loop-markets.directus.app/assets/020df49d-6cb7-4c03-b1f4-fab948cba61c" />
          <h3>Congratulations! </h3>
          <p>Your NFT has been successfully minted! </p>
          <Button
              className={styles.buy}
              onClick={() => history.push(`/myNft`)}
            >
              View My NFT
            </Button>
        </div>
      </div>
      <div>
        <NftFooter />
      </div>
    </>
  );
};

export default Transaction;
