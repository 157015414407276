import { Dec } from '@terra-money/terra.js';
import { commonConfig } from 'core/common';
import { networkType } from 'core/nftConfig';
import { NFTMinterQuery } from 'hooks/useLaunchPadNFTMinterQuery';
import { formatNumberString } from 'utils/conversion/conversion';

const LaunchRewards = ({ queryResult }: { queryResult?: NFTMinterQuery }) => {
  return (
    <div className='launchRewards'>
      <div className='title'>
        <img src='/icons/rewards_icon.svg' alt='thumb' />
        <h6>Rewards</h6>
      </div>
      {queryResult?.tier_infos.map((info, index) => {
        let mintRemaining = 0;
        for (let i = 0; i < queryResult.tier_infos.length; i++) {
          mintRemaining +=
            parseInt(queryResult.tier_infos[i].max_supply) -
            parseInt(queryResult.tier_infos[i].current_supply);
        }

        const tierValue =
          queryResult.mintConfig.nft_max_supply > 0
            ? new Dec(info.max_supply)
                .sub(new Dec(info.current_supply))
                .mul(100)
                .div(mintRemaining)
            : new Dec(0);
        let valueUST = new Dec(0);
        if (queryResult.token_reserve[info.token1_addr]) {
          valueUST = valueUST.add(
            new Dec(info.token1_amount)
              .mul(info.vesting_period)
              .mul(queryResult.token_reserve[info.token1_addr].usdcAmount)
              .div(queryResult.token_reserve[info.token1_addr].tokenAmount)
          );
        }
        if (queryResult.token_reserve[info.token2_addr]) {
          valueUST = valueUST.add(
            new Dec(info.token1_amount)
              .mul(info.vesting_period)
              .mul(queryResult.token_reserve[info.token2_addr].usdcAmount)
              .div(queryResult.token_reserve[info.token2_addr].tokenAmount)
          );
        }
        const nftPrice = new Dec(queryResult.mintConfig.nft_price_amount || 0);
        const tier = formatNumberString(info.max_supply, 0)
        return (
          <>
            <div className='tableHeader__mobile'>
              <p>
                Metal Type:
                <span>
                  {/*<img*/}
                  {/*  src={`/icons/reward_tier/tier_${*/}
                  {/*    index < 3 ? index + 1 : 3*/}
                  {/*  }.png`}*/}
                  {/*  alt=''*/}
                  {/*  style={{ paddingRight: '10px', width: '30px' }}*/}
                  {/*/>*/}
                  {/*{`Tier ${index + 1}`}*/}
                  {
                    tier === '60' ? 'Bronze' : tier === '30' ? 'Silver' : tier === '16' ? 'Gold' : ''
                  }
                </span>
              </p>
              <p>
                Minted/Total NFTs:{' '}
                <span>
                  {formatNumberString(info.current_supply, 0)}/
                  <b>{formatNumberString(info.max_supply, 0)}</b>
                </span>
              </p>
              <p>
                Probability of Minting:{' '}
                {/*
                <span
                  className={
                    tierValue.gte(50)
                      ? 'tiersGreen'
                      : tierValue.gte(20)
                      ? 'tiersYellow'
                      : 'tiersRed'
                  }
                >
                  {formatNumberString(tierValue)}%
                </span>
                  */}
                <span className='tiersGreen'>
                  {formatNumberString(tierValue)}%
                </span>
              </p>
              <p>
                Tokens:
                <span>
                  {new Dec(info.token1_amount).gt(0) && (
                    <div>
                      {formatNumberString(
                        new Dec(info.token1_amount)
                          .mul(info.vesting_period)
                          .div(1000000)
                      )}{' '}
                      {
                        commonConfig[networkType].TOKEN_SYMBOLS[
                          info.token1_addr
                        ]
                      }
                    </div>
                  )}
                  {new Dec(info.token2_amount).gt(0) && (
                    <div>
                      {formatNumberString(
                        new Dec(info.token2_amount)
                          .mul(info.vesting_period)
                          .div(1000000)
                      )}{' '}
                      {
                        commonConfig[networkType].TOKEN_SYMBOLS[
                          info.token2_addr
                        ]
                      }
                    </div>
                  )}
                </span>
              </p>
              <p>
                Vesting:{' '}
                <span>{formatNumberString(info.vesting_period)} days</span>
              </p>
              {/*
              <p>
                Value: <span>${formatNumberString(valueUST.div(1000000))}</span>
              </p>
              */}
              <p>
                APR:{' '}
                <span>
                  {formatNumberString(valueUST.mul(100).div(nftPrice))}%
                </span>
              </p>
            </div>
            <div className='tableHeader__mobile__divider' />
          </>
        );
      })}

      <div style={{ borderRadius: '20px', overflow: 'auto' }}>
        <table>
          <tbody>
            <tr className='tableHeader'>
              <td>Metal Type</td>
              <td className='tooltip_nfts'>Minted/Total NFTs</td>
              <td>Probability of Minting</td>
              <td>Tokens</td>
              <td>Vesting</td>
              {/*<td>Value</td>*/}
              <td>APR</td>
            </tr>
            {queryResult?.tier_infos.map((info, index) => {
              let mintRemaining = 0;
              for (let i = 0; i < queryResult.tier_infos.length; i++) {
                mintRemaining +=
                  parseInt(queryResult.tier_infos[i].max_supply) -
                  parseInt(queryResult.tier_infos[i].current_supply);
              }

              const tierValue =
                queryResult.mintConfig.nft_max_supply > 0
                  ? new Dec(info.max_supply)
                      .sub(new Dec(info.current_supply))
                      .mul(100)
                      .div(mintRemaining)
                  : new Dec(0);
              let valueUST = new Dec(0);
              if (queryResult.token_reserve[info.token1_addr]) {
                valueUST = valueUST.add(
                  new Dec(info.token1_amount)
                    .mul(info.vesting_period)
                    .mul(queryResult.token_reserve[info.token1_addr].usdcAmount)
                    .div(
                      queryResult.token_reserve[info.token1_addr].tokenAmount
                    )
                );
              }
              if (queryResult.token_reserve[info.token2_addr]) {
                valueUST = valueUST.add(
                  new Dec(info.token1_amount)
                    .mul(info.vesting_period)
                    .mul(queryResult.token_reserve[info.token2_addr].usdcAmount)
                    .div(
                      queryResult.token_reserve[info.token2_addr].tokenAmount
                    )
                );
              }
              const nftPrice = new Dec(
                queryResult.mintConfig.nft_price_amount || 0
              );
              const tier = formatNumberString(info.max_supply, 0)
              return (
                <tr key={JSON.stringify(info)}>
                  <td>
                    {/*<img*/}
                    {/*  src={`/icons/reward_tier/tier_${*/}
                    {/*    index < 3 ? index + 1 : 3*/}
                    {/*  }.png`}*/}
                    {/*  alt=''*/}
                    {/*/>*/}
                    {/*{`Tier ${index + 1}`}*/}
                    {
                        tier === '60' ? 'Bronze' : tier === '30' ? 'Silver' : tier === '16' ? 'Gold' : ''
                    }
                  </td>
                  <td>
                    {formatNumberString(info.current_supply, 0)}/
                    <b>{formatNumberString(info.max_supply, 0)}</b>
                  </td>
                  <td>
                    {/*
                    <p
                      className={
                        tierValue.gte(50)
                          ? 'tiersGreen'
                          : tierValue.gte(20)
                          ? 'tiersYellow'
                          : 'tiersRed'
                      }
                    >
                      {formatNumberString(tierValue)}%
                    </p>
                    */}
                    <p className='tiersGreen'>
                      {formatNumberString(tierValue)}%
                    </p>
                  </td>
                  <td>
                    {new Dec(info.token1_amount).gt(0) && (
                      <div>
                        {formatNumberString(
                          new Dec(info.token1_amount)
                            .mul(info.vesting_period)
                            .div(1000000)
                        )}{' '}
                        {
                          commonConfig[networkType].TOKEN_SYMBOLS[
                            info.token1_addr
                          ]
                        }
                      </div>
                    )}
                    {new Dec(info.token2_amount).gt(0) && (
                      <div>
                        {formatNumberString(
                          new Dec(info.token2_amount)
                            .mul(info.vesting_period)
                            .div(1000000)
                        )}{' '}
                        {
                          commonConfig[networkType].TOKEN_SYMBOLS[
                            info.token2_addr
                          ]
                        }
                      </div>
                    )}
                  </td>
                  <td>{formatNumberString(info.vesting_period)} days</td>
                  {/*<td>${formatNumberString(valueUST.div(1000000))} </td>*/}
                  <td>
                    {formatNumberString(valueUST.mul(100).div(nftPrice))}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LaunchRewards;
