import { useHistory, useParams } from 'react-router-dom';
import { Dec } from '@terra-money/terra.js';
import { formatNumberString } from 'utils/conversion/conversion';
import Button from 'components/Button';
import { DirectusImage } from 'core/common';
import { useEffect, useState } from 'react';

import styles from './collections.module.scss';
import ShareButton from 'components/SocialShare/shareButton';

const CollectionHeader = ({
  collectionDetail,
  detailView,
}: {
  collectionDetail?: any;
  detailView?: boolean;
}) => {
  const history = useHistory();
  const isFans = location.pathname.startsWith('/fans');
  const [seeMore, setSeeMore] = useState(false);
  const [socialShare, setSocialShare] = useState(false);
  const wordLimit = (text: string) => (seeMore ? text?.split(' ').length : 30);
  const limitWords = (text: string) =>
    text?.split(' ', wordLimit(text))?.join(' ') +
    (text?.split(' ').length > wordLimit(text) ? '...' : '');

  return (
    <>
      <div
        className={styles.collectionHeading}
        style={
          collectionDetail?.banner && {
            backgroundImage: `url(${DirectusImage(
              collectionDetail?.banner?.id
            )}?fit=cover&width=1920&height=525&quality=90)`,
          }
        }
      >
        <div className={styles.overlay} />
        <div className='wrapper' style={{ zIndex: 1 }}>
          <div className={styles.collectionContainer}>
            <div className={styles.collectionLogo}>
              {collectionDetail?.icon ? (
                <img src={`${DirectusImage(collectionDetail?.icon?.id)}?fit=cover&width=175&height=175&quality=90`} />
              ) : (
                <div className={styles.placeholderImage} />
              )}
            </div>
            <div className={styles.collectionAbout}>
              <div>
                {collectionDetail ? (
                  <>
                    <h3 className={styles.exploreCollectionHeading}>
                      {collectionDetail?.name}
                    </h3>
                    <p className={styles.owner}>
                      By: <span>{collectionDetail?.artist?.first_name}</span>
                    </p>
                  </>
                ) : (
                  <>
                    <h3 className={styles.exploreCollectionHeading}>
                      <div className={styles.loadingTitle} />
                    </h3>
                    <p className={styles.owner}>
                      <div className={styles.loadingText} />
                    </p>
                  </>
                )}
              </div>
              <div className={styles.collectionSocial}>
                {collectionDetail?.socials?.map((r, i) => {
                  return (
                    <div>
                      <a href={r?.url} key={i} target='_blank'>
                        <img src={`/collections/${r?.network}.svg`} />
                      </a>
                    </div>
                  );
                })}
                
                <ShareButton name={collectionDetail?.name} icon={`/collections/share.svg`} />
              </div>
            </div>
            {!detailView && collectionDetail?.description ? (
              <>
                <p className={styles.desc}>
                  {limitWords(collectionDetail?.description)}
                </p>
                <Button
                  onClick={() => setSeeMore(!seeMore)}
                  className={styles.seeMore}
                >
                  {seeMore ? 'See Less' : 'See More'}
                </Button>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div hidden={detailView} className={styles.collectionStats}>
        <div className='wrapper'>
          <div className={`${styles.collectionStatistics}`}>
            <ul className={styles.statContainer}>
              <li>
                <p>
                  <label>
                    {formatNumberString(
                      collectionDetail ? collectionDetail?.totalItems : 0
                    )}
                  </label>
                </p>
                <p>Items</p>
              </li>
              <li>
                <p>
                  <label>
                    {collectionDetail?.floorPrice ? (
                      <>
                        {formatNumberString(
                          new Dec(collectionDetail?.floorPrice).div(1000000)
                        )}
                        <small> USDC</small>
                      </>
                    ) : (
                      '-'
                    )}{' '}
                  </label>
                </p>
                <p>Floor Price</p>
              </li>
              <li>
                <p>
                  <label>
                    {collectionDetail?.volumn ? (
                      <>
                        {formatNumberString(
                          new Dec(collectionDetail?.daily_volume).div(1000000)
                        )}
                        <small> USDC</small>
                      </>
                    ) : (
                      '-'
                    )}{' '}
                  </label>
                </p>
                <p>24hr Volume</p>
              </li>
              <li>
                <p>
                  <label>
                    {formatNumberString(
                      collectionDetail ? collectionDetail?.ownerCount : 0
                    )}
                  </label>
                </p>
                <p>Owners</p>
              </li>
            </ul>

            {/* {!detailView && (
              <Button
                className={styles.ConnectionDetailBtn}
                onClick={() =>
                  history.push(
                    isFans
                      ? `/fans/exploreCollection/${collectionDetail?.url}/info`
                      : `/exploreCollection/${collectionDetail?.url}/info`
                  )
                }
              >
                About Collection
              </Button>
            )} */}
          </div>
        </div>
      </div>

      {/* <div className={styles.collectionNav} id='test'>
        <div className='wrapper'>
          <div className={styles.navbar}>
            <ul className={styles.items}>
              {collectionDetail?.address != "false" && (
                <li
                  className={
                    !location.pathname.includes('/info')
                      ? styles.itemActive
                      : styles.item
                  }
                  onClick={() =>
                    history.push(
                      isFans
                        ? `/fans/exploreCollection/${collectionDetail.url}`
                        : `/exploreCollection/${collectionDetail.url}`
                    )
                  }
                >
                  <p>Items</p>
                </li>
              )}
              <li
                className={
                  location.pathname.includes('/info')
                    ? styles.itemActive
                    : styles.item
                }
                onClick={() =>
                  history.push(
                    isFans
                      ? `/fans/exploreCollection/${collectionDetail.url}/info`
                      : `/exploreCollection/${collectionDetail.url}/info`
                  )
                }
              >
                <p>Collection Info</p>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CollectionHeader;
