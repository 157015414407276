import ActivityTable from './ActivityTable';

const MyActivity = () => {
  return (
    <div className='mynftRight'>
      <h3>My Activity</h3>
      <ActivityTable />
    </div>
  );
};

export default MyActivity;
