import styles from './giftCard.module.scss';
import gifticon from '../../assets/icons/giftbox.svg';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';

const GiftCard = ({
  info,
  setPaymentModal,
}: {
  info?: any;
  setPaymentModal?: any;
}) => {
  const { address } = useRecoilValue(walletState);
  return (
    <div className={styles.giftCard}>
      <div className={styles.container}>
        <div className={styles.info_header}>
          <h6>
            <span>GIFT</span> this Collectible
          </h6>
          <p>
            Deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
            consequat duis enim velit mollit. Exercitation veniam consequat sunt
            nostrud amet. deserunt ullamco est sit aliqua dolor do amet sint.
          </p>
        </div>
        <div
          className={styles.buttonGradient}
          onClick={() => {
            address && setPaymentModal(true);
          }}
        >
          <button disabled={!address} className={styles.button}>
            {' '}
            <img src={gifticon} /> Buy as Gift
          </button>
        </div>
        <div className='tableHeader__mobile__divider' />
      </div>
    </div>
  );
};

export default GiftCard;
