import React, { Component, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Faq } from 'hooks/queryCollections';

export default function Faqs({ faqs }: { faqs: Faq }) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      {faqs?.Questions?.length > 0 && (
        <div className={styles.faqs}>
          <h3 className={styles.heading}>Frequently Asked Questions</h3>
          {faqs?.Questions?.map((r, i) => {
            return (
              <div
                onClick={() => setActiveIndex(activeIndex === i ? '' : i)}
                className={styles.Accordion}
              >
                <div className={activeIndex === i ? styles.active : ''}>
                  <div className={styles.content}>
                    <div>
                      <h3>{r?.question}</h3>
                      {activeIndex === i && (
                        <div>
                          <p dangerouslySetInnerHTML={{ __html: r?.answer }} />
                        </div>
                      )}
                    </div>
                    <img
                      className={styles.icon}
                      src={activeIndex === i ? '/close.svg' : '/expand.svg'}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
