import Countdown, { zeroPad } from 'react-countdown';
import { LaunchPadNFT } from '../../../../core/nftConfig';
import moment from 'moment-timezone';

const CountdownComp = ({ launchpadNFT, index }: { launchpadNFT: LaunchPadNFT, index:number }) => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <>
        <h6>
          {days}d
          <span> : </span>
          {zeroPad(hours)}h
          <span> : </span>
          {zeroPad(minutes)}m
          <span> : </span>
          {zeroPad(seconds)}s
        </h6>
      </>
    );
  };
  
  const currentTime = moment.tz('America/Los_Angeles').valueOf();
  const genTime = (time:string) => moment(time).add(moment(time).utcOffset(), 'minutes').valueOf();

  const whitelistStartTime = genTime(moment.tz('America/Los_Angeles').format(`${launchpadNFT?.launchpad_type[Number(index)].launchInfo.startDate} ${launchpadNFT?.launchpad_type[Number(index)].launchInfo.startTime}`));
  const publicMintStartTime = genTime(moment.tz('America/Los_Angeles').format(`${launchpadNFT?.launchpad_type[Number(index)].launchInfo.publicDate} ${launchpadNFT?.launchpad_type[Number(index)].launchInfo.publicTime}`));
  const publicMintEndTime = genTime(moment.tz('America/Los_Angeles').format(`${launchpadNFT?.launchpad_type[Number(index)].launchInfo.endDate} ${launchpadNFT?.launchpad_type[Number(index)].launchInfo.endTime}`));

  const beforeWhitelistMint = currentTime < whitelistStartTime;
  const isWhitelistMint = whitelistStartTime <= currentTime && currentTime < publicMintStartTime;
  const isPublicMint = publicMintStartTime <= currentTime && currentTime < publicMintEndTime;

  return (
    <>
      <div className='header' style={{ marginBottom: '40px' }}>
        <div className='time'>
          {/* {(beforeWhitelistMint || isWhitelistMint || isPublicMint) && ( */}
          <Countdown
            date={
              beforeWhitelistMint
                ? whitelistStartTime
                : isWhitelistMint
                ? publicMintStartTime
                : isPublicMint
                ? publicMintEndTime
                : new Date()
            }
            renderer={renderer}
          />
          {/* )} */}
        </div>
        {isWhitelistMint && (
          <span className='status_message'>
            Public mint will go live after allow list(s)
          </span>
        )}
      </div>
    </>
  );
};

export default CountdownComp;
