import * as React from 'react'
import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/pagination/pagination.min.css'
import styles from './CompletedMints.module.scss'
import { Link } from 'react-router-dom'
import Button from "../../components/Button"
import {useScreen} from "../../hooks/useScreen"
import classNames from 'classnames'
import {useCompletedMints} from "../../api/state/launchpad"

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const List = ({item, isMobile = false}:{ item: any, isMobile?: boolean}) =>{
  return <Link to={`/launchpad/${item.project_slug}`} className={styles.link}>
    <div className={classNames(styles.container, isMobile ? styles.containerMob : '')}>
      <div className={styles.inner}>
                              <span className={styles.headDiv}>
                        <img
                            style={{width: '60px'}}
                            src={'https://loop-markets.directus.app/assets/' + item.project_logo.id}
                            alt='thumb'
                        />
                      </span>

        <div className={styles.head}>
          <h6 className={styles.heading}>{item.project_name}</h6>
          <h6 className={styles.small}>2,000 items</h6>
          <h6 className={styles.minted}><span className={styles.active}>2,500</span>/2,500 Minted</h6>
        </div>
        <div className={styles.child}>
          <label>
            Mint Price <b>{item.launchInfo.mintPrice}</b>
          </label>
          <label>
            Supply <b>{item.launchInfo.maxSupply}</b>
          </label>
          <label>
            Start Date
            <b>
              {new Date(item.launchInfo.startDate).getUTCDate()}
              {' '}
              {monthNames[new Date(item.launchInfo.startDate).getUTCMonth()]}
              {' '}
              {new Date(item.launchInfo.startDate).getUTCFullYear()}
            </b>
          </label>
          <label>
            End Date
            <b>
              {new Date(item.launchInfo.endDate).getUTCDate()}
              {' '}
              {monthNames[new Date(item.launchInfo.endDate).getUTCMonth()]}
              {' '}
              {new Date(item.launchInfo.endDate).getUTCFullYear()}
            </b>
          </label>
          <Button size={'lg'} className={styles.btn}>Visit Collection</Button>
        </div>
      </div>
    </div>
  </Link>
}

const CompletedMints = () => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
  const { contents, isLoading } = useCompletedMints()

  const { isMobile } = useScreen()

  return (
      <>
        { !isLoading && contents.length > 0 && (
            <div className={classNames(styles.main, isMobile ? styles.mainMob : '')}>
              <h6 className={styles.mainHeading}>
                Completed Mints
              </h6>
              {
                isMobile ? contents?.map((item: any, index: number) => {
                  return (<List item={item} isMobile={isMobile} />);
                })
               : <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  navigation
                  autoplay={{delay: 7000}}
                  className='feedSwiper'
                  slideToClickedSlide
                  breakpoints={{
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                  }}
              >
                  { contents?.map((item: any, index: number) => {
                    return (
                        <SwiperSlide key={JSON.stringify(item) + index}>
                          <List item={item} />
                        </SwiperSlide>
                    );
                  })
                  }
              </Swiper>
              }
            </div>
        )}
      </>
  );
};

export default CompletedMints;
