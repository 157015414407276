import { Dec } from '@terra-money/terra.js';
import Modal from 'components/Modal';
import { commonConfig, getIpfsUrl } from 'core/common';
import { networkType, nftConfig, TICKET_COLLECTIONS } from 'core/nftConfig';
import {
  bidAuctionNFTMsg,
  nftCancelAuctionMsg,
  nftCancelSellMsg,
  nftClaimAuctionMsg,
  nftStakeMsg,
  nftUnstakeMsg,
} from 'helper/junoContractMsg';
import { EligibleNFT } from 'hooks/useEligibleStakeNFTs';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { USDC_DENOM } from 'core/constants';
import { formatNumberString } from 'utils/conversion/conversion';
import ToolTip from 'components/ToolTips';

const WebAppLaunchpadCard = ({ data }: { data: EligibleNFT }) => {
  const {
    tokenId,
    contract,
    info,
    stakeInfo,
    auctionInfo,
    metadata,
    isInAuction,
    isInSale,
    daysStaked,
    daysRemain,
    dailyValue,
    totalValue,
    saleInfo,

    myBidAmount,
  } = data;

  const nftInfo = nftConfig[networkType].launchPadNFTs.find(
    item => item.NFT === contract
  );
  const { address } = useRecoilValue(walletState);
  const { estimateGas, submit } = useJunoTransactions();
  const [bidModal, setBidModal] = useState(false);
  const [bidPrice, setBidPrice] = useState('');
  const eventLocalData = TICKET_COLLECTIONS.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  const eventData = (name: string) => metadata.attributes.find(data => data.trait_type == (name));

  const bidAuctionHandler = useCallback(async () => {
    const txMsg = bidAuctionNFTMsg({
      address,
      networkType,
      nft: contract,
      nft_auction: nftConfig[networkType].AUCTION,
      token_id: tokenId,
      coins: [
        {
          amount: new Dec(bidPrice).mul(1000000).toFixed(0),
          denom: USDC_DENOM,
        },
      ],
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({ msgs: [txMsg] });
  }, [address, bidPrice, contract, estimateGas, submit, tokenId]);

  const stakeHandler = useCallback(async () => {
    const txMsg = nftStakeMsg({
      address,
      networkType,
      nft: contract,
      nft_staking: nftConfig[networkType].STAKING,
      token_id: tokenId,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({ msgs: [txMsg] });
  }, [address, contract, estimateGas, submit, tokenId]);

  const unStakeHandler = useCallback(async () => {
    const txMsg = nftUnstakeMsg({
      address,
      networkType,
      nft: contract,
      nft_staking: nftConfig[networkType].STAKING,
      token_id: tokenId,
    });
    console.log('txMsg = ', txMsg);
    const fee = await estimateGas([txMsg]);
    console.log('FEE:', fee);
    submit({ msgs: [txMsg] });
  }, [address, contract, estimateGas, submit, tokenId]);

  const cancelHandler = useCallback(async () => {
    const txMsgs = [
      isInAuction
        ? (auctionInfo?.end_time || 0) < Math.floor(new Date().getTime() / 1000)
          ? nftClaimAuctionMsg({
              address,
              networkType,
              nft: contract,
              nft_auction: nftConfig[networkType].AUCTION,
              token_id: tokenId,
            })
          : nftCancelAuctionMsg({
              address,
              networkType,
              nft: contract,
              nft_auction: nftConfig[networkType].AUCTION,
              token_id: tokenId,
            })
        : nftCancelSellMsg({
            address,
            networkType,
            nft: contract,
            nft_sale: nftConfig[networkType].SALE,
            token_id: tokenId,
          }),
    ];

    console.log('txMsgs = ', txMsgs);
    const fee = await estimateGas(txMsgs);
    console.log('FEE:', fee);
    submit({ msgs: txMsgs });
  }, [
    address,
    auctionInfo?.end_time,
    contract,
    estimateGas,
    isInAuction,
    submit,
    tokenId,
  ]);

  const claimAuctionHandler = useCallback(async () => {
    const txMsgs = [
      nftClaimAuctionMsg({
        address,
        networkType,
        nft: contract,
        nft_auction: nftConfig[networkType].AUCTION,
        token_id: tokenId,
      }),
    ];

    console.log('txMsgs = ', txMsgs);
    const fee = await estimateGas(txMsgs);
    console.log('FEE:', fee);
    submit({ msgs: txMsgs });
  }, [address, contract, tokenId, estimateGas, submit]);

  return (
    <Link to={`/webapp/ticketDetail/${contract}/${tokenId}`}>
      {nftInfo?.disabled && <ToolTip />}
      {metadata && (
        <>
      <div className='nftRightChild nftRightChild__webapp'>
        <div style={{padding: '20px'}}>
          <div className="img_container">
            <img src={getIpfsUrl(metadata?.image, true)} alt='' />
          </div>
        </div>
        <div className='mynftHub'>
          <div className='mynftHubHedare'>
            <label className='nftTitle'>
              <div className="header" style={{justifyContent: 'flex-start'}}>
                <div
                  className="qrcode"
                >
                  {metadata.external_url && (
                    <img
                      src={getIpfsUrl(metadata.external_url, true)}
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src     = '/blank.png';
                      }}
                      alt=''
                    />
                  )}
                </div>
                <div>
                  <span>Ticket no.</span>#{formatNumberString( metadata.name.split('#')[1])}
                  <p style={{paddingLeft: '10px'}}>{metadata.name}</p>
                </div>
              </div>
              {isInAuction && (
                <div className='bidInfo'>
                  <img src='/hammer_blue.png' alt='hammer' />
                  {myBidAmount ? (
                    <>
                      Your bid:
                      <span className='bidPrice'>
                        {formatNumberString(new Dec(myBidAmount).div(1000000))}
                      </span>
                      USDC
                      {auctionInfo?.high_bidder_addr.toLowerCase() !==
                        address.toLowerCase() && (
                        <>
                          <span className='separator'></span>
                          Highest bid:
                          <span className='bidPrice'>
                            {formatNumberString(
                              new Dec(auctionInfo?.high_bidder_amount).div(
                                1000000
                              )
                            )}
                          </span>
                          USDC
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      Highest bid:
                      <span className='bidPrice'>
                        {formatNumberString(
                          new Dec(auctionInfo?.high_bidder_amount).div(1000000)
                        )}
                      </span>
                      USDC
                    </>
                  )}
                </div>
              )}
              {isInSale && (
                <div className='bidInfo'>
                  <img src='/hammer_blue.png' alt='hammer' />
                  Sale price:
                  <span className='bidPrice'>
                    {formatNumberString(new Dec(saleInfo?.amount).div(1000000))}
                  </span>
                </div>
              )}
            </label>
          </div>


          <div className="dashed_div" />

          <div className='nftData nftData__webapp'>
            <label>
              <b>DATE</b>
              <p>{eventData('Date') ? eventData('Date')?.value : eventLocalData[0].date }</p>
            </label>
            <label>
              <b>TIME</b>
              <p>{eventData('Time') ? eventData('Time')?.value : eventLocalData[0].time }</p>
            </label>
          </div>

          <div className='nftData nftData__webapp'>
            <label className="full">
              <b>LOCATION</b>
              <p>{eventData('Location') ? eventData('Location')?.value : eventLocalData[0].location }</p>
            </label>
          </div>

          <div className="dashed_div" />

          <div className='webapp_actions'>
            {!isInAuction && !isInSale && (
              <Link to={`/webapp/ticketDetail/${contract}/${tokenId}`}>
                <button>
                  MANAGE
                </button>
              </Link>
            )}
            {stakeInfo.total_staked_time == 0 ? (
              <>
                {!stakeInfo.is_staking && (
                  <button
                    onClick={e => {
                      stakeHandler();
                      e.preventDefault();
                    }}
                  >
                    + STAKE
                  </button>
                )}
              </>
            ) : (
              <>
                {stakeInfo.is_staking ? (
                  <button
                    onClick={e => {
                      unStakeHandler();
                      e.preventDefault();
                    }}
                  >
                    CLAIM
                  </button>
                ) : (
                  <button
                    disabled
                    style={{background: '#484662', borderColor: '#484662'}}
                  >
                    REWARDED
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={bidModal} title='' onClose={() => setBidModal(false)}>
        <div className='SellAuction'>
          <h6>Bid</h6>
          <div className='aucHub'>
            <section>
              <span>
                <img src={getIpfsUrl(metadata.image, true)} alt='' />
              </span>
            </section>
            <div className='AucInput'>
              <h5>{metadata.name}</h5>
              <label>
                Bid{' '}
                <label>
                  Minimum Bid Price:{' '}
                  <span>
                    {formatNumberString(
                      new Dec(auctionInfo?.high_bidder_amount).div(1000000)
                    )}{' '}
                    <i>USDC</i>
                  </span>
                </label>
                <b>USDC</b>
              </label>
              <input
                className={
                  new Dec(auctionInfo?.high_bidder_amount || '0')
                    .div(1000000)
                    .lt(new Dec(bidPrice || '0'))
                    ? ''
                    : 'invalid'
                }
                type='text'
                placeholder='0.00'
                value={bidPrice}
                onChange={e => {
                  setBidPrice(e.target.value);
                }}
                onBlur={e => {
                  setBidPrice((parseFloat(e.target.value) || 0.0).toString());
                }}
              />
              <img src='/USDClogo.png' alt='' />
              <b>USDC</b>
            </div>
            {/* <p>
                      <b>
                        Royalty Fee: <i>4%</i>
                      </b>{' '}
                      |{' '}
                      <b>
                        Platfomr Fee: <i>2%</i>
                      </b>
                    </p> */}
            <button
              onClick={e => {
                bidAuctionHandler();
                e.preventDefault();
              }}
            >
              CONFIRM
            </button>
          </div>
        </div>
      </Modal>
      </>
      )}
    </Link>
  );
};

export default WebAppLaunchpadCard;
