import * as React from 'react'
import styles from './Blank.module.scss'
import leaf_icon from '../../assets/icons/leaf_icon.svg'
import link from '../../assets/icons/link.svg'
import {ExtLink, LinkButton} from "../../components";
const Blank = () => {
  return (
      <div className={styles.container}>
        <div className={styles.item}>
            <img src={leaf_icon} alt={''} height={'100px'} />
            <h1>Creators, Artists, DAOs & Projects</h1>
            <p><ExtLink className={styles.extLink} href={'https://docs.loop.markets/loop-finance/nft-marketplace/project-launch-info'} target={'_blank'}>Contact us</ExtLink> to plan your next DeFi mint</p>
            <LinkButton className={styles.btn} to={'/nftExplore?sortType=1&vesting=0&sort=1'} >Trade on our secondary Marketplace <img height={'20px'} src={link} alt={''} /></LinkButton>
        </div>
      </div>
  )
}

export default Blank;