import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import NftFooter from '../Nft/NftFooter';
import MyNftOwned from './owned';
import MyNftOnSale from './onsale';
import MyNftActivity from './activity';
import MyNftBids from './bids';
import { MyNftSideBar } from './sidebar';
import WebAppTabs from '../WebApp/WebAppTabs';
import WebAppHeader from 'pages/WebApp/WebAppHeader';
import { walletState, WalletStatusType } from 'state/walletAtoms';
import Messages from 'components/Mesages';
import { useRecoilValue } from 'recoil';

const MyNft = () => {
  const { address, status } = useRecoilValue(walletState);
  const location = useLocation();
  const isFans = location.pathname.startsWith('/fans');
  const pageRoute = (isFans ? '/fans' : '')
  const type =
    location.pathname === `${pageRoute}/mynft/onsale`
      ? 'onsale'
      : location.pathname === `${pageRoute}/mynft/bids`
      ? 'bids'
      : location.pathname === `${pageRoute}/mynft/activity`
      ? 'activity'
      : 'owned';
  
    const isWebApp = window.location.pathname.startsWith("/webapp");

  return (
    <>
      <Helmet>
        <title>MyNft | Loop DeFi NFTs</title>
      </Helmet>
      {isWebApp && (
        <div className='wrapper'>
          <WebAppHeader />
          <WebAppTabs />
          {status === WalletStatusType.connected && (<Messages type="nft" />)}
        </div>
      )}
      <div className='mfnftMain'>
        <div className='mynft'>
          <div className='wrapper'>
            <div className='mynftInner'>
              {MyNftSideBar(type)}
              {type === 'owned' && <MyNftOwned isWebApp={isWebApp} />}
              {type === 'onsale' && <MyNftOnSale />}
              {type === 'bids' && <MyNftBids />}
              {type === 'activity' && <MyNftActivity />}
            </div>
          </div>
        </div>
      </div>
      {!isWebApp && (
        <NftFooter />
      )}
    </>
  );
};

export default MyNft;
