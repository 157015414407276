export enum Network {
  MAINNET,
  TESTNET,
}

export enum SupportedCoins {}

export enum SupportedTokens {
  LOOP = 'loop',
  LOOPR = 'loopr',
}

export const SaleTokenOptions = [
  { value: '', label: 'All' },
  { value: 'ujuno', label: 'JUNO' },
  { value: 'ibc/EAC38D55372F38F1AFD68DF7FE9EF762DCF69F26520643CF3F9D292A738D8034', label: 'USDC' }
];

export const RewardTokenOptions = [
  { value: 'ujuno', label: 'JUNO' },
  { value: 'juno1qsrercqegvs4ye0yqg93knv73ye5dc3prqwd6jcdcuj8ggp6w0us66deup', label: 'LOOP' }
];

export const DENOM_UNIT = 10 ** 6;

export const JUNO_DENOM = 'ujuno';

export const USDC_DENOM =
  'ibc/EAC38D55372F38F1AFD68DF7FE9EF762DCF69F26520643CF3F9D292A738D8034';

export const ATOM_DENOM =
  'ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9';
