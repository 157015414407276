import { Dec } from '@terra-money/terra.js';
import { useEffect, useState } from 'react';
import styles from './nftloadingskeleton.module.scss';

const SkeletonComponent = ({
  count,
  width,
  children,
  info,
}: {
  count: number;
  width: string;
  children: any;
  info: any;
}) => {
  return children;
};

const NftSkeletonLoading = ({ isModal }: {isModal:any}) => {
  return (
    <>
      <div className={!isModal ? styles.page : ''}>
        <>
          <div className={styles.loadingWrapper}>
            <div className='wrapper'>
              <div className='detailTrending'>
                <div className='detailTrendingLeft'>
                  <div className={styles.nftImage}>
                    <SkeletonComponent
                      count={10}
                      info={'. . . .'}
                      width={'470px'}
                    >
                      <img className={styles.nft_img} src="/placeholder_470.svg" />
                    </SkeletonComponent>
                  </div>
                  <div className='nft-attributes'>
                  <span
                      style={{ float: 'right', marginBottom: '10px' }}
                      className={styles.attribute_loader}
                    >
                      . . . . .
                    </span>
                    <span
                      style={{ float: 'right', marginBottom: '10px' }}
                      className={styles.attribute_loader}
                    >
                      . . . . .
                    </span>
                    <span
                      style={{ float: 'right', marginBottom: '10px' }}
                      className={styles.attribute_loader}
                    >
                      . . . . .
                    </span>
                  </div>
                </div>
                <div className='detailTrendingRight'>
                  <div className=''>
                    <div className='dtLeft'>
                      <div className='dtrow'>
                        <div className='dtLeftbox'>
                          <h2 className={styles.small_loader} style={{minWidth: '400px'}}>Name Here</h2>
                          <p className={styles.small_loader} style={{marginTop: '10px'}}>
                            Creator: Artist Name
                          </p>
                          <div className='spaced'>
                            <ul>
                              <li>
                                <p className={styles.small_loader}>
                                  Collection
                                </p>
                                <b
                                  className={styles.small_loader}
                                  style={{ marginTop: '-10px' }}
                                >
                                  <SkeletonComponent
                                    count={1}
                                    width={'50px'}
                                    info={' . . . .'}
                                  >
                                    Collection Name
                                  </SkeletonComponent>
                                </b>
                              </li>
                            </ul>
                          </div>
                          <div className='desc'>
                            <p className={styles.large_loader}>
                              <SkeletonComponent
                                count={1}
                                width={'200px'}
                                info={'. . . .'}
                              >
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Praesent sed ultricies ipsum.
                                Duis fermentum pellentesque pretium. Proin ut
                                facilisis nulla. Sed eget scelerisque urna, eu
                                malesuada orci. Nulla facilisi. Proin massa
                                mauris, pulvinar sit amet dolor sit amet,
                                iaculis hendrerit justo. Vestibulum ante ipsum
                                primis in faucibus orci luctus et ultrices
                                posuere cubilia curae; Phasellus quis dui diam.
                                Aenean aliquam vestibulum facilisis. Phasellus
                                nec justo nec felis dapibus laoreet vitae in
                                tellus. Nullam sed dui lacinia, fermentum ex
                                vehicula, ornare est.
                              </SkeletonComponent>
                            </p>
                          </div>
                          <div className='spacedRewards' style={{marginTop: '100px'}}>
                            <p className={styles.large_loader}>
                              <SkeletonComponent
                                count={1}
                                width={'200px'}
                                info={'. . . .'}
                              >
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Praesent sed ultricies ipsum.
                                Duis fermentum pellentesque pretium. Proin ut
                                facilisis nulla. Sed eget scelerisque urna, eu
                                malesuada orci. Nulla facilisi. Proin massa
                                mauris, pulvinar sit amet dolor sit amet,
                                iaculis hendrerit justo. Vestibulum ante ipsum
                                primis in faucibus orci luctus et ultrices
                                posuere cubilia curae; Phasellus quis dui diam.
                                Aenean aliquam vestibulum facilisis. Phasellus
                                nec justo nec felis dapibus laoreet vitae in
                                tellus. Nullam sed dui lacinia, fermentum ex
                                vehicula, ornare est. Nunc non massa convallis,
                                aliquet ipsum sit amet, bibendum ante. Mauris
                                posuere arcu nec ullamcorper ultricies. Nunc in
                                facilisis arcu, et venenatis purus. Morbi
                                rhoncus dapibus arcu, vestibulum dictum libero
                                ullamcorper sit amet.

                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Praesent sed ultricies ipsum.
                                Duis fermentum pellentesque pretium. Proin ut
                                facilisis nulla. Sed eget scelerisque urna, eu
                                malesuada orci. Nulla facilisi. Proin massa
                                mauris, pulvinar sit amet dolor sit amet,
                                iaculis hendrerit justo. Vestibulum ante ipsum
                                primis in faucibus orci luctus et ultrices
                                posuere cubilia curae; Phasellus quis dui diam.
                                Aenean aliquam vestibulum facilisis. Phasellus
                                nec justo nec felis dapibus laoreet vitae in
                                tellus. Nullam sed dui lacinia, fermentum ex
                                vehicula, ornare est. Nunc non massa convallis,
                                aliquet ipsum sit amet, bibendum ante. Mauris
                                posuere arcu nec ullamcorper ultricies. Nunc in
                                facilisis arcu, et venenatis purus. Morbi
                                rhoncus dapibus arcu, vestibulum dictum libero
                                ullamcorper sit amet. Phasellus
                                nec justo nec felis dapibus laoreet vitae in
                                tellus. Nullam sed dui lacinia, fermentum ex
                                vehicula, ornare est. Nunc non massa convallis,
                                aliquet ipsum sit amet, bibendum ante. Mauris
                                posuere arcu nec ullamcorper ultricies. Nunc in
                                facilisis arcu, et venenatis purus. Morbi
                                rhoncus dapibus arcu, vestibulum dictum libero
                                ullamcorper sit amet.
                              </SkeletonComponent>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export default NftSkeletonLoading;
