import { useEffect, useState } from "react";
import * as api from './NFTHeaderApi';

// Banner Is Static for now, change value to disable.
const NftHeaderBanner = () => {
  const [systemData, setSystemData] = useState<any>(null);
  
  useEffect(() => {
    if (!systemData) {
      api.fetchSystemStatus().then(data => setSystemData(data));
    }
  }, [systemData]);
  
  return (
    <>
    {systemData?.enable === true && (
      <div className="banner">
        <div>
          <div>
            <span>
              <h3>{systemData.message_type.title}</h3>
              <p dangerouslySetInnerHTML={{__html: systemData.message_type.message}}></p>
            </span>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default NftHeaderBanner;
