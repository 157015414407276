import { Dec } from '@terra-money/terra.js';
import { Network } from 'core/constants';
import { nftConfig } from 'core/nftConfig';
import { IPFS_GATEWAY, PAGE_SIZE } from 'core/common';
import axios from 'axios';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';
import {
  EligibleNFT,
  LaunchPadNFTStakeInfo,
  LaunchpadNFTMetadata,
  LaunchPadNFTInfo,
} from './useEligibleStakeNFTs';

export const queryOwnedCosmoverseNfts = async (
  networkType: Network,
  address: string,
  previousLast = "",
  offset = 0,
  count = PAGE_SIZE,
  contractId = 'juno1zzcugrt4x30vu0qxg765rnxkxm6lru6cwe0nvcl90ctc9k4cwvksx8tcew',
): Promise<{
  nfts: EligibleNFT[];
  last: string
}> => {
  const client = await getQueryClient();

  let tokenIds = []
  let last = "";
  if (previousLast) {
    // try to query from not staked
    tokenIds = (await client.queryContractSmart(contractId, {
      tokens: {
        owner: address,
        start_after: previousLast,
        limit: count,
      },
    })).tokens as string[];
    last = tokenIds[tokenIds.length - 1];
  } else {
    const stakingAddress = nftConfig[networkType].STAKING;
    const stakedTokenIds = (await client.queryContractSmart(stakingAddress, {
      token_ids: {
        addr: address,
        nft_contract_addr: contractId,
      },
    })) as string[];

    tokenIds = stakedTokenIds.slice(offset, offset + count);
    if (tokenIds.length < count) {
      // try to query from not staked
      const ownedTokenIds = (await client.queryContractSmart(contractId, {
        tokens: {
          owner: address,
          limit: count - tokenIds.length
        },
      })).tokens as string[];
      last = ownedTokenIds[ownedTokenIds.length - 1];
      tokenIds.push(...ownedTokenIds);
    }
  }

  const nfts: EligibleNFT[] = [];
  for (let i = 0; i < tokenIds.length; i++) {
    const tokenId = tokenIds[i]

    // query nft configuration data
    const nftContractInfo = (await client.queryContractSmart(contractId, {
      contract_info: {},
    })) as {
      name: string,
      symbol: string,
      royalty_bps: number[];
    };

    // query launch pad nft info
    const _info = (await client.queryContractSmart(contractId, {
      nft_info: {
        token_id: tokenId,
      },
    })) as {
      extension: {
        tier_index: string;
        token1_addr: string;
        token1_amount: string;
        token2_addr: string;
        token2_amount: string;
        vesting_period: string;
      };
      token_uri: string;
    };

    const info: LaunchPadNFTInfo = {
      type: 'Reward',
      owner: address,
      tier_index: _info.extension.tier_index,
      token1_addr: _info.extension.token1_addr,
      token1_amount: _info.extension.token1_amount,
      token2_addr: _info.extension.token2_addr,
      token2_amount: _info.extension.token2_amount,
      vesting_period: _info.extension.vesting_period,
      token_uri: _info.token_uri,
    };
    console.log('info', info);

    const stakingAddress = nftConfig[networkType].STAKING;
    const stakeInfo = (await client.queryContractSmart(stakingAddress, {
      stake_info: {
        nft_contract_addr: contractId,
        nft_token_id: tokenId,
      },
    })) as LaunchPadNFTStakeInfo;
    const daysStaked = Math.floor(stakeInfo.total_staked_time / 60 / 60 / 24);
    console.log('stakeInfo', stakeInfo);

    // query nft metadata
    const metadata: LaunchpadNFTMetadata = await axios
      .get(IPFS_GATEWAY + info?.token_uri.replace(':/', ''))
      .then(response => response.data as LaunchpadNFTMetadata);

    nfts.push({
      type: 'launchpad',
      tokenId: tokenId,
      contract: contractId,
      name: nftContractInfo.name,
      symbol: nftContractInfo.symbol,
      info,
      stakeInfo,
      metadata: metadata,
      isInAuction: false,
      isInSale: false,
      daysStaked:
        daysStaked <= parseInt(info.vesting_period)
          ? daysStaked
          : parseInt(info.vesting_period),
      daysRemain:
        daysStaked <= parseInt(info.vesting_period)
          ? parseInt(info.vesting_period) - daysStaked
          : 0,
      dailyValue: new Dec(0),
      totalValue: new Dec(0),
    });
  }

  return {
    nfts,
    last
  };
};
