import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import styles from './index.module.scss';
import NftFooter from 'pages/Nft/NftFooter';
export default function Faq(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [faqs, setFaqs] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}faqs/marketplace`
        );
        const data = await response.data;
        setFaqs(data);
        setLoading(true);
      } catch (err) {
        // Handle any errors
        console.log(err);
        setLoading(true);
      }
    }
    if (!loading) {
      fetchData();
    }
  }, [!faqs, loading]);

  return (
    <>
      <div className={styles.faqBody}>
        <h3 className={styles.heading}>Frequently Asked Questions</h3>
        <div className={styles.faqs}>
          {faqs?.Questions?.map((r, i) => {
            return (
              <div
                onClick={() =>
                  r.answer && setActiveIndex(activeIndex === i ? '' : i)
                }
                className={
                  r.answer
                    ? styles.Accordion
                    : `${styles.Accordion} ${styles.AccordionHeader}`
                }
              >
                <div
                  className={r.answer && activeIndex === i ? styles.active : ''}
                >
                  <div className={styles.content}>
                    <div>
                      <h3>{r?.question}</h3>
                      {activeIndex === i && (
                        <div>
                          <p dangerouslySetInnerHTML={{ __html: r?.answer }} />
                        </div>
                      )}
                    </div>
                    {r.answer && (
                      <img
                        className={styles.icon}
                        src={activeIndex === i ? '/close.svg' : '/expand.svg'}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <NftFooter />
    </>
  );
}
