// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignoreimport { useEffect, useRef, useState } from "react"

import { FC, useEffect, useState, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';
import Modal from '../../components/Modal';
import Wallet from '../../components/Wallet';
import PlatformMenu from '../../components/PlatformMenu';
import logo from '../../assets/looplogo.png';
import search from '../../assets/search.png';
import { useJunoConnectedWallet } from 'hooks/newHooks/useJunoConnectedWallet';
import { useRecoilValue } from 'recoil';
import { walletState, WalletStatusType } from 'state/walletAtoms';
import * as api from './NFTHeaderApi';
import HeaderSearch from './NftSearch/Search.modal';
import SearchForm from './NftSearch/Search.form';
import PlatformMenuMobile from 'components/PlatformMenu/mobile';

interface Props {
  handleSidebar: Function;
}
const NftHeader: FC<Props> = handleSidebar => {
  const { mutate: connectWallet } = useJunoConnectedWallet();
  const location = useLocation();
  const pageName = location.pathname;
  const [menuToggle, setToggle] = useState(false);
  const [isOpenStakeModal, setIsOpenStakeModal] = useState(false);
  const [nftToggle, setNftToogle] = useState(false);
  const [scrolledClassName, setScrolledClassName] = useState('nftHeader');
  const { address, status } = useRecoilValue(walletState);
  const [menuItems, setMenuItems] = useState<any>();
  const [searchValue, setSearchValue] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [MobileSearch, setMobileSearch] = useState(false);

  //const cached_menu:any = localStorage.getItem('MENU_ITEMS');
  //const getRefreshDate:any = localStorage.getItem('MENU_ITEMS_REFRESH');
  //const refreshDate_ = JSON.parse(getRefreshDate);
  //const menuItems = JSON.parse(cached_menu);

  const resetSearch = () => {
    setShowSearch(false);
    setSearchValue('');
  };

  const closeStakeModal = () => {
    setIsOpenStakeModal(false);
  };

  const toggleMenu = () => {
    setToggle(!menuToggle);
    handleSidebar.handleSidebar(!menuToggle);
  };

  const mobileSearchHandle = () => {
    setMobileSearch(!MobileSearch);
  };
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 30) {
      setScrolledClassName('scrolled');
    } else {
      setScrolledClassName('nftHeader');
    }
  };

  useEffect(() => {
    if (status === WalletStatusType.connected) {
      setIsOpenStakeModal(false);
    }
    //if (!cached_menu && !refreshDate_ || refreshDate_ <= new Date().getTime()) {
    if (!menuItems) {
      //localStorage.removeItem('MENU_ITEMS');
      //localStorage.removeItem('MENU_ITEMS_REFRESH');
      api.getMenuItems().then(menuItems => setMenuItems(menuItems));
      //localStorage.setItem('MENU_ITEMS_REFRESH', JSON.stringify(new Date().getTime() + (24 * 60 * 60 * 1000)))
    }
  }, [status, menuItems]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  return (
    <>
      <div className={scrolledClassName}>
        <header className={menuToggle ? 'mobileHeader' : 'header'}>
          <div className='wrap'>
            <div className='logo'>
              <Link to='/' rel='noreferrer'>
                <img className='logo_img' src={logo} alt='logo' />
              </Link>
            </div>
            <div className='mobile_menu'>
              <a className='menu_icon' onClick={mobileSearchHandle}>
                <img src={search} alt='search' />
              </a>
              <a className='menu_icon' onClick={toggleMenu}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='50'
                  height='41'
                  viewBox='0 0 50 41'
                  fill='none'
                >
                  <path
                    d='M0 10C0 4.47715 4.47715 0 10 0H40C45.5228 0 50 4.47715 50 10V31C50 36.5228 45.5228 41 40 41H10C4.47715 41 0 36.5228 0 31V10Z'
                    fill='#1B1B1B'
                  />
                  <rect x='10' y='19' width='30' height='3' fill='#848486' />
                  <rect x='10' y='28' width='30' height='3' fill='#848486' />
                  <rect x='10' y='10' width='30' height='3' fill='#848486' />
                </svg>
              </a>
            </div>
            <nav className='navbar'>
              <div className='logo'>
                <Link to='/' rel='noreferrer' onClick={toggleMenu}>
                  <img className='logo_img' src={logo} alt='logo' />
                </Link>
              </div>
              <div className='icn_cloz' onClick={toggleMenu}></div>
              <ul className='menu'>
                <li className='loginCredHeaderHold'>
                  <Wallet />
                </li>
                {/* Main Menu */}
                <li
                  className={
                    pageName === '/launchpad' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <Link to='/launchpad'>LaunchPad</Link>
                </li>
                <li
                  className={
                    pageName === '/nftExplore' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <Link to='/nftExplore'>Explore NFTs</Link>
                </li>
                <li
                  className={
                    pageName === '/collections' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <Link to='/collections'>Collections</Link>
                </li>
                <li className='nav-item apello'>
                  <a href='https://www.apello.xyz/sales' target='_blank'>
                    Activity Feed
                  </a>
                </li>
                <li
                  className={
                    pageName.includes('myNFT') ? 'nav-item active' : 'nav-item'
                  }
                >
                  <Link to='/myNFT'>My NFTs</Link>
                </li>

                {/* {menuItems ? (
                  Object(menuItems[0].mainMenu).map((item: any) => {
                    return (
                      <li
                        hidden={!item.active}
                        className={
                          item.name.includes('NFTs') ? 'dropdown_list' : ''
                        }
                      >
                        <a href={item.link.includes('nft') ? '/' : item.link}>
                          {item.name}
                        </a>
                      </li>
                    );
                  })
                ) : (
                  <>
                    <li>
                      <a href='https://www.loop.markets/'>Home</a>
                    </li>
                    <li>
                      <a href='/'>NFTs</a>
                    </li>
                  </>
                )} */}
                {/* DropDown Menu */}
                {/* <li hidden={!menuItems} className='navbar_dropdown'>
                  <span>. . .</span>
                  <ul className='navbar_dropdown_content'>
                    {menuItems &&
                      Object(menuItems[1].subMenu).map((item: any) => {
                        return (
                          <>
                            <li hidden={!item.active}>
                              <a
                                className='dropdown-item'
                                href={item.link}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <span>{item.name}</span>
                              </a>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </li> */}
              </ul>
              <ul className='menu mneuValues'>
                <li className='loginCredHeaderHold'>
                  <PlatformMenu />
                </li>
                <li className='loginCredHeaderHold'>
                  <Wallet />
                </li>
              </ul>

              {/* Mobile Menu */}
              <ul className='trendingNewMenu mneuValues'>
                <li className='loginCredHeaderHold'>
                  <Wallet />
                </li>
              </ul>
              <div className='trendingNewMenu' style={{height: "100%", overflowY: "scroll", overflowX: "hidden"}}>
                <div className='trendingNewMenuLabel Actsidebar'>
                  NFT Marketplace
                </div>
                <ul className='sidemenuBar'>
                  <li
                    className={
                      location.pathname.startsWith('/launchpad')
                        ? 'trendingActiveMenu'
                        : ''
                    }
                  >
                    <Link to='/Launchpad' onClick={toggleMenu}>
                      <span>
                        <img src='/m2.svg' alt='menu' />
                        <img src='/m2a.svg' alt='menu' />
                      </span>{' '}
                      Launchpad
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname.startsWith('/nftExplore')
                        ? 'trendingActiveMenu'
                        : ''
                    }
                  >
                    <Link to='/nftExplore' onClick={toggleMenu}>
                      <span>
                        <img src='/m1.svg' alt='menu' />
                        <img src='/m1a.svg' alt='menu' />
                      </span>{' '}
                      Explore NFTs
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname.startsWith('/collections')
                        ? 'trendingActiveMenu'
                        : ''
                    }
                  >
                    <Link to='/collections' onClick={toggleMenu}>
                      <span>
                        <img src='/m3.svg' alt='menu' />
                        <img src='/m3a.svg' alt='menu' />
                      </span>{' '}
                      Collections
                    </Link>
                  </li>
                  <li className='apello'>
                    <a href='https://www.apello.xyz/sales' target='_blank'>
                      <span>
                        <img src='/m5.svg' alt='menu' />
                        <img src='/m5a.svg' alt='menu' />
                      </span>{' '}
                      Activity Feed
                    </a>
                  </li>
                  <li>
                    <Link to='/myNft' onClick={toggleMenu}>
                      <span>
                        <img src='/m5.svg' alt='menu' />
                        <img src='/m5a.svg' alt='menu' />
                      </span>{' '}
                      My NFTs
                    </Link>
                  </li>
                </ul>
                <PlatformMenuMobile />
              </div>
            </nav>
          </div>
        </header>
      </div>
      {MobileSearch && (
        <SearchForm
          classes='mobile_search'
          showIcon={false}
          isMobile={true}
          mobileSearchHandle={mobileSearchHandle}
        />
      )}
    </>
  );
};

export default NftHeader;
