import { useCallback, useEffect, useState } from 'react';
import ArrowDown from '../../../assets/arrow_down.png';
import ArrowUp from '../../../assets/arrow_up.png';
import Sold from '../../../assets/sold.png';
import Star from '../../../assets/star.png';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {
  queryUserActivity,
  UserActivity,
} from '../../../hooks/queryUserActivity';
import { NFTData } from '../../../hooks/useEligibleStakeNFTs';
import { useLocation } from 'react-router';
import './NftDetail.scss';
import {
  formatNumberString,
  formatTokenPrice,
} from 'utils/conversion/conversion';
import { Dec } from '@terra-money/terra.js';
import { junoAddress } from 'core/utils';
import { denomToSymbol } from 'core/common';
TimeAgo.addDefaultLocale(en);

const ActivityTable = (props?:any) => {
  const isModal = props.modal;
  const timeAgo = new TimeAgo('en-US');
  const [NFTHistory, setNFTHistory] = useState<UserActivity[]>();
  const [activePanel, setActivePanel] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<boolean>(true); // true => descending, false => ascending
  const { pathname, hash } = useLocation();
  const userActivity = useCallback(async () => {
    const nftAddress = `${isModal ? props.params.address : pathname.split('/')[2]}-${
      isModal ? props.params.id : pathname.split('/')[3]
    }${isModal ? props.params.hash : hash}`;
    const res = await queryUserActivity(nftAddress, activePanel, sortOrder);
    setNFTHistory(res.nodes);
  }, [pathname, hash, activePanel, sortOrder, isModal, props]);

  useEffect(() => {
    userActivity();
  }, [activePanel, sortOrder, userActivity]);

  return (
    <div className='detailTrendingRight4'>
      <div className='tbleAbout'>
        <h6>
          History{' '}
          <span>
            <button
              className={activePanel == '' ? 'activeActions' : ''}
              onClick={() => setActivePanel('')}
            >
              All
            </button>
            <button
              className={activePanel == 'Bid' ? 'activeActions' : ''}
              onClick={() => setActivePanel('Bid')}
            >
              Bid
            </button>
            <button
              className={activePanel == 'Mint' ? 'activeActions' : ''}
              onClick={() => setActivePanel('Mint')}
            >
              Minted
            </button>
            <button
              className={activePanel == 'Sold' ? 'activeActions' : ''}
              onClick={() => setActivePanel('Sold')}
            >
              Sold
            </button>
            <button
              className={activePanel == 'Rewards' ? 'activeActions' : ''}
              onClick={() => setActivePanel('Rewards')}
            >
              Rewards
            </button>
          </span>
        </h6>

        <table>
          <thead>
            <tr>
              <th>Event</th>
              <th>Price</th>
              <th>From</th>
              <th>To</th>
              <th
                className='th--cursor'
                onClick={() => setSortOrder(!sortOrder)}
              >
                Date{' '}
                {sortOrder ? (
                  <img src={ArrowDown} alt='updown' />
                ) : (
                  <img src={ArrowUp} alt='up' />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {NFTHistory?.map((history: any) => (
              <tr>
                <td>
                  {/*<td className='blueActive'>*/}
                  {history.event == 'Mint' && (
                    <img src={Star} alt='star-active.svg' />
                  )}
                  {history.event == 'Sold' && (
                    <img src={Sold} alt='star-active.svg' />
                  )}
                  {history.event == 'Bid' && (
                    <img src='/hammer.svg' alt='hammer.svg' />
                  )}
                  {history.event}
                </td>
                <td>
                  {history.amount > 0 &&
                    `${formatNumberString(
                      new Dec(history.amount).div(1000000)
                    )} ${denomToSymbol[history.denom] || 'USDC'}`}
                </td>
                <td>{junoAddress(history.from)}</td>
                <td>{junoAddress(history.to)}</td>
                <td>
                  {timeAgo.format(new Date(history.timestamp).getTime())}
                  <img
                    className='expander'
                    src='/expander.svg'
                    alt='expander.svg'
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActivityTable;
