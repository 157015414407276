import { getQueryClient } from './newHooks/useJunoConnectedWallet';

const lpStakingAddr =
  'juno1lrasded643d0um2s3td2cceyle93zp56sqerklsyg8le7mg949ksmvj4hy';

export const queryLoopPowerStaking = async (address = '') => {
  const client = await getQueryClient();
  // query loop power contract
  const _info = await client.queryContractSmart(lpStakingAddr, {
    token_ids: {
      addr: address,
      nft_contract_addr:
        'juno1pkye52d2zhqj6xuszfzjywt042z6hh279d5lydz25qsxyhw2dpys9z5vwf',
    },
  });
  return _info.length > 0 ? true : false;
};
