import {useEffect, useState, useContext} from 'react';
import {useQuery, useMutation} from 'react-query';

import {NotificationContext} from '../../contexts/notification';
import {useRecoilValue} from 'recoil';
import {walletState} from 'state/walletAtoms';
import {unsafelyGetDefaultExecuteFee} from 'utils/fees';
import {
    CreateTxFailed,
    Timeout,
    TxFailed,
    TxUnspecifiedError,
    UserDenied,
} from '@terra-money/wallet-provider';
import {MsgExecuteContract} from '@cosmjs/cosmwasm';
import {toUtf8} from '@cosmjs/encoding';
import {useWallet} from "../../Loop_Reuseable_Component/packages/loop_wallet";
import {SigningCosmWasmClient} from "@cosmjs/cosmwasm-stargate";
import {GasPrice} from "@cosmjs/stargate";
import {multiple} from "../../libs/math";

declare global {
    interface Window {
        obiSignAndBroadcast: any;
    }
}

enum TxStatus {
    Idle,
    Estimating,
    Ready,
    Broadcasting,
    Success,
    Failure,
}

enum TxError {
    None,
    UserDenied,
    CreateTxFailed,
    TxFailed,
    Timeout,
    TxUnspecified,
    Unknown,
}

type TxProps = {
    onSuccess?: (txHash: string, txLogs: any) => void;
    onFailure?: (txHash?: string) => void;
};

export default (props?: TxProps) => {
    const notificationContext = useContext(NotificationContext);
    const {onSuccess, onFailure} = props || {};

    const {client, address} = useRecoilValue(walletState);
    const [txStatus, setTxStatus] = useState<TxStatus>(TxStatus.Idle);
    const [txHash, setTxHash] = useState('');
    const [txError, setTxError] = useState<TxError>(TxError.None);
    const [txErrorMessage, setTxErrorMessage] = useState('');
    const [txLogs, setTxLogs] = useState<any>('')
    const [transactionInfo, setTransactionInfo] = useState<any>('')

    const convertMsgs = (msgs: MsgExecuteContract[]) => {
        return msgs.map(msg => ({
            typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
            value: {
                sender: msg.value.sender,
                msg: toUtf8(JSON.stringify(msg.value.msg)),
                contract: msg.value.contract,
                funds: msg.value.sent_funds || [],
            },
        }));
    };
    let {address: gAddress, wallet: batwa, client: gClient} = useWallet()

    const estimateGas = async (
        msgs: MsgExecuteContract[]
    ): Promise<number | null> => {
        setTxStatus(TxStatus.Estimating);
        if (!client) {
            setTxStatus(TxStatus.Failure);
            setTxError(TxError.Unknown);
            return null;
        }
        try {
            setTxStatus(TxStatus.Ready);
            setTxError(TxError.None);
            setTxHash('');

            if (gAddress) {
                // gClient =  await SigningCosmWasmClient.connectWithSigner(
                //     process.env.REACT_APP_RPC,
                //     batwa,
                //     {
                //       gasPrice: GasPrice.fromString(multiple("0.0025",multiple(msgs.length, '2')) + 'ujuno'),
                //     }
                // )
                return await gClient.simulate(address, convertMsgs(msgs), '');
                //  await client.signAndBroadcast(
                //     gAddress,
                //     [...(convertMsgs(msgs))],
                //     'auto'
                // )
            } else if (typeof window['obiSignAndBroadcast'] === 'function') {
                return 400000;
            } else {
                return await client.simulate(address, convertMsgs(msgs), '');
            }
        } catch (e: any) {
            console.log('e = ', e);
            setTxErrorMessage(e.message);
            setTxStatus(TxStatus.Failure);
            setTxError(TxError.Unknown);
        }
        return null;
    };

    const {mutate} = useMutation(
        ['execute', txStatus],
        async ({
                   msgs,
                   redirectTo = '',
               }: {
            msgs: MsgExecuteContract[];
            redirectTo?: string;
        }) => {
            setTxStatus(TxStatus.Broadcasting);
            notificationContext.setTheme({
                type: 0,
                subType: 0,
                redirectURL: redirectTo,
            });
            if (gAddress) {
                const defaultExecuteFee = unsafelyGetDefaultExecuteFee();
                const estimate = await client!.simulate(gAddress, convertMsgs(msgs), '');

                return client!.signAndBroadcast(
                    gAddress,
                    convertMsgs(msgs),
                    {
                        ...defaultExecuteFee,
                        gas: Math.floor(estimate * 1.3).toString(),
                    },
                    ''
                );
            } else if (typeof window['obiSignAndBroadcast'] === 'function') {
                return await window['obiSignAndBroadcast'](address, convertMsgs(msgs));
            } else {
                const defaultExecuteFee = unsafelyGetDefaultExecuteFee();
                const estimate = await client!.simulate(address, convertMsgs(msgs), '');
                return client!.signAndBroadcast(
                    address,
                    convertMsgs(msgs),
                    {
                        ...defaultExecuteFee,
                        gas: Math.floor(estimate * 1.3).toString(),
                    },
                    ''
                );
            }
        },
        {
            onError: (e: any) => {
                console.log('e = ', e);
                setTxErrorMessage(e.message);
                notificationContext.setTheme({
                    type: 0,
                    subType: 3,
                    txErrorMessage: e.message,
                });
                if (e instanceof UserDenied) {
                    setTxError(TxError.UserDenied);
                } else if (e instanceof CreateTxFailed) {
                    setTxError(TxError.CreateTxFailed);
                } else if (e instanceof TxFailed) {
                    setTxError(TxError.CreateTxFailed);
                } else if (e instanceof Timeout) {
                    setTxError(TxError.Timeout);
                } else if (e instanceof TxUnspecifiedError) {
                    setTxError(TxError.TxUnspecified);
                } else {
                    setTxError(TxError.Unknown);
                }

                setTxStatus(TxStatus.Failure);
            },
            onSuccess: data => {
                console.log('Contract Call Succeeded', data);
                notificationContext.setTheme({type: 0, subType: 1});
                setTxHash(data.transactionHash);
                setTxLogs(JSON.parse(data?.rawLog))
            },
        }
    );


    const {data: txInfo} = useQuery(
        ['txInfo', txHash, txLogs],
        () => client?.getTx(txHash),
        {
            enabled:
                txStatus !== TxStatus.Success &&
                txStatus !== TxStatus.Failure &&
                !!txHash,
            retry: true,
            onError: (e: any) => {
                setTxStatus(TxStatus.Failure);
                setTxError(TxError.Unknown);
                notificationContext.setTheme({
                    type: 0,
                    subType: 3,
                    txErrorMessage: e.message,
                });
            },
            onSuccess: data => {
                if (data) {
                    console.log("data", data)
                    setTxStatus(TxStatus.Success);
                    console.log('set notification success', data);
                    notificationContext.setTheme({
                        type: 0,
                        subType: 2,
                        txHash,
                        txLogs: txLogs,
                        transactionInfo: transactionInfo
                    });
                }
            },
        }
    );
    useEffect(() => {
        if (!!txInfo && !!txHash) {
            if (txInfo.code) {
                setTxStatus(TxStatus.Failure);
                setTxError(TxError.TxFailed);
                onFailure && onFailure(txHash);
            } else {
                setTxStatus(TxStatus.Success);
                setTxError(TxError.None);
                onSuccess && onSuccess(txHash, txLogs);
            }
        }
    }, [txInfo, onFailure, onSuccess, txHash, txLogs]);

    return {
        txStatus,
        txInfo,
        txHash,
        txLogs,
        txError,
        txErrorMessage,
        estimateGas,
        submit: mutate,
        setTransactionInfo
    };
};
