import NftFooter from '../NftFooter';

import {Helmet} from 'react-helmet';
import {useCallback, useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import Sidebar from '../../../components/Sidebar';
import Modal from '../../../components/Modal';
import {
    LaunchPadNFTQueryInfo,
    queryLaunchpadNFTInfo,
} from 'hooks/queryLaunchpadNFTInfo';
import {Dec} from '@terra-money/terra.js';
import {commonConfig, denomToSymbol, getIpfsUrl} from 'core/common';
import rewards from '../../../assets/reward.svg';
import rewardGrey from '../../../assets/rewardGrey.png';
import {
    bidAuctionNFTMsg,
    buySaleNFTMsg,
    nftAuctionMsg,
    nftCancelAuctionMsg,
    nftCancelSellMsg,
    nftClaimAuctionMsg,
    nftSellMsg,
    nftStakeClaimMsg,
    nftStakeMsg,
    nftTransferMsg,
    nftUnstakeMsg,
} from 'helper/junoContractMsg';
import {networkType, nftConfig} from 'core/nftConfig';
import useTokenReserve from 'hooks/useTokenReserve';
import useJunoTransactions from 'hooks/newHooks/useJunoTransactions';
import ActivityTable from './ActivityTable';
import {useRecoilValue} from 'recoil';
import {walletState} from 'state/walletAtoms';
import {USDC_DENOM} from 'core/constants';
import {
    formatNumberString,
    formatTokenPrice,
} from 'utils/conversion/conversion';
import Countdown, {zeroPad} from 'react-countdown';
import {toast} from 'react-toastify';
import {useJunoTokenBalance} from 'hooks/newHooks/useTokenBalance';
import ReactTooltip from 'react-tooltip';
import AMPMSelect from './AMPMSelect';
import ToolTip from '../../../components/ToolTips';
import {
    queryCollectionNftsCount,
    queryCollectionNftsCountByAttribute,
} from 'hooks/queryCount';
import WebAppHeader from 'pages/WebApp/WebAppHeader';
import DataLayer from 'components/DataLayer/DataLayer';
import {multiple} from 'libs/math';
import SelectBox, {Option} from "../../../components/Select";
import {queryLoopPowerStaking} from 'hooks/queryLoopPowerStaking';
import NftSkeletonLoading from 'components/NftLoadingSkeleton';

const maxSalePrice = 999999999;

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const SkeletonComponent = ({
                               count,
                               width,
                               children,
                               info,
                           }: {
    count: number;
    width: string;
    children: any;
    info: any;
}) => {
    return (
        <>
            {children}
            {/* {info ? (
        children
      ) : (
        <SkeletonTheme highlightColor='#a8a8a8'>
          <Skeleton count={count} width={width} />
        </SkeletonTheme>
      )} */}
        </>
    );
};

const NftExploreItemDetails = (props?: any) => {
    const isModal = props.modal;
    const params = isModal ? props.modalParams : useParams<any>();
    const location = useLocation();
    const history = useHistory();
    const isFans = location.pathname.startsWith('/fans');
    const [nftAddress] = useState(params && params.address);
    const [nftTokenId] = isModal
        ? useState<string>(
            params &&
            (decodeURI(props.modalParams.id) + props.modalParams.hash).toString()
        )
        : useState<string>(
            params && (decodeURI(params.id) + location.hash).toString()
        );
    const [info, setInfo] = useState<LaunchPadNFTQueryInfo & {
        claimableRewards1: Dec;
        claimableRewards2: Dec;
        claimableTime: number;
        isReward1: boolean;
        isReward2: boolean;
    }>();
    const {address, client} = useRecoilValue(walletState);
    const {balance, refetch} = useJunoTokenBalance(denomToSymbol[info?.saleInfo?.denom ?? 'USDC']);
    const {submit, estimateGas, setTransactionInfo} = useJunoTransactions();
    const token_reserve = useTokenReserve(
        networkType,
        Object.keys(commonConfig[networkType]?.IS_AVAILABLE_TOKEN || {})
    );
    const isOwner = info?.info?.owner === address;
    const isLevana = info?.metadata?.name.startsWith("Levana") && info?.info?.type === 'Standard'

    const isInStaking = info?.isInStaking;
    const isInSale = info?.saleInfo !== undefined;
    const isInAuction = info?.auctionInfo !== undefined;
    const [sellModal, setSellModal] = useState(false);
    const [bidModal, setBidModal] = useState(false);
    const [auctionModal, setAuctionModal] = useState(false);
    const [superLoopPowerModal, setSuperLoopPowerModal] = useState(false);
    const [transferModal, setTransferModal] = useState(false);
    const [salePrice, setSalePrice] = useState('');
    const [bidPrice, setBidPrice] = useState('');
    // @ts-ignore
    const [sellType, setSellType] = useState<Option | null>({
        value: 0,
        label: <div className="selectWithIcon"><img src={'/USDCicon.svg'} height={'20px'}/>USDC</div>
    });
    const [auctionType, setAuctionType] = useState<Option | null>({
        value: 0,
        label: <div className="selectWithIcon"><img src={'/USDCicon.svg'} height={'20px'}/>USDC</div>
    });
    // @ts-ignore
    const sellTypeOptions = {0: USDC_DENOM, 1: "ujuno"};
    const tokenTypesOptions = [
        {value: 0, label: <div className="selectWithIcon"><img src={'/USDCicon.svg'} height={'20px'}/>USDC</div>},
        {value: 1, label: <div className="selectWithIcon"><img src={'/junoicon.svg'} height={'20px'}/>JUNO</div>},
    ]

    useEffect(() => {
        refetch?.()
    }, [address, refetch, client])

    const amPmOptions = [
        {
            value: 'AM',
            label: 'AM',
        },
        {
            value: 'PM',
            label: 'PM',
        },
    ];
    const [auctionOption, setAuctionOption] = useState({
        amount: '',
        days: 0,
        hour: 0,
        minute: 0,
        amPm: amPmOptions[0],
    });
    const [recipient, setRecipient] = useState('');
    const minBidPrice = formatTokenPrice(
        info?.auctionInfo?.min_bid_amount != '0'
            ? info?.auctionInfo?.min_bid_amount
            : parseInt(info?.auctionInfo?.high_bidder_amount || '0') !== 0
                ? info?.auctionInfo?.high_bidder_amount
                : info?.auctionInfo?.min_amount
    );

    const [bidInputAlertText, setBidInputAlertText] = useState('');
    const [attributesCount, setAttributesCount] = useState<number[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const creator =
        nftConfig[networkType].launchPadNFTs.find(
            item => item.NFT === info?.contracts.nft
        )?.CREATOR ||
        commonConfig[networkType].NFT_CREATORS[info?.contracts.nft || ''];

    useEffect(() => {
        if (token_reserve) {
            queryLaunchpadNFTInfo(networkType, nftAddress, nftTokenId)?.then(
                result => {
                    if (result) {
                        console.log(result);
                        const daysStaked =
                            Math.floor(result.stakeInfo.total_staked_time / 60 / 60 / 24) <=
                            parseInt(result.info.vesting_period)
                                ? Math.floor(result.stakeInfo.total_staked_time / 60 / 60 / 24)
                                : parseInt(result.info.vesting_period);
                        const daysRemain =
                            parseInt(result.info.vesting_period) - daysStaked;
                        let dailyValue1 = new Dec(0);
                        let totalValue1 = new Dec(0);
                        let dailyValue2 = new Dec(0);
                        let totalValue2 = new Dec(0);

                        if (
                            result.info.token1_addr &&
                            token_reserve[result.info.token1_addr]
                        ) {
                            dailyValue1 = new Dec(result.info.token1_amount)
                                .mul(token_reserve[result.info.token1_addr].usdcAmount)
                                .div(token_reserve[result.info.token1_addr].tokenAmount);
                            totalValue1 = dailyValue1.mul(result.info.vesting_period);
                        }
                        if (
                            result.info.token2_addr &&
                            token_reserve[result.info.token2_addr]
                        ) {
                            dailyValue2 = new Dec(result.info.token2_amount)
                                .mul(result.info.vesting_period)
                                .mul(token_reserve[result.info.token2_addr].usdcAmount)
                                .div(token_reserve[result.info.token2_addr].tokenAmount);
                            totalValue2 = dailyValue2.mul(result.info.vesting_period);
                        }

                        setAttributesCount(result.metadata.attributes.map(_ => 0));
                        setTotalCount(0);

                        setInfo({
                            ...result,
                            daysStaked,
                            daysRemain,
                            dailyValue1,
                            totalValue1,
                            dailyValue2,
                            totalValue2,
                            isReward1: !!result.info.token1_addr && daysRemain > 0,
                            isReward2: !!result.info.token2_addr && daysRemain > 0,
                            claimableRewards1: result.info.token1_addr
                                ? new Dec(result.info.token1_amount)
                                    .mul(
                                        Math.max(
                                            Math.min(
                                                Math.floor(
                                                    result.stakeInfo.total_staked_time / 60 / 60 / 24
                                                ),
                                                parseInt(result.info.vesting_period)
                                            ) - result.stakeInfo.claimed_days,
                                            0
                                        )
                                    )
                                    .div(1000000)
                                : new Dec(0),
                            claimableRewards2: result.info.token2_addr
                                ? new Dec(result.info.token2_amount)
                                    .mul(
                                        Math.max(
                                            Math.min(
                                                Math.floor(
                                                    result.stakeInfo.total_staked_time / 60 / 60 / 24
                                                ),
                                                parseInt(result.info.vesting_period)
                                            ) - result.stakeInfo.claimed_days,
                                            0
                                        )
                                    )
                                    .div(1000000)
                                : new Dec(0),
                            claimableTime:
                                Date.now() +
                                (86400 - (result.stakeInfo.total_staked_time % 86400)) * 1000,
                        });
                    }
                }
            );
        }
    }, [nftAddress, nftTokenId, token_reserve]);

    useEffect(() => {
        if (info) {
            const queries = [];
            for (let i = 0; i < info.metadata.attributes.length; i++) {
                queries.push(
                    queryCollectionNftsCountByAttribute(
                        nftAddress,
                        info.metadata.attributes[i].trait_type,
                        info.metadata.attributes[i].value
                    )
                );
            }

            Promise.all(queries).then(counts => {
                setAttributesCount(counts);
            });

            queryCollectionNftsCount(nftAddress).then(count => setTotalCount(count));
        }
    }, [info, info?.metadata.attributes, nftAddress]);

    const isPower = nftAddress === "juno1pkye52d2zhqj6xuszfzjywt042z6hh279d5lydz25qsxyhw2dpys9z5vwf";
    const [lpStaked, setLpStaked] = useState(false);
    useEffect(() => {
        queryLoopPowerStaking(address)?.then((r: boolean) => {
            setLpStaked(r);
        });
    }, [isPower, address])

    const stakeHandler = useCallback(async () => {
        const txMsg = nftStakeMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_staking: info?.contracts.nftStaking,
            token_id: nftTokenId,
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
    }, [
        address,
        estimateGas,
        info?.contracts.nftStaking,
        info?.contracts.nft,
        nftTokenId,
        submit,
    ]);

    const unStakeHandler = useCallback(async () => {
        const txMsg = nftUnstakeMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_staking: info?.contracts.nftStaking,
            token_id: nftTokenId,
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
    }, [
        address,
        estimateGas,
        info?.contracts.nftStaking,
        info?.contracts.nft,
        nftTokenId,
        submit,
    ]);

    const getAuctionEndTime = useCallback(() => {
        const end_time = new Date();
        end_time.setDate(end_time.getDate() + auctionOption.days);
        end_time.setHours(
            auctionOption.amPm.value === 'AM'
                ? auctionOption.hour
                : auctionOption.hour + 12
        );
        end_time.setMinutes(auctionOption.minute);
        end_time.setSeconds(0);
        end_time.setMilliseconds(0);

        return end_time;
    }, [
        auctionOption.amPm.value,
        auctionOption.days,
        auctionOption.hour,
        auctionOption.minute,
    ]);

    const getAuctionEndTimeString = () => {
        const end_time = getAuctionEndTime();

        if (end_time.getHours() >= 12) {
            return (
                `${
                    months[end_time.getMonth()]
                } ${end_time.getDate()}, ${end_time.getFullYear()} at ${
                    end_time.getHours() - 12 < 10
                        ? '0' + (end_time.getHours() - 12).toString()
                        : (end_time.getHours() - 12).toString()
                }:${
                    end_time.getMinutes() < 10
                        ? '0' + end_time.getMinutes()
                        : end_time.getMinutes()
                } PM ` +
                end_time
                    .toLocaleDateString('en-US', {
                        day: '2-digit',
                        timeZoneName: 'short',
                    })
                    .slice(4)
            );
        }

        return (
            `${
                months[end_time.getMonth()]
            } ${end_time.getDate()}, ${end_time.getFullYear()} at ${
                end_time.getHours() < 10
                    ? '0' + end_time.getHours()
                    : end_time.getHours()
            }:${
                end_time.getMinutes() < 10
                    ? '0' + end_time.getMinutes()
                    : end_time.getMinutes()
            } AM ` +
            end_time
                .toLocaleDateString('en-US', {
                    day: '2-digit',
                    timeZoneName: 'short',
                })
                .slice(4)
        );
    };

    const getAuctionEndTimeStringUTC = () => {
        const end_time = getAuctionEndTime();

        if (end_time.getUTCHours() >= 12) {
            return `${
                months[end_time.getUTCMonth()]
            } ${end_time.getUTCDate()}, ${end_time.getUTCFullYear()} at ${
                end_time.getUTCHours() - 12 < 10
                    ? '0' + (end_time.getUTCHours() - 12).toString()
                    : (end_time.getUTCHours() - 12).toString()
            }:${
                end_time.getUTCMinutes() < 10
                    ? '0' + end_time.getUTCMinutes()
                    : end_time.getUTCMinutes()
            } PM UTC`;
        }

        return `${
            months[end_time.getUTCMonth()]
        } ${end_time.getUTCDate()}, ${end_time.getUTCFullYear()} at ${
            end_time.getUTCHours() < 10
                ? '0' + end_time.getUTCHours()
                : end_time.getUTCHours()
        }:${
            end_time.getUTCMinutes() < 10
                ? '0' + end_time.getUTCMinutes()
                : end_time.getUTCMinutes()
        } AM UTC`;
    };

    const listAuctionHandler = useCallback(async (type = "auc") => {
        const start_time = new Date();
        const end_time = getAuctionEndTime();

        if (end_time.getTime() < start_time.getTime()) {
            toast.error('invalid auction end time');
            return;
        }

        console.log({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_auction: info?.contracts.nftAuction,
            token_id: nftTokenId,
            end_time: Math.floor(end_time.getTime() / 1000),
            denom: USDC_DENOM,
            amount: new Dec(auctionOption.amount || '0').mul(1000000).toFixed(0),
        });
        const txMsg = nftAuctionMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_auction: info?.contracts.nftAuction,
            token_id: nftTokenId,
            end_time: Math.floor(end_time.getTime() / 1000),
            denom: type == "auc" ? info?.auctionInfo?.coin_denom || USDC_DENOM : auctionType?.value == 0 ? sellTypeOptions["0"] : sellTypeOptions["1"],
            amount: new Dec(auctionOption.amount || '0').mul(1000000).toFixed(0),
        });
        console.log('txMsg = ', txMsg, auctionType?.value == 0 ? sellTypeOptions["0"] : sellTypeOptions["1"]);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg], redirectTo: '/mynft/onsale'});
    }, [
        address,
        auctionOption.amount,
        estimateGas,
        info?.contracts.nftAuction,
        info?.contracts.nft,
        nftTokenId,
        submit,
        getAuctionEndTime,
        auctionType?.value,
        info?.auctionInfo?.coin_denom,
        sellTypeOptions
    ]);

    const cancelAuctionHandler = useCallback(async () => {
        const txMsg = nftCancelAuctionMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_auction: info?.contracts.nftAuction,
            token_id: nftTokenId,
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
    }, [
        address,
        estimateGas,
        info?.contracts.nftAuction,
        info?.contracts.nft,
        nftTokenId,
        submit,
    ]);

    const claimAuctionHandler = useCallback(async () => {
        const txMsg = nftClaimAuctionMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_auction: info?.contracts.nftAuction,
            token_id: nftTokenId,
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
    }, [
        address,
        estimateGas,
        info?.contracts.nftAuction,
        info?.contracts.nft,
        nftTokenId,
        submit,
    ]);

    const listSellHandler = useCallback(async (type = "buy") => {
        // @ts-ignore
        const txMsg = nftSellMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_sale: info?.contracts.nftSale,
            token_id: nftTokenId,
            denom: type == "buy" ? info?.saleInfo?.denom || USDC_DENOM : sellType?.value == 0 ? sellTypeOptions["0"] : sellTypeOptions["1"],
            amount: new Dec(salePrice).mul(1000000).toFixed(0),
        });
        const fee = await estimateGas([txMsg]);

        submit({msgs: [txMsg], redirectTo: '/mynft/onsale'});
    }, [
        address,
        estimateGas,
        info?.contracts.nftSale,
        info?.contracts.nft,
        nftTokenId,
        salePrice,
        submit,
    ]);

    const cancelSellHandler = useCallback(async () => {
        const txMsg = nftCancelSellMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_sale: info?.contracts.nftSale,
            token_id: nftTokenId,
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
    }, [
        address,
        estimateGas,
        info?.contracts.nftSale,
        info?.contracts.nft,
        nftTokenId,
        submit,
    ]);

    const buyNFTHandler = useCallback(async () => {
        const txMsg = buySaleNFTMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_sale: info?.contracts.nftSale,
            token_id: nftTokenId,
            coins: [
                {
                    amount: info?.saleInfo?.amount,
                    denom: info?.saleInfo?.denom || USDC_DENOM,
                },
            ],
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
        setTransactionInfo({
            type: 'buyNft',
            title: info?.metadata.name,
            creator: creator,
            collection: info?.metadata.project ? info?.metadata.project : info?.metadata.name?.split('#')[0],
            tier: info?.info.tier_index,
            value: info?.saleInfo?.amount,
            reward: multiple(info?.info.token1_amount, info?.info?.vesting_period),
        });
    }, [
        address,
        estimateGas,
        info?.metadata.name,
        info?.metadata.project,
        info?.contracts.nftSale,
        info?.info.tier_index,
        info?.contracts.nft,
        info?.saleInfo?.amount,
        info?.info.token1_amount,
        info?.info?.vesting_period,
        creator,
        nftTokenId,
        submit,
        setTransactionInfo,
    ]);
    const bidAuctionHandler = useCallback(async () => {
        const txMsg = bidAuctionNFTMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            nft_auction: info?.contracts.nftAuction,
            token_id: nftTokenId,
            coins: [
                {
                    amount: new Dec(bidPrice).mul(1000000).toFixed(0),
                    denom: info?.auctionInfo?.coin_denom || USDC_DENOM,
                },
            ],
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg], redirectTo: '/mynft/bids'});
    }, [
        address,
        bidPrice,
        estimateGas,
        info?.contracts.nftAuction,
        info?.contracts.nft,
        nftTokenId,
        submit,
        info?.auctionInfo?.coin_denom
    ]);

    const transferHandler = useCallback(async () => {
        const txMsg = nftTransferMsg({
            address,
            networkType,
            nft: info?.contracts.nft,
            token_id: nftTokenId,
            recipient,
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
    }, [
        address,
        estimateGas,
        info?.contracts.nft,
        nftTokenId,
        recipient,
        submit,
    ]);

    const claimStakeHandler = useCallback(async () => {
        const txMsg = nftStakeClaimMsg({
            address,
            networkType,
            nftStaking: info?.contracts.nftStaking,
            nft: info?.contracts.nft,
            token_id: nftTokenId,
        });
        console.log('txMsg = ', txMsg);
        const fee = await estimateGas([txMsg]);
        console.log('FEE:', fee);
        submit({msgs: [txMsg]});
    }, [
        address,
        estimateGas,
        info?.contracts.nft,
        info?.contracts.nftStaking,
        nftTokenId,
        submit,
    ]);

    const auctionRemain = () => {
        const endTime = info?.auctionInfo?.end_time || 0;
        const nowTime = Math.floor(new Date().getTime() / 1000);
        if (endTime <= nowTime) {
            return (
                <>
                    <label>Time Left:</label>
                    <b>0</b>d<b>0</b>
                    HR
                    <b>0</b>
                    Min
                </>
            );
        }

        const diff = endTime - nowTime;

        return (
            <>
                <label>Time Left:</label>
                <b>{Math.floor(diff / 86400)}</b>d
                <b>{Math.floor((diff % 86400) / 3600)}</b>
                HR
                <b>{Math.floor((diff % 3600) / 60)}</b>
                Min
            </>
        );
    };

    const renderer = ({hours, minutes, seconds}: any) => {
        return (
            <h6>
        <span
            style={{
                color: 'white',
                marginRight: '10px',
            }}
        >
          Next Epoch
        </span>
                {zeroPad(hours)}
                <span>:</span>
                {zeroPad(minutes)}
                <span>:</span>
                {zeroPad(seconds)}
            </h6>
        );
    };

    if (!info) {
        return (
            <>
                <Helmet>
                    <title>NFT | Explore Detail</title>
                </Helmet>
                <div>
                    <NftSkeletonLoading isModal={isModal}  />
                    {/* <div className={!isModal ? 'FullProductInfo' : ''}>
                        <div
                            className='wrapper'
                            style={{
                                height: 'calc(100vh - 480px)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className='loader' style={{margin: 'auto'}}></div>
                        </div>
                    </div> */}
                    {!location.pathname.startsWith('/webapp') && !isModal && (
                        <NftFooter/>
                    )}
                </div>
            </>
        );
    }

    const nftInfo = nftConfig[networkType].launchPadNFTs.find(
        item => item.NFT === info?.contracts.nft
    );
    const launchEndDate = nftInfo?.launchInfo?.endDate;
    const launchEndTime = nftInfo?.launchInfo?.endTime;
    const date = new Date().getTime();
    const endDate = new Date(launchEndDate + ' ' + launchEndTime).getTime();

    const isSuperLoopPower =
        nftAddress ===
        'juno1pkye52d2zhqj6xuszfzjywt042z6hh279d5lydz25qsxyhw2dpys9z5vwf' &&
        info.metadata.attributes.findIndex(
            attribute =>
                attribute.trait_type === 'SUPER LOOP Power' &&
                attribute.value === 'True'
        ) !== -1;

    // @ts-ignore
    return (
        <>
            <DataLayer
                itemInfo={{
                    type: 'viewNft',
                    title: info?.metadata.name,
                    creator: creator,
                    collection: info?.metadata.project ? info?.metadata.project : info?.metadata.name?.split('#')[0],
                    tier: info?.info.tier_index,
                    value: info?.saleInfo?.amount,
                    reward: multiple(
                        info?.info.token1_amount,
                        info?.info?.vesting_period
                    ),
                }}
            />
            <Helmet>
                <title>NFT | Explore Detail</title>
            </Helmet>
            {nftInfo && <ToolTip endDate={launchEndDate + ' ' + launchEndTime}/>}


            <div>
                <>
                    <div className={!isModal ? 'FullProductInfo' : ''}>
                        <div className='wrapper'>
                            {location.pathname.startsWith('/webapp') && (
                                <WebAppHeader history='mynfts'/>
                            )}
                            <div className='detailTrending'>
                                <Sidebar/>
                                <div className='detailTrendingLeft'>
                                    <div className='nftImage'>
                                        <SkeletonComponent count={10} info={info} width={'470px'}>
                                            <img
                                                src={getIpfsUrl(info?.metadata.image, true)}
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = '/blank.png';
                                                }}
                                                alt=''
                                                style={{width: '470px', maxWidth: '100%'}}
                                            />
                                        </SkeletonComponent>
                                    </div>
                                    {(info?.metadata.external_url ||
                                        info?.metadata.animation_url ||
                                        info?.metadata.youtube_url) && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignItems: 'center',
                                                paddingBottom: '10px',
                                                marginBottom: '20px',
                                                borderBottom: '1px solid #262632',
                                                fontSize: '20px',
                                                color: 'white',
                                            }}
                                        >
                                            Press to experience NFT Media:
                                            {info?.metadata.external_url && (
                                                <a
                                                    href={getIpfsUrl(info?.metadata.external_url, false)}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    <img
                                                        src='/s1.png'
                                                        alt='social'
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            marginLeft: '10px',
                                                            filter:
                                                                'invert(42%) sepia(85%) saturate(810%) hue-rotate(284deg) brightness(100%) contrast(98%)',
                                                        }}
                                                    />
                                                </a>
                                            )}
                                            {info?.metadata.animation_url && (
                                                <a
                                                    href={getIpfsUrl(info?.metadata.animation_url, false)}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    <img
                                                        src='/nd.svg'
                                                        alt='social'
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            marginLeft: '10px',
                                                            filter:
                                                                'invert(42%) sepia(85%) saturate(810%) hue-rotate(284deg) brightness(100%) contrast(98%)',
                                                        }}
                                                    />
                                                </a>
                                            )}
                                            {info?.metadata.youtube_url && (
                                                <a
                                                    href={getIpfsUrl(info?.metadata.youtube_url, false)}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    <img
                                                        src='/nd.svg'
                                                        alt='social'
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            marginLeft: '10px',
                                                            filter:
                                                                'invert(42%) sepia(85%) saturate(810%) hue-rotate(284deg) brightness(100%) contrast(98%)',
                                                        }}
                                                    />
                                                </a>
                                            )}
                                        </div>
                                    )}
                                    {info?.metadata.attributes.length > 0 && (
                                        <div className='nft-attributes'>
                                            <ul>
                                                <SkeletonComponent
                                                    count={8}
                                                    width={'100px'}
                                                    info={info}
                                                >
                                                    <div
                                                        style={{fontSize: '14px', marginBottom: '10px'}}
                                                    >
                                                        <span>Attributes</span>
                                                        {totalCount > 0 && (
                                                            <span
                                                                style={{float: 'right', color: '#919191'}}
                                                            >
                                Rarity
                              </span>
                                                        )}
                                                    </div>
                                                    {info?.metadata.attributes.map((attribute, index) => {
                                                        return (
                                                            <li key={JSON.stringify(attribute)}>
                                <span>
                                  {attribute.trait_type}:{' '}
                                    <b>{attribute.value}</b>
                                </span>
                                                                {attributesCount[index] > 0 && totalCount > 0 && (
                                                                    <>
                                    <span
                                        style={{
                                            float: 'right',
                                            color: '#919191',
                                            fontWeight: 400,
                                        }}
                                    >
                                      {formatNumberString(
                                          (attributesCount[index] * 100) /
                                          totalCount,
                                          2
                                      )}{' '}
                                        %
                                    </span>
                                                                        <div>
                                      <span
                                          style={{
                                              width:
                                                  Math.floor(
                                                      (attributesCount[index] * 100) /
                                                      totalCount
                                                  ) + '%',
                                          }}
                                      ></span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </li>
                                                        );
                                                    })}
                                                </SkeletonComponent>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className='detailTrendingRight'>
                                    <div className='detailTrendingRight1'>
                                        <div className='dtLeft'>
                                            <div className='dtrow'>
                                                <div className='dtLeftbox'>
                                                    {!isModal && (
                                                        <Link
                                                            to='#'
                                                            onClick={() => {
                                                                isFans ? history.push('/fans'): history.goBack();
                                                            }}
                                                        >
                                                            <img src='/barrow.png' alt='aro_lft.png'/> Back
                                                        </Link>
                                                    )}

                                                    <h2>
                                                        <SkeletonComponent
                                                            count={1}
                                                            width={'100px'}
                                                            info={info}
                                                        >
                                                            {info?.metadata.name}
                                                        </SkeletonComponent>
                                                    </h2>
                                                    {creator && (
                                                        <p>
                                                            Creator:
                                                            <span>
                                <SkeletonComponent
                                    count={1}
                                    width={'50px'}
                                    info={info}
                                >
                                  {creator}
                                </SkeletonComponent>
                              </span>
                                                        </p>
                                                    )}
                                                    <div className='spaced'>
                                                        <ul>
                                                            <li hidden>
                                                                ID<b>{nftTokenId}</b>
                                                            </li>
                                                            {info?.metadata.project ? (
                                                                <li>
                                                                    Collection
                                                                    <b>
                                                                        <SkeletonComponent
                                                                            count={1}
                                                                            width={'50px'}
                                                                            info={info}
                                                                        >
                                                                            {info?.metadata.project}
                                                                        </SkeletonComponent>
                                                                    </b>
                                                                </li>
                                                            ) : (
                                                                info?.metadata.name.includes('#') && (
                                                                    <li>
                                                                        Collection
                                                                        <b>
                                                                            <SkeletonComponent
                                                                                count={1}
                                                                                width={'50px'}
                                                                                info={info}
                                                                            >
                                                                                {info?.metadata.name.split('#')[0]}
                                                                            </SkeletonComponent>
                                                                        </b>
                                                                    </li>
                                                                )
                                                            )}
                                                            {(info.isReward1 || info.isReward2) &&
                                                                info?.info.tier_index && (
                                                                        info?.metadata?.name.startsWith('WATR') ? <li>
                                                                            Metal Type
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {info?.info.tier_index === '1' ? 'Bronze' : info?.info.tier_index === '2' ? 'Silver' : info?.info.tier_index === '3' ? 'Gold' : ''}
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </li> : <li>
                                                                        Tier
                                                                        <b>
                                                                            <SkeletonComponent
                                                                                count={1}
                                                                                width={'50px'}
                                                                                info={info}
                                                                            >
                                                                                {info?.info.tier_index}
                                                                            </SkeletonComponent>
                                                                        </b>
                                                                    </li>
                                                                )}
                                                        </ul>
                                                    </div>
                                                    <div className='desc'>
                                                        <p>
                                                            <SkeletonComponent
                                                                count={1}
                                                                width={'200px'}
                                                                info={info}
                                                            >
                                                                {info?.metadata.description}
                                                            </SkeletonComponent>
                                                        </p>
                                                        {isOwner && (
                                                            <>
                                                                {isInStaking && (
                                                                    <p className='claim'>
                                                                        <div>
                                                                            <span>Claimable rewards: </span>
                                                                            {formatNumberString(
                                                                                info.claimableRewards1
                                                                            )}{' '}
                                                                            {
                                                                                commonConfig[networkType].TOKEN_SYMBOLS[
                                                                                    info.info.token1_addr
                                                                                    ]
                                                                            }
                                                                            {info.info.token2_addr && (
                                                                                <>
                                                                                    {' / '}
                                                                                    {formatNumberString(
                                                                                        info.claimableRewards2
                                                                                    )}{' '}
                                                                                    {
                                                                                        commonConfig[networkType]
                                                                                            .TOKEN_SYMBOLS[
                                                                                            info.info.token2_addr
                                                                                            ]
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {info.claimableRewards1.gt(0) ||
                                                                            info.claimableRewards2.gt(0) ? (
                                                                                <button
                                                                                    onClick={() => claimStakeHandler()}
                                                                                >
                                                                                    {' '}
                                                                                    Claim Now
                                                                                </button>
                                                                            ) : (
                                                                                <Countdown
                                                                                    date={info.claimableTime}
                                                                                    renderer={renderer}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </p>
                                                                )}
                                                                <div className='detailStakeSellButtons'>

                                                                    {/* Remove --stakeToolTip-- ToopTip When DEX trading begins*/}
                                                                    {!isInSale &&
                                                                        !isInAuction &&
                                                                        !isInStaking &&
                                                                        info.info.type === 'Reward' && (
                                                                            <span
                                                                                data-tip
                                                                                data-for={
                                                                                    nftInfo?.disabled
                                                                                        ? 'stakeToolTip'
                                                                                        : ''
                                                                                }
                                                                            >
                                        <button
                                            className='stake'
                                            disabled={nftInfo?.disabled}
                                            onClick={() => {
                                                if (isSuperLoopPower || lpStaked) {
                                                    setSuperLoopPowerModal(true);
                                                } else {
                                                    stakeHandler();
                                                }
                                            }}
                                        >
                                          <img src='/stakeB.svg' alt='button'/>{' '}
                                            STAKE
                                        </button>
                                      </span>
                                                                        )}
                                                                    {isInStaking && (
                                                                        <span>
                                      <button
                                          className='unstake'
                                          onClick={() => unStakeHandler()}
                                      >
                                        - UNSTAKE
                                      </button>
                                                                            {(info.claimableRewards1.gt(0) ||
                                                                                info.claimableRewards2.gt(0)) && (
                                                                                <button
                                                                                    onClick={() => claimStakeHandler()}
                                                                                    style={{background: '#32fe9a'}}
                                                                                >
                                                                                    CLAIM
                                                                                </button>
                                                                            )}
                                    </span>
                                                                    )}
                                                                    {!isInSale && !isInAuction && !isInStaking && (
                                                                        <span
                                                                            data-tip
                                                                            data-for={
                                                                                (nftInfo && !nftInfo.actions &&
                                                                                    info.mintConfig
                                                                                        ?.nft_current_supply !==
                                                                                    info.mintConfig?.nft_max_supply &&
                                                                                    date <= endDate) ||
                                                                                nftInfo?.disabled
                                                                                    ? 'saleAuctionToolTip'
                                                                                    : ''
                                                                            }
                                                                        >
                                      <button
                                          disabled={
                                              (nftInfo && !nftInfo.actions &&
                                                  info.mintConfig
                                                      ?.nft_current_supply !==
                                                  info.mintConfig?.nft_max_supply &&
                                                  date <= endDate) ||
                                              nftInfo?.disabled
                                          }
                                          onClick={() => setSellModal(true)}
                                      >
                                        <img src='/sellB.svg' alt='buttons'/>{' '}
                                          SELL
                                      </button>
                                    </span>
                                                                    )}
                                                                    {isInSale && (
                                                                        <span>
                                      <button
                                          onClick={() => cancelSellHandler()}
                                      >
                                        CANCEL SALE
                                      </button>
                                    </span>
                                                                    )}
                                                                    {!isInSale && !isInAuction && !isInStaking && (
                                                                        <span>
                                      <button
                                          onClick={() => setTransferModal(true)}
                                      >
                                        <img
                                            src='/transferB.svg'
                                            alt='button'
                                        />{' '}
                                          TRANSFER
                                      </button>
                                    </span>
                                                                    )}
                                                                    {!isInSale && !isInAuction && !isInStaking && (
                                                                        <span
                                                                            data-tip
                                                                            data-for={
                                                                                (nftInfo && !nftInfo.actions &&
                                                                                    info.mintConfig
                                                                                        ?.nft_current_supply !==
                                                                                    info.mintConfig?.nft_max_supply &&
                                                                                    date <= endDate) ||
                                                                                nftInfo?.disabled
                                                                                    ? 'saleAuctionToolTip'
                                                                                    : ''
                                                                            }
                                                                        >
                                      <button
                                          disabled={
                                              (nftInfo && !nftInfo.actions &&
                                                  info.mintConfig
                                                      ?.nft_current_supply !==
                                                  info.mintConfig?.nft_max_supply &&
                                                  date <= endDate) ||
                                              nftInfo?.disabled
                                          }
                                          onClick={() => setAuctionModal(true)}
                                      >
                                        <img src='/auctionB.svg' alt='button'/>{' '}
                                          AUCTION
                                      </button>
                                    </span>
                                                                    )}
                                                                    <span>
                                    {isInAuction &&
                                        ((info.auctionInfo?.end_time || 0) <=
                                        new Date().getTime() / 1000 ? (
                                            <button
                                                onClick={() => claimAuctionHandler()}
                                            >
                                                {info.auctionInfo
                                                    ?.high_bidder_addr ? (
                                                    <>
                                                        <img
                                                            src='/auctionB.svg'
                                                            alt='button'
                                                        />{' '}
                                                        CLAIM AUCTION
                                                    </>
                                                ) : (
                                                    'CANCEL AUCTION'
                                                )}
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => cancelAuctionHandler()}
                                            >
                                                CANCEL AUCTION
                                            </button>
                                        ))}
                                  </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    {(isInAuction || isInSale) && (
                                                        <div className='spacedPrice'>
                                                            {isInAuction && (
                                                                <div>
                                                                    <p>
                                                                        Start Price:{' '}
                                                                        <b>
                                                                            {formatNumberString(
                                                                                new Dec(
                                                                                    info?.auctionInfo?.min_amount
                                                                                ).div(1000000)
                                                                            )}{' '}
                                                                            {
                                                                                denomToSymbol[
                                                                                info?.auctionInfo?.coin_denom ||
                                                                                USDC_DENOM
                                                                                    ]
                                                                            }
                                                                        </b>
                                                                    </p>
                                                                    {new Dec(
                                                                        info?.auctionInfo?.high_bidder_amount
                                                                    ).gt(
                                                                        new Dec(info?.auctionInfo?.min_amount)
                                                                    ) && (
                                                                        <p>
                                                                            Highest Price:{' '}
                                                                            <b style={{color: '#32fc99'}}>
                                                                                {info?.auctionInfo?.high_bidder_addr ===
                                                                                address
                                                                                    ? 'You are the highest bidder @ '
                                                                                    : ''}
                                                                                {formatNumberString(
                                                                                    new Dec(
                                                                                        info?.auctionInfo?.high_bidder_amount
                                                                                    ).div(1000000)
                                                                                )}{' '}
                                                                                {
                                                                                    denomToSymbol[
                                                                                    info?.auctionInfo?.coin_denom ||
                                                                                    USDC_DENOM
                                                                                        ]
                                                                                }
                                                                            </b>
                                                                        </p>
                                                                    )}
                                                                    {info?.auctionInfo?.high_bidder_addr && (
                                                                        <p>
                                                                            Highest Bidder:{' '}
                                                                            <b>
                                                                                {info?.auctionInfo?.high_bidder_addr}
                                                                            </b>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {isInSale && (
                                                                <div>
                                                                    <p>
                                                                        Sell Price: {' '}
                                                                        <b>
                                                                            {formatNumberString(
                                                                                new Dec(info?.saleInfo?.amount).div(
                                                                                    1000000
                                                                                )
                                                                            )}{' '}
                                                                            {
                                                                                denomToSymbol[
                                                                                info?.saleInfo?.denom || USDC_DENOM
                                                                                    ]
                                                                            }
                                                                        </b>
                                                                    </p>
                                                                </div>
                                                            )}
                                                            {address && !isOwner && (
                                                                <div className='actions'>
                                                                    {isInAuction && (
                                                                        <>
                                                                            {(info.auctionInfo?.end_time || 0) >
                                                                                new Date().getTime() / 1000 && (
                                                                                    <button
                                                                                        className='secondaryBtn'
                                                                                        disabled={
                                                                                            new Dec(balance).lt(
                                                                                                new Dec(minBidPrice).div(1000000)
                                                                                            ) ||
                                                                                            new Dec(balance).lt(
                                                                                                new Dec(
                                                                                                    info?.auctionInfo?.min_amount
                                                                                                ).div(1000000)
                                                                                            )
                                                                                        }
                                                                                        onClick={() => {
                                                                                            setBidModal(true);
                                                                                        }}
                                                                                    >
                                                                                        Bid
                                                                                    </button>
                                                                                )}
                                                                            {(new Dec(balance).lt(
                                                                                    new Dec(minBidPrice).div(1000000)
                                                                                ) ||
                                                                                new Dec(balance).lt(
                                                                                    new Dec(
                                                                                        info?.auctionInfo?.min_amount
                                                                                    ).div(1000000)
                                                                                )) && (
                                                                                <div>
                                                                                    <a
                                                                                        href='https://juno.loop.markets/'
                                                                                        target='_blank'
                                                                                        rel='noreferrer'
                                                                                    >
                                                                                        <button className='addFunds'>
                                                                                            ADD FUNDS TO WALLET
                                                                                        </button>
                                                                                    </a>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {isInAuction &&
                                                                        (info.auctionInfo?.end_time || 0) <=
                                                                        new Date().getTime() / 1000 &&
                                                                        info.auctionInfo?.high_bidder_addr ===
                                                                        address && (
                                                                            <button
                                                                                className='stake'
                                                                                onClick={() => {
                                                                                    claimAuctionHandler();
                                                                                }}
                                                                            >
                                                                                CLAIM AUCTION
                                                                            </button>
                                                                        )}
                                                                    {!isInAuction && (
                                                                        <button
                                                                            className='primaryBtn'
                                                                            disabled={new Dec(balance).lt(
                                                                                new Dec(info?.saleInfo?.amount).div(
                                                                                    1000000
                                                                                )
                                                                            )}
                                                                            onClick={() => {
                                                                                buyNFTHandler();
                                                                            }}
                                                                        >
                                                                            Buy Now
                                                                        </button>
                                                                    )}
                                                                    {new Dec(balance).lt(
                                                                        new Dec(info?.saleInfo?.amount).div(1000000)
                                                                    ) && (
                                                                        <div>
                                                                            <a
                                                                                href='https://juno.loop.markets/'
                                                                                target='_blank'
                                                                                rel='noreferrer'
                                                                            >
                                                                                <button className='addFunds'>
                                                                                    ADD FUNDS TO WALLET
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {(info.isReward1 || info.isReward2) && (
                                                        <div className='spacedRewards'>
                                                            <h6>
                                                                <img src={rewards} alt=''/> Rewards
                                                            </h6>
                                                            <table>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    {info.isReward1 && (
                                                                        <th>
                                                                            <img src={rewardGrey} alt=''/> Reward{' '}
                                                                            {info.isReward2 ? '1' : ''}
                                                                        </th>
                                                                    )}
                                                                    {info.isReward2 && (
                                                                        <th>
                                                                            <img src={rewardGrey} alt=''/> Reward{' '}
                                                                            {info.isReward1 ? '2' : '1'}
                                                                        </th>
                                                                    )}
                                                                </tr>
                                                                <tr>
                                                                    <td>Staking Value</td>
                                                                    {info.isReward1 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    $
                                                                                    {formatNumberString(
                                                                                        info?.totalValue1.div(1000000)
                                                                                    )}{' '}
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                    {info.isReward2 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    $
                                                                                    {formatNumberString(
                                                                                        info?.totalValue2.div(1000000)
                                                                                    )}{' '}
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                <tr>
                                                                    <td>Total Rewards</td>
                                                                    {info.isReward1 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {formatNumberString(
                                                                                        new Dec(info?.info.token1_amount)
                                                                                            .mul(
                                                                                                info?.info.vesting_period || '0'
                                                                                            )
                                                                                            .div(1000000)
                                                                                    )}{' '}
                                                                                    {
                                                                                        commonConfig[networkType]
                                                                                            ?.TOKEN_SYMBOLS[
                                                                                        info?.info.token1_addr || '0'
                                                                                            ]
                                                                                    }
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                    {info.isReward2 && (
                                                                        <td>
                                                                            {' '}
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {formatNumberString(
                                                                                        new Dec(info?.info.token2_amount)
                                                                                            .mul(
                                                                                                info?.info.vesting_period || '0'
                                                                                            )
                                                                                            .div(1000000)
                                                                                    )}{' '}
                                                                                    {
                                                                                        commonConfig[networkType]
                                                                                            ?.TOKEN_SYMBOLS[
                                                                                        info?.info.token2_addr || '0'
                                                                                            ]
                                                                                    }
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                <tr>
                                                                    <td>Daily</td>
                                                                    {info.isReward1 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {formatNumberString(
                                                                                        new Dec(
                                                                                            info?.info.token1_amount?.toLocaleString()
                                                                                        ).div(1000000)
                                                                                    )}{' '}
                                                                                    {
                                                                                        commonConfig[networkType]
                                                                                            ?.TOKEN_SYMBOLS[
                                                                                        info?.info.token1_addr || '0'
                                                                                            ]
                                                                                    }
                                                                                    /day
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                    {info.isReward2 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {formatNumberString(
                                                                                        new Dec(
                                                                                            info?.info.token2_amount
                                                                                        ).div(1000000)
                                                                                    )}{' '}
                                                                                    {
                                                                                        commonConfig[networkType]
                                                                                            ?.TOKEN_SYMBOLS[
                                                                                        info?.info.token2_addr || '0'
                                                                                            ]
                                                                                    }
                                                                                    /day
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                <tr>
                                                                    <td>Days Staked</td>
                                                                    {info.isReward1 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {info?.daysStaked} Days
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                    {info.isReward2 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {info?.daysStaked} Days
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                <tr>
                                                                    <td>Days Remaining</td>
                                                                    {info.isReward1 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {info?.daysRemain} Days
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                    {info.isReward2 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {info?.daysRemain} Days
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                <tr>
                                                                    <td>Percentage Remaining</td>
                                                                    {info.isReward1 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {info &&
                                                                                        Math.floor(
                                                                                            (info?.daysRemain * 100) /
                                                                                            parseInt(
                                                                                                info?.info.vesting_period
                                                                                            )
                                                                                        )}
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                    {info.isReward2 && (
                                                                        <td>
                                                                            <b>
                                                                                <SkeletonComponent
                                                                                    count={1}
                                                                                    width={'50px'}
                                                                                    info={info}
                                                                                >
                                                                                    {info &&
                                                                                        Math.floor(
                                                                                            (info?.daysRemain * 100) /
                                                                                            parseInt(
                                                                                                info?.info.vesting_period
                                                                                            )
                                                                                        )}
                                                                                </SkeletonComponent>
                                                                            </b>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ActivityTable modal={isModal} params={params}/>
                        </div>

                        <Modal
                            isOpen={sellModal}
                            title=''
                            onClose={() => setSellModal(false)}
                        >
                            <div className='SellAuction'>
                                <h6>Sell NFT</h6>
                                <div className='aucHub'>
                                    <section>
                    <span>
                      <img
                          src={getIpfsUrl(info?.metadata.image, true)}
                          alt=''
                      />
                    </span>
                                    </section>
                                    <div className='auc_split'>
                                        <div className='AucInput'>
                                            <h5>{info?.metadata.name}</h5>
                                            <label>Set Price</label>
                                            <div className="stylesell">
                                                <div className={"stylesell_dropdown"}>
                                                    <SelectBox
                                                        options={tokenTypesOptions}
                                                        onChange={(e: any) => setSellType(e)}
                                                        value={sellType}
                                                        placeholder={'Select Token'}
                                                    />
                                                </div>
                                                <div className="stylesellInput">
                                                    <input
                                                        type='number'
                                                        placeholder='0.00'
                                                        value={salePrice}
                                                        onChange={e => {
                                                            setSalePrice(e.target.value);
                                                        }}
                                                        onBlur={e => {
                                                            setSalePrice(
                                                                (parseFloat(e.target.value) > maxSalePrice
                                                                        ? maxSalePrice
                                                                        : parseFloat(e.target.value) || 0.0
                                                                ).toString()
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <b>
                                                Royalty Fee:{' '}
                                                <i>
                                                    {Math.floor(((info?.royalty_bp || 0) * 100) / 10000)}%
                                                </i>
                                            </b>{' '}
                                            |{' '}
                                            <b>
                                                Platform Fee: <i>2%</i>
                                            </b>
                                        </p>
                                        <button onClick={() => listSellHandler("sell")}>CONFIRM</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={transferModal}
                            title=''
                            onClose={() => setTransferModal(false)}
                        >
                            <div className='SellAuction'>
                                <h6>Transfer NFT</h6>
                                <div className='aucHub'>
                                    <section>
                    <span>
                      <img
                          src={getIpfsUrl(info?.metadata.image, true)}
                          alt=''
                      />
                    </span>
                                    </section>
                                    <div className='auc_split'>
                                        <div className='TransferInput'>
                                            <h5>{info?.metadata.name}</h5>
                                            <label>Recipient</label>
                                            <input
                                                type='text'
                                                placeholder=''
                                                value={recipient}
                                                onChange={e => {
                                                    setRecipient(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <button
                                            className='cnfrmTransfer'
                                            onClick={() => transferHandler()}
                                        >
                                            CONFIRM
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={bidModal}
                            title=''
                            onClose={() => setBidModal(false)}
                        >
                            <div className='SellAuction'>
                                <h6>Bid</h6>
                                <div className='aucHub'>
                                    <section>
                    <span>
                      <img
                          src={getIpfsUrl(info?.metadata.image, true)}
                          alt=''
                      />
                    </span>
                                    </section>
                                    <div className='auc_split'>
                                        <div className='AucInput'>
                                            <h5>{info?.metadata.name}</h5>
                                            <label>
                                                Bid{' '}
                                                <label>
                                                    Minimum Bid Price:{' '}
                                                    <span
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {
                                                            setBidPrice(minBidPrice);
                                                        }}
                                                    >
                            {formatNumberString(minBidPrice, 4)}
                                                        &nbsp;
                                                        <i>{denomToSymbol[
                                                        info?.auctionInfo?.coin_denom ||
                                                        USDC_DENOM
                                                            ]}</i>
                          </span>
                                                </label>
                                                <b>{denomToSymbol[
                                                info?.auctionInfo?.coin_denom ||
                                                USDC_DENOM
                                                    ]}</b>
                                            </label>
                                            <input
                                                type='number'
                                                placeholder='0.00'
                                                value={bidPrice}
                                                onChange={e => {
                                                    setBidPrice(e.target.value);
                                                }}
                                                onFocus={e => {
                                                    if (parseFloat(bidPrice) === 0) {
                                                        setBidPrice('');
                                                    }
                                                    setBidInputAlertText('');
                                                }}
                                                onBlur={e => {
                                                    if (
                                                        (parseFloat(e.target.value) || 0.0) <
                                                        parseFloat(minBidPrice)
                                                    ) {
                                                        setBidPrice(minBidPrice);
                                                        setBidInputAlertText('Min Bid Price has been set');
                                                    } else
                                                        setBidPrice(
                                                            (parseFloat(e.target.value) || 0.0).toString()
                                                        );
                                                }}
                                            />
                                            <img height="20px" width={"20px"} src={denomToSymbol[
                                            info?.auctionInfo?.coin_denom ||
                                            USDC_DENOM
                                                ] === "USDC" ? '/USDClogo.png' : denomToSymbol[
                                            info?.auctionInfo?.coin_denom ||
                                            USDC_DENOM
                                                ] === "JUNO" ? "/junoicon.svg" : "/atom_icon.svg"} alt=''/>
                                            <b>{denomToSymbol[
                                            info?.auctionInfo?.coin_denom ||
                                            USDC_DENOM
                                                ]}</b>
                                        </div>
                                        <div style={{fontSize: '12px'}}>{bidInputAlertText}</div>
                                        <button
                                            disabled={
                                                parseFloat(bidPrice === '' ? '0' : bidPrice) <
                                                parseFloat(minBidPrice)
                                            }
                                            className={
                                                parseFloat(bidPrice === '' ? '0' : bidPrice) <
                                                parseFloat(minBidPrice)
                                                    ? 'disabled'
                                                    : ''
                                            }
                                            onClick={() => bidAuctionHandler()}
                                        >
                                            CONFIRM
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={auctionModal}
                            title=''
                            onClose={() => setAuctionModal(false)}
                        >
                            <div className='SellAuction'>
                                <h6>Auction NFT</h6>
                                <div className='aucHub'>
                                    <section>
                    <span>
                      <img
                          src={getIpfsUrl(info?.metadata.image, true)}
                          alt=''
                      />
                    </span>
                                    </section>
                                    <div className='auc_split'>
                                        <div className='AucInput auctionSet'>
                                            <h5>{info?.metadata.name}</h5>
                                            <label>
                                                Min Bid{' '}
                                                <img
                                                    src='/si.png'
                                                    alt=''
                                                    data-tip={'Minimum Price Paid for NFT'}
                                                />
                                            </label>
                                            <div className="stylesell">
                                                <div className={"stylesell_dropdown"}>
                                                    <SelectBox
                                                        options={tokenTypesOptions}
                                                        onChange={(e: any) => setAuctionType(e)}
                                                        value={auctionType}
                                                        placeholder={'Select Token'}
                                                    />
                                                </div>
                                                <div className="stylesellInput">
                                                    <input
                                                        type='number'
                                                        placeholder='0.00'
                                                        value={auctionOption.amount}
                                                        onChange={e => {
                                                            setAuctionOption({
                                                                ...auctionOption,
                                                                amount: e.target.value,
                                                            });
                                                        }}
                                                        onBlur={e => {
                                                            setAuctionOption({
                                                                ...auctionOption,
                                                                amount: (
                                                                    parseFloat(e.target.value) || 0.0
                                                                ).toString(),
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='expireDate'>
                                            <label>
                                                Duration
                                                <span>
                          <input
                              type='number'
                              placeholder='0'
                              min='0'
                              value={
                                  auctionOption.days > 99 ? 99 : auctionOption.days
                              }
                              onChange={e => {
                                  const newDay = parseInt(e.target.value) || 0;
                                  setAuctionOption({
                                      ...auctionOption,
                                      days: newDay > 99 ? 99 : newDay,
                                  });
                              }}
                          />
                          Days
                        </span>
                                            </label>
                                            <div className='expireDateTiming'>
                                                <label>At</label>
                                                <span>
                          <div>
                            <input
                                type='text'
                                placeholder='0'
                                value={auctionOption.hour}
                                onChange={e => {
                                    const hh = parseInt(e.target.value) || 0;
                                    setAuctionOption({
                                        ...auctionOption,
                                        hour: hh > 11 ? 11 : hh,
                                    });
                                }}
                            />{' '}
                              H
                          </div>
                          <div>
                            <input
                                type='text'
                                placeholder='0'
                                value={auctionOption.minute}
                                onChange={e => {
                                    const min = parseInt(e.target.value) || 0;
                                    setAuctionOption({
                                        ...auctionOption,
                                        minute: min > 59 ? 59 : min,
                                    });
                                }}
                            />
                            M
                          </div>
                          <AMPMSelect
                              options={amPmOptions}
                              onChange={(e: any) => {
                                  setAuctionOption({
                                      ...auctionOption,
                                      amPm: e,
                                  });
                              }}
                              value={auctionOption.amPm}
                              placeholder={'Sort'}
                          />
                        </span>
                                            </div>
                                            <section className='puaction'>
                                                <p>Auction will end on</p> {getAuctionEndTimeString()}
                                                <img
                                                    src='/si.png'
                                                    alt=''
                                                    data-tip={getAuctionEndTimeStringUTC()}
                                                />
                                                <ReactTooltip/>
                                            </section>
                                        </div>
                                        <p className='mtop'>
                                            <b>
                                                Royalty Fee:{' '}
                                                <i>
                                                    {Math.floor(((info?.royalty_bp || 0) * 100) / 10000)}%
                                                </i>
                                            </b>{' '}
                                            |{' '}
                                            <b>
                                                Platform Fee: <i>2%</i>
                                            </b>
                                        </p>
                                        <button onClick={() => listAuctionHandler("auction")}>
                                            CONFIRM
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={superLoopPowerModal}
                            title=''
                            onClose={() => setSuperLoopPowerModal(false)}
                            customClass='super_loop_power_modal'
                        >
                            <div className='SellAuction'>
                                <div className='super_loop_power_dialog'>
                                    {!lpStaked ? (
                                        <>
                                            <h2>Good News!</h2>
                                            <span>Your NFT is eligible for 2 types of staking! </span>
                                            <span>
                      <b>1.</b> Stake here to receive your daily LOOP allocation & loose the SUPER LOOP Power attribute 
                    </span>
                                            <span>
                      <b>2.</b> Harness the LOOP Power from this NFT <a
                                                href="https://juno.loop.markets/stake?tab=STAKING#stake" target="_blank"
                                                rel="noreferrer">here</a> to boost your LOOP Power rewards + voting power on the Community DAO and Gauges
                    </span>
                                            <span>
                      Important: If you only do option 2 you will receive 12x LOOP Power for each LOOP in your NFT. If you do option 1 first then option 2: you will only receive 3x LOOP Power for each remaining LOOP in your NFT.
                    </span>

                                            <div>
                                                <button
                                                    onClick={() => {
                                                        setSuperLoopPowerModal(false);
                                                    }}
                                                >
                                                    CLOSE
                                                </button>
                                                <button onClick={() => stakeHandler()}>STAKE ANYWAY</button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h2 style={{marginTop: "20%"}}>Already Staked</h2>
                                            <span>
                        You are currently staking this NFT to boost your LOOP Power! First unstake
                        on the <a href='https://juno.loop.markets/stake?tab=STAKING#stake' target='_blank'>LOOP Power Dashboard</a> to be able to stake here
                      </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Modal>
                    </div>
                </>
                {!location.pathname.startsWith('/webapp') && !isModal && <NftFooter/>}
            </div>
        </>
    );
};

export default NftExploreItemDetails;
