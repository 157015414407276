import { useRecoilValue } from 'recoil';
import { walletState, WalletStatusType } from '../../state/walletAtoms';
import { useHistory } from "react-router-dom";
import profile from '../../assets/images/profile.png';
import { junoAddress } from 'core/utils';

const WebAppHeader = (props:any) => {
  const history = useHistory();
  const { address, status } = useRecoilValue(walletState);

  return (
    <section>
      <div
      className='mynftRight'
      style={{
         display: 'flex',
         flexDirection: 'column',
      }}
      >
         <div>
            <div className='header'>
               {props.history ? (
                  <div
                     className='webapp_back'
                     onClick={() => history.push(`/webapp/${props.history ? props.history : ''}`)}
                  >
                     <img src='/webapp_back.svg' alt='aro_lft.png' style={{marginRight: '10px'}}/>
                  </div>
               ) : (
                  <div />
               )}
               {address && (
                  <div className="webapp_walletconnected" style={{display: 'flex'}}>
                     <div>
                        <p style={{ fontSize: '12px', color: 'rgba(246, 245, 255, 0.6)', textAlign: 'right'}}>Wallet</p>
                        <p style={{ fontSize: '14px'}}>{junoAddress(address)}</p>
                     </div>
                     <img src={profile} alt='profile' className="webapp_profile" />
                  </div>
               )}
            </div>
         </div>
      </div>
    </section>
  );
};

export default WebAppHeader;
