import {Network} from './constants';

export type LaunchPadInfo = {
    MINTER?: string;
    logo: string;
    icon: string;
    name: string;
    description?: string;
    status: 'live' | 'upcoming' | 'completed';
    website: string;
    twitter: string;
    discord: string;
    mintPrice?: string;
    startDate: string;
    startTime: string;
    publicDate?: any;
    publicTime?: any;
    publicMintStartTime?: string;
    endDate: string;
    endTime: string;
    cirSupply?: number;
    maxSupply?: number;
};

export type ProjectInfo = {
    primary_usecase: string;
    additional_usecase: string;
    pre_mint: number;
    max_supply: number;
    founder_status: string;
    community_wallet: string;
};

export type LaunchPadNFT = {
    [x: string]: any;
    CREATOR: string;
    MINTER: string;
    NFT: string;
    url?: string;
    launchInfo: LaunchPadInfo;
    projectInfo?: ProjectInfo;
    disabled: boolean;
};

export type LPNFT = {};

export type NFTConfig = {
    launchPadNFTs: LaunchPadNFT[];
    lpNFTs: LPNFT[];
    STAKING: string;
    AUCTION: string;
    SALE: string;
};

export const nftConfig: Record<Network, NFTConfig> = {
    [Network.MAINNET]: {
        launchPadNFTs: [
            {
                CREATOR: 'Tradooors',
                NFT: 'juno1qnmgent6fmw8rufz978rhmzfgmvme9gvyj0t7rkpffklvwr9tteste35lg',
                url: 'tradooors',
                MINTER:
                    'juno1799tpjfjesxvydvutk53dy9a2tzlf2ugc35tzfzlqxwcq6cyg2eswje7fj',
                launchInfo: {
                    logo: '/tradooors_banner.jpg',
                    icon: '/tradooors_icon.jpg',
                    name: 'Tradooors',
                    status: 'live',
                    website: 'https://medium.com/@tradooorsnft',
                    twitter: 'https://twitter.com/TradooorsNFT',
                    discord: 'https://discord.gg/n6EWBtf4US',
                    mintPrice: '$60',
                    startDate: '16 Aug 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '17 Aug 2022 01:00:00 UTC',
                    endDate: '30 Sep 2022',
                    endTime: '01:00:00 UTC',
                    maxSupply: 5678,
                    description:
                        'Tradooors is an original pixel art collection celebrating the age of crypto traders, Anons, Interns, NFT collectors, and all-around degens pushing Web3 and crypto adoption while sitting behind their laptops in their bedrooms and basements. Bringing together unique utility and skill-based games for a fun, rewarding experience for all holders.',
                },
                projectInfo: {
                    primary_usecase: 'Gaming',
                    additional_usecase: 'Art, Reward',
                    pre_mint: 178,
                    max_supply: 5678,
                    founder_status: 'Publicly pseudonymous',
                    community_wallet: 'Coming Soon!',
                },
                disabled: false,
            },
            {
                CREATOR: 'Aqua and Casa',
                NFT: 'juno14z2x9druc74a4z4wfvdujudfg8snp30rpa6yfxeax8hwr5dtjugsjt9qpu',
                url: 'los-amigos',
                MINTER:
                    'juno17qwc3j4hd9eeqdkpq3jp7ajs69zdl632wtpl2364waqgsgjg9kes8dwlx5',
                launchInfo: {
                    logo: '/losamigos_banner.webp',
                    icon: '/losamigos_icon.webp',
                    name: 'Los Amigos by Aqua & Casa',
                    status: 'live',
                    website: 'https://medium.com/@AquaOnJuno',
                    twitter: 'https://twitter.com/AquaOnJuno',
                    discord: 'https://discord.gg/F73qKqQDcm',
                    mintPrice: '$75',
                    startDate: '29 Aug 2022',
                    startTime: '18:00:00 UTC',
                    publicMintStartTime: '29 Aug 2022 21:00:00 UTC',
                    endDate: '27 Nov 2022',
                    endTime: '18:00:00 UTC',
                    maxSupply: 1000,
                    description:
                        'Los Amigos NFTs are your key to unlocking multiple benefits throughout the Aqua Social Impact Ecosystem! In addition to receiving $CASA and $AQUA tokens, owners of Los Amigos NFTs will receive tokens of all upcoming Aqua Social Impact DAOs, pre-IDO swap access, automatic whitelisting, and discounts for upcoming NFTs, plus more! Get yours today and join us on our journey to create real social impact!',
                },
                projectInfo: {
                    primary_usecase: 'Reward',
                    additional_usecase: 'Charity, DAO',
                    pre_mint: 0,
                    max_supply: 1000,
                    founder_status: 'Publicly Known',
                    community_wallet: 'cosmos14awtxw9dmtg3yr7s506q2sucmlkh9fvx59yuf4',
                },
                disabled: false,
            },
            {
                CREATOR: 'NetaDAO',
                NFT: 'juno1s0s2nfrpfdqk3epatmv047wk0ljecfaxw2cn3wq80mpl6tlxuf5sfu5rw4',
                url: 'neta-trooprs',
                MINTER:
                    'juno14uv4nkeafxdc7hn2s8aweenjpvd8jpuw4ef8wrgpl8wg0yutrefqm3vps8',
                launchInfo: {
                    logo: '/netatrooprs_banner.png',
                    icon: '/netatrooprs_icon.png',
                    name: 'NETA Trooprs',
                    status: 'live',
                    website: 'https://netadao.zone/',
                    twitter: 'https://twitter.com/Neta_DAO',
                    discord: 'https://discord.gg/kyKY6F4Z6a',
                    mintPrice: '6 ATOM',
                    startDate: '8 Sep 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '8 Sep 2022 15:00:00 UTC',
                    endDate: '8 Oct 2022',
                    endTime: '15:00:00 UTC',
                    maxSupply: 3300,
                    description:
                        'Made by Rendrasc to support the Neta Community and those who sacrificed their 0.2 NETA to the LP. NETA Trooprs will donate 80% of these mint proceeds to the Neta DAO to help fund their mission to accelerate the growth of teams and dApps on the Juno Network! Strength and Honor!',
                },
                projectInfo: {
                    primary_usecase: 'Collectible',
                    additional_usecase: 'Art, Reward',
                    pre_mint: 80,
                    max_supply: 3300,
                    founder_status: 'Publicly anonymous',
                    community_wallet:
                        'juno10l39ctnue4muf3fh4cmdcyr53c5h23vg6n4dyu0syx3lvkgwhlkqwkm6xz',
                },
                disabled: false,
            },
            {
                CREATOR: 'Loop Events',
                NFT: 'juno1zzcugrt4x30vu0qxg765rnxkxm6lru6cwe0nvcl90ctc9k4cwvksx8tcew',
                url: 'cosmoverse',
                MINTER:
                    'juno1m7qmz49a9g6zeyzl032aj3rnsu856893cwryx8c4v2gf2s0ewv8qvtcsmx',
                launchInfo: {
                    logo: '/cosmoverse_banner.png',
                    icon: '/cosmoverse_icon.png',
                    name: 'Cosmoverse',
                    status: 'live',
                    website: 'http://events.loop.markets/',
                    twitter: 'https://twitter.com/loop_finance',
                    discord: 'https://discord.com/invite/loopfinance',
                    mintPrice: '5 USDC',
                    startDate: '20 Sep 2022',
                    startTime: '17:00:00 UTC',
                    publicMintStartTime: '20 Sep 2022 17:00:00 UTC',
                    endDate: '28 Sep 2022',
                    endTime: '17:00:00 UTC',
                    maxSupply: 1500,
                    description:
                        'Your Cosmic 5 Superhero Ape is your ticket to the Cosmic 5 Party. The party will kick off at 8pm on the 27th of September, day 2 of the Cosmoverse Conference in Medellin and will be held at The Garden Bar in El Poblado, Medellin. Holding a Superhero Ape will get you access to the party, as well as receiving a reward of a free drink and a chance to win a bottle! Get your ticket and see you at the party!',
                },
                projectInfo: {
                    primary_usecase: 'Event Ticket',
                    additional_usecase: 'Reward, Select',
                    pre_mint: 201,
                    max_supply: 1500,
                    founder_status: 'Publicly pseudonymous',
                    community_wallet: '',
                },
                disabled: false,
            },
            {
                CREATOR: 'Sky Mons',
                NFT: 'juno1l3aygqg8en2hh396wph6he4x05ujkm6vc48sf0c34ema0mm24d5qdhn00s',
                url: 'cosmic-sky-monks',
                MINTER:
                    'juno167v5rm7sfrtl4hvud2w2wg2ejkyxkeym7t69y7hh7pftug6rusxq9xe20n',
                launchInfo: {
                    logo: '/cosmic_sky_monks_logo.jpg',
                    icon: '/cosmic_sky_monks_icon.png',
                    name: 'juno1l3aygqg8en2hh396wph6he4x05ujkm6vc48sf0c34ema0mm24d5qdhn00s',
                    status: 'live',
                    website: '#',
                    twitter: '#',
                    discord: '#',
                    mintPrice: '37 JUNO',
                    startDate: '28 Apr 2023',
                    startTime: '16:00:00 UTC',
                    publicMintStartTime: '29 Apr 2023 16:00:00 UTC',
                    endDate: '28 Oct 2023',
                    endTime: '16:00:00 UTC',
                    maxSupply: 2900,
                    description:
                        "Sky Monks Second Generation. Bringing the cosmic soundscape to your NFTs. Cosmo's first generative music NFT collection. The Cosmic Sky Monks are a band of warriors bound by a common goal. Join us on this journey of shaping their reality..",
                },
                projectInfo: {
                    primary_usecase: 'Art',
                    additional_usecase: 'Collectible, Reward',
                    pre_mint: 30,
                    max_supply: 2900,
                    founder_status: 'Publicly Known',
                    community_wallet:
                        'juno1vnnrf7n83n7uncdpwycfdmp2spgvrefxma4wtu8wnq2l063n4hnqcxecaj',
                },
                disabled: true,
            },
            {
                CREATOR: 'ATLAS DAO',
                NFT: 'juno1kpsy0mh58fzrl973ndvppujs9ea5xe9wggjhhg07c42l0yqk5n7st5800h',
                url: 'atlas',
                MINTER:
                    'juno1xw99es9dwshk4hak3g9hajkppqu4lwnahzs0lkyd6zq7amj23pjqd7pnte',
                launchInfo: {
                    logo: '/cosmic_sky_monks_logo.jpg',
                    icon: '/cosmic_sky_monks_icon.png',
                    name: 'Atlas',
                    status: 'live',
                    website: 'https://dao.daodao.zone/juno1hcldlknu2mn3exckkg75tyzjnderl95zyjte2wl495z9jla0rmdqegxlxx#',
                    twitter: 'https://twitter.com/atlasdao_',
                    discord: 'https://discord.gg/kfqwdw9Apw',
                    mintPrice: '37 JUNO',
                    startDate: '28 Apr 2023',
                    startTime: '16:00:00 UTC',
                    publicMintStartTime: '29 Apr 2023 16:00:00 UTC',
                    endDate: '30 Apr 2023',
                    endTime: '16:00:00 UTC',
                    maxSupply: 2900,
                    description:
                        "Atlas NFT is Cosmos' first generative music NFT, backed by 30 Juno. Atlas NFT is the governance of Atlas DAO, which aims to create sustainable value and passive yield for its members. The mission of Atlas DAO is to foster a thriving ecosystem that benefits all stakeholders, while also making a positive impact through charitable donations and volunteer work.",
                },
                projectInfo: {
                    primary_usecase: 'Art',
                    additional_usecase: 'Collectible, Reward',
                    pre_mint: 30,
                    max_supply: 2900,
                    founder_status: 'Publicly Known',
                    community_wallet:
                        'juno1hcldlknu2mn3exckkg75tyzjnderl95zyjte2wl495z9jla0rmdqegxlxx',
                },
                disabled: false,
            },
            {
                CREATOR: 'Sunnyside Reapers',
                NFT: 'juno1xhdu25f3a2qrntyq06m4mk733p06cf0x5lu67mfu3nt6dvgepc2sm4k5e3',
                url: 'sunnyside-island',
                MINTER:
                    'juno1nhavnz7tgttlvje39pnulya72aej6z4g0qkrhzdt4rltnm0vfmyqmq3geq',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/20aec179-064d-4d9f-aa3b-d72e9ab4db47',
                    icon: 'https://loop-markets.directus.app/assets/b1735c0f-6510-4dcf-ac6f-d8fbd847c2de',
                    name: 'Sunnyside Island',
                    status: 'live',
                    website: 'https://ssr.rip/',
                    twitter: 'https://twitter.com/SunnysideReaper',
                    discord: 'https://discord.gg/GFYrJEsYpm',
                    mintPrice: '6.66 JUNO',
                    startDate: '4 Oct 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '4 Oct 2022 23:00:00 UTC',
                    endDate: '22 Oct 2022',
                    endTime: '15:00:00 UTC',
                    maxSupply: 666,
                    description: `666 sun-bleached spirits who celebrate the absurdity of life and death. A collection by the Sunnyside Reapers. Go fishing for exclusive reward tokens like $LOOP, $GLTO, and even the elusive $GKEY. Each mint also earns one free Sunnyside Reaper on Stargaze (by request: ssr.rip/Discord). Keep an eye out for our upcoming Non-P2E NFT card game, “Sunnyside Cemetery” estimated release date January 2023. 
 
            1 $GKEY (Current value ~$500-600) - .25 GKEY attached to 4 Golden Gelotto Cone NFTs which we will manually send to the address that mints these  
            ~ALL NFTs are BOGO eligible for a Standard Sunnyside Reaper from our original Stargaze Collection. Join our Discord and submit a ticket in order to claim! Reward Details 
             
            Let's Go Fishing! 
            Specific Golden Traits in Sunnyside Island are the traits you are looking for! Minting one of these golden assets (such as a golden Gelotto cone, or The Golden Whale) will reward you with the higher tiered rewards or part of the elusive $GKEY! `,
                },
                projectInfo: {
                    primary_usecase: 'Art',
                    additional_usecase: 'Reward, Select',
                    pre_mint: 15,
                    max_supply: 666,
                    founder_status: 'Publicly Known',
                    community_wallet: '',
                },
                disabled: false,
            },
            {
                CREATOR: 'Cute Crypto Girls',
                NFT: 'juno1cw6qdr5t2c87vh39rjrcj4a6djh863zn4q9sr47wrcsguzs803ysydydcq',
                url: 'loopy-crypto-girls',
                MINTER:
                    'juno1q25auphatpz3uq2z5tg3ah46g3lhnz67syvyp6t23kmjcll59yvqt5wht6',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/524db8dd-68f7-4972-b3c2-cd31681e85df',
                    icon: 'https://loop-markets.directus.app/assets/617f864f-d482-4243-9d40-e18a206cd6a2',
                    name: 'Loopy Crypto Girls',
                    status: 'live',
                    website: '',
                    twitter: 'https://twitter.com/CuteCryptoGirls',
                    discord: 'https://t.co/ketsXItNtY',
                    mintPrice: '2.75 JUNO',
                    startDate: '17 Oct 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '17 Oct 2022 15:00:00 UTC',
                    endDate: '17 Nov 2022',
                    endTime: '15:00:00 UTC',
                    maxSupply: 1111,
                    description: `Loopy Crypto Girls is all about being curious, exploring and enjoying the crypto life. First of a kind PFPs for Cosmonauts. Gift an NFT to your partner or a girl in your life to onboard them to crypto & web3.`,
                },
                projectInfo: {
                    primary_usecase: 'Art',
                    additional_usecase: 'Art, Collectible',
                    pre_mint: 25,
                    max_supply: 1111,
                    founder_status: 'Publicly pseudonymous',
                    community_wallet: '',
                },
                disabled: false,
            },
            {
                CREATOR: 'Juno Bear Society',
                NFT: 'juno1s8qkx4xpwrd4jqyzpk6cyk8lnm6xhx23dk6tjflxqmgas6jh5zuq8hkk2w',
                url: 'juno-bear-society',
                MINTER:
                    'juno1lelq7pmjnp4qtar54wp9k5k7xvwpjce42aushpc3r7k4haefcneqnrp503',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/5d1faaa6-88f1-49eb-9797-2779dbea487e',
                    icon: 'https://loop-markets.directus.app/assets/62b97e0f-a62e-481e-9fed-725d6e3c678a',
                    name: 'Juno Bear Society',
                    status: 'live',
                    website: '',
                    twitter: 'https://twitter.com/JunoBearSociety',
                    discord: 'https://discord.gg/P9hPPYe6',
                    mintPrice: '9 JUNO',
                    startDate: '21 Oct 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '17 Oct 2022 16:00:00 UTC',
                    endDate: '28 Nov 2022',
                    endTime: '16:00:00 UTC',
                    maxSupply: 555,
                    description: `Juno Bear Society(JBS) is a private NFT investment DAO. All mint proceeds will be used to trade NFTs. JBS holders will discuss & vote on decisions via DAODAO involving where the mint proceeds will be spent, which NFTs will be purchased, and when NFTs owned by the DAO will be sold. 70% of trading profits will be redistributed to JBS holders on a weekly basis. DAO members are granted entry to the DAO after minting a JBS NFT.`,
                },
                projectInfo: {
                    primary_usecase: 'Social Club Membership',
                    additional_usecase: 'Reward, Art',
                    pre_mint: 20,
                    max_supply: 555,
                    founder_status: 'Publicly pseudonymous',
                    community_wallet:
                        'juno1nx6j47s28m9l2cfn9edjcpnftxcp9gr6mm4h62pztdtplleuaawql50q3e',
                },
                disabled: false,
            },
            {
                CREATOR: 'Rekt Bulls',
                NFT: 'juno1xwcwnfh9gjqvchl2danhvzsl0h94hrnvuptwajfd3kwx4q9es2gs5uvpj4',
                url: 'rekt-bulls',
                MINTER:
                    '',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/42f868f8-9d83-41fa-ba90-b6f8fef71981',
                    icon: 'https://loop-markets.directus.app/assets/8c541294-11d7-4f03-bf6f-4e6cafff59ef',
                    name: 'Rekt Bulls',
                    status: 'live',
                    website: 'https://rektgang.io/',
                    twitter: 'https://twitter.com/rekt_gang',
                    discord: 'https://discord.gg/6TzSTkRvV5',
                    mintPrice: '50 USDC',
                    startDate: '27 Oct 2022',
                    startTime: '07:00:00 UTC',
                    publicMintStartTime: '27 Oct 2022 07:00:00 UTC',
                    endDate: '27 Oct 2022',
                    endTime: '07:00:00 UTC',
                    maxSupply: 2269,
                    description:
                        `Got Rekt in crypto because you were too bullish on the “tech”. Join the Rekt Bulls, an omnichain NFT collection of 2269 Rekt Bulls that roam through the Cosmos. Part of the infamous Rekt Gang.`,
                },
                projectInfo: {
                    primary_usecase: 'DAO',
                    additional_usecase: 'Art, Social Club',
                    pre_mint: 0,
                    max_supply: 2269,
                    founder_status: 'Publicly pseudonymous',
                    community_wallet: 'juno1pwha5a3hr55wmmur2d4fm0p8rf7q62wnu30efm',
                },
                disabled: false,
            },
            {
                CREATOR: 'Loop Finance',
                NFT: 'juno1pkye52d2zhqj6xuszfzjywt042z6hh279d5lydz25qsxyhw2dpys9z5vwf',
                url: 'loop-migration-airdrop',
                MINTER: 'juno1feq3xa9dn3g3qsd3cdmxkedmettahaygchcntx60qumc6k5k45jqxtdyrw',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/c2628e40-8764-46c6-a5cb-32d1b281dc26',
                    icon: 'https://loop-markets.directus.app/assets/3887aa64-3bfa-49f3-9f88-69fc7e2a5427',
                    name: 'Loop Migration Airdrop',
                    status: 'live',
                    website: 'https://nft-juno.loop.markets/',
                    twitter: 'https://twitter.com/LoopDeFi_NFT',
                    discord: 'https://discord.gg/loopfinance',
                    mintPrice: '0 JUNO',
                    startDate: '11 Nov 2022',
                    startTime: '17:00:00 UTC',
                    publicMintStartTime: '11 Nov 2022 17:00:00 UTC',
                    endDate: '2 Dec 2022',
                    endTime: '17:00:00 UTC',
                    maxSupply: 9949,
                    description:
                        `We’re celebrating our Loop supporters with an NFT airdrop, stakeable for LOOP! If you claimed this NFT, you are a valuable supporter of Loop and the Cosmos Network! You HODL your tokens to show your faith and support in community-first projects like Loop Finance!`,
                },
                projectInfo: {
                    primary_usecase: 'Reward',
                    additional_usecase: 'Art',
                    pre_mint: 0,
                    max_supply: 9949,
                    founder_status: 'Known',
                    community_wallet: '',
                },
                disabled: false,
                actions: true
            },
            {
                CREATOR: 'Kelepar OGs',
                NFT: 'juno1jyu9et49w2az2s7xjpjwq7wglfsxuapptlp2rs39ym80ufs26ehqp2qhls',
                url: 'kelepar-ogs',
                MINTER: 'juno1dp8089cv24n2f6nc9yd3f0ynh6klsgwnfmnrtxh7wxtx7x5gq8xstzvfu0',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/d5ef9646-35e9-457d-acc1-b9e708c0e7bf',
                    icon: 'https://loop-markets.directus.app/assets/77ef930d-7eb8-4bee-b4f5-250223beb23d',
                    name: 'Kelepar OGs',
                    status: 'live',
                    website: 'https://kelepar.com/',
                    twitter: 'https://twitter.com/KeleparGame',
                    discord: 'http://discord.gg/nMmr9pAe4N',
                    mintPrice: '4.4 ATOM',
                    startDate: '24 Nov 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '25 Nov 2022 15:00:00 UTC',
                    endDate: '15 Dec 2022',
                    endTime: '15:00:00 UTC',
                    maxSupply: 1800,
                    description:
                        `Kelepar is a thrilling game with stunning art leveraging NFT tech to benefit players. The OGs is a PFP collection for people that are part of this project from the very beginning. OGs get access to the Kelepar beta and rewards for their support when the game launches.`,
                },
                projectInfo: {
                    primary_usecase: 'Gaming',
                    additional_usecase: 'Art, Collectible',
                    pre_mint: 40,
                    max_supply: 1800,
                    founder_status: 'Publicly pseudonymous',
                    community_wallet: '',
                },
                disabled: false,
                //Visible: True | Hidden: false
                actions: false
            },
            {
                CREATOR: 'Pop Booty',
                NFT: 'juno1ylfw935gj6a4y289qjtrrl3andeeelfwq6wxd7kewaa45mda698qu9yqrn',
                url: 'pop-booty',
                MINTER: 'juno1ghqg73crhvkcnkf6vffgwt38xe0y2z9phkxf6p4r5p8ghq9jwzgse4xttp',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/25c29e01-5a54-4ce0-b1fc-9a30c771644d',
                    icon: 'https://loop-markets.directus.app/assets/9a9ee34a-85ba-4502-8554-be6749643ee8',
                    name: 'Pop Booty',
                    status: 'live',
                    website: '',
                    twitter: 'https://twitter.com/PopBootyNFT',
                    discord: 'http://discord.gg/5HqrbpJUF8',
                    mintPrice: '33 USDC',
                    startDate: '14 Dec 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '14 Dec 2022 16:00:00 UTC',
                    endDate: '3 Jan 2023',
                    endTime: '16:00:00 UTC',
                    maxSupply: 333,
                    description:
                        `Femininity, beauty, history and mathematics. Inspired by Pop Art, Pop Booty emerges as a response to the vision that treats NFTs' art as a satire without any creativity. Our goal is to give hodlers tools to fight against this unfair view.\nWe want to create a community able to show in public the beautiful things this movement has to offer. The voice of our hodlers will be a guide for us, rewarded by airdrops and whitelist of future collections by Pop Studios.`,
                },
                projectInfo: {
                    primary_usecase: 'Art',
                    additional_usecase: 'Reward, Social Club Membership',
                    pre_mint: 26,
                    max_supply: 333,
                    founder_status: 'Publicly pseudonymous',
                    community_wallet: '',
                },
                disabled: false,
                //Visible: True | Hidden: false
                actions: false
            },
            {
                CREATOR: 'AQUA',
                NFT: 'juno1e60jmmn4ukg4te627h9maaurceughs76mcs5v6en0qsfe5z7aqustrvy4q',
                url: 'watr-warriors',
                MINTER: 'juno1h5ntvxkrnwa5v2pms6auyy58vcnutu2m3z9wjq7gpfvv8jed5mqsf29hl3',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/57b10adb-7a2b-4904-a441-47335fa1eb15',
                    icon: 'https://loop-markets.directus.app/assets/c52ac7c6-2fd1-4f94-b0b7-5a92d188ecb4',
                    name: 'Watr Warriors',
                    status: 'live',
                    website: 'https://daodao.zone/dao/juno1p47j6xaf90ehrdml3vn3c6dw9j35eppm05ukswzuplydldrqnv0qtz9ng4/proposals',
                    twitter: 'https://twitter.com/AquaOnJuno',
                    discord: 'https://discord.gg/sA3q4khCYk',
                    mintPrice: '48 USDC',
                    startDate: '15 May 2023',
                    startTime: '17:00:00 UTC',
                    publicMintStartTime: '15 May 2023 20:00:00 UTC',
                    endDate: '14 June 2023',
                    endTime: '17:00:00 UTC',
                    maxSupply: 106,
                    description:
                        `Each $WATR Warrior is your ticket to all things Aqua. There are 3 tiers of Warriors. Whether you mint Bronze, Silver, or Gold, your Warrior is packed with $WATR tokens. Mint yours today and join our journey as we continue to bring clean water to those in dire need in Zambia, Africa and beyond.`,
                },
                projectInfo: {
                    primary_usecase: 'Charity, DAO, Reward',
                    additional_usecase: 'Art, Collectible',
                    pre_mint: 0,
                    max_supply: 106,
                    founder_status: 'Publicly Known',
                    community_wallet: 'juno1p47j6xaf90ehrdml3vn3c6dw9j35eppm05ukswzuplydldrqnv0qtz9ng4',
                },
                disabled: false,
                //Visible: True | Hidden: false
                actions: false
            },
            {
                CREATOR: 'Gelotto Ruby Deck',
                NFT: 'juno133u3xnzwmfkje570ycwwnx4cm7m5r0xtjq6zcvyrf47phrtkp4vsk723zg',
                url: 'gelotto-ruby-deck',
                MINTER: 'juno12y8mnxtpl78v7aq87ld7pzd9rk7cx72ummuccwtzsukuenz66n3qa4ypve',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/a5cec1bd-1e91-46d4-b3dd-8c7809b0326e',
                    icon: 'https://loop-markets.directus.app/assets/8df404d8-6e19-4a1e-947a-5adae5c60fbe',
                    name: 'Gelotto Ruby Deck',
                    status: 'live',
                    website: 'https://gelotto.io/',
                    twitter: 'https://twitter.com/Gelotto2',
                    discord: 'https://discord.com/invite/UpAkK67zcw',
                    mintPrice: '420 JUNO',
                    startDate: '6 July 2023',
                    startTime: '14:00:00 UTC',
                    publicMintStartTime: '11 July 2023 14:00:00 UTC',
                    endDate: '6 July 2024',
                    endTime: '14:00:00 UTC',
                    maxSupply: 54,
                    description:
                        `The Gelotto Gambit unfolds as a vibrant collection of 6 decks, set to roll out over the span of 2023-2024. These deck NFTs will provide various boosts/augmentations to your gaming experience when playing any game involving a deck of cards on the Gelotto platform (on/off chain). Additionally, these cards are your passport to the elite poker tournaments hosted by Gelotto, where enormous rewards awaits, along with discounted entry fee.`,
                },
                projectInfo: {
                    primary_usecase: 'Gaming',
                    additional_usecase: 'Art, Social',
                    pre_mint: 0,
                    max_supply: 54,
                    founder_status: 'Publicly Known',
                    community_wallet: '#',
                },
                disabled: true,
                //Visible: True | Hidden: false
                actions: false
            },
            {
                CREATOR: 'Gelotto Sapphire Deck',
                NFT: 'juno1cxjtgdzy6av76n0h2s3pxqfyfzldzk75uzrukgntlz3tldhasakqvqckuv',
                url: 'gelotto-sapphire-deck',
                MINTER: 'juno1m0ldwsvtrfs2hs23qdfwcx0w6rkfuckzn67uatd9h06d66ad8nzszv0xls',
                launchInfo: {
                    logo: 'https://loop-markets.directus.app/assets/9f7253e1-fa6f-49e4-95f7-8c6ad2b96c43',
                    icon: 'https://loop-markets.directus.app/assets/6d2f87ca-4d68-4784-b397-1b698c8a35a1',
                    name: 'Gelotto Sapphire Deck',
                    status: 'live',
                    website: 'https://gelotto.io/',
                    twitter: 'https://twitter.com/Gelotto2',
                    discord: 'https://discord.com/invite/UpAkK67zcw',
                    mintPrice: '420 JUNO',
                    startDate: '6 July 2023',
                    startTime: '14:00:00 UTC',
                    publicMintStartTime: '11 July 2023 14:00:00 UTC',
                    endDate: '6 July 2024',
                    endTime: '14:00:00 UTC',
                    maxSupply: 106,
                    description:
                        `The Gelotto Gambit unfolds as a vibrant collection of 6 decks, set to roll out over the span of 2023-2024. These deck NFTs will provide various boosts/augmentations to your gaming experience when playing any game involving a deck of cards on the Gelotto platform (on/off chain). Additionally, these cards are your passport to the elite poker tournaments hosted by Gelotto, where enormous rewards awaits, along with discounted entry fee.`,
                },
                projectInfo: {
                    primary_usecase: 'Gaming',
                    additional_usecase: 'Art, Social',
                    pre_mint: 4,
                    max_supply: 54,
                    founder_status: 'Publicly Known',
                    community_wallet: '#',
                },
                disabled: true,
                //Visible: True | Hidden: false
                actions: false
            },
        ],
        lpNFTs: [],
        STAKING: 'juno1jnesmj0kg0sg475fd87q9l5vn67gxg4mafm5rtp03ayhjxhey2wqasrcuk',
        AUCTION: 'juno18u5m3zamdz7hayj9qxx6jahps9y367myjhs88fe0fu9qu8hnnydstjwcs8',
        SALE: 'juno1kylehdql046nep2gtdgl56sl09l7wv4q6cj44cwuzfs6wxnh4flszdmkk0',
    },
    [Network.TESTNET]: {
        launchPadNFTs: [
            {
                CREATOR: 'LOOP Finance',
                NFT: 'terra1l4pmwpdjjvdqdtxkl5y8lwvkuperux4m3l5jn0',
                MINTER: 'terra1p6vngudqk2s0047dp7n3nv5v4nuxejq6hwsp3d',
                launchInfo: {
                    logo: '/threeImage.png',
                    icon: '/exploreThumb.png',
                    name: 'LUNI',
                    status: 'live',
                    website: '',
                    twitter: '',
                    discord: '',
                    mintPrice: '1 USDC',
                    startDate: '14 Jan 2022',
                    startTime: '15:00:00 UTC',
                    publicMintStartTime: '17 Aug 2022 01:00:00 UTC',
                    endDate: 'Unlimited',
                    endTime: '01:00:00 UTC',
                    maxSupply: 100,
                },
                disabled: false,
            },
        ],
        lpNFTs: [],
        STAKING: 'terra1434vzpgnys0tgce49t4wrp0k3zyjl0h5lzdwng',
        AUCTION: 'terra1dck0zawhjq4te9fm2jgctd9szxeyqrt3v0a5lt',
        SALE: 'terra1p0uxrse67n95jtfsearrugpfahjwd8f6w6n5hg',
    },
};

export const NFT_COLLECTIONS: {
    id: number;
    name: string;
    description: string;
    logo: string;
    icon: string;
    url: string;
    address: string;
    rewardTokens: string[];
    socials?: {
        website?: string;
        twitter?: string;
        discord?: string;
        medium?: string;
    };
}[] = [
    {
        id: 1,
        name: 'Beta Tester Apes',
        description:
            'The Beta Tester Apes are the secret, bug-squashing unit of Loop. Users who minted a Beta Tester Ape were the primary Beta Testers for Loop NFT Marketplace. Thank you for your service, sers.',
        logo: '/spaced-apes-logo.jpg',
        icon: 'spaced-apes-icon.jpg',
        url: 'beta-tester-apes',
        address: 'juno1j6ewq25hfgww8pty9p4d5q8kkyf4sg9j24crstj78a237tk0thnqe0pa93',
        rewardTokens: ['JUNO'],
        socials: {
            website: 'https://www.loop.markets',
            twitter: 'https://twitter.com/LoopDeFi_NFT',
            discord: 'https://discord.gg/loopfinance',
        },
    },
    {
        id: 2,
        name: 'Tradooors',
        description:
            'Tradooors is an original pixel art collection celebrating the age of crypto traders, Anons, Interns, NFT collectors, and all-around degens pushing Web3 and crypto adoption while sitting behind their laptops in their bedrooms and basements. Bringing together unique utility and skill-based games for a fun, rewarding experience for all holders.',
        logo: '/tradooors_banner.jpg',
        icon: '/tradooors_icon.jpg',
        url: 'tradooors',
        address: 'juno1qnmgent6fmw8rufz978rhmzfgmvme9gvyj0t7rkpffklvwr9tteste35lg',
        rewardTokens: ['LOOP', 'GKEY'],
        socials: {
            website: 'https://medium.com/@tradooorsnft',
            twitter: 'https://twitter.com/TradooorsNFT',
            discord: 'https://discord.gg/n6EWBtf4US',
        },
    },
    {
        id: 3,
        name: 'Los Amigos by Aqua & Casa',
        description:
            'Los Amigos NFTs are your key to unlocking multiple benefits throughout the Aqua Social Impact Ecosystem! In addition to receiving $CASA and $AQUA tokens, owners of Los Amigos NFTs will receive tokens of all upcoming Aqua Social Impact DAOs, pre-IDO swap access, automatic whitelisting, and discounts for upcoming NFTs, plus more! Get yours today and join us on our journey to create real social impact!',
        logo: '/losamigos_banner.webp',
        icon: '/losamigos_icon.webp',
        url: 'los-amigos',
        address: 'juno14z2x9druc74a4z4wfvdujudfg8snp30rpa6yfxeax8hwr5dtjugsjt9qpu',
        rewardTokens: ['AQUA', 'CASA'],
        socials: {
            website: 'https://medium.com/@AquaOnJuno',
            twitter: 'https://twitter.com/AquaOnJuno',
            discord: 'https://discord.gg/F73qKqQDcm',
        },
    },
    {
        id: 4,
        name: 'NETA Trooprs',
        description:
            'Made by Rendrasc to support the Neta Community and those who sacrificed their 0.2 NETA to the LP. NETA Trooprs will donate 80% of these mint proceeds to the Neta DAO to help fund their mission to accelerate the growth of teams and dApps on the Juno Network! Strength and Honor!',
        logo: '/netatrooprs_banner.png',
        icon: '/netatrooprs_icon.png',
        url: 'neta-trooprs',
        address: 'juno1s0s2nfrpfdqk3epatmv047wk0ljecfaxw2cn3wq80mpl6tlxuf5sfu5rw4',
        rewardTokens: [],
        socials: {
            website: 'https://netadao.zone/',
            twitter: 'https://twitter.com/Neta_DAO',
            discord: 'https://discord.gg/kyKY6F4Z6a',
        },
    },
    {
        id: 5,
        name: 'Levana Dragons',
        description:
            "Levana Dragons, the Levana flagship GameFi NFT project, is the largest and most successful NFT project within the Cosmos ecosystem to date. \n Levana NFTs evolve over time, depending on how players interact with their NFTs, our leveraged products, and the community. Levana's NFTs play a fundamental role in the growth and expansion of Levana's community. Levana Dragons have proven that compelling storytelling, unmatched visuals, and interactive gamification can succeed at onboarding millions of new users to DeFi based crypto products.",
        logo: '/levana_dragons_logo.png',
        icon: '/levana_dragons_icon.png',
        url: 'levana-dragons',
        address: 'juno1a90f8jdwm4h43yzqgj4xqzcfxt4l98ev970vwz6l9m02wxlpqd2squuv6k',
        rewardTokens: [],
        socials: {
            website: 'https://www.levana.finance/',
            twitter: 'https://twitter.com/Levana_protocol',
            discord: 'https://discord.gg/p4GsrVycgR',
            medium: 'https://blog.levana.finance/',
        },
    },
    {
        id: 6,
        name: 'Levana Loot',
        description: 'Glorious Loot from Levana gaming conquests',
        logo: '/levana_loots_logo.png',
        icon: '/levana_loots_icon.png',
        url: 'levana-loot',
        address: 'juno1gmnkf4fs0qrwxdjcwngq3n2gpxm7t24g8n4hufhyx58873he85ss8q9va4',
        rewardTokens: [],
        socials: {
            website: 'https://www.levana.finance/',
            twitter: 'https://twitter.com/Levana_protocol',
            discord: 'https://discord.gg/p4GsrVycgR',
            medium: 'https://blog.levana.finance/',
        },
    },
    {
        id: 7,
        name: 'Levana Riders',
        description:
            'The last hope of humanity in the year 2472. Bravely fighting alongside Levana Dragons for the future of Mars.',
        logo: '/levana_riders_logo.png',
        icon: '/levana_riders_icon.png',
        url: 'levana-riders',
        address: 'juno1uw3pxkga3dxhqs28rjgpgqkvcuedhmc7ezm2yxvvaey9yugfu3fq7ej2wv',
        rewardTokens: [],
        socials: {
            website: 'https://www.levana.finance/',
            twitter: 'https://twitter.com/Levana_protocol',
            discord: 'https://discord.gg/p4GsrVycgR',
            medium: 'https://blog.levana.finance/',
        },
    },
    {
        id: 8,
        name: 'Cosmoverse',
        description:
            'Your Cosmic 5 Superhero Ape is your ticket to the Cosmic 5 Party. The party will kick off at 8pm on the 27th of September, day 2 of the Cosmoverse Conference in Medellin and will be held at The Garden Bar in El Poblado, Medellin. Holding a Superhero Ape will get you access to the party, as well as receiving a reward of a free drink and a chance to win a bottle! Get your ticket and see you at the party!',
        logo: '/cosmoverse_banner.png',
        icon: '/cosmoverse_icon.png',
        url: 'cosmoverse',
        address: 'juno1zzcugrt4x30vu0qxg765rnxkxm6lru6cwe0nvcl90ctc9k4cwvksx8tcew',
        rewardTokens: ['BOTTLE', 'DRINK'],
        socials: {
            website: 'http://events.loop.markets/',
            twitter: 'https://twitter.com/loop_finance',
            discord: 'https://discord.com/invite/loopfinance',
        },
    },
    {
        id: 9,
        name: 'Cosmic Sky Monks',
        description:
            "Sky Monks Second Generation. Bringing the cosmic soundscape to your NFTs. Cosmo's first generative music NFT collection. The Cosmic Sky Monks are a band of warriors bound by a common goal. Join us on this journey of shaping their reality..",
        logo: '/cosmic_sky_monks_logo.jpg',
        icon: '/cosmic_sky_monks_icon.png',
        url: 'cosmic-sky-monks',
        address: 'juno1vnnrf7n83n7uncdpwycfdmp2spgvrefxma4wtu8wnq2l063n4hnqcxecaj',
        rewardTokens: [],
        socials: {
            website: '#',
            twitter: '#',
            discord: '#',
        },
    },
    {
        id: 10,
        name: 'Sunnyside Island',
        description: `666 sun-bleached spirits who celebrate the absurdity of life and death. A collection by the Sunnyside Reapers. Go fishing for exclusive reward tokens like $LOOP, $GLTO, and even the elusive $GKEY. Each mint also earns one free Sunnyside Reaper on Stargaze (by request: ssr.rip/Discord). Keep an eye out for our upcoming Non-P2E NFT card game, “Sunnyside Cemetery” estimated release date January 2023. 

        1 $GKEY (Current value ~$500-600) - .25 GKEY attached to 4 Golden Gelotto Cone NFTs which we will manually send to the address that mints these  
        ~ALL NFTs are BOGO eligible for a Standard Sunnyside Reaper from our original Stargaze Collection. Join our Discord and submit a ticket in order to claim! Reward Details 
         
        Let's Go Fishing! 
        Specific Golden Traits in Sunnyside Island are the traits you are looking for! Minting one of these golden assets (such as a golden Gelotto cone, or The Golden Whale) will reward you with the higher tiered rewards or part of the elusive $GKEY! `,
        logo: 'https://loop-markets.directus.app/assets/20aec179-064d-4d9f-aa3b-d72e9ab4db47',
        icon: 'https://loop-markets.directus.app/assets/b1735c0f-6510-4dcf-ac6f-d8fbd847c2de',
        url: 'sunnyside-island',
        address: 'juno1xhdu25f3a2qrntyq06m4mk733p06cf0x5lu67mfu3nt6dvgepc2sm4k5e3',
        rewardTokens: ['LOOP', 'GLTO'],
        socials: {
            website: 'https://ssr.rip/',
            twitter: 'https://twitter.com/SunnysideReaper',
            discord: 'https://discord.gg/GFYrJEsYpm',
            medium: 'https://medium.com/@SunnysideReapers',
        },
    },
    {
        id: 11,
        name: 'Loopy Crypto Girls',
        description: `Loopy Crypto Girls is all about being curious, exploring and enjoying the crypto life. First of a kind PFPs for Cosmonauts. Gift an NFT to your partner or a girl in your life to onboard them to crypto & web3.`,
        logo: 'https://loop-markets.directus.app/assets/524db8dd-68f7-4972-b3c2-cd31681e85df',
        icon: 'https://loop-markets.directus.app/assets/617f864f-d482-4243-9d40-e18a206cd6a2',
        url: 'loopy-crypto-girls',
        address: 'juno1cw6qdr5t2c87vh39rjrcj4a6djh863zn4q9sr47wrcsguzs803ysydydcq',
        rewardTokens: [],
        socials: {
            twitter: 'https://twitter.com/CuteCryptoGirls',
            discord: 'https://t.co/ketsXItNtY',
        },
    },
    {
        id: 12,
        name: 'Juno Bear Society',
        description: `Juno Bear Society(JBS) is a private NFT investment DAO. All mint proceeds will be used to trade NFTs. JBS holders will discuss & vote on decisions via DAODAO involving where the mint proceeds will be spent, which NFTs will be purchased, and when NFTs owned by the DAO will be sold. 70% of trading profits will be redistributed to JBS holders on a weekly basis. DAO members are granted entry to the DAO after minting a JBS NFT.`,
        logo: 'https://loop-markets.directus.app/assets/5d1faaa6-88f1-49eb-9797-2779dbea487e',
        icon: 'https://loop-markets.directus.app/assets/62b97e0f-a62e-481e-9fed-725d6e3c678a',
        url: 'juno-bear-society',
        address: 'juno1s8qkx4xpwrd4jqyzpk6cyk8lnm6xhx23dk6tjflxqmgas6jh5zuq8hkk2w',
        rewardTokens: [],
        socials: {
            twitter: 'https://twitter.com/JunoBearSociety',
            discord: 'https://discord.gg/P9hPPYe6',
        },
    },
    {
        id: 13,
        name: 'Rekt Bulls',
        description: `Got Rekt in crypto because you were too bullish on the “tech”. Join the Rekt Bulls, an omnichain NFT collection of 2269 Rekt Bulls that roam through the Cosmos. Part of the infamous Rekt Gang.`,
        logo: '/rektbulls_banner.png',
        icon: '/rektbulls_icon.png',
        url: 'rekt-bulls',
        address: 'juno1xwcwnfh9gjqvchl2danhvzsl0h94hrnvuptwajfd3kwx4q9es2gs5uvpj4',
        rewardTokens: [],
        socials: {
            website: 'https://rektgang.io/',
            twitter: 'https://twitter.com/rekt_gang',
            discord: 'https://discord.gg/6TzSTkRvV5',
            medium: 'https://mirror.xyz/rektgang.eth',
        },
    },
    {
        id: 14,
        name: 'Loop Migration Airdrop',
        description: `We’re celebrating our Loop supporters with an NFT airdrop, stakeable for LOOP! If you claimed this NFT, you are a valuable supporter of Loop and the Cosmos Network! You HODL your tokens to show your faith and support in community-first projects like Loop Finance!`,
        logo: 'https://loop-markets.directus.app/assets/3887aa64-3bfa-49f3-9f88-69fc7e2a5427',
        icon: 'https://loop-markets.directus.app/assets/c2628e40-8764-46c6-a5cb-32d1b281dc26',
        url: 'loop-migration-airdrop',
        address: 'juno1pkye52d2zhqj6xuszfzjywt042z6hh279d5lydz25qsxyhw2dpys9z5vwf',
        rewardTokens: ['LOOP'],
        socials: {
            website: 'https://nft-juno.loop.markets/',
            twitter: 'https://twitter.com/LoopDeFi_NFT',
            discord: 'https://discord.gg/loopfinance',
        },
    },
    {
        id: 15,
        name: 'NETA Trooprs Cards',
        description: `Made by Rendrasc to support the Neta Community and those who sacrificed their 0.2 NETA to the LP. 30 animated NFTs series are exclusively dropped to TOP 30 NETA OG.`,
        logo: '/neta_trooprs_cards_banner.png',
        icon: '/neta_trooprs_cards_icon.png',
        url: 'neta-trooprs-cards',
        address: 'juno17hh3cjc7l0agsxhjn4z5cdvs8kh432wtrh42awqs050qds2d8f6s7tdu6y',
        rewardTokens: [],
        socials: {
            website: 'https://netadao.zone/',
            twitter: 'https://twitter.com/Neta_DAO',
            discord: 'https://discord.gg/kyKY6F4Z6a',
        },
    },
    {
        id: 16,
        name: 'Kelepar OGs',
        description: `Kelepar is a thrilling game with stunning art leveraging NFT tech to benefit players. The OGs is a PFP collection for people that are part of this project from the very beginning. OGs get access to the Kelepar beta and rewards for their support when the game launches.`,
        logo: 'https://loop-markets.directus.app/assets/d5ef9646-35e9-457d-acc1-b9e708c0e7bf',
        icon: 'https://loop-markets.directus.app/assets/77ef930d-7eb8-4bee-b4f5-250223beb23d',
        url: 'kelepar-ogs',
        address: 'juno1jyu9et49w2az2s7xjpjwq7wglfsxuapptlp2rs39ym80ufs26ehqp2qhls',
        rewardTokens: [],
        socials: {
            website: 'https://kelepar.com/',
            twitter: 'https://twitter.com/KeleparGame',
            discord: 'http://discord.gg/nMmr9pAe4N',
        },
    },
    {
        id: 17,
        name: '8-Bit Booty',
        description: `Pop Art took as its inspiration the characteristic elements of its time. Pop Booty takes the current culture as inspiration. However, one of the elements that were not included in Pop Booty are video games, which are more present in our society every day. This is why this spin-off collection is all about video games! To have a good time and to thank the most active members, 8-Bit Booty NFTs will be distributed as prizes to winners of games organized by Pop Studios.`,
        logo: '/8_bit_booty_banner.png',
        icon: '/8_bit_booty_icon.png',
        url: '8-bit-booty',
        address: 'juno1y5rmkjcumgsrr0apd6xj08gexqwl85ysju2dggmlg8dr9sxj7e5sgxccgq',
        rewardTokens: [],
        socials: {
            twitter: 'https://twitter.com/PopBootyNFT',
            discord: 'http://discord.gg/5HqrbpJUF8',
        },
    },
    {
        id: 18,
        name: 'Pop Booty',
        description:
            `Femininity, beauty, history and mathematics. Inspired by Pop Art, Pop Booty emerges as a response to the vision that treats NFTs' art as a satire without any creativity. Our goal is to give hodlers tools to fight against this unfair view.\nWe want to create a community able to show in public the beautiful things this movement has to offer. The voice of our hodlers will be a guide for us, rewarded by airdrops and whitelist of future collections by Pop Studios.`,
        logo: 'https://loop-markets.directus.app/assets/25c29e01-5a54-4ce0-b1fc-9a30c771644d',
        icon: 'https://loop-markets.directus.app/assets/9a9ee34a-85ba-4502-8554-be6749643ee8',
        url: 'pop-booty',
        address: 'juno1ylfw935gj6a4y289qjtrrl3andeeelfwq6wxd7kewaa45mda698qu9yqrn',
        rewardTokens: [],
        socials: {
            twitter: 'https://twitter.com/PopBootyNFT',
            discord: 'http://discord.gg/5HqrbpJUF8',
        },
    },
    {
        id: 19,
        name: 'ATLAS DAO',
        description:
            "Atlas NFT is Cosmos' first generative music NFT, backed by 30 Juno. Atlas NFT is the governance of Atlas DAO, which aims to create sustainable value and passive yield for its members. The mission of Atlas DAO is to foster a thriving ecosystem that benefits all stakeholders, while also making a positive impact through charitable donations and volunteer work.",
        logo: '/cosmic_sky_monks_logo.jpg',
        icon: '/cosmic_sky_monks_icon.png',
        url: 'atlas',
        address: 'juno1kpsy0mh58fzrl973ndvppujs9ea5xe9wggjhhg07c42l0yqk5n7st5800h',
        rewardTokens: [],
        socials: {
            website: 'https://dao.daodao.zone/juno1hcldlknu2mn3exckkg75tyzjnderl95zyjte2wl495z9jla0rmdqegxlxx#',
            twitter: 'https://twitter.com/atlasdao_',
            discord: 'https://discord.gg/kfqwdw9Apw',
        },
    },
    {
        id: 20,
        name: 'Watr Warriors',
        description:
            "Each $WATR Warrior is your ticket to all things Aqua. There are 3 tiers of Warriors. Whether you mint Bronze, Silver, or Gold, your Warrior is packed with $WATR tokens. Mint yours today and join our journey as we continue to bring clean water to those in dire need in Zambia, Africa and beyond.",
        logo: 'https://loop-markets.directus.app/assets/57b10adb-7a2b-4904-a441-47335fa1eb15',
        icon: 'https://loop-markets.directus.app/assets/c52ac7c6-2fd1-4f94-b0b7-5a92d188ecb4',
        url: 'watr-warriors',
        address: 'juno1e60jmmn4ukg4te627h9maaurceughs76mcs5v6en0qsfe5z7aqustrvy4q',
        rewardTokens: ['WATR'],
        socials: {
            website: 'https://daodao.zone/dao/juno1p47j6xaf90ehrdml3vn3c6dw9j35eppm05ukswzuplydldrqnv0qtz9ng4/proposals',
            twitter: 'https://twitter.com/AquaOnJuno',
            discord: 'https://discord.gg/sA3q4khCYk',
        },
    },
    {
        id: 21,
        name: 'Gelotto Ruby Deck',
        description: "The Gelotto Gambit unfolds as a vibrant collection of 6 decks, set to roll out over the span of 2023-2024. These deck NFTs will provide various boosts/augmentations to your gaming experience when playing any game involving a deck of cards on the Gelotto platform (on/off chain). Additionally, these cards are your passport to the elite poker tournaments hosted by Gelotto, where enormous rewards awaits, along with discounted entry fee. ",
        logo: 'https://loop-markets.directus.app/assets/a5cec1bd-1e91-46d4-b3dd-8c7809b0326e',
        icon: 'https://loop-markets.directus.app/assets/8df404d8-6e19-4a1e-947a-5adae5c60fbe',
        url: 'gelotto-ruby-deck',
        address: 'juno133u3xnzwmfkje570ycwwnx4cm7m5r0xtjq6zcvyrf47phrtkp4vsk723zg',
        rewardTokens: ['GRUB'],
        socials: {
            website: 'https://gelotto.io/',
            twitter: 'https://twitter.com/Gelotto2',
            discord: 'https://discord.com/invite/UpAkK67zcw',
        },
    },
    {
        id: 22,
        name: 'Gelotto Sapphire Deck',
        description: "The Gelotto Gambit unfolds as a vibrant collection of 6 decks, set to roll out over the span of 2023-2024. These deck NFTs will provide various boosts/augmentations to your gaming experience when playing any game involving a deck of cards on the Gelotto platform (on/off chain). Additionally, these cards are your passport to the elite poker tournaments hosted by Gelotto, where enormous rewards awaits, along with discounted entry fee.",
        logo: 'https://loop-markets.directus.app/assets/9f7253e1-fa6f-49e4-95f7-8c6ad2b96c43',
        icon: 'https://loop-markets.directus.app/assets/6d2f87ca-4d68-4784-b397-1b698c8a35a1',
        url: 'gelotto-sapphire-deck',
        address: 'juno1cxjtgdzy6av76n0h2s3pxqfyfzldzk75uzrukgntlz3tldhasakqvqckuv',
        rewardTokens: ['GRUB'],
        socials: {
            website: 'https://gelotto.io/',
            twitter: 'https://twitter.com/Gelotto2',
            discord: 'https://discord.com/invite/UpAkK67zcw',
        },
    },
];

export const TICKET_COLLECTIONS: {
    name: string;
    date: string;
    time: string;
    location: string;
    reward: any;
}[] = [
    {
        name: 'The Cosmic 5 Party',
        date: '27 Sep 2022',
        time: '8:00 PM',
        location: 'The Garden Bar, Medellin, Colombia',
        reward: [1, 2, 3, 4, 5],
    },
];


export const blockList = [
    "juno1l3aygqg8en2hh396wph6he4x05ujkm6vc48sf0c34ema0mm24d5qdhn00s"
];

export const networkType = Network.MAINNET;
