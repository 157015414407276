import { NFT_COLLECTIONS } from 'core/nftConfig';
import axios from 'axios';
import { BACKEND_ENDPOINT, PAGE_SIZE } from 'core/common';

export type NftCollection = {
  name: string;
  description: string;
  logo: string;
  icon: string;
  url: string;
  address: string;
  rewardTokens: string[];
  itemCount: number;
  ownerCount: number;
  floorPrice: number;
  volumn: number;
  socials?: {
    website?: string;
    twitter?: string;
    discord?: string;
    medium?: string;
  };
};

export type Faq = {
  Questions: any;
};

export type CollectionDetailItems = {
  features: any,
  benefits: any,
  description: any,
  video_link: any,
  image_placeholder: any
};

export type CollectionDetails = {
  id: string;
  name: string;
  description: string;
  logo: string;
  icon: { id: string };
  banner: { id: string };
  artist: any,
  url: string;
  address: string;
  rewardTokens: string[];
  itemCount: number;
  ownerCount: number;
  floorPrice: number;
  volumn: number;
  daily_volume: number;
  totalItems: number;
  socials?: [{
    website?: string;
    twitter?: string;
    discord?: string;
    medium?: string;
  }];
  faqs?: Faq;
  collection_detail?: CollectionDetailItems;
};

export type NftAttribute = {
  trate_type: string;
  value: string;
}

export const queryCollections = async (
  nftType: string,
  sortOrder: string,
  sortType = '',
  rewardToken = '',
  collection = '',
  offset = 0,
  count = PAGE_SIZE
): Promise<{
  totalCount: number;
  collections: NftCollection[];
}> => {
  const prefilteredNftAddresses = NFT_COLLECTIONS.filter(item => {
    let isValid = true;
    if (nftType === 'Reward' && item.rewardTokens.length === 0) {
      isValid = false;
    } else if (nftType === 'Standard' && item.rewardTokens.length !== 0) {
      isValid = false;
    }

    if (rewardToken && !item.rewardTokens.includes(rewardToken)) {
      isValid = false;
    }

    if (collection && item.address !== collection) {
      isValid = false;
    }

    return isValid;
  }).map(item => item.address);

  let orderString = '';
  if (sortType === 'FloorPrice') {
    if (sortOrder === 'Asc') {
      orderString = 'NFTS_MIN_SALE_AMOUNT_ASC';
    } else {
      orderString = 'NFTS_MIN_SALE_AMOUNT_DESC';
    }
  }

  const query = `
  {
    nftContracts(
      filter: {
        id: {
          in: ${JSON.stringify(prefilteredNftAddresses)}
        }
      }
      orderBy: [
        ${orderString}
      ]
    ) {
       nodes {
        id
        name
        nfts (filter: {
          or: [
            {
              inSale: {
                equalTo: true
              }
            }
            {
              inAuction: {
                equalTo: true
              }
            }
          ]
          saleAmount: {
            greaterThan: "0"
          }
        }) {
          aggregates {
            distinctCount {
              id
              owner
            }
            min {
              saleAmount
            }
          }
        }
      }
    }
  }`;
  const data: {
    data: {
      nftContracts: {
        totalCount: number;
        nodes: {
          id: string;
          name: string;
          nfts: {
            aggregates: {
              distinctCount: {
                id: string;
                owner: string;
              };
              min: {
                saleAmount: string;
              };
            };
          };
        }[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  let collections: NftCollection[] = [];
  for (let i = 0; i < data.data.nftContracts.nodes.length; i++) {
    const item = data.data.nftContracts.nodes[i];
    const collection = NFT_COLLECTIONS.find(e => e.address === item.id)!;
    if (!item.nfts.aggregates.min.saleAmount) {
      continue;
    }

    const query = `
    {
      userActivities(filter: {
        nftId: {
          startsWith: "${collection.address}"
        }
        event: {
          equalTo: "Sold"
        }
        amount: {
          greaterThan: "0"
        }
      }) {
        aggregates {
          sum {
            amount
          }
        }
      }
    }`;
    const volumnData: {
      data: {
        userActivities: {
          aggregates: {
            sum: {
              amount: string;
            };
          };
        };
      };
    } = await axios
      .post(BACKEND_ENDPOINT, {
        operationName: null,
        query,
        variables: {},
      })
      .then((response: { data: any }) => response.data);

    collections.push({
      ...collection,
      itemCount: parseFloat(item.nfts.aggregates.distinctCount.id || '0'),
      ownerCount: parseFloat(item.nfts.aggregates.distinctCount.owner || '0'),
      floorPrice: parseFloat(
        item.nfts.aggregates.min.saleAmount || '0'
      ),
      volumn: parseFloat(
        volumnData.data.userActivities.aggregates.sum.amount || '0'
      ),
    });
  }

  if (sortType === 'RecentlyListed') {
    const addressToId: {
      [address: string]: number
    } = {}

    for (let i = 0; i < NFT_COLLECTIONS.length; i++) {
      addressToId[NFT_COLLECTIONS[i].address] = NFT_COLLECTIONS[i].id
    }
    collections = collections.sort((a, b) => {
      if (sortOrder === 'Asc') {
        return addressToId[a.address] - addressToId[b.address]
      } else {
        return addressToId[b.address] - addressToId[a.address]
      }
    });
  } else if (sortType === 'FloorPrice') {
    collections = collections.sort((a, b) => {
      if (sortOrder === 'Asc') {
        return a.floorPrice - b.floorPrice
      } else {
        return b.floorPrice - a.floorPrice
      }
    });
  }

  return {
    totalCount: data.data.nftContracts.totalCount,
    collections,
  };
};

export const queryCollection = async (
  address: string
): Promise<{
  collection: NftCollection,
  attributes: NftAttribute[]
} | null> => {
  const query = `
  {
    nftContracts(
      filter: {
        id: {
          equalTo: "${address}"
        }
      }
    ) {
       nodes {
        id
        name
        nfts (filter: {
          or: [
            {
              inSale: {
                equalTo: true
              }
            }
            {
              inAuction: {
                equalTo: true
              }
            }
          ]
        })  {
          aggregates {
            min {
              saleAmount
            }
          }
        }
      }
    }

    nftAttributes(filter: {
      contractId:{
        equalTo: "${address}"
      }
    }) {
      totalCount
      groupedAggregates (groupBy: [
        TRAIT_TYPE,
        VALUE
      ]) {
        keys
      }
    }
  }`;
  const data: {
    data: {
      nftContracts: {
        totalCount: number;
        nodes: {
          id: string;
          name: string;
          nfts: {
            aggregates: {
              min: {
                saleAmount: string;
              };
            };
          };
        }[];
      };
      nftAttributes: {
        totalCount: number,
        groupedAggregates: {
          keys: string[]
        }[]
      }
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  if (data.data.nftContracts.nodes.length === 0) {
    return null;
  }

  const item = data.data.nftContracts.nodes[0];
  const collection = NFT_COLLECTIONS.find(e => e.address === item.id)!;
  const oneDayAgo = new Date(new Date().getTime() - 1000 * 60 * 60 * 24);
  const volumnQuery = `
    {
      nftContracts(
        filter: {
          id: {
            equalTo: "${address}"
          }
        }
      ) {
         nodes {
          id
          name
          nfts {
            aggregates {
              distinctCount {
                id
                owner
              }
            }
          }
        }
      }
      userActivities(filter: {
        nftId: {
          startsWith: "${collection.address}"
        }
        event: {
          equalTo: "Sold"
        }
        timestamp: {
          greaterThan: "${oneDayAgo.toISOString()}"
        }
        amount: {
          greaterThan: "0"
        }
      }) {
        aggregates {
          sum {
            amount
          }
        }
      }
    }`;
  const volumnData: {
    data: {
      nftContracts: {
        totalCount: number;
        nodes: {
          id: string;
          name: string;
          nfts: {
            aggregates: {
              distinctCount: {
                id: string;
                owner: string;
              };
              min: {
                saleAmount: string;
              };
            };
          };
        }[];
      };
      userActivities: {
        aggregates: {
          sum: {
            amount: string;
          };
        };
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query: volumnQuery,
      variables: {},
    })
    .then((response: { data: any }) => response.data);

  return {
    collection: {
      ...collection,
      itemCount: parseFloat(volumnData.data.nftContracts.nodes[0].nfts.aggregates.distinctCount.id || '0'),
      ownerCount: parseFloat(volumnData.data.nftContracts.nodes[0].nfts.aggregates.distinctCount.owner || '0'),
      floorPrice: parseFloat(
        item.nfts.aggregates.min.saleAmount || '0'
      ),
      volumn: parseFloat(
        volumnData.data.userActivities.aggregates.sum.amount || '0'
      ),
    },
    attributes: data.data.nftAttributes.groupedAggregates.map(item => {
      return {
        trate_type: item.keys[0],
        value: item.keys[1]
      }
    })
  };
};
