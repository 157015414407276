import styles from './collectibleDetail.module.scss';
import { Button, ToolTip } from 'components';

import infoIcon from '../../../../assets/icons/info.svg';

const CollectibleDetails = ({ info, index }: { info: any; index: number }) => {
  const collectibles = info?.launchpad_type[index - 1];
  return (
    collectibles?.collectibles && (
      <div className={styles.collectibleDetails}>
        <div className={styles.info_header}>
          <h6>Collectible Details</h6>
        </div>

        <div className={styles.items}>
          {collectibles?.collectibles?.map((r: any, i: number) => {
            return (
              <div className={styles.item_card} key={i}>
                <p>
                  <img src={`/fans/icons/${r?.icon}.svg`} />
                  {r?.name}
                </p>
                <ToolTip content={r?.tooltip_info}>
                  <img src={infoIcon} />
                </ToolTip>
              </div>
            );
          })}
        </div>
        <div className='tableHeader__mobile__divider' />
      </div>
    )
  );
};

export default CollectibleDetails;
