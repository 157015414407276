import { Dec } from '@terra-money/terra.js';

export const protectAgainstNaN = (value: number) => (isNaN(value) ? 0 : value);

export function convertMicroDenomToDenom(
  value: number | string,
  decimals: number
): number {
  if (decimals === 0) return Number(value);

  return protectAgainstNaN(Number(value) / Math.pow(10, decimals));
}

export function convertDenomToMicroDenom(
  value: number | string,
  decimals: number
): number {
  if (decimals === 0) return Number(value);

  return protectAgainstNaN(
    parseInt(String(Number(value) * Math.pow(10, decimals)), 10)
  );
}

export function convertFromMicroDenom(denom: string) {
  return denom?.substring(1).toUpperCase();
}

export function convertToFixedDecimals(value: number | string): string {
  const amount = Number(value);
  return amount > 0.01 ? amount.toFixed(2) : String(amount);
}

export const formatTokenName = (name: string) => {
  if (name) {
    return name.slice(0, 1).toUpperCase() + name.slice(1).toLowerCase();
  }
  return '';
};

export const formatTokenPrice = (amount = '0', denom = 6): string => {
  const price = new Dec(amount).div(new Dec(10).pow(denom));

  for (let i = denom; i >= 2; i--) {
    if (price.lt(new Dec(0.1).pow(i))) {
      return price.toFixed(i + 1);
    }
  }
  return price.toFixed(2);
};

export const formatNumberString = (
  numberString: string | number | Dec,
  precision = 2
): string => {
  const value = new Dec(numberString);

  if (value.eq(0)) {
    return '0';
  }

  let res = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  })
    .format(value.toNumber())
    .replace('$', '');

  if (res.includes('.')) res = res.replace(/0+$/, '');
  if (res.endsWith('.')) return res.slice(0, -1);

  const result = res || '0';

  if (result === '0' && precision < 6) {
    return formatNumberString(numberString, precision + 1);
  }

  return result;
};
