import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import { networkType, nftConfig } from 'core/nftConfig';

const Completed = () => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);

  if (
    nftConfig[networkType].launchPadNFTs.filter(
      item => item.launchInfo.status === 'completed'
    ).length === 0
  ) {
    return <></>;
  }

  return (
    <div className='launchCompletedProjects'>
      <h6>Completed Projects</h6>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        navigation
        loop
        autoplay={{ delay: 7000 }}
        className='feedSwiper'
        slideToClickedSlide
        breakpoints={{
          1200: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        }}
      >
        {nftConfig[networkType].launchPadNFTs
          .filter(item => item.launchInfo.status === 'completed')
          .map((item, index) => {
            return (
              <SwiperSlide key={JSON.stringify(item) + index}>
                <div className='launchCompletedSlider'>
                  <div className='launchCompletedSliderInner'>
                    <span>
                      <img src='/exploreThumb.png' alt='thumb' />
                      Chill Dude
                    </span>
                    <p>{item.launchInfo.maxSupply} items</p>
                    <div className='launchlist'>
                      <div className='whitelist'>
                        <p>
                          <a href=''>WHITELIST</a>
                          <a href=''>PUBLIC MINT</a>
                        </p>
                        <p>
                          {item.launchInfo.cirSupply} /
                          <b>{item.launchInfo.maxSupply} Minted</b>
                        </p>
                      </div>
                      <div className='launchLoader'>
                        <div></div>
                      </div>
                      <div className='pricingCompleted'>
                        <b>Mint Price</b>
                        <p>{item.launchInfo.mintPrice}</p>
                      </div>
                      <div className='pricingCompleted'>
                        <b>End Date</b>
                        <p>{item.launchInfo.endDate}</p>
                      </div>
                      <div className='pricingCompleted'>
                        <b>End Time</b>
                        <p>{item.launchInfo.endTime}</p>
                      </div>
                      <div className='pricingCompleted'>
                        <b>Minimum LUA Power Required</b>
                        <p>0</p>
                      </div>
                      {/* <button>Buy on Market</button> */}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default Completed;
