import React from 'react';
import Modal from '../../../components/Modal';

import CompleteIcon from '../../../assets/images/complete.svg';
import styles from './notification.module.scss';
import {useHistory} from 'react-router-dom';

const WebAppComplete = ({
                          setTheme,
                          txHash,
                          redirectURL,
                        }: {
  setTheme: any;
  txHash: string | undefined;
  redirectURL: string | undefined;
}) => {
  const history = useHistory();

  function onClose() {
    setTheme({
      type   : undefined,
      subType: undefined,
    });
    if (redirectURL) {
      history.push(redirectURL);
    } else {
      window.location.reload();
    }
  }

  return (
    <Modal isOpen={true} title='' onClose={() => onClose()} zIndex={4} center webApp>
      <div className={styles.notification_content}>
        <img src={CompleteIcon} alt='wait'/>
        <p className={styles.title}>Successful</p>
        <div className={styles.description}>
          Your transaction is successful!
        </div>
        <button style={{background: 'transparent', color: '#787B9C', position: 'absolute', bottom: '20px'}} onClick={() => onClose()}>Close</button>
        {/*<button className='view-nft' onClick={() => onClose()}>*/}
        {/*  ViewNFT*/}
        {/*</button>*/}
      </div>
    </Modal>
  );
};

export default WebAppComplete;
