import { useQuery } from 'react-query';
import { Dec } from '@terra-money/terra.js';

import { Network, USDC_DENOM } from 'core/constants';
import { NFTAuctionInfo, NFTSaleInfo } from './queryLaunchpadNFTInfo';
import { LaunchPadNFTInfo, LaunchpadNFTMetadata } from './useEligibleStakeNFTs';
import { commonConfig } from 'core/common';
import {
  NativeTokenInfo,
  PairInfo,
  PoolInfo,
  TokenReserve,
} from './useLaunchPadNFTMinterQuery';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';
import { isJunoContractAddress } from 'utils/check';

export type MarketNFTs = {
  type: 'launchpad' | 'lp';
  tokenId: string;
  info: LaunchPadNFTInfo;
  metadata: LaunchpadNFTMetadata;
  saleInfo?: NFTSaleInfo;
  auctionInfo?: NFTAuctionInfo;
  valueUST: Dec;
  APR: string;
};

export const useTokenReserve = (
  networkType: Network,
  token_addresses: string[]
): TokenReserve | undefined => {
  const { data, isLoading } = useQuery<unknown, unknown, TokenReserve>(
    ['tokenReserve', networkType, token_addresses],
    async () => {
      const token_reserve: TokenReserve = {};

      const client = await getQueryClient();
      // query token prices
      const isToken: {
        [token: string]: boolean;
      } = {};
      const tokens = [];
      const pairInfoQueries = [];

      for (let i = 0; i < token_addresses.length; i++) {
        const token_addr = token_addresses[i];
        if (
          !isToken[token_addr] &&
          commonConfig[networkType].IS_AVAILABLE_TOKEN[token_addr]
        ) {
          isToken[token_addr] = true;
          tokens.push(token_addr);
          pairInfoQueries.push(
            client.queryContractSmart(commonConfig[networkType].SWAP_FACTORY, {
              pair: {
                asset_infos: [
                  isJunoContractAddress(token_addr)
                    ? {
                        token: {
                          contract_addr: token_addr,
                        },
                      }
                    : {
                        native_token: {
                          denom: token_addr,
                        },
                      },
                  {
                    native_token: {
                      denom: USDC_DENOM,
                    },
                  },
                ],
              },
            })
          );
        }
      }

      const pairInfos = (await Promise.all(pairInfoQueries)) as PairInfo[];

      const poolInfoQueries = [];
      for (let i = 0; i < pairInfos.length; i++) {
        poolInfoQueries.push(
          client.queryContractSmart(pairInfos[i].contract_addr, {
            pool: {},
          })
        );
      }
      const poolInfos = (await Promise.all(poolInfoQueries)) as PoolInfo[];

      for (let i = 0; i < tokens.length; i++) {
        if (
          (poolInfos[i].assets[1].info as NativeTokenInfo).native_token
            .denom === USDC_DENOM
        ) {
          token_reserve[tokens[i]] = {
            tokenAmount: poolInfos[i].assets[0].amount,
            usdcAmount: poolInfos[i].assets[1].amount,
          };
        } else {
          token_reserve[tokens[i]] = {
            tokenAmount: poolInfos[i].assets[1].amount,
            usdcAmount: poolInfos[i].assets[0].amount,
          };
        }
      }

      return token_reserve;
    }
  );

  if (isLoading || !data) {
    return undefined;
  }

  return data;
};

export default useTokenReserve;
