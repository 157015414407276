import { ChainInfo } from '@keplr-wallet/types';
import CHAIN from '../../chain_info.mainnet.json';

export const unsafelyReadChainInfoCache = () => CHAIN;

export const useChainInfo = () => {
  // const { data, isLoading } = useQuery<ChainInfo>(
  //   chainInfoQueryKey,
  //   async () => {
  //     const response = ChainData
  //     console.log("response", response)
  //     return response
  //   },
  //   {
  //     onError(e) {
  //       console.error('Error loading chain info:', e)
  //     },
  //   }
  // )
  const isLoading = false;
  const data: ChainInfo = CHAIN;

  return [data, isLoading] as const;
};
