import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import styles from './launchpad.module.scss';
import NftFooter from '../Nft/NftFooter';
import Upcoming from './upcoming';
import Completed from './completed';
import SwipeItem from './SwiperItem';
import * as api from '../../api/launchpad.api';
import Live from './live';
import Staging from './staging';
import { RewardsTable } from './index';
import CompletedMints from './CompletedMints';
import { Link } from 'react-router-dom';
import Blank from './Blank';
//import Fans from './fans';

const Launchpad = (props: any) => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
  const [launchpadList, setLaunchpadList] = useState<any>();
  const hostname = window.location.hostname;
  const checkHost = hostname === 'nft-juno.loop.markets';

  useEffect(() => {
    if (!launchpadList) {
      //Live
      api
        .FetchLaunchPadLiveList()
        .then(launchpadList => setLaunchpadList(launchpadList));
    }
  }, [launchpadList]);

  return (
    <>
      {launchpadList?.length > 0 ? (
        <div>
          {!props.onlyFeatured && (
            <Helmet>
              <title>NFT | Launchpad</title>
            </Helmet>
          )}
          <div className='launchSection'>
            <div
              className={
                props.onlyFeatured
                  ? 'launchLiveProject launchLiveProject__no_margin'
                  : 'launchLiveProject'
              }
            >
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                //navigation
                loop={launchpadList.length > 1}
                autoplay={{ delay: 4000 }}
                className='feedSwiper'
                slideToClickedSlide
                breakpoints={{
                  1200: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
              >
                {launchpadList.map((item: any, index: number) => {
                  return (
                    <SwiperSlide className={'mainslider'}>
                      <SwipeItem item={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>

            {!checkHost && <Staging />}

            {/*<div className={styles.launchPadContrainer}>*/}
            {/*    <RewardsTable />*/}
            {/*</div>*/}

            <Live />
            {/* <Fans /> */}
            <Upcoming />
            {/*<CompletedMints />*/}

            {!props.onlyFeatured && (
              <>
                <Completed />
              </>
            )}
          </div>
          {!props.onlyFeatured && <NftFooter />}
        </div>
      ) : true ? (
        <>
          <Blank />
          {!checkHost && <Staging />}
          <Upcoming />
          <CompletedMints />
          <NftFooter />
        </>
      ) : (
        <div className='noPosts'>
          <p
            className='loader'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          ></p>
        </div>
      )}
    </>
  );
};

export default Launchpad;
