import { useState } from 'react';
import HeaderSearch from './Search.modal';
import search from '../../../assets/search.png';
import { useHistory } from 'react-router-dom';

const SearchForm = ({
  classes,
  showIcon,
  mobileSearchHandle,
  isMobile
}: {
  classes?: string;
  showIcon?: boolean;
  mobileSearchHandle?: any;
  isMobile?: boolean;
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const history = useHistory();

  const resetSearch = () => {
    setShowSearch(false);
    setSearchValue('');
    mobileSearchHandle && mobileSearchHandle(false)
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      history.push(`/nftExplore?term=${searchValue}`);
      resetSearch();
      event.preventDefault();
      event.target.blur();
    }
    if (event.key === 'Escape') {
      resetSearch();
      event.preventDefault();
      event.target.blur();
    }
  };

  return (
    <div className={classes}>
      <div className='search_field'>
        <img hidden={!showIcon} src={search} alt='search' />
        <button hidden={showIcon} onClick={() => mobileSearchHandle()}>
          X
        </button>
        <input
          type='text'
          autoFocus={classes === 'mobile_search'}
          placeholder='Search Collections or NFTs'
          value={searchValue}
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          onClick={() => setShowSearch(true)}
          onKeyDown={handleKeyDown}
          //placeholder='Reward token, collection, NFT '
        />
      </div>
      {(showSearch || isMobile) && (
        <HeaderSearch search={searchValue} resetSearch={resetSearch} />
      )}
    </div>
  );
};

export default SearchForm;
