import styles from './Mesages.module.scss'
export default function Messages({type, align}:{type:string, align?: string}) {
  return (
    <div className={styles.messageComp}>
        {/* NFT Message */}
        {type == "nft" && (
          <p style={{textAlign: 'center'}}><b>Note:</b> Recently purchased NFTs may take a moment to appear in the list.</p>
        )}
    </div>
  );
}
