import { DirectusImage } from 'core/common';
import styles from './collectiondesc.module.scss';

const CollectionDesc = ({ info }: { info?: any }) => {
  return (
    info?.collection_detail?.description && (
      <div className={styles.card}>
        <div className={styles.item}>
          <div className={styles.right}>
            <span>{info?.name}</span>
            <h4>Description</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: info?.collection_detail?.description,
              }}
            />
          </div>
          <div className={styles.collectionImage}>
            <img
              src={DirectusImage(
                info?.collection_detail?.video_placeholder?.id
              )}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default CollectionDesc;
