import axios from 'axios';

export async function getMenuItems() {
  const result = await axios
    .get(`https://api.loop.markets/v1/menu_v2`);
    //console.log("Made It!")
  return result.data;
}

export async function fetchSystemStatus() {
  const result = await axios
    .get(`https://loop-markets.directus.app/items/status_types?fields=*.*`);
    //console.log("Made It!")
  return result.data?.data;
}
