import styles from './view.module.scss';
import lock from '../../../../assets/icons/fans/lock.svg';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';

const Additional_info = ({
  info,
  setPaymentModal,
}: {
  info?: any;
  setPaymentModal?: any;
}) => {
  const { address } = useRecoilValue(walletState);
  return (
    <div className={styles.benefits_bg}>
      {info?.launchpad_type?.map((r: any, i: number) => {
        const curDate = new Date().toISOString();
        const mintStarted =
          curDate >= new Date(r?.launchInfo.startDate).toISOString();

        const mintEnded =
          curDate >= new Date(r?.launchInfo.endDate).toISOString();

        return (
          <div key={i}>
            <div className={styles.benefits_type}>
              <div className={styles.info_header}>
                <h6>
                  {info?.launchpad_type[i]?.collections_type?.name}{' '}
                  <span>Benefits</span>
                </h6>
                <p
                  dangerouslySetInnerHTML={{
                    __html: info?.launchpad_type[i]?.collections_type?.desc,
                  }}
                />
                {mintStarted ? (
                  mintEnded ? (
                    <button className={styles.buy} disabled>
                      Mint Ended
                    </button>
                  ) : (
                    <button
                      disabled={!address}
                      onClick={() => setPaymentModal(true)}
                      className={styles.buy}
                    >
                      Buy Now
                    </button>
                  )
                ) : (
                  <button disabled className={styles.buy}>
                    Minting Soon
                  </button>
                )}
              </div>
              <div>
                {info?.launchpad_type[i]?.benefits?.benefit.map(
                  (r: any, i: number) => {
                    return (
                      <div className={styles.benefit} key={i}>
                        <div className={styles.img}>
                          <img
                            src={
                              'https://loop-markets.directus.app/assets/' +
                              r?.image
                            }
                          />
                        </div>
                        <div>
                          <b>{r?.name}</b>
                          <br />
                          <span>{r?.desc}</span>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className={styles.divider} />
          </div>
        );
      })}
    </div>
  );
};

export default Additional_info;
