import {useCallback, useEffect, useState} from 'react';
import {Dec} from '@terra-money/terra.js';
import {toast} from 'react-toastify';
import {
    EligibleNFT,
    queryEligibleStakeNFTs,
} from 'hooks/useEligibleStakeNFTs';
import LaunchpadCard from './launchpadCard';
import useTokenReserve from 'hooks/useTokenReserve';
import {commonConfig} from 'core/common';
import {queryStakedNFTs} from 'hooks/queryStakedNFTs';
import {nftStakeClaimMsg} from 'helper/junoContractMsg';
import {networkType, nftConfig} from 'core/nftConfig';
import {queryListedNFTs} from '../../hooks/queryListedNFTs';
import {useRecoilValue} from 'recoil';
import {walletState, WalletStatusType} from '../../state/walletAtoms';
import useJunoTransactions from '../../hooks/newHooks/useJunoTransactions';
import {formatNumberString} from 'utils/conversion/conversion';
import WalletConnectState from './walletConnectState';
import {queryMyLevana, queryMyStandardNfts, StandardNft} from 'hooks/queryMyStandardNfts';
import LaunchpadCardStandard from './launchpadCardStandard';
import LevanaCard from "./LevanaCard";

const MyNftOwned = ({isWebApp}: { isWebApp: any }) => {
    const {address, status} = useRecoilValue(walletState);
    const {submit, estimateGas} = useJunoTransactions();
    const token_reserve = useTokenReserve(
        networkType,
        Object.keys(commonConfig[networkType]?.IS_AVAILABLE_TOKEN || {})
    );

    const [totalSaleNfts, setTotalSaleNfts] = useState(0);
    const [totalOnwedNfts, setTotalOwnedNfts] = useState(0);
    const [totalStakedNfts, setTotalStakedNfts] = useState(0);
    const [myNfts, setNfts] = useState<EligibleNFT[]>([]);
    const [isRewardNftsLoading, setRewardNftsLoading] = useState(true);
    const [isLevanaLoading, setIsLevanaLoading] = useState(false);
    const [rewardNftMoreExists, setRewardNftMoreExists] = useState(false);
    const [result, setResult] = useState({
        totalValue: new Dec(0),
        dailyValue: new Dec(0),
    });

    const [totalStandardNfts, setTotalStandardNfts] = useState(0);
    const [myStandardNfts, setStandardNfts] = useState<StandardNft[]>([]);
    const [isStandardNftsLoading, setStandardNftsLoading] = useState(true);
    const [standardNftMoreExists, setStandardNftMoreExists] = useState(false);
    const [levanaNFTS, setLevanaNFTS] = useState<any>([]);

    // const [isRubyDeckLoading, setRubyDeckLoading] = useState(false);
    const [rubyDeskList, setRubyDeskList] = useState<any>([]);

    const [isRubySapphireLoading, setRubySapphireLoading] = useState(false);
    const [rubySapphireList, setRubySapphireList] = useState<any>([]);

    useEffect(() => {
        if (token_reserve) {
            setRewardNftsLoading(true);
            queryEligibleStakeNFTs(networkType, address).then(
                ({nfts, stakerRewardsInfo, totalCount, totalStakeCount}) => {
                    setTotalOwnedNfts(totalCount);
                    setTotalStakedNfts(totalStakeCount);
                    setRewardNftMoreExists(nfts.length < totalCount);
                    setRewardNftsLoading(false);

                    let totalValue = new Dec(0);
                    let dailyValue = new Dec(0);

                    for (let i = 0; i < nfts.length; i++) {
                        const info = nfts[i].info;
                        if (token_reserve[info.token1_addr]) {
                            nfts[i].dailyValue = nfts[i].dailyValue.add(
                                new Dec(info.token1_amount)
                                    .mul(token_reserve[info.token1_addr].usdcAmount)
                                    .div(token_reserve[info.token1_addr].tokenAmount)
                            );
                        }
                        if (token_reserve[info.token2_addr]) {
                            nfts[i].dailyValue = nfts[i].dailyValue.add(
                                new Dec(info.token2_amount)
                                    .mul(token_reserve[info.token2_addr].usdcAmount)
                                    .div(token_reserve[info.token2_addr].tokenAmount)
                            );
                        }

                        nfts[i].totalValue = nfts[i].dailyValue.mul(nfts[i].daysRemain);
                    }

                    for (let i = 0; i < stakerRewardsInfo.length; i++) {
                        if (token_reserve[stakerRewardsInfo[i].token]) {
                            dailyValue = dailyValue.add(
                                new Dec(stakerRewardsInfo[i].daily_reward)
                                    .mul(token_reserve[stakerRewardsInfo[i].token].usdcAmount)
                                    .div(token_reserve[stakerRewardsInfo[i].token].tokenAmount)
                            );
                            totalValue = totalValue.add(
                                new Dec(stakerRewardsInfo[i].total_reward)
                                    .mul(token_reserve[stakerRewardsInfo[i].token].usdcAmount)
                                    .div(token_reserve[stakerRewardsInfo[i].token].tokenAmount)
                            );
                        }
                    }

                    setNfts(nfts);

                    setResult({
                        dailyValue,
                        totalValue,
                    });
                }
            );
        }

        queryListedNFTs(address).then(({totalCount}) => {
            setTotalSaleNfts(totalCount);
        });
    }, [address, token_reserve, totalSaleNfts]);

    const loadMoreRewardNfts = () => {
        if (token_reserve) {
            setRewardNftsLoading(true);
            queryEligibleStakeNFTs(networkType, address, myNfts.length).then(
                ({nfts, totalCount}) => {
                    setRewardNftMoreExists(myNfts.length + nfts.length < totalCount);
                    setRewardNftsLoading(false);

                    nfts = [...myNfts, ...nfts];

                    for (let i = 0; i < nfts.length; i++) {
                        const info = nfts[i].info;
                        if (token_reserve[info.token1_addr]) {
                            nfts[i].dailyValue = nfts[i].dailyValue.add(
                                new Dec(info.token1_amount)
                                    .mul(token_reserve[info.token1_addr].usdcAmount)
                                    .div(token_reserve[info.token1_addr].tokenAmount)
                            );
                        }
                        if (token_reserve[info.token2_addr]) {
                            nfts[i].dailyValue = nfts[i].dailyValue.add(
                                new Dec(info.token2_amount)
                                    .mul(token_reserve[info.token2_addr].usdcAmount)
                                    .div(token_reserve[info.token2_addr].tokenAmount)
                            );
                        }

                        nfts[i].totalValue = nfts[i].dailyValue.mul(nfts[i].daysRemain);
                    }

                    setNfts(nfts);
                }
            );
        }
    };

    useEffect(() => {
        setStandardNftsLoading(true);
        queryMyStandardNfts('owned', networkType, address).then(
            ({nfts, totalCount}) => {
                setTotalStandardNfts(totalCount);
                setStandardNftMoreExists(nfts.length < totalCount);
                setStandardNftsLoading(false);
                setStandardNfts(nfts);
            }
        );
    }, [address]);

    useEffect(() => {
        setIsLevanaLoading(true);
        queryMyLevana(address, 'juno1a90f8jdwm4h43yzqgj4xqzcfxt4l98ev970vwz6l9m02wxlpqd2squuv6k').then(
            (data) => {
                setLevanaNFTS(data)
            }
        );
    }, [address]);

    useEffect(() => {
        setRubyDeskList(true);
        queryMyLevana(address, 'juno133u3xnzwmfkje570ycwwnx4cm7m5r0xtjq6zcvyrf47phrtkp4vsk723zg').then(
            (data) => {
                setRubyDeskList(data)
            }
        );
    }, [address]);

    useEffect(() => {
        setRubySapphireLoading(true);
        queryMyLevana(address, 'juno1cxjtgdzy6av76n0h2s3pxqfyfzldzk75uzrukgntlz3tldhasakqvqckuv').then(
            (data) => {
                setRubySapphireList(data)
            }
        );
    }, [address]);

    const loadMoreStandardNfts = () => {
        setStandardNftsLoading(true);
        queryMyStandardNfts(
            'owned',
            networkType,
            address,
            myStandardNfts.length
        ).then(({nfts, totalCount}) => {
            setTotalStandardNfts(totalCount);
            setStandardNftMoreExists(
                myStandardNfts.length + nfts.length < totalCount
            );
            setStandardNftsLoading(false);
            setStandardNfts([...myStandardNfts, ...nfts]);
        });
    };

    const claimHandler = useCallback(async () => {
        const res = await queryStakedNFTs(address);
        const txMsgs = [];
        for (let i = 0; i < res.nfts.length; i++) {
            const nft = res.nfts[i];
            const msg = nftStakeClaimMsg({
                address,
                networkType,
                nftStaking: nftConfig[networkType].STAKING,
                nft: nft.contract,
                token_id: nft.token_id,
            });

            const fee = await estimateGas([msg]);
            if (fee) {
                txMsgs.push(msg);
            }
        }

        if (txMsgs.length === 0) {
            toast.error(
                <div>
                    <div>No rewards are currently available.</div>
                    <div>Stake your Reward NFTs to earn rewards.</div>
                </div>
            );
        } else {
            const fee = await estimateGas(txMsgs);
            console.log('FEE:', fee);
            submit({msgs: txMsgs});
        }
    }, [address, estimateGas, submit]);

    const myStandardTokenIds = myStandardNfts?.map((item) => item.tokenId)
    const standardNFTsList = [...myStandardNfts, ...levanaNFTS.filter((item) => !myStandardTokenIds.includes(item.tokenID))?.map((item) => ({
        ...item,
        contract: item.contractId,
        tokenId: item.tokenID,
        metadata: {...item, tokenId: item?.tokenID, image: item?.image}
    }))]

    return (
        <section>
            {(status === WalletStatusType.connected &&
                (myNfts?.length > 0 || myStandardNfts.length > 0)) ||
            isRewardNftsLoading ||
            isStandardNftsLoading ? (
                <>
                    {!isWebApp && (
                        <div className='mynftLeft'>
                            <ul>
                                <li>
                    <span>
                      <b>
                        Total Staking Value {/* <img src='info.png' alt='' /> */}
                      </b>
                      <p>
                        {formatNumberString(result?.totalValue.div(1000000))}{' '}
                          <i>USDC</i>
                      </p>
                    </span>
                                    <img src='/m2.png' alt='' style={{marginLeft: 10}}/>
                                </li>
                                <li>
                    <span>
                      <b>
                        Daily Staking Value {/* <img src='info.png' alt='' /> */}
                      </b>
                      <p>
                        {formatNumberString(result?.dailyValue.div(1000000))}{' '}
                          <i>USDC</i>
                      </p>
                    </span>
                                    <img src='/m3.png' alt='' style={{marginLeft: 10}}/>
                                </li>
                                <li>
                    <span>
                      <b>
                        Staking Ratio
                          {/* <img src='info.png' alt='' /> */}
                      </b>
                      <p>
                        {totalOnwedNfts + totalSaleNfts === 0
                            ? 0
                            : formatNumberString(
                                new Dec(totalStakedNfts * 100).div(
                                    totalOnwedNfts + totalSaleNfts
                                )
                            )}{' '}
                          <i>%</i>
                      </p>
                    </span>
                                    <img src='/m4.png' alt='' style={{marginLeft: 10}}/>
                                </li>
                            </ul>
                        </div>
                    )}
                    <div
                        className='mynftRight'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {(isRewardNftsLoading || myNfts?.length > 0) && (
                            <div>
                                <div className='header'>
                                    <h6 style={{fontSize: '25px'}}>Reward NFTs</h6>
                                    {myNfts.findIndex(
                                        info =>
                                            Math.floor(
                                                info.stakeInfo.total_staked_time / 60 / 60 / 24
                                            ) > info.stakeInfo.claimed_days
                                    ) !== -1 && (
                                        <button className='claim' onClick={() => claimHandler()}>
                                            Claim All Rewards
                                        </button>
                                    )}
                                </div>
                                {myNfts?.length > 0 &&
                                    myNfts?.map((nft, index) => {
                                        if (nft.type === 'launchpad') {
                                            return (
                                                <LaunchpadCard
                                                    key={JSON.stringify(nft) + index}
                                                    data={nft}
                                                />
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}

                                {isRewardNftsLoading ? (
                                    <div className='noPosts'>
                                        <p
                                            className='loader'
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                            }}
                                        ></p>
                                    </div>
                                ) : (
                                    rewardNftMoreExists && (
                                        <div className='flex-center'>
                                            <button onClick={loadMoreRewardNfts}>Load More</button>
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {(isStandardNftsLoading || standardNFTsList?.length > 0) && (
                            <div
                                style={{
                                    marginTop:
                                        isRewardNftsLoading || myNfts.length > 0 ? '50px' : '0px',
                                }}
                            >
                                <div className='header'>
                                    <h6 style={{fontSize: '25px'}}>Standard NFTs</h6>
                                </div>
                                <div className='trendingInfo'>
                                    <ul>
                                        {standardNFTsList?.length > 0 &&
                                            standardNFTsList?.map((nft, index) => {
                                                if (nft.type === 'launchpad') {
                                                    return (
                                                        <li>
                                                            <LaunchpadCardStandard
                                                                key={JSON.stringify(nft) + index}
                                                                data={nft}
                                                            />
                                                        </li>
                                                    );
                                                } else {
                                                    return <></>;
                                                }
                                            })}
                                    </ul>
                                </div>

                                {isStandardNftsLoading ? (
                                    <div className='noPosts'>
                                        <p
                                            className='loader'
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                            }}
                                        ></p>
                                    </div>
                                ) : (
                                    standardNftMoreExists && (
                                        <div className='flex-center'>
                                            <button onClick={loadMoreStandardNfts}>Load More</button>
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {!isRewardNftsLoading &&
                            !isStandardNftsLoading &&
                            myNfts.length === 0 &&
                            myStandardNfts.length === 0 && <p>No NFTs in your wallet yet</p>}
                    </div>
                    <div>
                        {myNfts?.length > 0 && totalSaleNfts > 0 && (
                            <a href='/mynft/onsale'>{totalSaleNfts} nfts are on sale</a>
                        )}
                    </div>
                    {/*{(levanaNFTS?.length > 0) && (
                        <div
                            style={{
                                marginTop:
                                    isRewardNftsLoading || myNfts.length > 0 ? '50px' : '0px',
                            }}
                        >
                            <div className='header'>
                                <h6 style={{fontSize: '25px'}}>Levana NFTs</h6>
                            </div>
                            <div className='trendingInfo'>
                                <ul>
                                    {levanaNFTS?.length > 0 &&
                                        levanaNFTS?.map((nft, index) => {
                                            return (
                                                <li>
                                                    <LevanaCard
                                                        key={JSON.stringify(nft) + index}
                                                        data={nft}
                                                    />
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    )}*/}

                    {(rubyDeskList?.length > 0) && (
                        <div
                            style={{
                                marginTop:
                                    isRewardNftsLoading || myNfts.length > 0 ? '50px' : '0px',
                            }}
                        >
                            <div className='header'>
                                <h6 style={{fontSize: '25px'}}>Ruby Deck NFTs</h6>
                            </div>
                            <div className='trendingInfo'>
                                <ul>
                                    {rubyDeskList?.length > 0 &&
                                        rubyDeskList?.map((nft, index) => {
                                            return (
                                                <li>
                                                    <LevanaCard
                                                        key={JSON.stringify(nft) + index}
                                                        data={nft}
                                                    />
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    )}
                    {(rubySapphireList?.length > 0) && (
                        <div
                            style={{
                                marginTop:
                                    isRewardNftsLoading || myNfts.length > 0 ? '50px' : '0px',
                            }}
                        >
                            <div className='header'>
                                <h6 style={{fontSize: '25px'}}>Ruby Sapphire NFTs</h6>
                            </div>
                            <div className='trendingInfo'>
                                <ul>
                                    {rubySapphireList?.length > 0 &&
                                        rubySapphireList?.map((nft, index) => {
                                            return (
                                                <li>
                                                    <LevanaCard
                                                        key={JSON.stringify(nft) + index}
                                                        data={nft}
                                                    />
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <WalletConnectState
                        type='owned'
                        connected={Boolean(address)}
                        noNft={myNfts?.length > 0}
                        isWebApp={isWebApp}
                    />
                </>
            )}
        </section>
    );
};

export default MyNftOwned;
