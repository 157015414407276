import { useState, useEffect, useCallback } from 'react';
import NftFooter from '../../../Nft/NftFooter';
import LaunchRewards from './reward.table';
import LaunchpadForm from './mint.form';
import UpcomingForm from './upcoming.form';
import UpcomingRewards from './upcomingReward.table';
import { useParams } from 'react-router-dom';
import { networkType } from 'core/nftConfig';
import useLaunchPadNFTMinterQuery from 'hooks/useLaunchPadNFTMinterQuery';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import ProjectInfo from './projectInfo';
import * as api from '../../../../api/fansLaunchpad.api';
import { Helmet } from 'react-helmet';
import DataLayer from 'components/DataLayer/DataLayer';

import ImageGallery from 'react-image-gallery';
import styles from './view.module.scss';
import GiftCard from 'components/launchpad/giftCard';
import CollectibleDetails from './collectibleDetails';
import { getParam, setParam } from 'components/URLHandler/helper';
import Modal from 'components/Modal';
import { Button, ToolTip } from 'components';

import infoIcon from '../../../../assets/icons/info.svg';
import { DirectusImage } from 'core/common';
import Gallery from 'components/ImageGallery/gallery';

const LaunchpadView = (props: any) => {
  const params = useParams<any>();
  const [launchpadNFT, setData] = useState<any>();
  const [loaded, setLoaded] = useState(false);
  const { address } = useRecoilValue(walletState);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [mintCount, setMintCount] = useState(1);
  const mintableCount = 1;

  const queryResult = useLaunchPadNFTMinterQuery(
    networkType,
    launchpadNFT?.info?.launchinfo?.MINTER,
    address
  );
  useEffect(() => {
    if (!launchpadNFT && !loaded) {
      const address = params.address;
      api.FetchLaunchPad(address).then(launchpadNFT => setData(launchpadNFT));
      setLoaded(true);
    }
  }, [launchpadNFT, params, loaded]);

  const [type, setType] = useState(1);
  const paramType = getParam('type');
  const [price, symbol] = launchpadNFT?.info?.launchpad_type[
    Number(type) - 1
  ]?.launchInfo.mintPrice?.split(' ') || ['', 'USDC'];

  useEffect(() => {
    if (paramType) {
      setType(paramType);
    }
  }, [paramType]);

  const [paymentUrl, setPaymtentUrl] = useState('');

  const generate_paymentUrl = useCallback(async () => {
    const launchpad_id = launchpadNFT?.info?.id;
    const collection_name = launchpadNFT?.info.project_name;
    const mintPrice = 470825 * (price * mintCount);
    const data = {
      wallet_addr: address,
      launchpad_id: launchpad_id,
      collection_name: collection_name,
      payment_amount: mintPrice,
    };
    console.log(data);
    setPaymentLoading(true);
    try {
      await api.generatePaymentUrl(data).then((url: string) => {
        if (url) {
          setPaymtentUrl(url);
          window.location.href = url;
        } else {
          setPaymentLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [paymentUrl, launchpadNFT, address]);

  return (
    <>
      {launchpadNFT && (
        <>
          {queryResult && (
            <DataLayer
              itemInfo={{
                type: 'viewItemLaunchapad',
                id: queryResult?.mintConfig?.nft_symbol,
                title: launchpadNFT?.info?.project_name,
                creator: launchpadNFT?.info?.additional_info?.founder_status,
                collection: launchpadNFT?.info?.project_name,
                price: queryResult?.mintConfig?.nft_price_amount,
                currency:
                  launchpadNFT?.info?.launchinfo?.mintPrice?.split(' ')[1] ||
                  'USDC',
              }}
            />
          )}
          <Helmet>
            <title>NFT | Launchpad: {launchpadNFT?.info?.project_name}</title>
            <meta
              name='description'
              content={(launchpadNFT?.info?.project_about || '')
                .replace(/(<([^>]+)>)/gi, '')
                .substring(0, 155)}
            />
            <meta property='og:url' content='%PUBLIC_URL%' />
            {/* <meta
              property='og:image'
              content={
                'https://loop-markets.directus.app/assets/' +
                launchpadNFT?.info?.banner.id
              }
            /> */}

            <meta
              name='twitter:description'
              content={(launchpadNFT?.info?.project_about || '')
                .replace(/(<([^>]+)>)/gi, '')
                .substring(0, 155)}
            />
            {/* <meta
              name='twitter:image'
              content={
                'https://loop-markets.directus.app/assets/' +
                launchpadNFT?.info?.banner.id
              }
            />
            <meta
              name='twitter:card'
              content={
                'https://loop-markets.directus.app/assets/' +
                launchpadNFT?.info?.banner.id
              }
            /> */}
          </Helmet>
          <div
            className={styles.back}
            onClick={() =>
              (window.location.href = `https://loop.fans/artist/${launchpadNFT?.info?.artist?.username}`)
            }
          >
            <img
              src='/barrow.png'
              alt='aro_lft.png'
              style={{ marginRight: '10px' }}
            />{' '}
            {launchpadNFT?.info?.artist?.first_name} Profile
          </div>

          <div className={styles.typeSection}>
            <div className={styles.types}>
              {launchpadNFT?.info?.launchpad_type?.map((r: any, i: number) => {
                return (
                  <div
                    key={i}
                    className={
                      type === i + 1 ? styles.type_active : styles.type
                    }
                    onClick={() => {
                      setType(i + 1);
                      setParam('type', i + 1);
                    }}
                  >
                    Type {i + 1}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.launchViewHeader}>
            <div className={styles.launchViewLeft}>
              <div className={styles.nft_view}>
                <Gallery launchpadNFT={launchpadNFT} index={Number(type) - 1} />
              </div>
            </div>

            <div className={styles.launchViewRight}>
              {launchpadNFT?.info?.project_status == 'live' && (
                <LaunchpadForm
                  queryResult={queryResult}
                  launchpadNFT={launchpadNFT.info}
                  index={Number(type) - 1}
                  setPaymentModal={setPaymentModal}
                />
              )}
              {launchpadNFT?.info?.project_status == 'upcoming' && (
                <UpcomingForm
                  queryResult={queryResult}
                  launchpadNFT={launchpadNFT.info}
                  index={Number(type) - 1}
                />
              )}
            </div>
          </div>
          {/* {!isAirdropNFt && launchpadNFT?.info?.reward_status == true && (
            <>
              {launchpadNFT?.info?.project_status == 'live' && (
                <LaunchRewards queryResult={queryResult} />
              )}
              {launchpadNFT?.info?.project_status == 'upcoming' && (
                <UpcomingRewards launchpadNFT={launchpadNFT.info} />
              )}
            </>
          )} */}
          <ProjectInfo
            info={launchpadNFT.info}
            setPaymentModal={setPaymentModal}
          />
          <CollectibleDetails info={launchpadNFT.info} index={Number(type)} />
          <GiftCard setPaymentModal={setPaymentModal} />
        </>
      )}
      <NftFooter />

      <Modal
        isOpen={paymentModal}
        title=''
        onClose={() => setPaymentModal(false)}
        modalContentClass={styles.modalContent}
        blur={true}
      >
        <div className={styles.paymentModal}>
          <div className={styles.modalHeader}>
            <img
              src={DirectusImage(
                launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.collection
                  .icon
              )}
            />
            <h6>
              {
                launchpadNFT?.info?.launchpad_type[Number(type) - 1].collection
                  .name
              }
            </h6>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.items}>
              <div className={styles.item}>
                <div className={styles.left}>NFTs</div>
                <div className={styles.right}>
                  <div className={styles.input}>
                    <button
                      id='minus'
                      className={styles.minus}
                      disabled={!address || mintCount === 0}
                      onClick={() => {
                        setMintCount(mintCount > 0 ? mintCount - 1 : 0);
                      }}
                    >
                      {' '}
                      -{' '}
                    </button>
                    <input
                      type='text'
                      disabled={!address}
                      value={mintCount}
                      placeholder='0'
                      onChange={e => {
                        const value = parseInt(e.target.value);
                        setMintCount(
                          value < 0
                            ? 0
                            : value <= mintableCount
                            ? value
                            : mintableCount
                        );
                      }}
                    />
                    <button
                      id='plus'
                      className={styles.plus}
                      disabled={!address || mintCount === mintableCount}
                      onClick={() => {
                        const value = mintCount + 1;
                        setMintCount(
                          value < 0
                            ? 0
                            : value <= mintableCount
                            ? value
                            : mintableCount
                        );
                      }}
                    >
                      {' '}
                      +{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.left}>
                  Fees · 0%
                  <ToolTip content={'. . . .'}>
                    <img src={infoIcon} />
                  </ToolTip>
                </div>
                <div className={styles.right}>$0.00</div>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <div className={styles.left}>
                  Total cost · 0%
                  <ToolTip content={'. . . .'}>
                    <img src={infoIcon} />
                  </ToolTip>
                </div>
                <div className={styles.right}>
                  ${price * mintCount} {symbol}
                  <br />
                  <span>{470825 * (price * mintCount)} COP</span>
                </div>
              </div>
            </div>
            <Button
              className={styles.buy}
              disabled={!address || mintCount === 0}
              onClick={() => {
                setPaymentModal(false);
                generate_paymentUrl();
              }}
            >
              Proceed to Pay
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={paymentLoading}
        title=''
        onClose={() => {
          setPaymentLoading(false);
        }}
        modalContentClass={styles.modalContent}
        blur={true}
      >
        <div className={styles.content}>
          <div className={styles.loader}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={styles.text}>
            <h3>Generating Payment Link</h3>
            <p>Please do not close this modal</p>
          </div>
          {/* <iframe
            src="https://checkout.wompi.co/l/test_2kZTX2"
            frameBorder="0"
            width={"100%"}
            height={"100%"}
            style={{height: "100vh"}}
          /> */}
        </div>
      </Modal>
    </>
  );
};

export default LaunchpadView;
