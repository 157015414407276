import { BACKEND_ENDPOINT } from 'core/common';
import axios from 'axios';
import {NFT_COLLECTIONS, nftConfig} from 'core/nftConfig';
import {Network} from "../core/constants";

export type UserActivity = {
  id: string;
  event: string;
  nftId: string;
  from: string;
  contractId: string;
  to: string;
  amount: string;
  denom: string;
  timestamp: string;
  hash?: string;
  icon?: string;
  name?: string;
  url?: string;
  nft_addr?: string;
  nft_id?: string;
  isLaunchpad?: boolean;
  nft: {
    project: string,
    name: string
    tokenID: string
  }
};

export const queryUserActivity = async (
  nftId: string,
  event: string,
  dateSortOrder: boolean
): Promise<{
  nodes: UserActivity[];
}> => {
  let eventFilter = ""
  if (event === "Bid") {
    eventFilter = `
      event: {
        in: ["Bid"]
      }
    `
  }
  else if (event === "Mint") {
    eventFilter = `
      event: {
        in: ["Mint"]
      }
    `}
  else if (event === "Sold") {
    eventFilter = `
      event: {
        in: ["Sold"]
      }
    `}
  else if (event === "Rewards") {
    eventFilter = `
      event: {
        in: ["Stake", "Unstake", "Claim"]
      }
    `}
  const query = `
    {
      userActivities(
        filter: {
          nftId: {
            equalTo: "${nftId}"
          }
          ${eventFilter}
        }
        orderBy: ${dateSortOrder ? 'TIMESTAMP_DESC' : 'TIMESTAMP_ASC'}
      ) {
        nodes {
            id
            event
            nftId
            from
            to
            amount
            denom
            timestamp
            contractId
            nft{
              project
              name
              tokenID
            }
        }
      }
    }`
  const data: {
    data: {
      userActivities: {
        nodes: UserActivity[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query,
    })
    .then((response: { data: any }) => response.data);

  return {
    nodes: data.data.userActivities.nodes?.map((item) =>{
      const hash = item.id.split('-')?.[2];
      const nft = NFT_COLLECTIONS.find((li) => li.address === item?.contractId)
      const config = nftConfig[Network.MAINNET]?.launchPadNFTs?.find((li) => li.NFT === nft?.address)
      const name = nft?.name
      const icon = nft?.icon
      const url = nft?.url
      const nft_addr = config?.NFT
      const id = item?.nft?.tokenID
      const launchInfo = config?.launchInfo
      const launchEndDate = launchInfo?.endDate;
      const launchEndTime = launchInfo?.endTime;
      const date = new Date().getTime();
      const endDate = new Date(launchEndDate + ' ' + launchEndTime).getTime();
      return {...item, hash, icon, name, url, nft_addr, nft_id: id, isLaunchpad: date < endDate}
    })
  };
};

export const queryUserTransaction = async (
  address = ' ',
  event: string,
  dateSortOrder: boolean
): Promise<{
  nodes: UserActivity[];
}> => {
  let eventFilter = ""
  if (event === "Bid") {
    eventFilter = `
      event: {
        in: ["Bid"]
      }
    `
  }
  else if (event === "Mint") {
    eventFilter = `
      event: {
        in: ["Mint"]
      }
    `}
  else if (event === "Sold") {
    eventFilter = `
      event: {
        in: ["Sold"]
      }
    `}
  else if (event === "Rewards") {
    eventFilter = `
      event: {
        in: ["Stake", "Unstake", "Claim"]
      }
    `}

  const query = `
  {
    userActivities(
      filter: {
        or: [
          {
            from: {
              equalTo:"${address}"
            }
          }
          {
            to:{
              equalTo: "${address}"
            }
          }
        ]
        ${eventFilter}
      }
      orderBy: ${dateSortOrder ? 'TIMESTAMP_DESC' : 'TIMESTAMP_ASC'}
    ) {
      nodes {
          id
          event
          nftId
          from
          to
          amount
          denom
          timestamp
          contractId
          nft{
            project
            name
            tokenID
          }
      }
    }
  }`

  const data: {
    data: {
      userActivities: {
        nodes: UserActivity[];
      };
    };
  } = await axios
    .post(BACKEND_ENDPOINT, {
      operationName: null,
      query: query,
    })
    .then((response: { data: any }) => response.data);
  return {
    nodes: data.data.userActivities.nodes?.map((item) =>{
      const hash = item.id.split('-')?.[2];
      const nft = NFT_COLLECTIONS.find((li) => li.address === item?.contractId)
      const config = nftConfig[Network.MAINNET]?.launchPadNFTs?.find((li) => li.NFT === nft?.address)
      const name = nft?.name
      const icon = nft?.icon
      const url = nft?.url
      const nft_addr = config?.NFT
      const id = item?.nft?.tokenID
      const launchInfo = config?.launchInfo
      const launchEndDate = launchInfo?.endDate;
      const launchEndTime = launchInfo?.endTime;
      const date = new Date().getTime();
      const endDate = new Date(launchEndDate + ' ' + launchEndTime).getTime();
      return {...item, hash, icon, name, url, nft_addr, nft_id: id, isLaunchpad: date <= endDate}
    })
  };
};
