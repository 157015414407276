import { useState } from 'react';
import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/pagination/pagination.min.css';
import Countdown, { zeroPad } from 'react-countdown';
import CountdownComp from './countdown';
import { LaunchPadNFT } from 'core/nftConfig';
import { NFTMinterQuery } from 'hooks/useLaunchPadNFTMinterQuery';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const UpcomingForm = ({
  queryResult,
  launchpadNFT,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT: LaunchPadNFT;
}) => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
  const [mintInput, setMintInput] = useState(0);

  const mintValue = (e: any) => {
    const type = e.target.id;
  };

  const isMintStart =
    queryResult &&
    queryResult.mintConfig.mint_start_time !== 0 &&
    new Date().getTime() / 1000 > queryResult.mintConfig.mint_start_time;

  const isPublicMint =
    isMintStart &&
    new Date().getTime() / 1000 >=
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.whitelist_mint_period &&
    new Date().getTime() / 1000 <
      queryResult.mintConfig.mint_start_time +
        queryResult.mintConfig.public_mint_period;

  return (
    <div className='nft_view' style={{ marginTop: '100px' }}>
      <div className='header'>
        <div className='upocming'>UPCOMING</div>
        {!launchpadNFT.launch_tba && <CountdownComp launchpadNFT={launchpadNFT} />}
      </div>
      <div className='details'>
        <label>
          <p>
            Items <b>{launchpadNFT.launchInfo.maxSupply}</b>
          </p>
        </label>
        <label>
          <p>
            Start Date
            <b>
              {launchpadNFT.launch_tba ? 'TBA' : (
                <>
                  {new Date(launchpadNFT.launchInfo.startDate).getUTCDate()}
                  {' '}
                  {monthNames[new Date(launchpadNFT.launchInfo.startDate).getUTCMonth()]}
                  {' '}
                  {new Date(launchpadNFT.launchInfo.startDate).getUTCFullYear()}
                </>
              )}
            </b>
          </p>
        </label>
        <label>
          <p>
            End Date
            <b>
              {launchpadNFT.launch_tba ? 'TBA' : (
                <>
                  {new Date(launchpadNFT.launchInfo.endDate).getUTCDate()}
                  {' '}
                  {monthNames[new Date(launchpadNFT.launchInfo.endDate).getUTCMonth()]}
                  {' '}
                  {new Date(launchpadNFT.launchInfo.endDate).getUTCFullYear()}
                </>
              )}
            </b>
          </p>
        </label>
        <label>
          <p>
            Mint Price <b>{launchpadNFT.launchInfo.mintPrice}</b>
          </p>
        </label>
      </div>
      <div hidden className='footer'>
        <button id='send' onClick={e => mintValue(e)} disabled>
          {isPublicMint ? 'MINT NFT' : 'APPLY FOR ALLOW LIST'}
        </button>
      </div>
    </div>
  );
};

export default UpcomingForm;
