import NftFooter from '../Nft/NftFooter';
import { useEffect, useRef, useState } from 'react';
import SelectBox, { Option } from '../../components/Select';
import styles from './collections.module.scss';
import { NFT_COLLECTIONS } from 'core/nftConfig';
import { NftCollection, queryCollections } from 'hooks/queryCollections';
import { formatNumberString } from 'utils/conversion/conversion';
import { Dec } from '@terra-money/terra.js';
import * as api from '../../api/collection.api';
import usePrevious from 'pages/Shared/hooks/usePrevious';
import NftLoading from 'components/NftLoading';

const Collection = (props: any) => {
  const isFans = location.pathname.startsWith("/fans");
  const pageRoute = (isFans ? '/fans/exploreCollection/' : '/exploreCollection/')

  const [sidebarActive, setSidebar] = useState(false);
  const [filterbarActive, setFilterbar] = useState(false);

  const [nftType, setNftType] = useState<'Reward' | 'All'>('All');
  const [sortOrder, setSortOrder] = useState<'Asc' | 'Desc'>('Desc');

  const sortOptions = [
    { value: 'date_created', label: 'Recently Listed' },
    { value: 'floorPrice', label: 'Floor Price' },
    { value: 'volumn', label: 'Total Volume' },
  ];
  const [sortType, setSortType] = useState<Option>(sortOptions[2]);

  const collectionOptions = NFT_COLLECTIONS.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  }).map(option => ({
    value: option.address,
    label: option.name,
  }));
  const [collection, setCollection] = useState<Option | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [moreExists, setMoreExists] = useState(false);

  const [collections, setCollections] = useState([] as NftCollection[]);

  const [rewardToken, setRewarToken] = useState<Option | null>(null);
  const rewardOptions = NFT_COLLECTIONS?.reduce((acc, cur) => {
    return [...acc, ...cur.rewardTokens];
  }, [] as string[])
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    .map(option => ({ value: option, label: option }));

  //rewardOptions = rewardOptions.filter(r => r.value != 'NoReward');

  const toggleFilter = () => {
    setFilterbar(!filterbarActive);
  };

  const resetSorting = () => {
    setNftType('All');
    setSortOrder('Desc');
    setSortType(sortOptions[0]);
    setRewarToken(null);
    setCollection(null);
  };

  const prevType = usePrevious(sortType?.value.toString());
  const prevSortOrder = usePrevious(sortOrder);
  const prevnftType = usePrevious(nftType);
  const prevRewardToken = usePrevious(rewardToken?.value.toString());

  useEffect(() => {
    if (
      prevType != sortType?.value.toString() ||
      prevSortOrder != sortOrder ||
      prevnftType != nftType ||
      prevRewardToken != rewardToken?.value.toString()
    ) {
      setCollections([]);
      setMoreExists(false);
      setIsLoading(true);
    }
    if (collections.length === 0) {
      api
        .FetchCollections({
          sortType: sortType?.value.toString(),
          sortOrder,
          nftType,
          rewardToken: rewardToken?.value.toString(),
          platform: isFans ? 'fans' : 'cosmos'
        })
        .then(collections => {
          setCollections(collections);
          setIsLoading(false);
        });
    }
  }, [
    isLoading,
    collections,
    sortType,
    sortOrder,
    nftType,
    rewardToken,
    prevType,
    prevSortOrder,
    prevnftType,
    prevRewardToken,
  ]);

  return (
    <>
      <div className='newFilterJey collectionHub wrapper'>
        <div className='headerText'>
          <h3 className='exploreHeading'>Explore Collections</h3>
        </div>
      </div>
      <button className={styles.btnFilter} onClick={toggleFilter}>
        Filters
      </button>
      <div className={sidebarActive ? 'sidebarActiveBg' : ''}>
        <div className='exploreCollections wrapper'>
          <section className={`newFilterJeyInner ${styles.desktopFilter}`}>
            <div className='newFilterJeyLeft'>
              <h6>
                Filters{' '}
                <button onClick={() => resetSorting()}>
                  Clear all Filters
                </button>
              </h6>
              <div className='newFilterJeyRight' style={{ marginTop: '20px' }}>
                <SelectBox
                  options={sortOptions}
                  value={sortType}
                  onChange={(e: any) => setSortType(e)}
                  placeholder={'Sort'}
                />
                <section>
                  <button
                    className={sortOrder === 'Asc' ? 'jeyActive' : ''}
                    onClick={() => setSortOrder('Asc')}
                  >
                    <i>{sortType?.value === 'date_created' && 'Oldest'}</i>
                    <i>{sortType?.value === 'floorPrice' && 'Low'}</i>
                    <i>{sortType?.value === 'volumn' && 'Low'}</i>
                  </button>
                  <button
                    className={sortOrder === 'Desc' ? 'jeyActive' : ''}
                    onClick={() => setSortOrder('Desc')}
                  >
                    <i>{sortType?.value === 'date_created' && 'Latest'}</i>
                    <i>{sortType?.value === 'floorPrice' && 'High'}</i>
                    <i>{sortType?.value === 'volumn' && 'High'}</i>
                  </button>
                </section>
                {!isFans && (
                  <section>
                    <button
                      className={nftType === 'All' ? 'jeyActive' : ''}
                      onClick={() => setNftType('All')}
                    >
                      <i>All NFTs</i>
                    </button>
                    <button
                      className={nftType === 'Reward' ? 'jeyActive' : ''}
                      onClick={() => setNftType('Reward')}
                    >
                      <i>Rewards NFTs</i>
                    </button>
                  </section>
                )}
              </div>
              {!isFans && (
                <section>
                  <SelectBox
                    options={rewardOptions}
                    value={rewardToken}
                    onChange={(e: any) => setRewarToken(e)}
                    placeholder={'Reward Token'}
                  />
                  {/*
                  <SelectBox
                    options={collectionOptions}
                    value={collection}
                    onChange={(e: any) => setCollection(e)}
                    placeholder={'Collection'}
                  />
                  */}
                </section>
              )}
            </div>
          </section>
          <section
            className={
              filterbarActive
                ? `newFilterJeyInner ${styles.mobileFilter} ${styles.show}`
                : `newFilterJeyInner ${styles.mobileFilter} ${styles.hide}`
            }
          >
            <div
              className={`icn_cloz ${styles.cloz_left}`}
              onClick={toggleFilter}
            ></div>
            <div className='newFilterJeyLeft'>
              <h6>
                Filters <button>Clear all Filters</button>
              </h6>
              <div className='newFilterJeyRight' style={{ marginTop: '20px' }}>
                <SelectBox
                  options={sortOptions}
                  value={sortType}
                  onChange={(e: any) => setSortType(e)}
                  placeholder={'Sort'}
                />
                <section>
                  <button
                    className={sortOrder === 'Asc' ? 'jeyActive' : ''}
                    onClick={() => setSortOrder('Asc')}
                  >
                    <i>{sortType?.value === 'date_created' && 'Oldest'}</i>
                    <i>{sortType?.value === 'floorPrice' && 'Low'}</i>
                    <i>{sortType?.value === 'volumn' && 'Low'}</i>
                  </button>
                  <button
                    className={sortOrder === 'Desc' ? 'jeyActive' : ''}
                    onClick={() => setSortOrder('Desc')}
                  >
                    <i>{sortType?.value === 'date_created' && 'Latest'}</i>
                    <i>{sortType?.value === 'floorPrice' && 'High'}</i>
                    <i>{sortType?.value === 'volumn' && 'High'}</i>
                  </button>
                </section>
                <section>
                  <button
                    className={nftType === 'All' ? 'jeyActive' : ''}
                    onClick={() => setNftType('All')}
                  >
                    <i>All NFTs</i>
                  </button>
                  <button
                    className={nftType === 'Reward' ? 'jeyActive' : ''}
                    onClick={() => setNftType('Reward')}
                  >
                    <i>Rewards NFTs</i>
                  </button>
                </section>
              </div>
              <section>
                <SelectBox
                  options={rewardOptions}
                  value={rewardToken}
                  onChange={(e: any) => setRewarToken(e)}
                  placeholder={'Reward Token'}
                />
                {/*
                <SelectBox
                  options={collectionOptions}
                  value={collection}
                  onChange={(e: any) => setCollection(e)}
                  placeholder={'Collection'}
                />
                */}
              </section>
            </div>
          </section>
          <div className='exploreList' style={{width: "100%"}}>
            <div className='setNewTrending'>
              <ul>
                {isLoading ? (
                  <NftLoading />
                ) : (
                  collections.length > 0 ? collections?.map((item: any) => {
                    return (
                      <li key={item.address}>
                        <a
                          href={`${pageRoute + item.url}${item.address == "false" ? '/info' : '/'}`}
                          style={{ height: '100%' }}
                        >
                          <div className='collectionImage'>
                            <img
                              src={
                                'https://loop-markets.directus.app/assets/' +
                                item.banner.id
                              }
                              alt={item.name}
                            />
                          </div>
                          <span>
                            <div className='collectionProfileImage'>
                              <img
                                src={
                                  'https://loop-markets.directus.app/assets/' +
                                  item.icon.id
                                }
                                alt={item.name}
                                style={{ height: '50px', width: 'auto' }}
                              />
                            </div>
                            {item.name}
                            <div className='newColl'>
                              <label>
                                <b>Floor Price: </b>
                                {item.floorPrice
                                  ? formatNumberString(
                                      new Dec(item.floorPrice).div(1000000)
                                    ) + ' USDC'
                                  : '-'}
                              </label>

                              <label>
                                <b>Total Volume: </b>
                                {item.volumn
                                  ? Number(formatNumberString(
                                      new Dec(item.volumn).div(1000000)
                                    )) < 500 ? "< 500" + ' USDC' :
                                    formatNumberString(
                                      new Dec(item.volumn).div(1000000)
                                    ) + ' USDC'
                                  : '-'}
                              </label>
                            </div>
                            <b>
                              {formatNumberString(item.itemCount)} Items for
                              sale | {formatNumberString(item.totalItems)} Total
                              items
                            </b>
                          </span>
                        </a>
                      </li>
                    );
                  }) : (
                    <>
                    . . . .
                    </>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        <NftFooter />
      </div>
    </>
  );
};

export default Collection;
