import React, { useState } from 'react';
import styles from './share.module.scss';

export default function ShareButton({
  name,
  icon,
}: {
  name: any;
  icon: string;
}) {
  const [socialShare, setSocialShare] = useState(false);
  const shareMessage = `Check%20this%20out%20on%20LOOP%20NFT%20Marketplace%20-%20`;

  return (
    <>
      <div
        onClick={() => {
          setSocialShare(!socialShare);
        }}
        className={styles.shareSection}
      >
        <img
          style={{
            //opacity: socialShare ? '0' : '1',
            transition: 'all 350ms',
            //visibility: socialShare ? 'hidden' : 'visible',
            transform: socialShare ? 'scale(0.8)' : 'scale(1)',
            transformOrigin: 'bottom center',
          }}
          className={styles.shareIcon}
          src={icon}
        />

        <div
          className={styles.socialShare}
          onMouseLeave={() => {
            setSocialShare(!socialShare);
          }}
          style={{
            opacity: !socialShare ? '0' : '1',
            transition: 'all 350ms',
            visibility: !socialShare ? 'hidden' : 'visible',
            transform: !socialShare ? 'scale(0.3)' : 'scale(1)',
            transformOrigin: 'bottom center',
          }}
        >
          <a
            href={`https://t.me/share/url?url=${window.location.href}&text=${shareMessage}${name}`}
            target='_blank'
          >
            <img src={`/icons/socials/telegram.svg`} />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${shareMessage}${name}&url=${window.location.href}`}
            target='_blank'
          >
            <img src={`/icons/socials/twitter.svg`} />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target='_blank'
          >
            <img src={`/icons/socials/facebook.svg`} />
          </a>
          <a
            href={`whatsapp://send?text=${shareMessage}${name}%20${window.location.href}`}
            target='_blank'
          >
            <img src={`/icons/socials/whatsapp.svg`} />
          </a>
          <a
            href={`mailto:?subject=${shareMessage}${name}:%20${window.location.href}`}
            target='_blank'
          >
            <img src={`/icons/socials/email.svg`} />
          </a>
        </div>
      </div>
    </>
  );
}
