import { networkType, nftConfig } from 'core/nftConfig';
import { NFTAuctionInfo, NFTSaleInfo } from './queryLaunchpadNFTInfo';
import {
  LaunchPadNFTInfo,
  LaunchpadNFTMetadata,
  LaunchPadNFTStakeInfo,
  NFTData,
} from './useEligibleStakeNFTs';
import axios from 'axios';
import { BACKEND_ENDPOINT, PAGE_SIZE } from 'core/common';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';
import { USDC_DENOM } from 'core/constants';

export type MarketNFTs = {
  type: 'Reward' | 'Standard';
  collection: string;
  tokenId: string;
  info: LaunchPadNFTInfo;
  metadata: LaunchpadNFTMetadata;
  saleInfo?: NFTSaleInfo;
  auctionInfo?: NFTAuctionInfo;
  daysRemain: number;
};

export const queryNFTData = async (
  owner: any,
  tokenID: any,
  contract: any,
  vesting_period: any,
  type: string,
  metadata: any,
): Promise<{
  nftInfo: any;
}> => {
  const client = await getQueryClient();
  const nftInfo: any[] = [];

  const info = {
    owner: owner,
  };

  let daysRemain = 0;
  const getstakeInfo = (await client.queryContractSmart(
    nftConfig[networkType].STAKING,
    {
      stake_info: {
        nft_contract_addr: contract,
        nft_token_id: tokenID,
      },
    }
  )) as LaunchPadNFTStakeInfo;
  const daysStaked = Math.floor(getstakeInfo.total_staked_time / 60 / 60 / 24);
  daysRemain =
    daysStaked <= parseInt(vesting_period)
      ? parseInt(vesting_period) - daysStaked
      : 0;

  if (type === "Buy Now") {
    try {
      const saleInfo = (await client.queryContractSmart(
        nftConfig[networkType].SALE,
        {
          nft_info: {
            wallet: owner,
            nft_contract_addr: contract,
            nft_token_id: tokenID,
          },
        }
      )) as NFTSaleInfo;
      nftInfo.push({
        type: type,
        info,
        metadata,
        saleInfo,
        daysRemain,
      });
    } catch {
      console.log(
        'sale info not available: ' + contract + ' / ' + tokenID
      );
    }
  }  else if (type === "Auction") {
    try {
      const auctionInfo = (await client?.queryContractSmart(
        nftConfig[networkType].AUCTION,
        {
          auction_state: {
            token_id: tokenID,
            token_address: contract,
          },
        }
      )) as NFTAuctionInfo;
      nftInfo.push({
        collection: contract,
        tokenId: tokenID,
        info,
        metadata,
        auctionInfo,
        daysRemain,
      });

      try {
        const min_bid_amount = (await client.queryContractSmart(
          nftConfig[networkType].AUCTION,
          {
            next_min_bid_amount: {
              token_id: tokenID,
              token_address: contract,
            },
          }
        )) as string;
        auctionInfo.min_bid_amount = min_bid_amount;

        nftInfo[0].auctionInfo = auctionInfo;
      } catch {
        console.log(
          'auction min bid amount not available: ' +
          contract +
          ' / ' +
          tokenID
        );
      }
    } catch {
      console.log(
        'auction info not available: ' + contract + ' / ' + tokenID
      );
    }
  }

  return {
    nftInfo,
  };
};
