import axios from 'axios';
export async function queryCollectionDetail({
  url,
}: {
  url: string;
}) {
  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}collection/${url}`
  );

  return result.data;
}

export async function queryCreatorCollections({
  username,
}: {
  username: string;
}) {
  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}collections/creator/${username}`
  );

  return result.data;
}