import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { querySaleCollections, querySaleNFTs } from 'hooks/useSaleNFTs';

import NftFooter from '../NftFooter';
import NftTrending from '../NftTrending';
import { NFTtype, transformNFTs } from '../../../core/utils';
import { RewardTokenOptions, SaleTokenOptions } from '../../../core/constants';
import { Option } from 'components/Select';
import { networkType, NFT_COLLECTIONS } from '../../../core/nftConfig';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { PAGE_SIZE } from 'core/common';
import { useHistory } from 'react-router-dom';
import { getParam, setParam } from 'components/URLHandler/helper';

const NftExplore = () => {
  const { address } = useRecoilValue(walletState);
  const [isLoading, setIsLoading] = useState(true);
  const [moreExists, setMoreExists] = useState(false);

  // filter options
  const [shownNFTs, setShownNFTs] = useState<NFTtype[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const term = getParam('term');
  const urlSortParam = getParam('sortType');
  const vestingParam = getParam('vesting');
  const sortOrderParam = getParam('sort');

  const history = useHistory();

  const saleOptions = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Auction' },
    { value: 2, label: 'Fixed Sale' },
  ];
  const [saleType, setSaleType] = useState<Option | null>();
  const [saleAsset, setSaleAsset] = useState<Option | null>();

  const [rewardTokenType, setRewardTokenType] = useState<Option | null>();

  const [collectionOptions, setCollectionOptions] = useState([] as Option[]);
  const [collection, setCollection] = useState<Option | null>(null);

  const sortOptions = [
    { value: 1, label: 'Recently Listed' },
    { value: 2, label: 'Price' },
    { value: 3, label: 'End Time' },
    { value: 4, label: 'Rewards per day' },
    // { value: 5, label: 'Total Rewards' },
  ];

  const [sortType, setSortType] = useState<Option>(
    sortOptions[urlSortParam ? urlSortParam - 1 : 0]
  );

  /*
   * 0: Ascending
   * 1: Descending
   */
  const [sortOrder, setSortOrder] = useState<number>(
    sortOrderParam === 0 ? sortOrderParam : 1
  );

  /**
   * 0: no filter
   * 1: filter only token vesting nfts
   */
  const [tokenVesting, setTokenVesting] = useState<number>(
    vestingParam === 0 ? vestingParam : 1
  );

  useEffect(() => {
    setParam('sortType', sortType.value);
    setParam('vesting', tokenVesting);
    setParam('sort', sortOrder);
  }, [
    sortType,
    urlSortParam,
    tokenVesting,
    vestingParam,
    sortOrder,
    saleAsset,
    sortOrderParam,
  ]);

  useEffect(() => {
    querySaleCollections(address).then(result => {
      NFT_COLLECTIONS.sort((a: any, b: any) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setCollectionOptions(
        NFT_COLLECTIONS.map(option => ({
          value: option.address,
          label: option.name,
        })).filter(item => result.includes(item.value))
      );
    });
  }, [address]);

  useEffect(() => {
    if (collectionOptions.length > 0) {
      setMoreExists(false);
      setShownNFTs([]);
      setIsLoading(true);
      querySaleNFTs(
        address,
        term?.toString(),
        tokenVesting,
        sortOrder,
        sortType?.value.toString(),
        saleType?.value.toString(),
        saleAsset?.value.toString(),
        rewardTokenType?.value.toString(),
        collection?.value.toString(),
        0,
        PAGE_SIZE * 2
      ).then(({ nfts, totalCount }) => {
        setMoreExists(nfts.length < totalCount);
        setShownNFTs(transformNFTs(nfts, networkType));
        setIsLoading(false);
      });
    }
  }, [
    collectionOptions,
    tokenVesting,
    sortOrder,
    sortType,
    saleType,
    saleAsset,
    collection,
    rewardTokenType,
    address,
    term,
  ]);

  const loadMore = () => {
    setIsLoading(true);
    querySaleNFTs(
      address,
      term?.toString(),
      tokenVesting,
      sortOrder,
      sortType?.value.toString(),
      saleType?.value.toString(),
      saleAsset?.value.toString(),
      rewardTokenType?.value.toString(),
      collection?.value.toString(),
      shownNFTs.length
    ).then(({ nfts, totalCount }) => {
      setMoreExists(shownNFTs.length + nfts.length < totalCount);
      setShownNFTs([...shownNFTs, ...transformNFTs(nfts, networkType)]);
      setIsLoading(false);
    });
  };

  const resetSorting = () => {
    setSortType(sortOptions[0]);
    setCollection(null);
    setTokenVesting(1);
    setSortOrder(1);
    setSaleType(null);
    setRewardTokenType(null);
    setParam('sortType', 0);
    term && history.push(`/nftExplore`);
  };

  return (
    <>
      <Helmet>
        <title>NFT | Marketplace</title>
      </Helmet>
      <div>
        <div>
          <NftTrending
            filteredNFTs={shownNFTs}
            pageIdentity='explore'
            setSearchString={setSearchString}
            searchString={searchString}
            saleType={saleType}
            setSaleType={setSaleType}
            rewardTokenType={rewardTokenType}
            setRewardTokenType={setRewardTokenType}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setSortType={setSortType}
            tokenVesting={tokenVesting}
            setTokenVesting={setTokenVesting}
            saleOptions={saleOptions}
            sortOptions={sortOptions}
            sortType={sortType}
            rewardTokenOptions={RewardTokenOptions}
            collectionOptions={collectionOptions}
            collection={collection}
            setCollection={setCollection}
            resetSorting={resetSorting}
            loadMore={moreExists ? loadMore : undefined}
            isLoading={isLoading}
            search={term}
            saleAssetOptions={SaleTokenOptions}
            setSaleAsset={setSaleAsset}
            saleAsset={saleAsset}
          />
        </div>
        <NftFooter />
      </div>
    </>
  );
};

export default NftExplore;
