import { Link } from 'react-router-dom';

export const MyNftSideBar = (selected: string) => {
  const isFans = location.pathname.startsWith('/fans');
  const pageRoute = isFans ? '/fans' : '';
  return (
    !window.location.pathname.startsWith('/webapp') && (
      <div className='mynftSideNav'>
        <p className='heading'>My Wallet</p>
        <ul>
          <li className={selected === 'owned' ? 'myActive' : ''}>
            <Link to={`${pageRoute}/mynft`}>
              <img src='/icons/mynfts_icon.svg' alt='' />
              <img src='/icons/mynfts_iconA.svg' alt='' /> My NFTs
            </Link>
          </li>
          {/* <li className={selected === 'benefits' ? 'myActive' : ''}>
            <Link to='#'>
              <img src='/icons/benefits.svg' alt='' />
              <img src='/icons/benefitsA.svg' alt='' /> My Benefits
            </Link>
          </li>
          <li className={selected === 'fav' ? 'myActive' : ''}>
            <Link to='#'>
              <img src='/icons/fav.svg' alt='' />
              <img src='/icons/favA.svg' alt='' /> Favorites
            </Link>
          </li> */}
          <li className={selected === 'bids' ? 'myActive' : ''}>
            <Link to={`${pageRoute}/mynft/bids`}>
              <img src='/icons/mybids.svg' alt='' />
              <img src='/icons/mybidsA.svg' alt='' /> My Bids
            </Link>
          </li>
          <li className={selected === 'onsale' ? 'myActive' : ''}>
            <Link to={`${pageRoute}/mynft/onsale`}>
              <img src='/icons/selling.svg' alt='' />
              <img src='/icons/sellingA.svg' alt='' /> Selling
            </Link>
          </li>
          <li className={selected === 'activity' ? 'myActive' : ''}>
            <Link to={`${pageRoute}/mynft/activity`}>
              <img src='/icons/activity.svg' alt='' />
              <img src='/icons/activityA.svg' alt='' /> Activity
            </Link>
          </li>
        </ul>

        <ul className='external'>
          <li className='link'>
            <a href="https://juno.loop.markets/#Swap" target="_blank">Buy Loop</a>
          </li>
          <li className='link'>
            <a href="https://juno.loop.markets/my" target="_blank">My Crypto</a>
          </li>
          <li className='link apello'>
            <a href="https://www.apello.xyz/sales" target="_blank">Platform Sales</a>
          </li>
        </ul>
      </div>
    )
  );
};
