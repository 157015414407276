import NftFooter from '../Nft/NftFooter';
import { useEffect, useState } from 'react';
import SelectBox from '../../components/Select';
import styles from './collections.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import {
  NftAttribute,
  NftCollection,
  queryCollection,
  CollectionDetails,
} from 'hooks/queryCollections';
import { networkType, NFT_COLLECTIONS } from 'core/nftConfig';
import { Dec } from '@terra-money/terra.js';
import { Option } from 'components/Select';
import { formatNumberString } from 'utils/conversion/conversion';
import { PAGE_SIZE } from 'core/common';
import { NFTtype, transformNFTs } from 'core/utils';
import { queryCollectionNFTs } from 'hooks/useSaleNFTs';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import Nft from 'components/Nft';
import AttributeFilter from 'components/AttributeFilter';
import LoadingIcon from '../../assets/loading.svg';
import NftExploreItemDetails from 'pages/Nft/NftExplore/NftExploreItemDetails';
import NftLoading from 'components/NftLoading';
import {
  queryCollectionNftsAttributesCount,
  queryCollectionNftsCount,
} from 'hooks/queryCount';
import { queryCollectionDetail } from '../../api/queryCollectionDetail.api';
import CopyToClipboard from 'react-copy-to-clipboard';
import { SaleTokenOptions } from '../../core/constants';
import hyperlink from '../../assets/icons/hyperlink.svg';
import close from '../../assets/icons/close.svg';
import openLinkIcon from '../../assets/icons/open.svg';
import Button from 'components/Button';
import CollectionHeader from './collectionHeader';
import { FetchCollectionAddress } from 'api/collection.api';

const ExploreCollection = () => {
  const params = useParams<any>();
  const history = useHistory();
  const { address } = useRecoilValue(walletState);
  const [collection, setCollection] = useState<NftCollection>();
  const [collectionDetail, setCollectionDetail] = useState<CollectionDetails>();
  const [collectionDetailLoaded, setCollectionDetailLoaded] = useState(false);
  const [attributes, setAttributes] = useState<NftAttribute[]>([]);
  const [sidebarActive, setSidebar] = useState(false);
  const [filterbarActive, setFilterbar] = useState(false);
  const [saleAsset, setSaleAsset] = useState<Option | null>();
  const isFans = location.pathname.startsWith('/fans');
  const pageRoute = (isFans ? '/fans/exploreCollection/' : '/exploreCollection/')

  // filter options
  const [isLoading, setIsLoading] = useState(true);
  const [moreExists, setMoreExists] = useState(false);
  const [shownNFTs, setShownNFTs] = useState<NFTtype[]>([]);

  const listTypeOptions = [
    { value: 0, label: 'For Sale' },
    { value: 1, label: 'Not for Sale' },
  ];
  const [listType, setListType] = useState<Option | any>({
    value: 0,
    label: 'For Sale',
  });

  const saleOptions = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Auction' },
    { value: 2, label: 'Fixed Sale' },
  ];
  const [saleType, setSaleType] = useState<Option | null>({
    value: 0,
    label: 'All',
  });

  const sortOptions = [
    { value: 1, label: 'Recently Listed' },
    { value: 2, label: 'Price' },
    { value: 3, label: 'End Time' },
    { value: 4, label: 'Rewards per day' },
    // { value: 5, label: 'Total Rewards' },
  ];
  const [sortType, setSortType] = useState<Option>(sortOptions[0]);

  const types = attributes
    .map(item => item.trate_type)
    .filter((value, index, self) => self.indexOf(value) === index);
  const values = types.map(type =>
    attributes
      .filter(item => item.trate_type === type && item.value !== 'Terra ')
      .map(item => item.value)
  );

  const [attributeFilter, setAttributeFilter] = useState<
    {
      attribute: string;
      value: string;
    }[]
  >([]);

  /*
   * 0: Ascending
   * 1: Descending
   */
  const [sortOrder, setSortOrder] = useState<number>(1);

  /**
   * 0: no filter
   * 1: filter only token vesting nfts
   */
  const [tokenVesting, setTokenVesting] = useState<number>(0);

  const [totalCount, setTotalCount] = useState(0);
  const [attributesCount, setAttributesCount] = useState(
    {} as {
      [s: string]: number;
    }
  );

  useEffect(() => {
    const url = params.id;
    if (collectionDetail?.address == "false") {
      history.push(`${pageRoute}${url}/info`);
    }
  }, [collectionDetail]);

  useEffect(() => {
    const fetchCollectionDetail = async () => {
      const url = params.id;
      if (!collectionDetailLoaded) {
        const collectionDetail = await queryCollectionDetail({ url });
        setCollectionDetail(collectionDetail);
        setCollectionDetailLoaded(true);
      }
    };

    fetchCollectionDetail();
  }, [collectionDetailLoaded]);

  useEffect(() => {
    if (collection) {
      setMoreExists(false);
      setShownNFTs([]);
      setIsLoading(true);
      queryCollectionNFTs(
        collection.address,
        address,
        tokenVesting,
        sortOrder,
        sortType?.value.toString(),
        saleType?.value.toString(),
        saleAsset?.value.toString(),
        0,
        PAGE_SIZE * 2,
        listType?.value,
        attributeFilter
      ).then(({ nfts, totalCount }) => {
        setMoreExists(nfts.length < totalCount);
        setShownNFTs(transformNFTs(nfts, networkType));
        setIsLoading(false);
      });
    }
  }, [
    tokenVesting,
    sortOrder,
    sortType,
    saleType,
    address,
    attributeFilter,
    listType,
    collection,
  ]);

  const loadMore = () => {
    if (collection) {
      setIsLoading(true);
      queryCollectionNFTs(
        collection.address,
        address,
        tokenVesting,
        sortOrder,
        sortType?.value.toString(),
        saleType?.value.toString(),
        saleAsset?.value.toString(),
        shownNFTs.length,
        PAGE_SIZE,
        listType?.value,
        attributeFilter
      ).then(({ nfts, totalCount }) => {
        setMoreExists(shownNFTs.length + nfts.length < totalCount);
        setShownNFTs([...shownNFTs, ...transformNFTs(nfts, networkType)]);
        setIsLoading(false);
      });
    }
  };

  const resetSorting = () => {
    setSortType(sortOptions[0]);
    setTokenVesting(0);
    setSortOrder(1);
    setSaleType({
      value: 0,
      label: 'All',
    });
    setAttributeFilter([]);
  };

  const [addressLoaded, setAddressLoaded] = useState(false);

  useEffect(() => {
    const url = params.id;
    //const contractAddr = NFT_COLLECTIONS.find(e => e.url === url)?.address;
    let contractAddr;
    if (!addressLoaded) {
      setAddressLoaded(true);
      FetchCollectionAddress({ url })
        .then(collection => {
          //console.log(collection);
          return collection.address;
        })
        .then(contractAddr => {
          queryCollection(contractAddr).then(resp => {
            if (resp) {
              setCollection(resp.collection);
              setAttributes(resp.attributes);
            }
          });
          queryCollectionNftsCount(contractAddr).then(count =>
            setTotalCount(count)
          );
          queryCollectionNftsAttributesCount(contractAddr).then(res =>
            setAttributesCount(res)
          );
        });
    }
  }, [params, saleAsset]);

  const handleSidebar = (status: any) => {
    setSidebar(status);
  };

  const toggleFilter = () => {
    setFilterbar(!filterbarActive);
  };

  const windowPageName = window.location.pathname;
  const [pageName, setPagename] = useState('');
  useEffect(() => {
    if (!pageName) {
      setPagename(windowPageName);
    }
  }, [windowPageName, pageName]);

  const [showNftModal, setNftModal] = useState(false);
  const [modalParams, setModalParams] = useState<object>();
  const nftShareUrl = (postObj: any) =>
    window.location.protocol +
    '//' +
    window.location.host +
    `${isFans ? '/fans/nftDetail' : '/nftDetail'}/${postObj.collection}/${
      postObj.tokenId
    }`;

  const OpenTabHandle = () => window.open(nftShareUrl(modalParams), '_blank');
  const setNftData = (postObj: any) => {
    setModalParams({
      address: postObj.collection,
      id: postObj.tokenId.split('#')[0],
      hash: `${
        postObj.tokenId.includes('#') ? `#${postObj.tokenId.split('#')[1]}` : ''
      }`,
      tokenId: postObj.tokenId,
      collection: postObj.collection,
    });
    setNftModal(true);
    document.body.style.overflowY = 'hidden';
  };
  const clearNftData = () => {
    setNftModal(false);
    document.body.style.overflowY = 'scroll';
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  // scroll event callback function
  const onScroll = (target: Event) => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollheight = document.documentElement.scrollHeight;
    const window = target.currentTarget as Window;

    if (
      moreExists &&
      !isLoading &&
      window.innerHeight + scrollTop >= scrollheight - 1200
    ) {
      return loadMore();
    }
    return false;
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(function () {
      setCopied(false);
    }, 3000);
  };

  const SkeletonComponent = ({
    count,
    width,
    children,
    info,
  }: {
    count: number;
    width: string;
    children: any;
    info: any;
  }) => {
    return (
      <>
        {children}
        {/* {info ? (
          children
        ) : (
          <SkeletonTheme highlightColor='#a8a8a8'>
            <Skeleton count={count} width={width} />
          </SkeletonTheme>
        )} */}
      </>
    );
  };
  /* 
  if (!collection) {
    return <></>;
  }
 */
  return (
    <>
      {showNftModal && modalParams && (
        <>
          <div
            className='nftmodal_backdrop'
            onClick={() => clearNftData()}
          ></div>
          <div className='nftmodal_wrapper'>
            <div className={`nftmodal animate__animated animate__backInUp`}>
              <div className='action_button' onClick={() => clearNftData()}>
                <img src={close} alt='close' />
              </div>
              <CopyToClipboard text={nftShareUrl(modalParams)}>
                <div
                  className={`action_button ${
                    copied ? 'action_button__copied' : 'action_button__copy'
                  }`}
                  onClick={() => handleCopy()}
                >
                  <img src={hyperlink} alt='hyperlink' />
                </div>
              </CopyToClipboard>
              <div
                className='action_button action_button__open'
                onClick={() => OpenTabHandle()}
              >
                <img src={openLinkIcon} alt='openLink' />
              </div>
              <NftExploreItemDetails modal={true} modalParams={modalParams} />
            </div>
          </div>
        </>
      )}
      {!collectionDetail && collectionDetailLoaded ? (
        <>
        <div className="wrapper">
          <div className={styles.notFound}>
            <div>
              <h3>Collection Not Found</h3>
              <p>Looks like you were trying to view a collection thats not yet live or dosen't exist.</p>
              <Button onClick={() => isFans ? history.push('/fans/exploreCollection') :  history.push('/exploreCollection')}>View Collections</Button>
            </div>
          </div>
        </div>
        </>
      ) : (
        <>
          <CollectionHeader collectionDetail={collectionDetail} />

          <div style={showNftModal ? { filter: 'blur(50px)' } : {}}>
            <button className={styles.btnFilter} onClick={toggleFilter}>
              Filters
            </button>
            <div className={sidebarActive ? 'sidebarActiveBg' : ''}>
              <div className='exploreCollections wrapper'>
                <section
                  className={`newFilterJeyInner ${styles.desktopFilter}`}
                  style={{
                    overflow: 'auto',
                    //height: 'calc(100vh - 150px)',
                  }}
                >
                  <div className='newFilterJeyLeft'>
                    <h6>
                      Filters{' '}
                      <button onClick={() => resetSorting()}>
                        Clear all Filters
                      </button>
                    </h6>
                    <div
                      className='newFilterJeyRight'
                      style={{ marginTop: '20px' }}
                    >
                      <SelectBox
                        options={sortOptions}
                        onChange={(e: any) => setSortType(e)}
                        value={sortType}
                        placeholder={'Sort'}
                      />
                      <section>
                        <button
                          className={sortOrder === 0 ? 'jeyActive' : ''}
                          onClick={() => setSortOrder(0)}
                        >
                          <i>{sortType?.value === 1 && 'Oldest'}</i>
                          <i>{sortType?.value === 2 && 'Low'}</i>
                          <i>{sortType?.value === 3 && 'Soonest'}</i>
                          <i>{sortType?.value === 4 && 'Low'}</i>
                        </button>
                        <button
                          className={sortOrder === 1 ? 'jeyActive' : ''}
                          onClick={() => setSortOrder(1)}
                        >
                          <i>{sortType?.value === 1 && 'Latest'}</i>
                          <i>{sortType?.value === 2 && 'High'}</i>
                          <i>{sortType?.value === 3 && 'Latest'}</i>
                          <i>{sortType?.value === 4 && 'High'}</i>
                        </button>
                      </section>
                      <section>
                        <button
                          className={tokenVesting === 0 ? 'jeyActive' : ''}
                          onClick={() => setTokenVesting(0)}
                        >
                          <i>All NFTs</i>
                        </button>
                        <button
                          className={tokenVesting === 1 ? 'jeyActive' : ''}
                          onClick={() => setTokenVesting(1)}
                        >
                          <i>Rewards NFTs</i>
                        </button>
                      </section>
                    </div>
                    <section>
                      <SelectBox
                        options={SaleTokenOptions}
                        onChange={(e: any) => setSaleAsset(e)}
                        value={saleAsset}
                        placeholder={'Sale Asset'}
                      />
                      <SelectBox
                        options={saleOptions}
                        onChange={(e: any) => setSaleType(e)}
                        value={saleType}
                        placeholder={'Sale'}
                      />
                      <SelectBox
                        options={listTypeOptions}
                        onChange={(e: any) => setListType(e)}
                        value={listType}
                        placeholder={'For Sale'}
                      />
                      <AttributeFilter
                        types={types}
                        values={values}
                        filter={attributeFilter}
                        setFilter={setAttributeFilter}
                        attributesCount={attributesCount}
                        totalCount={totalCount}
                      />
                    </section>
                  </div>
                </section>
                <section
                  className={
                    filterbarActive
                      ? `newFilterJeyInner ${styles.mobileFilter} ${styles.show}`
                      : `newFilterJeyInner ${styles.mobileFilter} ${styles.hide}`
                  }
                  style={{
                    overflow: 'auto',
                  }}
                >
                  <div
                    className={`icn_cloz ${styles.cloz_left}`}
                    onClick={toggleFilter}
                  ></div>
                  <div className='newFilterJeyLeft'>
                    <h6>
                      Filters{' '}
                      <button onClick={() => resetSorting()}>
                        Clear all Filters
                      </button>
                    </h6>
                    <div
                      className='newFilterJeyRight'
                      style={{ marginTop: '20px' }}
                    >
                      <SelectBox
                        options={sortOptions}
                        onChange={(e: any) => setSortType(e)}
                        value={sortType}
                        placeholder={'Sort'}
                      />
                      <section>
                        <button
                          className={sortOrder === 0 ? 'jeyActive' : ''}
                          onClick={() => setSortOrder(0)}
                        >
                          <i>{sortType?.value === 1 && 'Oldest'}</i>
                          <i>{sortType?.value === 2 && 'Low'}</i>
                          <i>{sortType?.value === 3 && 'Soonest'}</i>
                          <i>{sortType?.value === 4 && 'Low'}</i>
                        </button>
                        <button
                          className={sortOrder === 1 ? 'jeyActive' : ''}
                          onClick={() => setSortOrder(1)}
                        >
                          <i>{sortType?.value === 1 && 'Latest'}</i>
                          <i>{sortType?.value === 2 && 'High'}</i>
                          <i>{sortType?.value === 3 && 'Latest'}</i>
                          <i>{sortType?.value === 4 && 'High'}</i>
                        </button>
                      </section>
                      <section>
                        <button
                          className={tokenVesting === 0 ? 'jeyActive' : ''}
                          onClick={() => setTokenVesting(0)}
                        >
                          <i>All NFTs</i>
                        </button>
                        <button
                          className={tokenVesting === 1 ? 'jeyActive' : ''}
                          onClick={() => setTokenVesting(1)}
                        >
                          <i>Rewards NFTs</i>
                        </button>
                      </section>
                    </div>
                    <section>
                      <SelectBox
                        options={saleOptions}
                        onChange={(e: any) => setSaleType(e)}
                        value={saleType}
                        placeholder={'Sale'}
                      />
                      <AttributeFilter
                        types={types}
                        values={values}
                        filter={attributeFilter}
                        setFilter={setAttributeFilter}
                        attributesCount={attributesCount}
                        totalCount={totalCount}
                      />
                    </section>
                  </div>
                </section>
                <div style={{ flex: 1 }}>
                  <div className='trendingInfo'>
                    {collection?.name === 'Loop Migration Airdrop' && (
                      <div className='warning'>
                        <b>Note: </b>
                        <span>
                          <SkeletonComponent count={1} width={'50px'} info={''}>
                            These NFTs are eligible for 2 different kinds of
                            staking. The new 2nd staking option to boost your
                            LOOP Power rewards + voting power on the Community
                            DAO and Gauges is live!{' '}
                            <a
                              href='https://juno.loop.markets/stake?tab=STAKING#stake'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Click here
                            </a>{' '}
                            to go to your LOOP Power Dashboard.
                          </SkeletonComponent>
                        </span>
                      </div>
                    )}

                    <div className='setNewTrending'>
                      <ul>
                        {shownNFTs && shownNFTs.length > 0 ? (
                          shownNFTs.map((postObj: any, index: any) => (
                            <Nft
                              key={index}
                              postObj={postObj}
                              index={index}
                              setNftModal={setNftData}
                              isSale={listType?.value}
                            />
                          ))
                        ) : isLoading ? (
                          <>
                            {/*
                                <p
                                className='loader'
                                style={{
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}
                              ></p>
                              */}
                            <NftLoading />
                          </>
                        ) : (
                          <div className='noPosts'>
                            <p>No NFTs yet</p>
                          </div>
                        )}
                      </ul>
                    </div>
                    {moreExists && isLoading && (
                      <>
                        {/* <p
                      className='loader'
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '22px',
                        height: '22px',
                        borderWidth: '5px',
                      }}
                    ></p> */}

                        <div className='setNewTrending'>
                          <ul>
                            <NftLoading />
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <NftFooter />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ExploreCollection;
