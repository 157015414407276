import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const NftFooter = () => {
    return (
        <>
            <div id='footerId' className='footer'>
                <div className='wrapper'>
                    <div className='footerInner'>
                        <div className='footerPrimary'>
                            <a className='footerLogo' href='/'>
                                <img src='/loop-logo.svg' alt='' />
                            </a>
                            {/* <h3>World's First NFT Fixed Yield Bond Marketplace</h3> */}
                            <h3>Stakeable Reward NFTs </h3>
                            <ul>
                                <li>
                                    <a
                                        target='_blank'
                                        href='https://twitter.com/LoopDeFi_NFT'
                                        rel='noreferrer'
                                    >
                                        <img src='/na.svg' alt='social' />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target='_blank'
                                        href='https://t.co/F8M5MpkDgP'
                                        rel='noreferrer'
                                    >
                                        <img src='/nb.svg' alt='social' />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target='_blank'
                                        href='https://t.co/IxkmQgjkqD'
                                        rel='noreferrer'
                                    >
                                        <img src='/nc.svg' alt='social' />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target='_blank'
                                        href='https://www.youtube.com/channel/UCecsvrIVwT-bgy6-lDIEBTQ'
                                        rel='noreferrer'
                                    >
                                        <img src='/nd.svg' alt='social' />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target='_blank'
                                        href='https://www.linkedin.com/company/loop-markets'
                                        rel='noreferrer'
                                    >
                                        <img src='/ne.svg' alt='social' />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target='_blank'
                                        href='https://www.loop.markets/user/40376'
                                        rel='noreferrer'
                                    >
                                        <img src='/Loop.svg' alt='social' />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='footerSecondary'>
                            <div className='footerSecondary1'>
                                <ul>
                                    <li>Explore</li>
                                    <li className='activeMarket'>
                                        <a href='/nftExplore'>Marketplace</a>
                                    </li>
                                    <li>
                                        <a href='/launchpad'>Launchpad</a>
                                    </li>
                                    <li className='apello'>
                                        <a href="https://www.apello.xyz/sales" target="_blank">Activity Feed</a>
                                    </li>
                                    <li className='cmsoon'>
                                        <a>Minting Studio</a>
                                    </li>
                                    {/*
                  <li className='cmsoon'>
                    <a>Collections</a>
                  </li>
                  */}
                                    <li
                                        className={
                                            window.location.pathname == '/collections' ? 'activeMarket' : ''
                                        }
                                    >
                                        <Link to='/collections'>Collections</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='footerSecondary1'>
                                <ul>
                                    <li>Creators</li>
                                    <li className='activeMarket'>
                                        <a
                                            href='https://docs.loop.markets/loop-finance/nft-marketplace/products'
                                            target='_blank' rel="noreferrer"
                                        >
                                            Learn more
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://docs.loop.markets/loop-finance/nft-marketplace/project-launch-info/requirements/application'
                                            target='_blank' rel="noreferrer"
                                        >
                                            Apply to Launch
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href='https://docs.loop.markets/loop-finance/nft-marketplace/project-listing-on-marketplace'
                                            target='_blank' rel="noreferrer"
                                        >
                                            Apply to List{' '}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='footerSecondary1'>
                                <ul>
                                    <li>Support</li>
                                    <li>
                                        <Link to="/faqs">
                                            FAQs
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            target='_blank'
                                            href='https://docs.loop.markets/loop-finance/nft-marketplace/marketplace-overview'
                                            rel='noreferrer'
                                        >
                                            Docs
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target='_blank'
                                            href='https://t.me/loopfinance'
                                            rel='noreferrer'
                                        >
                                            Telegram
                                        </a>
                                    </li>
                                    <li>
                                        <Link
                                            to='/privacy'
                                        >
                                            Privacy
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='footerSecondary1'>
                                <ul>
                                    <li>Community</li>
                                    <li>
                                        <a
                                            target='_blank'
                                            href='https://t.co/F8M5MpkDgP'
                                            rel='noreferrer'
                                        >
                                            Telegram
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target='_blank'
                                            href='https://t.me/loopannouncements'
                                            rel='noreferrer'
                                        >
                                            Announcements
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target='_blank'
                                            href='https://t.co/IxkmQgjkqD'
                                            rel='noreferrer'
                                        >
                                            Discord
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target='_blank'
                                            href='https://twitter.com/LoopDeFi_NFT'
                                            rel='noreferrer'
                                        >
                                            Twitter
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NftFooter;