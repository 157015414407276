import { Route } from 'react-router-dom';
import Launchpad from 'pages/Launchpad/launchpad';
import MyNft from 'pages/MyNft';
import NftExplore from 'pages/Nft/NftExplore/NftExplore';
import Collection from 'pages/Collections/collections';
import ExploreCollection from 'pages/Collections/exploreCollection';
import NftExploreItemDetails from 'pages/Nft/NftExplore/NftExploreItemDetails';
import FansLaunchpadView from 'pages/Fans/Launchpad/DetailsView/view';
import CollectionDetailView from 'pages/Collections/collectionDetailView';

// Transaction Status
import Transaction from '../pages/TransactionStatus/status';


const FanRoutes = () => {
  return (
    <>
      <Route path='/fans/transaction' exact component={Transaction} />
      <Route path='/fans/nftExplore' exact component={NftExplore} />
      <Route path='/fans/launchpad' exact component={Launchpad} />
      <Route path='/fans/launchpad/:address' exact component={FansLaunchpadView} />
      <Route path='/fans/mint/:address' exact component={FansLaunchpadView} />
      <Route path='/fans/mynft' component={MyNft} />
      <Route path='/fans/' exact component={Collection} />
      <Route
        path='/fans/exploreCollection/:address/mint'
        exact
        component={FansLaunchpadView}
      />
      <Route
        path='/fans/exploreCollection/:id'
        exact
        component={ExploreCollection}
      />
      <Route
        path='/fans/exploreCollection/:id/info'
        exact
        component={CollectionDetailView}
      />
      <Route
        path='/fans/nftDetail/:address/:id'
        exact
        component={NftExploreItemDetails}
      />
    </>
  );
};
export default FanRoutes;
