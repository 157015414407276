import { useEffect, useReducer, useState } from 'react';
import Nft from '../../components/Nft';
import NftPopup from '../../components/NftPopup';
import SelectBox, { Option } from '../../components/Select';
import LoadingIcon from '../../assets/loading.svg';
import { NFTtype } from 'core/utils';
import NftExploreItemDetails from './NftExplore/NftExploreItemDetails';
import NftLoading from 'components/NftLoading';
import CopyToClipboard from 'react-copy-to-clipboard';
import hyperlink from '../../assets/icons/hyperlink.svg';
import close from '../../assets/icons/close.svg';
import openLinkIcon from '../../assets/icons/open.svg';

export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: 'none',
    border: 0,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    background: 'var(--secondary-bg)',
    margin: 0,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    backgroundImage: 'url(/images/polygonDropArrow.png)',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#ffffff',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    fontSize: '14px',
    color: '#ffffff',
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    background: 'none',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    display: 'inline-block',
    width: '120px',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    border: '0px',
    color: '#ffffff',
    background: 'var(--secondary-bg)',
    padding: '0',
    fontSize: '12px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    border: '0px',
    color: '#ffffff',
    background: state.isSelected || state.isFocused ? '#1b1b1b' : 'var(--secondary-bg)',
    padding: '5px 10px',
    fontSize: '12px',
    '&:hover': {
      background: '#1b1b1b',
    },
    '&:focus': {
      background: '#1b1b1b',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    transition: 'opacity 300ms',
    color: '#ffffff',
    fontSize: '12px',
    optionName: 'display:none',
  }),
};
const optionSetDivide = {
  input: (provided: any, state: any) => ({
    ...provided,
    // display: 'none !important'
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: '0',
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: 'none !important',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '2px 5px',
    fontSize: '11px',
    background: state.isSelected || state.isFocused ? '#1b1b1b' : 'var(--secondary-bg)',
    textAlign: 'center',
    '&:hover': {
      background: '#1b1b1b',
    },
  }),
};
export const customStylesSelect = { ...customStyles, ...optionSetDivide };

export const reducer = (state: { togglePopup: any; toggleInfo: any }, action: any) => {
  switch (action.type) {
    case 'togglePopup':
      return {
        togglePopup: !state.togglePopup,
        toggleInfo: state.toggleInfo,
      };

    case 'toggleInfo':
      return { togglePopup: state.togglePopup, toggleInfo: action.value };
    default:
      return state;
  }
};

const NftTrending = (props: {
  filteredNFTs: NFTtype[];
  pageIdentity: string;
  setSearchString: any;
  searchString: string;
  collection?: Option | null;
  saleType?: Option | null;
  setSaleType: any;
  rewardTokenType?: Option | null;
  setRewardTokenType: any;
  sortOrder: number;
  setSortOrder: any;
  setSortType: any;
  setCollection: any;
  tokenVesting: number;
  setTokenVesting: any;
  saleOptions: Option[];
  sortOptions: Option[];
  collectionOptions: Option[];
  sortType?: Option | null;
  rewardTokenOptions: Option[];
  resetSorting: any;
  loadMore?: any;
  searchData?: any;
  isLoading?: boolean;
  search?: any;
  saleAssetOptions: Option[];
  setSaleAsset: any;
  saleAsset?: Option | null;
}) => {
  const isFans = location.pathname.startsWith('/fans');
  const pageName = window.location.pathname;
  const [state, dispatch] = useReducer(reducer, {
    togglePopup: null,
    toggleInfo: null,
  });
  const [currentPostPopup, SetCurrentPostPopup] = useState(null);
  const [showNftModal, setNftModal] = useState(false);
  const [modalParams, setModalParams] = useState<object>();
  const nftShareUrl = (postObj: any) =>
    window.location.protocol +
    '//' +
    window.location.host +
    `${isFans ? '/fans/nftDetail' : '/nftDetail'}/${postObj.collection}/${postObj.tokenId}`;

  const OpenTabHandle = () => window.open(nftShareUrl(modalParams), '_blank');
  const setNftData = (postObj: any) => {
    setModalParams({
      address: postObj.collection,
      id: postObj.tokenId.split('#')[0],
      hash: `${
        postObj.tokenId.includes('#') ? `#${postObj.tokenId.split('#')[1]}` : ''
      }`,
      tokenId: postObj.tokenId,
      collection: postObj.collection,
    });
    setNftModal(true);
    document.body.style.overflowY = 'hidden';
  };
  const clearNftData = () => {
    setNftModal(false);
    document.body.style.overflowY = 'scroll';
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  // scroll event callback function
  const onScroll = (target: Event) => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollheight = document.documentElement.scrollHeight;
    const window = target.currentTarget as Window;

    if (
      props.loadMore &&
      !props.isLoading &&
      window.innerHeight + scrollTop >= scrollheight - 1200
    ) {
      return props.loadMore();
    }
    return false;
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(function () {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      {showNftModal && modalParams && (
        <>
          <div
            className='nftmodal_backdrop'
            onClick={() => clearNftData()}
          ></div>
          <div className='nftmodal_wrapper'>
            <div className={`nftmodal animate__animated animate__backInUp`}>
              <div className='action_button' onClick={() => clearNftData()}>
                <img src={close} alt='close' />
              </div>
              <CopyToClipboard text={nftShareUrl(modalParams)}>
                <div className={`action_button ${copied ? "action_button__copied" : "action_button__copy"}`} onClick={() => handleCopy()}>
                  <img src={hyperlink} alt='hyperlink' />
                </div>
              </CopyToClipboard>
              <div
                className='action_button action_button__open'
                onClick={() => OpenTabHandle()}
              >
                <img src={openLinkIcon} alt='openLink' />
              </div>
              <NftExploreItemDetails modal={true} modalParams={modalParams} />
            </div>
          </div>
        </>
      )}
      <div
        className='trending'
        style={showNftModal ? { filter: 'blur(50px)' } : {}}
      >
        <div className='wrapper'>
          <div className='trendingInner'>
            {/* <h2
              className={
                pageName === '/nftExplore' ? 'hideContent' : 'showContent'
              }
            >
              Trending NFTs
            </h2> */}
            <div
              className={
                pageName === '/nftExplore' ? 'showContent' : 'showContent'
              }
            >
              <h3 className='exploreHeading'>
                {props.search ? (
                  <>
                    Showing Results for{' '}
                    <span style={{ color: '#1eb2ff' }}>{props.search}</span>
                  </>
                ) : (
                  'Explore NFTs'
                )}
              </h3>
            </div>
            <div className='trendingFiltersHub'>
              {/* <div className='trendingNewMenu'>
                <ul>
                  <li className='trendingActiveMenu'>
                    <a href='/nftExplore'>
                      <span>
                        <img src='/m1.svg' alt='menu' />
                        <img src='/m1a.svg' alt='menu' />
                      </span>{' '}
                      Marketplace
                    </a>
                  </li>
                  <li>
                    <a href='/launchpad'>
                      <span>
                        <img src='/m2.svg' alt='menu' />
                        <img src='/m2a.svg' alt='menu' />
                      </span>{' '}
                      Launchpad
                    </a>
                  </li>
                  <li>
                    <a href='/collections'>
                      <span>
                        <img src='/m3.svg' alt='menu' />
                        <img src='/m3a.svg' alt='menu' />
                      </span>{' '}
                      Collections
                    </a>
                  </li>
                  <li>
                    <a href='/nftFarm'>
                      <span>
                        <img src='/m5.svg' alt='menu' />
                        <img src='/m5a.svg' alt='menu' />
                      </span>{' '}
                      Minting Studio
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href='/myNft'>
                      <span>
                        <img src='/m5.svg' alt='menu' />
                        <img src='/m5a.svg' alt='menu' />
                      </span>{' '}
                      My NFTs
                    </a>
                  </li>
                  <li>
                    <a href=''>
                      <span>
                        <img src='/m8.svg' alt='menu' />
                        <img src='/m8a.svg' alt='menu' />
                      </span>{' '}
                      Log Out
                    </a>
                  </li>
                </ul>
              </div> */}

              <div className='trendingFiltersRight hideMakingNew'>
                <div className='newFilterJey'>
                  <section className='newFilterJeyInner'>
                    <div className='newFilterJeyLeft'>
                      <h6>
                        Filters{' '}
                        <button onClick={() => props.resetSorting()}>
                          Clear all Filters
                        </button>
                      </h6>
                      <div
                        className='newFilterJeyRight'
                        style={{ marginTop: '20px' }}
                      >
                        <SelectBox
                          options={props.sortOptions}
                          onChange={(e: any) => props.setSortType(e)}
                          value={props.sortType}
                          placeholder={'Sort'}
                        />
                        <section>
                          <button
                            className={props.sortOrder === 0 ? 'jeyActive' : ''}
                            onClick={() => props.setSortOrder(0)}
                          >
                            <i>{props.sortType?.value === 1 && 'Oldest'}</i>
                            <i>{props.sortType?.value === 2 && 'Low'}</i>
                            <i>{props.sortType?.value === 3 && 'Soonest'}</i>
                            <i>{props.sortType?.value === 4 && 'Low'}</i>
                          </button>
                          <button
                            className={props.sortOrder === 1 ? 'jeyActive' : ''}
                            onClick={() => props.setSortOrder(1)}
                          >
                            <i>{props.sortType?.value === 1 && 'Latest'}</i>
                            <i>{props.sortType?.value === 2 && 'High'}</i>
                            <i>{props.sortType?.value === 3 && 'Latest'}</i>
                            <i>{props.sortType?.value === 4 && 'High'}</i>
                          </button>
                        </section>
                        <section>
                          <button
                            className={
                              props.tokenVesting === 0 ? 'jeyActive' : ''
                            }
                            onClick={() => props.setTokenVesting(0)}
                          >
                            <i>All NFTs</i>
                          </button>
                          <button
                            className={
                              props.tokenVesting === 1 ? 'jeyActive' : ''
                            }
                            onClick={() => props.setTokenVesting(1)}
                          >
                            <i>Rewards NFTs</i>
                          </button>
                        </section>
                      </div>

                      <section>
                        <SelectBox
                          options={props.collectionOptions}
                          value={props.collection}
                          onChange={(e: any) => props.setCollection(e)}
                          placeholder={'Collection'}
                        />
                        <SelectBox
                          options={props.saleOptions}
                          onChange={(e: any) => props.setSaleType(e)}
                          value={props.saleType}
                          placeholder={'Sale'}
                        />
                        <SelectBox
                          options={props.saleAssetOptions}
                          onChange={(e: any) => props.setSaleAsset(e)}
                          value={props.saleAsset}
                          placeholder={'Sale Asset'}
                        />
                        <SelectBox
                          options={props.rewardTokenOptions}
                          onChange={(e: any) => props.setRewardTokenType(e)}
                          value={props.rewardTokenType}
                          placeholder={'Reward Token'}
                        />
                      </section>
                    </div>
                  </section>
                </div>
                <div style={{ flex: 1 }}>
                  <div className='trendingInfo'>
                    <div className='setNewTrending'>
                      <ul>
                        {props.filteredNFTs && props.filteredNFTs.length > 0 ? (
                          props.filteredNFTs.map((postObj: any, index: any) => (
                            <Nft
                              key={index}
                              postObj={postObj}
                              index={index}
                              state={state}
                              setNftModal={setNftData}
                              handleTogglePopupClick={() =>
                                dispatch({ type: 'togglePopup' })
                              }
                              SetCurrentPost={SetCurrentPostPopup}
                            />
                          ))
                        ) : props.isLoading ? (
                          <>
                            {/* 
                                  <p
                                  className='loader'
                                  style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                  }}
                                ></p>
                                */}
                            <NftLoading />
                          </>
                        ) : (
                          <div className='noPosts'>
                            <p>No NFTs yet</p>
                          </div>
                        )}
                      </ul>
                    </div>
                    {props.pageIdentity !== 'explore' && (
                      <a className='refreshBtn' href='/nftExplore'>
                        <img src='refresh.png' alt='refresh' /> Explore More{' '}
                        <i></i>
                      </a>
                    )}
                  </div>
                  {props.loadMore && (
                    <div className='trendingInfo'>
                      <div className='setNewTrending'>
                        <ul>{props.isLoading && <NftLoading />}</ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <NftPopup
          currentPost={currentPostPopup}
          state={state}
          handleTogglePopupClick={() => [
            dispatch({ type: 'togglePopup' }),
            dispatch({ type: 'toggleInfo', value: false }),
          ]}
        />
      </div>
    </>
  );
};

export default NftTrending;
