import NftFooter from '../Nft/NftFooter';
import { useEffect, useState } from 'react';
import styles from './collections.module.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CollectionDetails } from 'hooks/queryCollections';
import { DirectusImage } from 'core/common';
import {
  queryCollectionDetail,
  queryCreatorCollections,
} from '../../api/queryCollectionDetail.api';
import CollectionHeader from './collectionHeader';
import Faqs from 'components/faq';
import MintCard from 'components/MintCard/MintCard';
import CollectionDesc from './collectionDesc/collectionDesc';
import MintCardPlaceholder from 'components/MintCard/MintCardPlaceholder';
import Button from 'components/Button';

const CollectionDetailView = () => {
  const params = useParams<any>();
  const history = useHistory();
  const [collectionDetail, setCollectionDetail] = useState<CollectionDetails>();
  const [collectionDetailLoaded, setCollectionDetailLoaded] =
    useState<boolean>(false);
  const isFans = location.pathname.startsWith('/fans');

  const [creatorCollections, setCreatorCollections] =
    useState<CollectionDetails>();
  const [creatorCollectionsLoaded, setCreatorCollectionsLoaded] =
    useState(false);

  const creator = collectionDetail?.artist;

  /****************************
   * Fetch Collection Details
   *****************************/
  useEffect(() => {
    const fetchCollectionDetail = async () => {
      const url = params.id;
      if (!collectionDetailLoaded) {
        const collectionDetail = await queryCollectionDetail({ url });
        setCollectionDetail(collectionDetail);
        setCollectionDetailLoaded(true);
      }
    };

    fetchCollectionDetail();
  }, [collectionDetailLoaded]);

  /****************************
   * Fetch Creator Collections
   *****************************/
  useEffect(() => {
    const FetchCreatorCollections = async () => {
      const username = collectionDetail?.artist.username;
      if (collectionDetailLoaded && !creatorCollectionsLoaded && username) {
        queryCreatorCollections({ username }).then(
          (creatorCollections: any) => {
            setCreatorCollections(creatorCollections);
            setCreatorCollectionsLoaded(true);
          }
        );
      }
    };

    FetchCreatorCollections();
  }, [creatorCollectionsLoaded, collectionDetailLoaded]);

  const AboutArtist = ({
    name,
    text,
    className,
  }: {
    name: string;
    text: string;
    className: any;
  }) => {
    return (
      <div className={className}>
        <div className={styles.profilePic}>
          <img
            hidden={!collectionDetail?.icon}
            src={`${DirectusImage(creator?.avatar?.id)}?fit=cover&width=240&height=240&quality=90`}
          />
        </div>
        <div style={{ width: '100%' }}>
          <p>
            <span>Meet the creator</span>
          </p>
          <h3>
            {name} <img hidden src='/collections/arrowOpen.svg' />
          </h3>
          <p>{text}</p>
        </div>
      </div>
    );
  };

  const MoreByArtist = ({ title, items }: { title: string; items: any }) => {
    return (
      <div className={styles.moreCollections} hidden={!items}>
        <h3>
          More by <span>{title}</span>
        </h3>
        <div className={styles.cards}>
          {items?.map(i => {
            return (
              <Link
                to={
                  isFans
                    ? `/fans/exploreCollection/${i.url}`
                    : `/exploreCollection/${i.url}`
                }
                className={styles.item}
              >
                <div className={styles.nameOverlay}>
                  <p>{i.name}</p>
                </div>

                <div className={styles.rainbow}>
                  <span></span>
                  <span></span>
                </div>
                <img src={`${DirectusImage(i.banner.id)}?fit=cover&width=270&height=330&quality=90`} />
              </Link>
            );
          })}
        </div>
      </div>
    );
  };

  return !collectionDetail && collectionDetailLoaded ? (
    <div className="wrapper">
      <div className={styles.notFound}>
        <div>
          <h3>Collection Not Found</h3>
          <p>Looks like you were trying to view a collection thats not yet live or dosen't exist.</p>
          <Button onClick={() => isFans ? history.push('/fans') :  history.push('/exploreCollection')}>View Collections</Button>
        </div>
      </div>
    </div>
  ) : (
    <>
      <CollectionHeader collectionDetail={collectionDetail} detailView={true} />

      {!collectionDetailLoaded ? (
        <MintCardPlaceholder id={collectionDetail?.url} />
      ) : (
        <MintCard id={collectionDetail?.url} address={collectionDetail?.address} />
      )}

      <div className='wrapper'>
        <div className={styles.collectionDetail}>
          {collectionDetail?.collection_detail?.benefits && (
            <div className={styles.detailBenefits}>
              <div className={styles.left}>
                <h3>Features & Benefits</h3>
                <p>{collectionDetail?.collection_detail?.features}</p>
              </div>
              <div className={styles.right}>
                <span>Benefits</span>
                {collectionDetail?.collection_detail?.benefits?.map(
                  (benefit: any) => {
                    return <p>{benefit?.title}</p>;
                  }
                )}
              </div>
            </div>
          )}

          <CollectionDesc info={collectionDetail} />

          <AboutArtist
            name={creator?.first_name}
            text={creator?.description}
            className={styles.AboutArtist}
          />

          <MoreByArtist
            title={creator?.first_name}
            items={creatorCollections}
          />
          <Faqs faqs={collectionDetail?.faqs} />
        </div>
      </div>
      <NftFooter />
    </>
  );
};

export default CollectionDetailView;
