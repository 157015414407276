// @ts-nocheck
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { convertMicroDenomToDenom } from '../../utils/conversion/conversion';
import { CW20 } from '../../services/cw20';
import { walletState, WalletStatusType } from '../../state/walletAtoms';
import { DEFAULT_TOKEN_BALANCE_REFETCH_INTERVAL } from '../../utils/constants';
import { unsafelyGetTokenInfo } from './useTokenInfo';
import { getQueryClient } from './useJunoConnectedWallet';

async function fetchTokenBalance({
  token: { denom, native, token_address, decimals },
  address,
}: {
  token: {
    denom?: string;
    token_address?: string;
    native?: boolean;
    decimals?: number;
  };
  address: string;
}) {
  const client = await getQueryClient();
  if (!denom && !token_address) {
    throw new Error(
      `No denom or token_address were provided to fetch the balance.`
    );
  }

  /*
   * if this is a native asset or an ibc asset that has juno_denom
   *  */
  if (native) {
    // console.log('test', address, denom);
    const coin = await client.getBalance(address, denom);
    const amount = coin ? Number(coin.amount) : 0;
    return convertMicroDenomToDenom(amount, decimals);
  }

  /*
   * everything else
   *  */
  if (token_address) {
    const balance = await CW20(client).use(token_address).balance(address);
    return convertMicroDenomToDenom(Number(balance), decimals);
  }

  return 0;
}

export const useJunoTokenBalance = (tokenSymbol: string) => {
  const { address, status, client } = useRecoilValue(walletState);
  const {
    data: balance = 0,
    refetch,
    isLoading,
  } = useQuery(
    ['tokenBalance', tokenSymbol, address],
    async ({ queryKey: [, symbol] }) => {
      if (symbol && client) {
        return await fetchTokenBalance({
          client,
          address,
          token: unsafelyGetTokenInfo(tokenSymbol) || {},
        });
      }
      return undefined;
    },
    {
      enabled: Boolean(tokenSymbol && status === WalletStatusType.connected),
      refetchOnMount: 'always',
      refetchInterval: DEFAULT_TOKEN_BALANCE_REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return { balance, isLoading, refetch };
};
