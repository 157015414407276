import Countdown, { zeroPad } from 'react-countdown';
import { LaunchPadNFT } from 'core/nftConfig';
import moment from 'moment';

const CountdownComp = ({ item }: { item: LaunchPadNFT }) => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <>
        <span>
          {days}d
          <span>:</span>
          {zeroPad(hours)}h
          <span>:</span>
          {zeroPad(minutes)}m
          <span>:</span>
          {zeroPad(seconds)}s
        </span>
      </>
    );
  };

  const currentTime = moment.tz('America/Los_Angeles').valueOf();
  const genTime = (time:string) => moment(time).add(moment(time).utcOffset(), 'minutes').valueOf();

  const whitelistStartTime = genTime(moment.tz('America/Los_Angeles').format(`${item.bookingInfo.startDate} ${item.bookingInfo.startTime}`));
  const publicMintStartTime = genTime(moment.tz('America/Los_Angeles').format(`${item.bookingInfo.publicDate} ${item.bookingInfo.publicTime}`));
  const publicMintEndTime = genTime(moment.tz('America/Los_Angeles').format(`${item.bookingInfo.endDate} ${item.bookingInfo.endTime}`));

  const beforeWhitelistMint = currentTime < whitelistStartTime;
  const isWhitelistMint = whitelistStartTime <= currentTime && currentTime < publicMintStartTime;
  const isPublicMint = publicMintStartTime <= currentTime && currentTime < publicMintEndTime;

  return (
    <>
      <div className=''>
        <div className=''>
          <Countdown
            date={publicMintEndTime}
            renderer={renderer}
          />
        </div>
      </div>
    </>
  );
};

export default CountdownComp;
