import React from 'react';
import Modal from '../Modal';

import Wait from '../../assets/images/wait.svg';
import styles from './notification.module.scss';

const Approval = ({ setTheme }: { setTheme: any }) => {
  function onClose() {
    console.log('onClose')
    setTheme(undefined, undefined);
    return;
  }

  return (
    <Modal isOpen={true} title='' onClose={onClose} zIndex={4} center>
      <div className={styles.notification_content}>
        <div className={styles.image_content}>
          <img src={Wait} alt='wait' />
          <p className={styles.title}>Waiting for approval</p>
          <div style={{ textAlign: 'center', marginBottom: '50px' }}>
            {/*Approve transaction from extension or mobile app*/}
            Approve transaction from extension
          </div>
        </div>
        <button onClick={() => onClose()}>CANCEL</button>
      </div>
    </Modal>
  );
};

export default Approval;
