import Countdown, { zeroPad } from 'react-countdown';
import { LaunchPadNFT } from 'core/nftConfig';
import moment from 'moment-timezone';

const CountdownComp = ({ launchpadNFT }: { launchpadNFT: LaunchPadNFT }) => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <>
        <h6>
          {days}
          <span>:</span>
          {zeroPad(hours)}
          <span>:</span>
          {zeroPad(minutes)}
          <span>:</span>
          {zeroPad(seconds)}
        </h6>
        <p className='time_detail'>
          Days
          <span></span>
          hours
          <span></span>
          minutes
          <span></span>
          seconds
        </p>
      </>
    );
  };
  
  const currentTime = moment.tz('America/Los_Angeles').valueOf();
  const genTime = (time:string) => moment(time).add(moment(time).utcOffset(), 'minutes').valueOf();

  const whitelistStartTime = genTime(moment.tz('America/Los_Angeles').format(`${launchpadNFT.launchInfo.startDate} ${launchpadNFT.launchInfo.startTime}`));
  const publicMintStartTime = genTime(moment.tz('America/Los_Angeles').format(`${launchpadNFT.launchInfo.publicDate} ${launchpadNFT.launchInfo.publicTime}`));
  const publicMintEndTime = genTime(moment.tz('America/Los_Angeles').format(`${launchpadNFT.launchInfo.endDate} ${launchpadNFT.launchInfo.endTime}`));

  const beforeWhitelistMint = currentTime < whitelistStartTime;
  const isWhitelistMint = whitelistStartTime <= currentTime && currentTime < publicMintStartTime;
  const isPublicMint = publicMintStartTime <= currentTime && currentTime < publicMintEndTime;

  return (
    <>
      <div className='header' style={{ marginBottom: '40px' }}>
        <div className='time'>
          <p>
            {beforeWhitelistMint
              ? 'MINT IN'
              : isWhitelistMint
              ? 'ENDS IN'
              : isPublicMint
              ? 'ENDS IN'
              : 'MINT ENDED'}
          </p>
          {/* {(beforeWhitelistMint || isWhitelistMint || isPublicMint) && ( */}
          <Countdown
            date={
              beforeWhitelistMint
                ? whitelistStartTime
                : isWhitelistMint
                ? publicMintStartTime
                : isPublicMint
                ? publicMintEndTime
                : new Date()
            }
            renderer={renderer}
          />
          {/* )} */}
        </div>
        {isWhitelistMint && (
          <span className='status_message'>
            Public mint will go live after allow list(s)
          </span>
        )}
      </div>
    </>
  );
};

export default CountdownComp;
