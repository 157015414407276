import axios from 'axios';
export async function FetchCollections({
  sortType,
  sortOrder,
  nftType,
  rewardToken,
  platform,
}: {
  sortType: string;
  sortOrder: string;
  nftType: string;
  rewardToken: any;
  platform: string;
}) {
  const result = await axios.post(
    `${process.env.REACT_APP_BACKEND_API}collections`,
    {
      sortType,
      sortOrder,
      nftType,
      rewardToken,
      platform,
    }
  );
  return result.data;
}

export async function FetchCollectionAddress({ url }: { url: string }) {
  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}collectionByUrl/${url}`
  );
  return result.data;
}
