import { LaunchPadNFT, networkType, nftConfig, TICKET_COLLECTIONS } from 'core/nftConfig';
import { useJunoTokenBalance } from 'hooks/newHooks/useTokenBalance';
import useLaunchPadNFTMinterQuery from 'hooks/useLaunchPadNFTMinterQuery';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { walletState } from 'state/walletAtoms';
import { formatNumberString } from 'utils/conversion/conversion';
import CountdownComp from './countdown';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const WebAppEvent = ({ item }: { item: LaunchPadNFT }) => {
  const { address } = useRecoilValue(walletState);
  const queryResult = useLaunchPadNFTMinterQuery(
    networkType,
    item?.bookingInfo.MINTER,
    address
  );
  const { balance } = useJunoTokenBalance('USDC') || { balance: 0 };
  const totalMinted      =
  queryResult?.tier_infos.reduce(
    (res, cur) => res + parseInt(cur.current_supply),
    0
  ) || 0;
  const totalMintRemains =
    (queryResult?.mintConfig.nft_max_supply || 0) -
    (queryResult?.mintConfig.nft_current_supply || 0);

  const eventLocalData = TICKET_COLLECTIONS.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  const demoAccount = !address || address === 'juno1l4cmryk9q06pm54g89rdxxazyst6p39k4y7v4e';


  return (
    <Link
      to={`/webapp/ticket/${item?.project_slug}`}
      className={balance < 0 ? 'disabled' : ''}
    >
      <div className='webapp_launchLiveSlider'>
        <div
          className='sliderBlock'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
          }}
        >
          <div 
            className='daynamicSlider'
            style={{ borderRadius: '20px' }}>
            <div>
              <img
                src={'https://loop-markets.directus.app/assets/' + item?.banner.id}
                alt='rect'
              />
              <div
                className='image_overlay'
              />
            </div>
            {item.project_status == 'live' && (
              <div className='webapp_project'>
                <div className='project_left'>
                    <div className="date">
                      <span className="day">{new Date(item.bookingInfo.startDate).getUTCDate()}</span>
                      <span className="month">{monthNames[new Date(item.bookingInfo.startDate).getUTCMonth()]}</span> 
                    </div>
                </div>
                <div className='project_right'>
                  <p>{eventLocalData[0].time} onwards</p>
                  <span>{eventLocalData[0].location}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='webapp_launchLiveSliderInner'>
          <div className='details'>
            <h6>{item.ticket_name}</h6>
            <p className="divider_right">
              {item.project_status == 'live' ? (
                <>
                  <img src="/m_clock.svg" /> Mint Ends: <b className="highlight"><CountdownComp item={item} /></b> | {!demoAccount ? <p>Tickets left: <b className="highlight">{formatNumberString(totalMintRemains)}</b></p> : <p>Total Tickets: <b className="highlight">{formatNumberString(item.bookingInfo.maxSupply)}</b></p>}
                </>
            ) : (
                <>
                  <img src="/m_clock_soon.svg" /> <b className="highlight_soon">Mint starts soon</b>
                </>
            )}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default WebAppEvent;
