import axios from 'axios';
import { Dec } from '@terra-money/terra.js';
import { IPFS_GATEWAY } from 'core/common';
import { Network } from 'core/constants';
import { MintConfigCosmWasm } from './useLaunchPadNFTMinterQuery';
import { nftConfig } from 'core/nftConfig';
import {
  LaunchpadNFTMetadata,
  LaunchPadNFTStakeInfo,
} from './useEligibleStakeNFTs';
import { getQueryClient } from './newHooks/useJunoConnectedWallet';
import { LaunchPadNFTQueryInfo } from './queryLaunchpadNFTInfo';

export const queryCosmoverseNftInfo = async (
  networkType: Network,
  nft: string,
  tokenId: string,
  address = ""
) => {
  const client = await getQueryClient();
  // query launch pad nft info
  const _info = (await client.queryContractSmart(nft, {
    nft_info: {
      token_id: tokenId,
    },
  })) as {
    extension: {
      tier_index: string;
      token1_addr: string;
      token1_amount: string;
      token2_addr: string;
      token2_amount: string;
      vesting_period: string;
    };
    token_uri: string;
  };

  const owner_of_ = (await client.queryContractSmart(nft, {
    owner_of: {
      token_id: tokenId,
    },
  })) as {
    owner: string;
  };

  const info = {
    owner: owner_of_.owner,
    tier_index: _info.extension.tier_index,
    token1_addr: _info.extension.token1_addr,
    token1_amount: _info.extension.token1_amount,
    token2_addr: _info.extension.token2_addr,
    token2_amount: _info.extension.token2_amount,
    vesting_period: _info.extension.vesting_period,
    token_uri: _info.token_uri,

  };
  console.log('info', info);

  // query nft configuration data
  const nftContractInfo = (await client.queryContractSmart(nft, {
    contract_info: {},
  })) as {
    royalty_bps: number[];
  };

  // query nft mint info
  const minterAddress =
    nftConfig[networkType].launchPadNFTs.find(item => item.NFT === nft)
      ?.MINTER ?? '0';
  const mintConfig = (await client.queryContractSmart(minterAddress, {
    config: {},
  })) as MintConfigCosmWasm;
  console.log('mintConfig', mintConfig);


  // query nft staking info
  const stakingAddress = nftConfig[networkType].STAKING;
  const stakedTokenIds = (await client.queryContractSmart(stakingAddress, {
    token_ids: {
      addr: address,
      nft_contract_addr: nft,
    },
  })) as string[];
  console.log('stakedTokenIds', stakedTokenIds);

  if (stakedTokenIds.includes(tokenId)) {
    info.owner = address;
  }

  const stakeInfo = (await client.queryContractSmart(stakingAddress, {
    stake_info: {
      nft_contract_addr: nft,
      nft_token_id: tokenId,
    },
  })) as LaunchPadNFTStakeInfo;
  console.log('stakeInfo', stakeInfo);

  // query nft metadata
  const metadata: LaunchpadNFTMetadata = await axios
    .get(IPFS_GATEWAY + info?.token_uri.replace(':/', ''))
    .then(response => response.data as LaunchpadNFTMetadata);

  const auctionAddress = nftConfig[networkType].AUCTION;
  const saleAddress = nftConfig[networkType].SALE;
  return {
    mintConfig,
    info,
    stakeInfo,
    metadata,
    auctionInfo: undefined,
    saleInfo: undefined,
    daysStaked: 0,
    daysRemain: 0,
    dailyValue1: new Dec('0'),
    totalValue1: new Dec('0'),
    dailyValue2: new Dec('0'),
    totalValue2: new Dec('0'),
    royalty_bp: nftContractInfo.royalty_bps
      ? nftContractInfo.royalty_bps.reduce((acc, cur) => acc + cur, 0)
      : 0,
    contracts: {
      nft: nft,
      nftStaking: stakingAddress,
      nftAuction: auctionAddress,
      nftSale: saleAddress,
    },
    isInStaking: stakeInfo.is_staking,
  } as LaunchPadNFTQueryInfo;
};
