import {useState} from "react";

const useCopyAddress = (address: string) => {
    const [copied, setCopied] = useState(false)
    const reset = () => setCopied(false)

    const copy = async () => {
        try {
            await navigator.clipboard.writeText(address)
            setCopied(true)
            setTimeout(() => setCopied(false), 1000)
        } catch (error) {
            console.error(error)
        }
    }

    return { copy, copied, reset }
}
export default useCopyAddress