import axios from 'axios';
const hostname = window.location.hostname;
const checkHost = hostname === 'nft-juno.loop.markets';

export async function generatePaymentUrl({
  wallet_addr,
  launchpad_id,
  collection_name,
  payment_amount,
}: {
  wallet_addr: string;
  launchpad_id: number;
  collection_name: string;
  payment_amount: number;
}) {
  const result = await axios.post(
    `${process.env.REACT_APP_BACKEND_API}wompi/generate_paymentid`,
    {
      wallet_addr: wallet_addr,
      launchpad_id: launchpad_id,
      collection_name: collection_name,
      payment_amount: payment_amount,
    }
  );
  const id = result.data?.data?.id;
  console.log(id)
  const url = `https://checkout.wompi.co/l/${id}`;
  return id ? url : null;
}

export async function FetchLaunchPadLiveList() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/fans_launchpad?filter[project_status][_eq]=live&sort[]=-date_created&fields=*.*`
  );
  return result.data.data;
}

export async function FetchLaunchPadUpcoming() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/fans_launchpad?filter[project_status][_eq]=upcoming&sort=launchInfo.startDate&fields=*.*`
  );
  return result.data.data;
}

export async function FetchLaunchPad(name: string) {
  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}fans/fans_launchpad/${name}`
  );
  return result.data;
}

export async function FetchQC() {
  if (checkHost) {
    const result = await axios.get(
      `https://loop-markets.directus.app/items/fans_launchpad?filter[status][_eq]=qc&sort=launchInfo.startDate&fields=*.*`
    );
    return result.data.data;
  } else {
    const result = await axios.get(
      `https://loop-markets.directus.app/items/fans_launchpad?filter[status][_eq]=qc&sort=launchInfo.startDate&fields=*.*`,
      {
        headers: {
          Authorization:
            'Bearer ' +
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE5MTQ1MjNiLWZjYzQtNGI3Yy05ODdiLTEwYTMyZTQyYjExYSIsInJvbGUiOiJmMWY0MTYzNi0zMmM4LTRjZDYtYWRkNi0wYTc3YWIxZjdjNzciLCJhcHBfYWNjZXNzIjp0cnVlLCJhZG1pbl9hY2Nlc3MiOmZhbHNlLCJpYXQiOjE2NzE2MTY0ODgsImV4cCI6MTY3MTYxNzM4OCwiaXNzIjoiZGlyZWN0dXMifQ.oFD5Bq-SFGJTUNfeq1nr7Cpd6cDOCQ4N21piIM7e88Q',
        },
      }
    );
    return result.data.data;
  }
}

export async function FetchTicketBooking() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/ticket_booking?sort[]=-date_created&fields=*.*`
  );
  return result.data.data;
}

export async function FetchTicket(name: string) {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/ticket_booking?filter[project_slug][_eq]=${name}&fields=*.*`
  );
  return result.data.data[0];
}

export async function FetchTicketData(name: string) {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/ticket_booking?filter[project_slug][_eq]=${name}&fields=project_slug,bookingInfo.*,banner.id`
  );
  return result.data.data[0];
}

export async function fetchHotCollections() {
  const result = await axios.get(
    `https://loop-markets.directus.app/items/nft_collections`
  );
  return result.data.data;
}

export async function FetchJunoTokenUnitPrice() {
  const result = await axios.get(
    `https://middlewareapi.loop.markets/v1/juno/tokenInfo`
  );
  return result?.data;
}
